import { gql } from "@apollo/client";

import { CART_FIELDS, CART_OPTIMISED_FIELDS } from "@/graphql/queries/getCart";

const generateRemoveAlcoholicBeveragesFromCartMutation = (
  operationName,
  mutationName,
  cartFragment,
) => gql`
  mutation ${operationName} {
    ${mutationName}(input: {}) {
      ...CartFields
    }
  }
  ${cartFragment}
`;
export const REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_MUTATION =
  generateRemoveAlcoholicBeveragesFromCartMutation(
    "RemoveAlcoholicBeveragesFromCart",
    "removeAlcoholicBeveragesFromCart",
    CART_FIELDS,
  );
export const REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_OPTIMISED_MUTATION =
  generateRemoveAlcoholicBeveragesFromCartMutation(
    "RemoveAlcoholicBeveragesFromCartOptimised",
    "removeAlcoholicBeveragesFromCartOptimised",
    CART_OPTIMISED_FIELDS,
  );
