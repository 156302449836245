import { useContext } from "react";

import { useApolloClient } from "@apollo/client";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";

import CartContext from "@/components/Cart/cartContext";
import constants from "@/graphql/utils/constants";
import useAnalytics from "@/hooks/useAnalytics";
import {
  INTRO_MODAL_DISMISSED,
  PRODUCT_CONFIGURATION,
  REFERRAL_CODE,
} from "@/utils/constants";
import getUrlParams from "@/utils/getUrlParams";

export default function useAuth(location) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { analytics, events } = useAnalytics();
  const outletQrHash = localStorage.getItem(constants.QR_HASH);
  const { refetchCart } = useContext(CartContext);
  const posthog = usePostHog();

  const menuPath = outletQrHash
    ? `/qr/${outletQrHash}`
    : window.dinerManifest?.menuOnly
    ? "/"
    : "/menu";

  const redirectOnLogin = () => {
    if (location) {
      const params = getUrlParams(location);
      if (params?.redirect) {
        return navigate(params.redirect, { replace: true });
      }
    }

    if (localStorage.getItem(REFERRAL_CODE)) {
      return navigate("/account/referrals", { replace: true });
    } else {
      return navigate(menuPath, { replace: true });
    }
  };

  const setTokens = ({
    accessToken,
    refreshToken,
    redirect = true,
    delay = 0,
  }) => {
    localStorage.setItem(constants.ACCESS_TOKEN, accessToken);
    localStorage.setItem(constants.REFRESH_TOKEN, refreshToken);

    localStorage.removeItem(constants.ALCOHOL_AGE_VERIFIED);

    refetchCart();

    if (redirect) {
      if (delay) {
        setTimeout(() => redirectOnLogin(), delay);
      } else {
        redirectOnLogin();
      }
    }
  };

  const logout = () => {
    localStorage.removeItem(constants.ACCESS_TOKEN);
    localStorage.removeItem(constants.RESET_TOKEN);
    localStorage.removeItem(constants.REFRESH_TOKEN);
    localStorage.removeItem(constants.X_CLIENT_UUID);
    localStorage.removeItem(constants.ALCOHOL_AGE_VERIFIED);
    localStorage.removeItem(REFERRAL_CODE);
    localStorage.removeItem(INTRO_MODAL_DISMISSED);
    localStorage.removeItem(PRODUCT_CONFIGURATION);
    client.resetStore();
    analytics.track(events.logout);
    window.location.href = menuPath;
    posthog.reset();
    return;
  };

  const loggedIn = () => !!localStorage.getItem(constants.ACCESS_TOKEN);

  return { setTokens, logout, loggedIn };
}
