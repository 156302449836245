import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { compare } from "compare-versions";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from "virtual:pwa-register/react";

import getVersionInfoFromTag from "@/utils/getVersionInfoFromTag";

import styles from "./ReloadPrompt.module.less";
import Button from "../Button";

const REFRESH_CHECK_INTERVAL = 60 * 1000; // 1 min

const UPDATE_TYPES = {
  MANDATORY: "mandatory",
  OPTIONAL: "optional",
};
const APP_TYPE = "dinerApp";

const ReloadPrompt = () => {
  const { t } = useTranslation();
  const [visibleUpdateType, setVisibleUpdateType] = useState();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegistered(r) {
      r &&
        setInterval(() => {
          r.update();
        }, REFRESH_CHECK_INTERVAL);
    },
  });

  const handleDismiss = () => {
    setNeedRefresh(false);
  };

  const handleReload = () => {
    updateServiceWorker(true);
    setTimeout(() => {
      setVisibleUpdateType(null);
    }, 500);
  };

  useEffect(() => {
    if (needRefresh) {
      fetch(`${import.meta.env.VITE_ACCOUNT_GRAPH_URL}/app-versions`)
        .then((response) => response.json())
        .then((data) => {
          const mandatoryTag = data[APP_TYPE];
          const currentTag = import.meta.env.VITE_APP_RELEASE_VERSION;

          if (mandatoryTag && currentTag) {
            // Mandatory version from configs
            const {
              version: mandatoryVersion,
              versionEnvironment: mandatoryVersionEnvironment,
            } = getVersionInfoFromTag(mandatoryTag);

            // Current version from GIT tag
            const {
              version: currentVersion,
              versionEnvironment: currentVersionEnvironment,
            } = getVersionInfoFromTag(currentTag);

            if (
              mandatoryVersionEnvironment === currentVersionEnvironment &&
              compare(mandatoryVersion, currentVersion, ">=")
            ) {
              setVisibleUpdateType(UPDATE_TYPES.MANDATORY);
            } else {
              setVisibleUpdateType(UPDATE_TYPES.OPTIONAL);
            }
          } else {
            setVisibleUpdateType(UPDATE_TYPES.OPTIONAL);
          }
        })
        .catch(() => {
          setVisibleUpdateType(UPDATE_TYPES.OPTIONAL);
        });
    } else {
      setVisibleUpdateType(null);
    }
  }, [needRefresh]);

  if (!visibleUpdateType) return null;

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 bg-black bg-opacity-50 z-40",
        {
          "w-full h-screen flex justify-center items-center":
            visibleUpdateType === UPDATE_TYPES.MANDATORY,
        },
      )}
    >
      <div
        className={classNames(
          "rounded-lg p-3 md:max-w-[280px] shadow-lg",
          styles.wrapper,
          {
            "fixed z-40 bottom-5 left-6 right-6 md:right-auto":
              visibleUpdateType === UPDATE_TYPES.OPTIONAL,
          },
        )}
      >
        <h3 className="font-bold text-base mb-3">
          {t(
            `reloadPrompt.${
              visibleUpdateType === UPDATE_TYPES.MANDATORY
                ? "mandatory"
                : "optional"
            }.title`,
          )}
        </h3>
        <p className="mb-3">{t("reloadPrompt.optional.subTitle")}</p>
        <div className="flex gap-3">
          {visibleUpdateType === UPDATE_TYPES.OPTIONAL ? (
            <Button onClick={() => handleDismiss()} className="flex-1">
              {t("reloadPrompt.optional.cancelText")}
            </Button>
          ) : (
            <div className="flex-1"></div>
          )}

          <Button
            onClick={() => handleReload()}
            type="primary"
            className="flex-1"
          >
            {t(
              `reloadPrompt.${
                visibleUpdateType === UPDATE_TYPES.MANDATORY
                  ? "mandatory"
                  : "optional"
              }.okText`,
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReloadPrompt;
