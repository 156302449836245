import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Button from "@/common/Button";
import Image from "@/common/Image";
import MenuItem from "@/components/Menu/MenuItem";
import useAnalytics from "@/hooks/useAnalytics";

import { TEMPLATE_TYPES } from "./PageTemplateSections";

DynamicColumnsTemplate.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sectionRef: PropTypes.object,
  rest: PropTypes.any,
};

export default function DynamicColumnsTemplate({
  section,
  index,
  sectionRef,
  ...rest
}) {
  const { analytics, events } = useAnalytics();
  return (
    <div
      ref={sectionRef}
      className={`${index % 2 === 1 ? "" : "bg-default2"} ${
        section?.fullWidth ? "" : "py-12"
      }`}
      style={section?.style}
      {...rest}
    >
      <div className="px-3 mx-auto max-w-screen-xl sm:px-6">
        {section?.header && (
          <div
            className={`${
              section?.beforeItems ? "mb-6" : "mb-10"
            } text-3xl font-display`}
          >
            {section?.header}
          </div>
        )}
        {section?.beforeItems && (
          <div
            className="mb-6"
            dangerouslySetInnerHTML={{ __html: section.beforeItems }}
          />
        )}
        <div
          className={`grid sm:grid-cols-${section?.items?.length || 1} gap-6 ${
            section.type === TEMPLATE_TYPES.BESTSELLERS ? "text-left" : ""
          }`}
        >
          {section?.items?.map((item, i) => {
            if (section.type === TEMPLATE_TYPES.BESTSELLERS) {
              return (
                <MenuItem
                  key={`bestseller_${i}`}
                  isPreview
                  item={item}
                  {...item}
                />
              );
            }
            const Component = item.href ? "a" : "div";
            const componentProps = item.href
              ? {
                  href: item.href,
                  target: item.target,
                  rel: item.target === "_blank" ? "noreferrer" : null,
                }
              : {};
            return (
              <Component
                key={`section_${index}_item_${i}`}
                className={`flex items-center justify-center w-full ${
                  item?.className ?? ""
                }`}
                style={item?.style}
                {...componentProps}
              >
                <div
                  className={
                    item?.equalize
                      ? "flex flex-col justify-between h-full"
                      : null
                  }
                >
                  {item?.image && (
                    <Image
                      className="mb-3"
                      src={item.image?.src}
                      style={item.image?.style}
                    />
                  )}
                  {item?.label && (
                    <div
                      className="mb-3 text-lg font-bold text-default"
                      dangerouslySetInnerHTML={{ __html: item?.label }}
                    />
                  )}
                  {item?.description && (
                    <div
                      className={`text-default ${
                        item?.equalize
                          ? `flex flex-col justify-between ${
                              item?.label ? "" : "h-full"
                            }`
                          : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  )}
                </div>
              </Component>
            );
          })}
        </div>
        {section?.afterItems && (
          <div
            className="mt-6"
            dangerouslySetInnerHTML={{ __html: section.afterItems }}
          />
        )}
        {section?.cta && (
          <div className="mt-12">
            <Link to="/menu">
              <Button
                type="primary"
                onClick={() =>
                  analytics.track(events.requested_menu_from_best_sellers)
                }
              >
                {section?.cta}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
