import React from "react";

import ChevronDownIcon from "@heroicons/react/outline/ChevronDownIcon";
import { Empty } from "antd";
import PropTypes from "prop-types";

import Input from "@/common/Input";

import AntSelect from "./AntSelect";

export { Empty } from "antd";

import "./Select.less";

Select.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  selectStyle: PropTypes.object,
  /** Determine whether the dropdown menu and the select input are the same width. Default set min-width same as input. Will ignore when value less than select width. false will disable virtual scroll */
  dropdownMatchSelectWidth: PropTypes.bool,
  /** Built on top of Select component from Ant Design. See API at https://ant.design/components/select/#API. */
  rest: PropTypes.any,
};

Select.defaultProps = {
  dropdownMatchSelectWidth: false,
};

export default function Select({
  options,
  className,
  selectStyle,
  dropdownMatchSelectWidth,
  ...rest
}) {
  let selectKlass =
    "custom-select flex items-center block w-full bg-button-default sm:text-sm rounded-sm shadow-sm border border-default focus:ring-primary focus:border-primary";
  if (className) {
    selectKlass += ` ${className}`;
  }
  let iconKlass = "h-5 w-5 text-on-button-default";
  iconKlass += rest.disabled ? " opacity-40" : "";

  function renderOptions(options, showDivider) {
    return options.map((option, index) => {
      if (Array.isArray(option?.options)) {
        const { options: subOptions, ...rest } = option;
        if (Object.keys(rest).length > 0) {
          return (
            <AntSelect.OptGroup
              key={`option-${option?.value ?? option}-${index}`}
              {...rest}
            >
              {renderOptions(subOptions)}
            </AntSelect.OptGroup>
          );
        }

        return renderOptions(subOptions, index !== 0);
      }

      if (typeof option !== "object") {
        // convert into an object to be processed below
        option = { label: option, value: option };
      }

      const { label, renderLabel, ...props } = option;
      if (showDivider && index === 0) {
        const baseKlass = "border-t border-gray-200";
        if (props.className) {
          props.className = `${baseKlass} ${props.className}`;
        } else {
          props.className = baseKlass;
        }
      }
      return (
        <AntSelect.Option key={label} {...props}>
          {renderLabel ? renderLabel() : label}
        </AntSelect.Option>
      );
    });
  }

  function renderReadOnlyInput() {
    let selectedOption = options.find((option) => {
      if (typeof option !== "object") {
        return option === rest.value;
      }
      return option.value === rest.value;
    });
    if (typeof selectedOption !== "object") {
      // convert into an object to be processed below
      selectedOption = { label: selectedOption, value: selectedOption };
    }
    return <Input value={selectedOption?.label ?? ""} readOnly />;
  }

  return rest.readOnly ? (
    renderReadOnlyInput()
  ) : (
    <div className={selectKlass} style={selectStyle}>
      <AntSelect
        className="w-full"
        dropdownClassName="border-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        bordered={false}
        suffixIcon={<ChevronDownIcon className={iconKlass} />}
        showSearch={true}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
        }
        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {...rest}
      >
        {renderOptions(options)}
      </AntSelect>
    </div>
  );
}
