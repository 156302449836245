import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import ConfigContext from "@/components/Config/configContext";
import useAuth from "@/hooks/useAuth";
import constants from "@/utils/constants";

export default function AccountNavigation() {
  const { t } = useTranslation();
  const location = useLocation();

  const navigationTabs = useAccountNavigations();

  function isSelectedNav(nav) {
    return location.pathname === nav.link;
  }

  return (
    <div className="flex flex-col pb-3 overflow-y-auto bg-default sm:pb-4">
      <h1 className="mb-6 text-3xl text-center font-display sm:text-left">
        {t("account.header")}
      </h1>
      <div className="flex-grow">
        <nav
          className="flex overflow-x-auto space-x-9 sm:block sm:space-x-0 sm:space-y-4"
          aria-label="Sidebar"
        >
          {navigationTabs
            .filter((navigationTab) => navigationTab.link)
            .map((navigationTab, index) => (
              <Link
                key={index}
                to={navigationTab.link}
                className={
                  "text-default hover:text-default border-transparent hover:border-primary border-b-2 pb-3 text-sm whitespace-nowrap sm:border-b-0 sm:border-l-2 sm:pb-0 sm:pl-3 sm:block sm:py-0.5" +
                  (isSelectedNav(navigationTab)
                    ? " font-bold border-primary"
                    : "")
                }
              >
                {navigationTab.label}
              </Link>
            ))}
        </nav>
      </div>
    </div>
  );
}

export function useAccountNavigations() {
  const { logout, loggedIn } = useAuth();
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const isReferralsProgramEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.ENABLE_REFERRALS,
  );

  let navigationTabs = [
    {
      label: t("account.navigation.orders"),
      link: "/account/orders",
    },
    {
      label: t("account.navigation.profile"),
      link: "/account/profile",
    },
    {
      label: t("account.navigation.addresses"),
      link: "/account/addresses",
    },
    // TODO: Enable when the feature is ready
    // {
    //   label: t("account.navigation.paymentMethods"),
    //   link: "/account/payment-methods",
    // },
  ];

  // Merge referral tabs into account navigation if feature is enabled
  if (isReferralsProgramEnabled) {
    navigationTabs = navigationTabs.concat([
      {
        label:
          configQuery?.referral?.sidebarLabel ||
          t("account.navigation.referrals"),
        link: "/account/referrals",
      },
      {
        label: t("account.navigation.promoCodes"),
        link: "/account/promo-codes",
      },
    ]);
  }

  if (
    configQuery?.config?.pointProgram?.label &&
    configQuery?.config?.pointProgram.currencyValueInCents > 0
  ) {
    navigationTabs = navigationTabs.concat([
      {
        label:
          configQuery?.config?.pointProgram?.label ||
          t("account.navigation.referrals"),
        link: "/account/loyalty",
      },
    ]);
  }

  if (loggedIn()) {
    return navigationTabs.concat([
      { label: t("navbar.logout"), onClick: logout, dropdownOnly: true },
    ]);
  } else {
    return [
      { label: t("navbar.signup"), link: "/signup", dropdownOnly: true },
      { label: t("navbar.login"), link: "/login", dropdownOnly: true },
    ];
  }
}
