import React, { useState, useEffect } from "react";

import { Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../Button";

import "./ScrollToTop.less";

const ScrollToTop = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [additionalClass, setAdditionalClass] = useState(null);
  const element = document.getElementsByClassName("fullscreen");
  /* the element will be visible only if user scrolled down the page more than 300px
     which takes reference from the current mobile banner height */
  const MOBILE_BANNER_HEIGHT = 300;

  const toggleVisible = () => {
    const scrolled = element[0].scrollTop;
    if (scrolled > MOBILE_BANNER_HEIGHT) {
      setVisible(true);
    } else if (scrolled <= MOBILE_BANNER_HEIGHT) {
      setVisible(false);
    }

    // move up if mobile navigation for sub-sections is visible
    const targetContainers = document.querySelectorAll(
      ".mobile-sub-sections-floating-nav",
    );
    const targetContainer = targetContainers[targetContainers.length - 1];
    if (
      targetContainer &&
      targetContainer.clientHeight > 0 &&
      targetContainer.classList.contains("opacity-100")
    ) {
      setAdditionalClass("mb-8");
    } else {
      setAdditionalClass(null);
    }
  };

  const scrollToTop = () => {
    const element = document.getElementsByClassName("fullscreen");
    element[0]?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    element[0]?.addEventListener("scroll", toggleVisible);

    return () => element[0]?.addEventListener("scroll", toggleVisible);
  }, []);

  return (
    <Transition
      className="z-40"
      show={visible}
      enter="ease-in-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      unmount={false}
    >
      <Button
        className={classNames(
          "transition-all",
          "px-2 text-xs rounded-md border-transparent bg-primary hover:bg-primary-dark btn-scroll center text-on-primary",
          additionalClass,
        )}
        onClick={scrollToTop}
        style={{ display: "inline" }}
      >
        <ChevronUpIcon className="-mt-2.5 -mb-1" /> {t("scroll.top")}
      </Button>
    </Transition>
  );
};

export default ScrollToTop;
