import { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { CheckCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";

import Modal from "@/common/Modal";
import PoweredByAtlasId from "@/common/PoweredByAtlasId";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import { REQUEST_LOGIN_OTP_MUTATION } from "@/graphql/mutations/requestLoginOtp";
import { USER_LOGIN_WITH_OTP_OR_SSO_MUTATION } from "@/graphql/mutations/userLoginWithOtpOrSso";
import constants from "@/utils/constants";

const OTP_LENGTH = 6;

const LOCAL_NOTIFICATION_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};

OtpModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  isSignUp: PropTypes.bool,
  isVerify: PropTypes.bool,
  email: PropTypes.string,
  maskedEmail: PropTypes.string,
  phone: PropTypes.string,
  maskedPhone: PropTypes.string,
  ssoToken: PropTypes.string,
  loginSource: PropTypes.string,
  initialOtpDeliveryMode: PropTypes.string,
  newNumber: PropTypes.string,
  errorMessage: PropTypes.string,
  handleLogin: PropTypes.func,
};

export default function OtpModal({
  visible,
  closeModal,
  isSignUp,
  isVerify,
  email,
  maskedEmail,
  phone,
  maskedPhone,
  ssoToken,
  loginSource,
  initialOtpDeliveryMode,
  newNumber,
  errorMessage,
  handleLogin,
}) {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);

  const [formDisabled, setFormDisabled] = useState(false);
  const [isOtpVerified, setOtpVerified] = useState(false);
  const [lastOtpDeliveryMode, setLastOtpDeliveryMode] = useState(null);
  const [localNotification, setLocalNotification] = useState(null);
  const enableCorsCredentialsForOtpRequest =
    configQuery?.config?.features?.includes(
      constants.FEATURES.ENABLE_CORS_CREDENTIALS_FOR_OTP_REQUEST,
    );

  const [otp, setOtp] = useState("");

  const [requestLoginOtp, { loading: requestLoginOtpLoading }] = useMutation(
    REQUEST_LOGIN_OTP_MUTATION,
    {
      context: {
        graph: "diners",
        credentials: enableCorsCredentialsForOtpRequest
          ? constants.CORS_CREDENTIALS.INCLUDE
          : constants.CORS_CREDENTIALS.OMIT,
      },
      onCompleted: (data) => {
        setLastOtpDeliveryMode(data?.requestLoginOtp?.lastOtpDeliveryMode);
        setLocalNotification({
          type: LOCAL_NOTIFICATION_TYPES.SUCCESS,
          message:
            data?.requestLoginOtp?.lastOtpDeliveryMode ===
            constants.OTP_CHANNELS.SMS
              ? t(
                  lastOtpDeliveryMode === constants.OTP_CHANNELS.SMS
                    ? "otpModal.codeResentToSms"
                    : "otpModal.codeSentToSms",
                  { phone: phone || maskedPhone },
                )
              : t(
                  lastOtpDeliveryMode === constants.OTP_CHANNELS.EMAIL
                    ? "otpModal.codeResentToEmail"
                    : "otpModal.codeSentToEmail",
                  { email: email || maskedEmail },
                ),
        });
      },
      onError: (error) => {
        if (error?.message) {
          setLocalNotification({
            type: LOCAL_NOTIFICATION_TYPES.ERROR,
            message: error.message,
          });
        }
      },
    },
  );

  const [userLoginWithOtpOrSso, { loading: userLoginWithOtpOrSsoLoading }] =
    useMutation(USER_LOGIN_WITH_OTP_OR_SSO_MUTATION, {
      context: { graph: "diners" },
      onCompleted: (data) => {
        setOtpVerified(true);
        setFormDisabled(true);

        if (data?.userLoginWithOtpOrSso) {
          if (handleLogin) {
            handleLogin(data?.userLoginWithOtpOrSso);
          }
        }
      },
      onError: (error) => {
        if (error?.message) {
          setLocalNotification({
            type: LOCAL_NOTIFICATION_TYPES.ERROR,
            message: error.message,
          });
        }
      },
    });

  useEffect(() => {
    if (visible) {
      setLocalNotification(null);
    }
  }, [visible]);

  useEffect(() => {
    setLastOtpDeliveryMode(initialOtpDeliveryMode);
  }, [initialOtpDeliveryMode]);

  useEffect(() => {
    if (errorMessage) {
      setLocalNotification({
        type: LOCAL_NOTIFICATION_TYPES.ERROR,
        message: errorMessage,
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      userLoginWithOtpOrSso({
        variables: {
          input: {
            email,
            mobileNumber: phone,
            newMobileNumber: newNumber,
            otp,
            ssoToken,
            loginSource,
          },
        },
      });
    }
  }, [email, phone, otp]);

  useEffect(() => {
    if (email && ssoToken) {
      userLoginWithOtpOrSso({
        variables: {
          input: {
            email,
            mobileNumber: null,
            otp: null,
            ssoToken,
            loginSource,
          },
        },
      });
    }
  }, [email, ssoToken]);

  const requestOtp = (deliveryMode) => {
    setOtp("");
    setLastOtpDeliveryMode(deliveryMode);

    requestLoginOtp({
      variables: {
        input: {
          email,
          mobileNumber: phone,
          deliveryMode,
          loginSource,
          isResend: true,
        },
      },
    });
  };

  const renderSendToSms = () => (
    <a
      className="cursor-pointer text-link hover:text-link"
      onClick={() => {
        requestOtp(constants.OTP_CHANNELS.SMS);
      }}
    >
      {t(
        lastOtpDeliveryMode === constants.OTP_CHANNELS.SMS
          ? "otpModal.resendToSms"
          : "otpModal.sendToSms",
        { phone: phone || maskedPhone },
      )}
    </a>
  );

  const renderSendToEmail = () => (
    <a
      className="cursor-pointer text-link hover:text-link"
      onClick={() => {
        requestOtp(constants.OTP_CHANNELS.EMAIL);
      }}
    >
      {t(
        lastOtpDeliveryMode === constants.OTP_CHANNELS.EMAIL
          ? "otpModal.resendToEmail"
          : "otpModal.sendToEmail",
        { email: email || maskedEmail },
      )}
    </a>
  );

  return (
    <Modal
      className="min-w-[320px] max-w-[320px]"
      antModalClassName="!max-w-[320px]"
      visible={!ssoToken && visible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
      closable={
        !formDisabled &&
        !(requestLoginOtpLoading || userLoginWithOtpOrSsoLoading) &&
        !isOtpVerified
      }
    >
      <Spin spinning={requestLoginOtpLoading || userLoginWithOtpOrSsoLoading}>
        <div className="flex flex-col gap-6 mb-4">
          {!isOtpVerified && (
            <div className="flex flex-col gap-3">
              <h2 className="m-0 font-bold">
                <strong>
                  {t(
                    isVerify
                      ? "otpModal.verifyTitle"
                      : isSignUp
                      ? "otpModal.signUpTitle"
                      : "otpModal.loginTitle",
                  )}
                </strong>
              </h2>

              <p className="m-0 text-default2">
                {t(
                  isVerify
                    ? "otpModal.verifySubTitle"
                    : isSignUp
                    ? "otpModal.signUpSubTitle"
                    : "otpModal.loginSubTitle",
                  {
                    deliveryMode: t(
                      `otpModal.otpChannels.${lastOtpDeliveryMode}`,
                    ),
                    channelLabel: configQuery?.currentStorefront?.label,
                  },
                )}
              </p>
            </div>
          )}

          {!isOtpVerified && (
            <>
              <div className="flex flex-col gap-2 w-full otp-input-container">
                <OtpInput
                  shouldAutoFocus
                  inputType="number"
                  value={otp}
                  onChange={setOtp}
                  numInputs={OTP_LENGTH}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="box-border flex-1 h-12 text-lg font-bold text-gray-900 rounded-lg border border-solid outline-none border-default focus-visible:border-primary"
                    />
                  )}
                  containerStyle={{
                    display: "flex",
                    gap: "8px",
                  }}
                />
              </div>

              <div className="flex flex-col text-center">
                {/* error message */}
                {localNotification?.message && (
                  <div
                    className={classNames("text-center", {
                      "text-danger":
                        localNotification?.type ===
                        LOCAL_NOTIFICATION_TYPES.ERROR,
                      "text-success":
                        localNotification?.type ===
                        LOCAL_NOTIFICATION_TYPES.SUCCESS,
                    })}
                  >
                    {localNotification?.message}
                  </div>
                )}

                {/* sent to last delivery mode message */}
                {localNotification?.type !==
                  LOCAL_NOTIFICATION_TYPES.SUCCESS && (
                  <>
                    {lastOtpDeliveryMode === constants.OTP_CHANNELS.SMS && (
                      <div>
                        {t("otpModal.codeSentToSms", {
                          phone: phone || maskedPhone,
                        })}
                      </div>
                    )}
                    {lastOtpDeliveryMode === constants.OTP_CHANNELS.EMAIL && (
                      <div>
                        {t("otpModal.codeSentToEmail", {
                          email: email || maskedEmail,
                        })}
                      </div>
                    )}
                  </>
                )}

                {/* resend */}
                {lastOtpDeliveryMode === constants.OTP_CHANNELS.SMS ? (
                  <div>
                    {renderSendToSms()}
                    {!isSignUp && (
                      <>
                        {t("otpModal.or")}
                        {renderSendToEmail()}
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    {renderSendToEmail()}
                    {t("otpModal.or")}
                    {renderSendToSms()}
                  </div>
                )}
              </div>
            </>
          )}

          {isOtpVerified && (
            <div className="flex flex-col gap-2 items-center text-center">
              <CheckCircleIcon className="mb-2 w-12 h-12 text-success" />
              <div className="text-success">{t("otpModal.successMessage")}</div>
            </div>
          )}
        </div>

        <div className="flex justify-center font-bold text-primary">
          <PoweredByAtlasId />
        </div>
      </Spin>
    </Modal>
  );
}
