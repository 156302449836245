import React from "react";

import ClockIcon from "@heroicons/react/solid/ClockIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

SelectedTimeslotLabel.propTypes = {
  label: PropTypes.node,
  isTransparent: PropTypes.bool,
  className: PropTypes.string,
  rest: PropTypes.any,
};

export default function SelectedTimeslotLabel({
  label,
  isTransparent,
  className,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-base lg:hidden">{t("navbar.timeslotLabel")}</div>
      <div
        className={`flex items-center border-b-2 py-2 px-1 cursor-pointer ${
          isTransparent
            ? "text-on-transparent-header border-on-transparent-header"
            : "border-on-header"
        } ${className ?? ""}`}
        {...rest}
      >
        <div className="mr-2">
          <ClockIcon
            className={`w-5 h-5 ${
              isTransparent ? "text-on-transparent-header" : "text-primary"
            }`}
          />
        </div>
        <div
          className={`truncate ${
            isTransparent ? "text-on-transparent-header" : "text-on-header"
          }`}
        >
          {label}
        </div>
      </div>
    </div>
  );
}
