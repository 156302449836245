import React from "react";

import PropTypes from "prop-types";

ZhBadge.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default function ZhBadge({
  className = "w-7 h-7",
  fill = "var(--color-primary)",
  containerStyle = null,
}) {
  return (
    <div className="rounded-full bg-header" style={containerStyle}>
      <svg
        x="0px"
        y="0px"
        version="1.1"
        className={className}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12.4183 12.4182 16 8 16C3.58179 16 0 12.4183 0 8C0 3.58167 3.58179 0 8 0C12.4182 0 16 3.58167 16 8ZM9.83398 6.75146H12.0623C11.7937 7.46777 11.4314 8.06799 10.9688 8.56653C10.4797 8.05017 10.1042 7.43945 9.83398 6.75146ZM14 10.2036C13.0281 9.98523 12.2207 9.61047 11.5649 9.10486C12.1523 8.46924 12.6128 7.69275 12.9548 6.75146H13.8857V6.00269H11.3538V5H10.5669V6.00269H8.073V6.75146H8.98853C9.33105 7.68323 9.76807 8.47961 10.343 9.13354C9.69556 9.62732 8.9043 9.98669 7.95874 10.2354C8.11108 10.4193 8.35229 10.7875 8.44116 10.9778C9.40967 10.6748 10.2341 10.2588 10.9231 9.7041C11.5952 10.2743 12.4219 10.6969 13.448 10.9651C13.5686 10.743 13.8159 10.3812 14 10.2036ZM4.2019 6.10413H2V9.32153H2.76782V8.96619H4.2019V10.9587H5.00781V8.96619H6.44849V9.28979H7.24805V6.10413H5.00781V5H4.2019V6.10413ZM4.2019 6.85303H2.76782V8.21729H4.2019V6.85303ZM5.00781 8.21729H6.44849V6.85303H5.00781V8.21729Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
