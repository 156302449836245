import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Image from "@/common/Image";

import ContactForm from "./ContactForm";

TwoColumnsTemplate.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sectionRef: PropTypes.object,
  rest: PropTypes.any,
};

export default function TwoColumnsTemplate({
  section,
  index,
  sectionRef,
  ...rest
}) {
  return (
    <div
      ref={sectionRef}
      className={classNames([
        index % 2 === 1 ? "" : "bg-default2",
        section?.fullWidth ? "" : "py-12",
      ])}
      style={section?.style}
      {...rest}
    >
      <div
        className={classNames([
          "relative flex flex-col",
          section?.reverse
            ? "sm:flex-row-reverse sm:space-x-reverse"
            : "sm:flex-row",
          section?.fullWidth
            ? ""
            : "max-w-screen-xl px-3 mx-auto sm:px-6 space-y-4 sm:space-y-0 sm:space-x-6",
        ])}
      >
        {section?.columns?.map((column, columnIndex) => {
          let imageContainerMargin;
          let columnContainerMargin;
          let textContainerPadding;

          switch (column.type) {
            case "image":
              if (column.image.fullWidth) {
                imageContainerMargin =
                  columnIndex === 0 ? "sm:ml-3" : "sm:mr-3";

                if (column.reverse) {
                  imageContainerMargin =
                    columnIndex === 0 ? "sm:mr-3" : "sm:ml-3";
                }
              }

              return (
                <div
                  key={`section_${index}_column_${columnIndex}`}
                  className={classNames([
                    "w-full h-full space-y-4 text-center sm:relative sm:h-auto sm:w-1/2 sm:space-y-6",
                    section?.columnsOverlapOnMobile ? "absolute" : "",
                  ])}
                  style={column?.style}
                >
                  {column?.image && (
                    <div
                      className={classNames([
                        column.image.fullWidth ? "" : "max-w-[640px]",
                        imageContainerMargin,
                      ])}
                    >
                      <Image
                        src={column.image.src}
                        className={column.image.class}
                        style={column.image.style}
                      />
                    </div>
                  )}
                  {column?.image?.caption && (
                    <div
                      dangerouslySetInnerHTML={{ __html: column.image.caption }}
                    />
                  )}
                </div>
              );
            case "text":
              switch (column?.align) {
                case "left":
                  columnContainerMargin = section?.reverse
                    ? "sm:ml-auto sm:mr-0"
                    : "sm:mr-auto sm:ml-0";
                  break;
                case "right":
                  columnContainerMargin = section?.reverse
                    ? "sm:mr-auto sm:ml-0"
                    : "sm:ml-auto sm:mr-0";
                  break;
                default:
                  columnContainerMargin = "";
              }

              if (section?.fullWidth) {
                textContainerPadding =
                  columnIndex === 0 ? "px-3 sm:pl-6" : "px-3 sm:pr-6";
                if (section?.reverse) {
                  textContainerPadding =
                    columnIndex === 0 ? "px-3 sm:pr-6" : "px-3 sm:pl-6";
                }
              }

              return (
                <div
                  key={`section_${index}_column_${columnIndex}`}
                  className="sm:w-1/2"
                  style={{
                    zIndex: 1,
                    ...(column?.style || {}),
                  }}
                >
                  <div
                    className={classNames([
                      "max-w-[640px] mx-auto",
                      columnContainerMargin,
                    ])}
                  >
                    <div
                      className={classNames([
                        section?.columnsOverlapOnMobile
                          ? "text-center"
                          : "text-left",
                        textContainerPadding,
                        section?.fullWidth ? "py-12" : "",
                        column?.align ? `sm:text-${column.align}` : "",
                      ])}
                    >
                      {column?.header && (
                        <div
                          className={classNames([
                            "text-2xl sm:text-3xl font-display",
                            column?.description ? "mb-6" : "",
                          ])}
                          dangerouslySetInnerHTML={{ __html: column.header }}
                        />
                      )}
                      {column?.description && (
                        <div
                          className="custom-description"
                          dangerouslySetInnerHTML={{
                            __html: column.description,
                          }}
                        />
                      )}
                      {column?.form && (
                        <ContactForm
                          formType={column.form.formType}
                          subjects={column.form.subjects}
                          successMessage={column.form.successMessage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
}
