import { gql } from "@apollo/client";

export const MAKE_ADYEN_PAYMENT_MUTATION = gql`
  mutation MakeAdyenPayment(
    $paymentMethod: JSON!
    $url: String!
    $line2: String
    $isCutleryRequired: Boolean
    $notes: String
    $contactName: String
    $contactNumber: String
    $email: String
    $paymentType: PaymentMethodEnum
  ) {
    makeAdyenPayment(
      input: {
        paymentMethod: $paymentMethod
        url: $url
        line2: $line2
        isCutleryRequired: $isCutleryRequired
        notes: $notes
        contactName: $contactName
        contactNumber: $contactNumber
        email: $email
        paymentType: $paymentType
      }
    )
  }
`;
