import { importScript } from "./importScript";

export function importGooglePlacesScript() {
  if (!window.googlePlacesScriptImported) {
    window.googlePlacesScriptImported = true;
    importScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        import.meta.env.VITE_GOOGLE_PLACES_API_KEY
      }&libraries=places`,
    );
  }
}
