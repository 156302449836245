import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PointIcon from "@/common/Loyalty/PointIcon";
import ConfigContext from "@/components/Config/configContext";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";

CartFooterSummary.propTypes = {
  isCheckingOut: PropTypes.bool,
  availableFulfilmentTypes: PropTypes.arrayOf(PropTypes.string),
  fulfilmentType: PropTypes.string,
  paymentBreakdown: PropTypes.object,
  outlet: PropTypes.object,
};

CartFooterSummary.defaultProps = {
  isCheckingOut: false,
};

export default function CartFooterSummary({
  isCheckingOut,
  availableFulfilmentTypes,
  fulfilmentType,
  paymentBreakdown,
  outlet,
}) {
  const { t } = useTranslation();
  const { outletQrHash } = useContext(ConfigContext);
  const { configQuery } = useContext(ConfigContext);
  const pointProgram = configQuery?.config?.pointProgram;

  const formattedDeliveryFee =
    paymentBreakdown.deliveryFee > 0
      ? formatPrice(paymentBreakdown.deliveryFee)
      : t("menu.cart.footer.labels.freeDelivery");

  const amountToFreeDelivery = paymentBreakdown.amountToFreeDelivery;
  const freeDeliveryProgressFilledPercentage =
    paymentBreakdown.deliveryFee > 0
      ? Math.min(
          100,
          (paymentBreakdown.subtotal /
            paymentBreakdown.deliveryFeeWaiverCartSubtotal) *
            100,
        )
      : 100;

  const minimumOrderProgressFilledPercentage = Math.min(
    100,
    (paymentBreakdown.subtotal / paymentBreakdown.minimumOrderValue) * 100,
  );

  const nextDeliveryFeeProgressFilledPercentage =
    paymentBreakdown?.amountToNextDeliveryFee
      ? (paymentBreakdown?.subtotal /
          (paymentBreakdown?.subtotal +
            paymentBreakdown?.amountToNextDeliveryFee?.nextAmount)) *
        100
      : 100;

  const minimumOrderValueNotMet =
    fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
    paymentBreakdown.minimumOrderValue &&
    paymentBreakdown.subtotal < paymentBreakdown.minimumOrderValue;

  function renderMinimumOrderNotMet() {
    return (
      <div className="flex justify-between font-normal">
        <div className="text-danger">
          {t("menu.cart.footer.labels.minimumOrderValue", {
            minimumOrderValue: formatPrice(paymentBreakdown.minimumOrderValue),
            remainingValue: formatPrice(
              paymentBreakdown.minimumOrderValue - paymentBreakdown.subtotal,
            ),
          })}
        </div>
      </div>
    );
  }

  function renderProgressBar() {
    return (
      <div className="relative mt-2 mb-4">
        <div className="progress-bar w-full h-1.5 rounded-full bg-faded">
          <div
            className="absolute h-full transition-all duration-500 rounded-full progress-bar-filled bg-primary"
            style={{
              width: `${
                minimumOrderValueNotMet
                  ? minimumOrderProgressFilledPercentage
                  : freeDeliveryProgressFilledPercentage
              }%`,
            }}
          />
        </div>
      </div>
    );
  }

  function renderDeliveryFeeRow(options) {
    if (outletQrHash) return;

    const { className } = options || {};
    return (
      <div className={classNames("flex justify-between", className)}>
        <div className="max-w-[80%]">
          {t(
            `menu.cart.footer.labels.${
              fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY
                ? "deliveryFee"
                : "pickup"
            }`,
          )}
          <br />
          <div className="text-xs opacity-50">
            {t("menu.cart.footer.labels.outletServedBy", {
              labelForPickup: outlet?.labelForPickup,
            })}
          </div>
          {!isCheckingOut && availableFulfilmentTypes.length > 1 && (
            <a
              className="inline-block text-link hover:text-link"
              onClick={() => {
                emitEvent("selectAddress");
                setTimeout(() => {
                  emitEvent(
                    "selectFulfilmentType",
                    fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY
                      ? constants.FULFILMENT_TYPES.PICKUP
                      : constants.FULFILMENT_TYPES.DELIVERY,
                  );
                }, 0);
              }}
            >
              {t(
                `menu.cart.footer.labels.${
                  fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY
                    ? "switchToPickup"
                    : "switchToDelivery"
                }`,
              )}
            </a>
          )}
        </div>
        <div>{formattedDeliveryFee}</div>
      </div>
    );
  }

  function renderPointUsageRow() {
    return (
      !!paymentBreakdown?.pointsAmount && (
        <div className="flex items-center justify-between text-secondary">
          <div className="flex flex-grow items-center gap-1">
            <div className="flex items-end">
              {pointProgram.isCustomCurrency
                ? t("menu.cart.footer.labels.usePoints", {
                    pointsAmount: paymentBreakdown?.pointsAmount,
                  })
                : t("menu.cart.footer.labels.useCashback", {
                    cashbackAmount: formatPrice(paymentBreakdown?.pointsValue),
                  })}
            </div>
            {pointProgram.isCustomCurrency && <PointIcon />}
          </div>
          <span className="text-success">
            {formatPrice(-paymentBreakdown?.pointsValue)}
          </span>
        </div>
      )
    );
  }

  function renderDiscountRow() {
    return (
      paymentBreakdown.discount > 0 && (
        <div className="flex justify-between">
          <div>{t("menu.cart.footer.labels.discount")}</div>
          <div className="text-success">
            {formatPrice(-paymentBreakdown.discount)}
          </div>
        </div>
      )
    );
  }

  return (
    <div className="flex flex-col flex-grow p-4 cart-footer-summary bg-default3">
      {isCheckingOut || outletQrHash ? (
        <>
          {minimumOrderValueNotMet && (
            <>
              {renderMinimumOrderNotMet()}
              {renderProgressBar()}
            </>
          )}
          {paymentBreakdown && (
            <div className="space-y-2 font-normal sm:space-y-4">
              <div className="flex justify-between">
                <div>{t("menu.cart.footer.labels.subtotal")}</div>
                <div>{formatPrice(paymentBreakdown.subtotal)}</div>
              </div>
              {renderDeliveryFeeRow()}
              {renderPointUsageRow()}
              {paymentBreakdown.surcharge > 0 && (
                <div className="flex justify-between">
                  <div>
                    {paymentBreakdown.surchargeLabel ||
                      t("menu.cart.footer.labels.surcharge")}
                  </div>
                  <div>{formatPrice(paymentBreakdown.surcharge)}</div>
                </div>
              )}
              {/* render discount row here only if NOT post-tax discount */}
              {!paymentBreakdown.isPostTaxDiscount && renderDiscountRow()}
              {(paymentBreakdown?.serviceCharge &&
                paymentBreakdown.serviceCharge) > 0 && (
                <div className="flex justify-between">
                  <div>{t("menu.cart.footer.labels.serviceCharge")}</div>
                  <div>{formatPrice(paymentBreakdown.serviceCharge)}</div>
                </div>
              )}
              {!paymentBreakdown?.taxInclusivePrices &&
                paymentBreakdown.tax > 0 && (
                  <div className="flex justify-between">
                    <div>{t("menu.cart.footer.labels.tax")}</div>
                    <div>{formatPrice(paymentBreakdown.tax)}</div>
                  </div>
                )}
              {/* render discount row here only if post-tax discount */}
              {paymentBreakdown.isPostTaxDiscount && renderDiscountRow()}
              <div className="justify-between hidden font-bold sm:flex">
                <div>{t("menu.cart.footer.labels.totalIncludingTax")}</div>
                <div>{formatPrice(paymentBreakdown.totalIncludingTax)}</div>
              </div>
              {paymentBreakdown?.taxInclusivePrices &&
                paymentBreakdown.tax > 0 && (
                  <div className="flex justify-between">
                    <div>{t("menu.cart.footer.labels.taxIncluded")}</div>
                    <div>{formatPrice(paymentBreakdown.tax)}</div>
                  </div>
                )}
            </div>
          )}
        </>
      ) : (
        <>
          {((paymentBreakdown.deliveryFeeWaiverCartSubtotal &&
            !paymentBreakdown.amountToNextDeliveryFee) ||
            minimumOrderValueNotMet) && (
            <>
              {minimumOrderValueNotMet ? (
                renderMinimumOrderNotMet()
              ) : (
                <div className="text-xs font-normal">
                  {paymentBreakdown.deliveryFeeWaiverCartSubtotal > 0 &&
                  amountToFreeDelivery > 0 &&
                  paymentBreakdown.deliveryFee > 0
                    ? t("menu.cart.footer.amountToFreeDelivery", {
                        amount: formatPrice(amountToFreeDelivery),
                      })
                    : t("menu.cart.footer.freeDelivery")}
                </div>
              )}

              {(!paymentBreakdown.amountToNextDeliveryFee ||
                minimumOrderValueNotMet) &&
                renderProgressBar()}
            </>
          )}
          {!minimumOrderValueNotMet &&
            fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
            paymentBreakdown.amountToNextDeliveryFee && (
              <>
                <div className="flex justify-between font-normal">
                  <div className="text-xs">
                    {paymentBreakdown?.amountToNextDeliveryFee.nextAmount === 0
                      ? t("menu.cart.footer.freeDelivery")
                      : paymentBreakdown?.amountToNextDeliveryFee
                          .nextFeeTier === 0
                      ? t("menu.cart.footer.amountToFreeDelivery", {
                          amount: formatPrice(
                            paymentBreakdown.amountToNextDeliveryFee.nextAmount,
                          ),
                        })
                      : t("menu.cart.footer.labels.amountToNextDeliveryFee", {
                          nextDeliveryFee: formatPrice(
                            paymentBreakdown.amountToNextDeliveryFee
                              .nextFeeTier,
                          ),
                          amount: formatPrice(
                            paymentBreakdown.amountToNextDeliveryFee.nextAmount,
                          ),
                        })}
                  </div>
                </div>
                <div className="relative mt-2 mb-4">
                  <div className="progress-bar w-full h-1.5 rounded-full bg-faded">
                    <div
                      className={`absolute h-full transition-all duration-500 rounded-full progress-bar-filled ${
                        paymentBreakdown.amountToNextDeliveryFee.nextFeeTier ===
                        500
                          ? "bg-yellow-500"
                          : paymentBreakdown.amountToNextDeliveryFee
                              .nextFeeTier === 300
                          ? "bg-yellow-300"
                          : "bg-success"
                      }`}
                      style={{
                        width: `${nextDeliveryFeeProgressFilledPercentage}%`,
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          {renderDeliveryFeeRow({ className: "font-bold" })}
        </>
      )}
    </div>
  );
}
