import React, { useState } from "react";

import PropTypes from "prop-types";

const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  return (
    // The value has same format of useState hook: value = [appState, setAppState]
    <AppContext.Provider value={useState({})}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.any,
};

export default AppContext;
