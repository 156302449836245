import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import minBy from "lodash/minBy";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import Tabs from "@/common/Tabs";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import {
  SET_FULFILMENT_TYPE_MUTATION,
  SET_FULFILMENT_TYPE_OPTIMISED_MUTATION,
} from "@/graphql/mutations/setFulfilmentType";
import useAuth from "@/hooks/useAuth";
import constants from "@/utils/constants";
import { emitEvent, useListenEvent } from "@/utils/eventBus";

import AddressList from "./AddressList";
import AddressSearch from "./AddressSearch";
import FulfilmentSelectorActions from "./FulfilmentSelectorActions";
import OutletSelector from "./OutletSelector";

FulfilmentSelectorModal.propTypes = {
  isAddressModalVisible: PropTypes.bool,
  closeAddressModal: PropTypes.func,
  openTimeslotModal: PropTypes.func,
};

export default function FulfilmentSelectorModal({
  isAddressModalVisible,
  closeAddressModal,
  openTimeslotModal,
}) {
  // Setup
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    cartQuery,
    cartLoading,
    refetchCart,
    addressesAndOutlets,
    getAddressesAndOutlets,
    refetchAddressAndOutlets,
    addressAndOutletsLoading,
    selectedOutletFulfilmentTypeStatesLoading,
    otherOutletsFulfilmentTypeStatesLoading,
  } = useContext(CartContext);

  const { configQuery, pathConfig } = useContext(ConfigContext);
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [searching, setSearching] = useState(false);
  const [creatingAddress, setCreatingAddress] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [availableOutlets, setAvailableOutlets] = useState([]);
  const [noAvailableOutletsAlert, setNoAvailableOutletsAlert] = useState(false);
  const { loggedIn } = useAuth();

  // flag to block UI when changing fulfilment type
  const [checkCartLoading, setCheckCartLoading] = useState(false);

  const fulfilmentTypes = configQuery?.config?.brandAvailableFulfilmentTypes;
  const tabs = fulfilmentTypes?.map((fulfilmentType) => {
    return {
      id: fulfilmentType,
      label: t(`fulfilmentTypes.${fulfilmentType}`),
      className: "font-bold w-1/2 text-center",
      onClick: () => setSelectedTab(fulfilmentType),
    };
  });

  const [selectedTab, setSelectedTab] = useState(null);
  const isDelivery = selectedTab === constants.FULFILMENT_TYPES.DELIVERY;

  const [setFulfilmentType, { loading: setFulfilmentTypeLoading }] =
    useMutation(
      useOptimisedDinerCartType
        ? SET_FULFILMENT_TYPE_OPTIMISED_MUTATION
        : SET_FULFILMENT_TYPE_MUTATION,
      {
        context: { graph: "diners" },
        errorPolicy: "all",
        cachePolicy: "no-cache",
        update: (cache, { data }) => {
          const updatedCartData = useOptimisedDinerCartType
            ? data?.setFulfilmentTypeOptimised
            : data?.setFulfilmentType;
          if (updatedCartData) {
            if (
              location.pathname.includes("/outlet/") &&
              pathConfig?.menuPath
            ) {
              navigate(pathConfig?.menuPath);
            }
          }

          cache.modify({
            id: cache.identify(cartQuery?.cart),
            fields: {
              outlet() {
                return updatedCartData?.outlet;
              },
              outletId() {
                return updatedCartData?.outletId;
              },
              address() {
                return updatedCartData?.address;
              },
              timeslot() {
                return updatedCartData?.timeslot;
              },
              fulfilmentType() {
                return updatedCartData?.fulfilmentType;
              },
              // update payment breakdown to reflect change in delivery fee
              paymentBreakdown() {
                return updatedCartData?.paymentBreakdown;
              },
            },
          });

          // cart will only be fetched after a short timeout
          setTimeout(() => setCheckCartLoading(true), 1);

          refetchCart();

          if (updatedCartData?.removedItems) {
            emitEvent("showRemovedItemsModal", updatedCartData?.removedItems);
          }

          if (
            updatedCartData?.fulfilmentType ===
              constants.FULFILMENT_TYPES.DELIVERY &&
            !updatedCartData?.address?.availableOutlets?.length
          ) {
            setNoAvailableOutletsAlert(true);
          }
        },
      },
    );

  // Methods
  function onClose() {
    setSelectedAddress(null);
    closeAddressModal();
  }

  useListenEvent("selectFulfilmentType", (fulfilmentType) =>
    setSelectedTab(fulfilmentType),
  );

  // Effects

  // on change fulfilment type, close address modal after cart is loaded
  useEffect(() => {
    if (checkCartLoading && !cartLoading) {
      setCheckCartLoading(false);
      closeAddressModal();

      if (!cartQuery?.cart?.timeslot) {
        openTimeslotModal();
      }
    }
  }, [checkCartLoading, cartLoading]);

  // select tab based on cart fulfilment type on open modal / cart fulfilment type change / cart address change
  useEffect(() => {
    if (isAddressModalVisible && cartQuery?.cart?.fulfilmentType) {
      setSelectedTab(cartQuery.cart?.fulfilmentType);

      // if no address set yet, set to cart address
      if (!selectedAddress) {
        setSelectedAddress(cartQuery?.cart?.address);
      }
    }
  }, [
    cartQuery?.cart?.fulfilmentType,
    cartQuery?.cart?.address,
    isAddressModalVisible,
  ]);

  // update available addresses
  useEffect(() => {
    if (addressesAndOutlets?.addresses) {
      setAvailableAddresses(addressesAndOutlets?.addresses);
    }
  }, [addressesAndOutlets?.addresses]);

  // select user default address on load available addresses, if no selected address yet
  useEffect(() => {
    if (!selectedAddress && availableAddresses) {
      setSelectedAddress(
        availableAddresses.find(
          (address) => address.user.selectedAddressId === address.id,
        ),
      );
    }
  }, [availableAddresses, selectedAddress]);

  // only refetch outlets on change in selected address
  useEffect(() => {
    if (selectedAddress && isAddressModalVisible) {
      if (addressesAndOutlets) {
        refetchAddressAndOutlets({
          point:
            selectedAddress && isDelivery
              ? [selectedAddress.longitude, selectedAddress.latitude]
              : null,
          fulfilmentType: selectedTab,
        });
      } else {
        getAddressesAndOutlets();
      }
    }
  }, [JSON.stringify(selectedAddress)]);

  // update available outlets only on load complete
  useEffect(() => {
    if (!addressAndOutletsLoading && addressesAndOutlets?.outlets) {
      setAvailableOutlets(addressesAndOutlets.outlets);
    }
  }, [addressAndOutletsLoading]);

  // update outlets again on load fulfilmentTypeStates
  useEffect(() => {
    if (
      !selectedOutletFulfilmentTypeStatesLoading &&
      !addressAndOutletsLoading &&
      addressesAndOutlets?.outlets
    ) {
      setAvailableOutlets(addressesAndOutlets.outlets);
    }
  }, [selectedOutletFulfilmentTypeStatesLoading]);
  useEffect(() => {
    if (
      !otherOutletsFulfilmentTypeStatesLoading &&
      !addressAndOutletsLoading &&
      addressesAndOutlets?.outlets
    ) {
      setAvailableOutlets(addressesAndOutlets.outlets);
    }
  }, [otherOutletsFulfilmentTypeStatesLoading]);

  // select nearest outlet on change in available outlets, if delivery, has selected address, and not setting fulfilment type
  // MUST BE DEFINED BEFORE [select cart selected outlet on open modal] useEffect
  useEffect(() => {
    if (
      isDelivery &&
      availableOutlets.length > 0 &&
      selectedAddress &&
      !setFulfilmentTypeLoading
    ) {
      let nearestOutlet;
      if (selectedAddress.nearestOutlet?.id) {
        nearestOutlet = availableOutlets.find(
          (outlet) => outlet.id === selectedAddress.nearestOutlet.id,
        );
      }
      if (nearestOutlet) {
        setSelectedOutlet(nearestOutlet);
      } else {
        // fallback to find by shortest distance
        setSelectedOutlet(
          minBy(
            // skip auto-selecting of outlet that is pickup only
            availableOutlets.filter((outlet) => !outlet.pickupOnly),
            (outlet) => outlet.distanceFromAddress,
          ),
        );
      }
    }
  }, [availableOutlets]);

  // select cart selected outlet on open modal
  useEffect(() => {
    if (
      isAddressModalVisible &&
      availableOutlets?.length > 0 &&
      cartQuery?.cart?.outletId
    ) {
      setSelectedOutlet(
        availableOutlets.find(
          (outlet) => outlet.id === cartQuery?.cart?.outletId,
        ),
      );
    }
  }, [
    isAddressModalVisible,
    cartQuery?.cart?.outletId,
    availableOutlets?.length > 0,
  ]);

  useEffect(() => {
    if (!loggedIn) {
      setAvailableOutlets([]);
      setAvailableAddresses([]);
    }
  }, [loggedIn]);

  return (
    <>
      <Modal
        visible={noAvailableOutletsAlert}
        noCancel={true}
        maskClosable={false}
        wrapperClassName="z-30"
        okText={t("addresses.noAvailableOutlets.okay")}
        onOk={() => {
          setNoAvailableOutletsAlert(false);
        }}
      >
        <div className="mb-4 text-2xl font-display">
          {t("addresses.noAvailableOutlets.header")}
        </div>
        <div className="mt-2 text-sm">
          {t("addresses.noAvailableOutlets.copy")}
        </div>
      </Modal>
      <Modal
        visible={isAddressModalVisible}
        noCancel={true}
        onCancel={onClose}
        footer={null}
        closable={!(setFulfilmentTypeLoading || checkCartLoading)}
        maskClosable={!(setFulfilmentTypeLoading || checkCartLoading)}
        antModalClassName="!pb-0 !top-[3vh]"
        zIndex={1010}
      >
        <Spin
          spinning={
            creatingAddress || setFulfilmentTypeLoading || checkCartLoading
          }
        >
          <div className="flex flex-col max-h-[75vh] sm:max-h-[90vh]">
            {fulfilmentTypes?.length > 1 && (
              <Tabs
                className="flex-grow mb-6"
                options={tabs}
                selectedTab={selectedTab}
              />
            )}
            <div className="min-h-[64px] flex-grow px-4 -mx-4 overflow-y-auto sm:px-6 sm:-mx-6 scrollbar">
              <div className="mb-3 text-2xl font-display">
                {isDelivery
                  ? t("addressPicker.header")
                  : t("outletSelector.header")}
              </div>
              <AddressSearch
                visible={isDelivery}
                setSearching={setSearching}
                setCreatingAddress={setCreatingAddress}
                setSelectedAddress={setSelectedAddress}
                availableOutlets={availableOutlets}
                setSelectedOutlet={setSelectedOutlet}
                setNoAvailableOutletsAlert={setNoAvailableOutletsAlert}
              />
              <AddressList
                visible={isDelivery}
                searching={searching}
                addresses={availableAddresses}
                loadingAddresses={addressAndOutletsLoading}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
              />
              <OutletSelector
                visible={(!isDelivery || selectedAddress) && !searching}
                fulfilmentType={selectedTab}
                loading={addressAndOutletsLoading}
                fulfilmentTypeStatesLoading={
                  selectedOutletFulfilmentTypeStatesLoading
                }
                availableOutlets={availableOutlets}
                selectedOutlet={selectedOutlet}
                setSelectedOutlet={setSelectedOutlet}
                isDelivery={isDelivery}
              />
            </div>
            <FulfilmentSelectorActions
              searching={searching}
              selectedTab={selectedTab}
              selectedOutlet={selectedOutlet}
              selectedAddress={selectedAddress}
              setFulfilmentType={setFulfilmentType}
              onClose={onClose}
              closeAddressModal={closeAddressModal}
            />
          </div>
        </Spin>
      </Modal>
    </>
  );
}
