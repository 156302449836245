import { gql } from "@apollo/client";

export const CHECKOUT_CART_MUTATION = gql`
  mutation CheckoutCart(
    $token: String
    $line2: String
    $isCutleryRequired: Boolean
    $notes: String
    $contactName: String
    $contactNumber: String
    $email: String
    $paymentType: PaymentMethodEnum
  ) {
    checkout(
      input: {
        token: $token
        line2: $line2
        isCutleryRequired: $isCutleryRequired
        notes: $notes
        contactName: $contactName
        contactNumber: $contactNumber
        email: $email
        paymentType: $paymentType
      }
    ) {
      id
      identifier
      servingDate
      timeslotRange
      paymentType
      clientSecret
      paymentDetails {
        qrCode
        total
        brandLabel
        paymentUen
        paymentMobileNumber
        paymentLabel
      }
    }
  }
`;
