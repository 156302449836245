import React from "react";

import compact from "lodash/compact";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RadioGroup from "@/common/RadioGroup";
import Spin from "@/common/Spin";

AddressList.propTypes = {
  searching: PropTypes.bool,
  addresses: PropTypes.arrayOf(PropTypes.object),
  loadingAddresses: PropTypes.bool,
  selectedAddress: PropTypes.object,
  setSelectedAddress: PropTypes.func,
  visible: PropTypes.bool,
};

export default function AddressList({
  searching,
  addresses,
  loadingAddresses,
  selectedAddress,
  setSelectedAddress,
  visible,
}) {
  // Setup
  const { t } = useTranslation();

  if (searching || !visible) return null;

  return (
    <div data-testid="address-list">
      {addresses.length > 0 && (
        <div className="pt-4 mb-4 font-bold text-body">
          {t("account.addresses.selectAddressHeader")}
        </div>
      )}
      <Spin spinning={loadingAddresses && !addresses.length}>
        {loadingAddresses && !addresses.length && (
          // space for spinner
          <div className="flex items-center justify-center h-16 mt-4" />
        )}
        <RadioGroup
          options={addresses}
          selected={selectedAddress}
          setSelected={setSelectedAddress}
          matchById={true}
          renderLabel={(address) => {
            return (
              <div
                data-testid={`address-list-element-${address.id}`}
                className="flex items-center justify-between space-x-3"
              >
                <div className="flex flex-col flex-grow">
                  <span>
                    {compact([address.line1, address.line2]).join(", ")}
                  </span>
                </div>
              </div>
            );
          }}
        />
      </Spin>
    </div>
  );
}
