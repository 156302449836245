export default function generatePrivacyAgreementHTML({
  language,
  joinCopy,
  partnerPrivacyPolicy,
  privacyPolicyTitle,
  termsOfServiceTitle,
  privacyPolicyLineEnding,
}) {
  const languageSpace = language === "zh" ? "的" : "'s ";

  if (
    !!partnerPrivacyPolicy?.partnerLink &&
    !!partnerPrivacyPolicy?.partnerName &&
    !!partnerPrivacyPolicy?.partnerTerms
  ) {
    return `${partnerPrivacyPolicy?.partnerName}${languageSpace}<a class="font-bold text-link hover:text-link" target="_blank" href=${partnerPrivacyPolicy?.partnerTerms}>${termsOfServiceTitle}</a>${joinCopy}<a class="font-bold text-link hover:text-link" target="_blank" href=${partnerPrivacyPolicy?.partnerLink}>${privacyPolicyTitle}</a>, ${privacyPolicyLineEnding}`;
  } else if (
    !!partnerPrivacyPolicy?.partnerLink &&
    !!partnerPrivacyPolicy?.partnerName
  ) {
    return `${partnerPrivacyPolicy?.partnerName}${languageSpace}<a class="font-bold text-link hover:text-link" target="_blank" href=${partnerPrivacyPolicy?.partnerLink}>${privacyPolicyTitle}</a>, ${privacyPolicyLineEnding}`;
  } else if (
    !!partnerPrivacyPolicy?.partnerTerms &&
    !!partnerPrivacyPolicy?.partnerName
  ) {
    return `${partnerPrivacyPolicy?.partnerName}${languageSpace}<a class="font-bold text-link hover:text-link" target="_blank" href=${partnerPrivacyPolicy?.partnerTerms}>${termsOfServiceTitle}</a>, ${privacyPolicyLineEnding}`;
  } else {
    return null;
  }
}
