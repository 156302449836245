import { formatValueToStandardUnit } from "@/utils/formatPrice";

// Utils
const topLevelItemToGtmItem = (item) => {
  const subtotal = formatValueToStandardUnit(item?.subtotal);
  return {
    item_id: item?.item?.id || item?.itemId,
    item_name: item?.name,
    price: formatValueToStandardUnit(
      (item?.subtotal || item?.price) / item?.quantity,
    ),
    quantity: item?.quantity,
    ...(subtotal && { subtotal: subtotal }),
  };
};

const topLevelItemsToGtmArray = (topLevelItems) => {
  return topLevelItems?.map(topLevelItemToGtmItem);
};

const ItemTypeOptimisedToGtmItem = (item) => {
  return {
    item_id: item?.id,
    item_name: item?.label,
    price: formatValueToStandardUnit(item?.unitPriceFractional),
  };
};

// Events
const signUp = () => {
  const gtmPayload = { method: "Atlas Online" };
  window.dataLayer.push({ event: "sign_up", ...gtmPayload });
};

const login = () => {
  const gtmPayload = { method: "Atlas Online" };
  window.dataLayer.push({ event: "login", ...gtmPayload });
};

const addToCart = ({ value, item }) => {
  const gtmPayload = {
    value: formatValueToStandardUnit(value),
    currency: item?.currency || "SGD",
    items: [ItemTypeOptimisedToGtmItem(item)],
  };
  window.dataLayer.push({ event: "add_to_cart", ecommerce: gtmPayload });
};

const viewCart = (payload) => {
  const gtmPayload = {
    items: topLevelItemsToGtmArray(payload),
  };
  window.dataLayer.push({ event: "view_cart", ecommerce: gtmPayload });
};

const removeFromCart = ({ value, item }) => {
  const gtmPayload = {
    value: Math.abs(formatValueToStandardUnit(value)),
    currency: item?.currency || "SGD",
    items: [ItemTypeOptimisedToGtmItem(item)],
  };
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: gtmPayload,
  });
};

const beginCheckout = (payload) => {
  const gtmPayload = {
    currency: "SGD",
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    items: topLevelItemsToGtmArray(payload.topLevelItems),
  };
  window.dataLayer.push({ event: "begin_checkout", ecommerce: gtmPayload });
};

const addPaymentInfo = (payload) => {
  const gtmPayload = {
    currency: "SGD",
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    items: topLevelItemsToGtmArray(payload.topLevelItems),
  };
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: gtmPayload,
  });
};

const purchase = (payload) => {
  const gtmPayload = {
    currency: "SGD",
    value: formatValueToStandardUnit(payload?.paymentBreakdown?.subtotal),
    items: topLevelItemsToGtmArray(payload?.orderItems),
    transaction_id: payload?.identifier,
    shipping: formatValueToStandardUnit(payload?.paymentBreakdown?.deliveryFee),
    discount: formatValueToStandardUnit(payload?.paymentBreakdown?.discount),
    email: payload?.contactEmail,
    coupon: payload?.promoCode,
    tax: formatValueToStandardUnit(payload?.paymentBreakdown?.tax),
    points_value: formatValueToStandardUnit(
      payload?.paymentBreakdown?.pointsValue,
    ),
  };
  window.dataLayer.push({ event: "purchase", ecommerce: gtmPayload });
};

const track = (eventName, data) => {
  if (typeof gtm[eventName] === "function") {
    gtm[eventName](data);
  }
};

const gtm = {
  signUp,
  login,
  addToCart,
  viewCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  purchase,
  track,
};

export default gtm;
