import { gql } from "@apollo/client";

export const GET_ACTIVE_POPUP_QUERY = gql`
  query GetActivePopUp($outletId: Int) {
    activePopup(outletId: $outletId) {
      id
      name
      startsAt
      endsAt
      hasButton
      buttonText
      buttonUrl
      buttonHorizontalAlignment
      audienceTargeting
      triggerType
      triggerValue
      frequencyType
      frequencyValue
      desktopImageUrl
      mobileImageUrl
      isActive
      outletIds
      storefrontIds
      createdAt
      updatedAt
    }
  }
`;
