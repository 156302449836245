import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AntTabs from "@/common/AntTabs";
import Tabs from "@/common/Tabs";
import QuantityControl from "@/components/QuantityControl";

const MACROS = ["calories", "protein", "carbs", "fat"];

NutritionCalculator.propTypes = {
  section: PropTypes.object,
};

export default function NutritionCalculator({ section }) {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState({});

  const tabs = section?.tabs?.map((tab) => {
    return {
      ...tab,
      className: "font-bold text-center",
      onClick: () => {
        setSelectedTab(tab.id);
        setNutritionalCalculatorSections(tab.sections);
        setSelectedSection("calculator_section_1");
      },
    };
  });
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.id);
  const [nutritionalCalculatorSections, setNutritionalCalculatorSections] =
    useState(tabs?.[0]?.sections ?? section?.sections);
  const [selectedSection, setSelectedSection] = useState(
    "calculator_section_1",
  );

  function onQuantityChange(item, quantity) {
    if (quantity <= 0) {
      const clone = { ...selectedItems };
      delete clone[item.label];
      setSelectedItems(clone);
    } else {
      setSelectedItems({
        ...selectedItems,
        [item.label]: {
          ...item,
          quantity,
        },
      });
    }
  }

  function getMacroTotal(macro) {
    return Object.keys(selectedItems).reduce((total, key) => {
      const itemMacro =
        selectedItems[key][macro] * selectedItems[key]["quantity"];
      return parseFloat((total + itemMacro).toFixed(2));
    }, 0);
  }

  if (!nutritionalCalculatorSections?.length > 0) {
    return null;
  }

  return (
    <div
      className="max-w-5xl px-4 py-12 mx-auto sm:px-8"
      style={section?.style}
    >
      {section?.header && (
        <div
          className={`text-2xl sm:text-3xl font-display ${
            section?.description ? "mb-6" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: section.header }}
        />
      )}
      {section?.description && (
        <div dangerouslySetInnerHTML={{ __html: section.description }} />
      )}
      <div className="flex flex-col space-y-4 text-left sm:flex-row sm:space-x-12 sm:space-y-0">
        <div className="sm:w-1/2">
          <Tabs
            className="mb-4"
            navClassName="flex -mb-px"
            tabClassName="flex-grow p-2 border-b-2 border-transparent opacity-40 hover:border-default"
            selectedTabClassName="flex-grow p-2 border-b-2 border-primary"
            options={tabs}
            selectedTab={selectedTab}
          />
          <AntTabs
            animated={false}
            type="card"
            size="default"
            moreIcon={null}
            activeKey={selectedSection}
            onChange={(activeKey) => setSelectedSection(activeKey)}
          >
            {nutritionalCalculatorSections.map((section, i) => {
              return (
                <AntTabs.TabPane
                  tab={section.label}
                  key={`calculator_section_${i + 1}`}
                >
                  <div className="p-4 bg-default">
                    <div className="mb-2 text-lg font-display">
                      {section.label}
                    </div>
                    <div className="mb-4 border-b-2 border-default" />
                    <div className="space-y-2">
                      {section.items.map((item, j) => {
                        return (
                          <div
                            key={`calculator_section_${i}_item_${j}`}
                            className="flex items-center justify-between"
                          >
                            <span className="text-sm">{item.label}</span>
                            <QuantityControl
                              className="w-auto"
                              size="small"
                              quantity={
                                selectedItems[item.label]?.quantity || 0
                              }
                              onChange={(quantity) =>
                                onQuantityChange(item, quantity)
                              }
                              debounce={0}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </AntTabs.TabPane>
              );
            })}
          </AntTabs>
        </div>
        <div className="space-y-4 sm:w-1/2">
          <div className="text-lg font-display">
            {t("pages.nutritionCalculator.resultsHeader")}
          </div>
          <div className="p-4 bg-default" style={section?.macrosContainerStyle}>
            <div
              className="mb-2 text-xl font-display"
              style={section?.macrosHeaderStyle}
            >
              {t("pages.nutritionCalculator.macrosHeader")}
            </div>
            <div
              className="mb-4 border-b-2 border-default"
              style={section?.macrosHeaderBorderStyle}
            />
            <div className="space-y-2">
              {MACROS.map((macro, i) => {
                return (
                  <div
                    key={`calculator_macro_${i}`}
                    className="flex items-center justify-between text-sm"
                  >
                    <div>
                      {t(`pages.nutritionCalculator.macros.label.${macro}`)}
                    </div>
                    <div className="font-semibold">
                      {getMacroTotal(macro)}{" "}
                      {t(`pages.nutritionCalculator.macros.suffix.${macro}`)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {Object.keys(selectedItems).length > 0 && (
            <div className="p-4 bg-default">
              <div className="flex items-center justify-between mb-2">
                <div className="text-lg font-display">
                  {t("pages.nutritionCalculator.selectedHeader")}
                </div>
                <a
                  className="float-right underline text-default hover:underline hover:text-default"
                  onClick={() => setSelectedItems({})}
                >
                  {t("pages.nutritionCalculator.resetButtonTitle")}
                </a>
              </div>
              <div className="mb-4 border-b-2 border-default" />
              <div className="space-y-2">
                {Object.keys(selectedItems).map((label) => {
                  return (
                    <div
                      key={`selected_${label}`}
                      className="flex items-center justify-between text-sm"
                    >
                      <div>{label}</div>
                      <QuantityControl
                        className="w-auto"
                        size="small"
                        quantity={selectedItems[label]?.quantity || 0}
                        onChange={(quantity) =>
                          onQuantityChange(selectedItems[label], quantity)
                        }
                        debounce={0}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
