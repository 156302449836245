export function updateManifest() {
  if (!window.manifestUpdated) {
    const manifest = {
      short_name: window.dinerManifest?.title,
      name: window.title,
      icons: [
        {
          src: "assets/logo192.png",
          type: "image/png",
          sizes: "192x192",
        },
      ],
      display: "standalone",
      theme_color: window.dinerManifest?.meta["theme-color"],
      background_color: "#ffffff",
    };

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#website-manifest")
      .setAttribute("href", manifestURL);

    window.manifestUpdated = true;
  }
}
