import React, { useContext, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { withItemAvailability } from "./MenuArea";
import MenuSectionWithItems from "./MenuSectionWithItems";
import MenuContext from "./menuContext";

MenuSubSection.propTypes = {
  section: PropTypes.object,
  menuSectionRefs: PropTypes.object,
  sidebarRefIndex: PropTypes.number,
  setSelectedItem: PropTypes.func,
  setSelectedItemSection: PropTypes.func,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
  cartData: PropTypes.object,
};

export default function MenuSubSection({
  section,
  menuSectionRefs,
  sidebarRefIndex,
  setSelectedItem,
  setSelectedItemSection,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
  cartData,
}) {
  const { t } = useTranslation();
  const { menu } = useContext(MenuContext);

  const subSectionRef = useRef(null);
  const dataTestId = `${section.label}-menu-sub-section`;

  if (subSectionRef?.current && menuSectionRefs?.current) {
    menuSectionRefs.current[section.id] = subSectionRef.current;
  }

  return (
    <div className="relative">
      <a
        id={`menu_section_${section?.id}`}
        ref={subSectionRef}
        data-sidebar-ref-index={sidebarRefIndex}
        className="absolute -top-18"
      />
      <div data-testid={dataTestId}>
        <MenuSectionWithItems
          isSubSection
          section={section}
          items={withItemAvailability(menu.isAvailable, section?.items, t)}
          setSelectedItem={setSelectedItem}
          setSelectedItemSection={() => setSelectedItemSection(section)}
          showPreOrderModal={showPreOrderModal}
          showAvailabilityModal={showAvailabilityModal}
          showMenuSectionDisabledModal={showMenuSectionDisabledModal}
          cartData={cartData}
        />
      </div>
    </div>
  );
}
