import { gql } from "@apollo/client";

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    line1
    line2
    latitude
    longitude
    postalCode
    notes
    isContactless
    isCutleryRequired
    user {
      selectedAddressId
    }
    availableOutlets {
      id
    }
    nearestOutlet {
      id
    }
  }
`;

export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress(
    $line1: String!
    $line2: String
    $latitude: Float!
    $longitude: Float!
    $postalCode: String!
    $notes: String
    $isContactless: Boolean!
    $isCutleryRequired: Boolean!
  ) {
    createAddress(
      input: {
        line1: $line1
        line2: $line2
        latitude: $latitude
        longitude: $longitude
        postalCode: $postalCode
        notes: $notes
        isContactless: $isContactless
        isCutleryRequired: $isCutleryRequired
      }
    ) {
      ...AddressFields
    }
  }
  ${ADDRESS_FIELDS}
`;
