import React, { useState, useEffect, useMemo } from "react";

import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import { GET_ORDER } from "@/graphql/queries/getOrder";
import useAnalytics from "@/hooks/useAnalytics";
import constants from "@/utils/constants";

OrderStatus.propTypes = {
  order: PropTypes.object,
  track: PropTypes.bool,
  formattedServingDate: PropTypes.string,
};

export default function OrderStatus({ track, order, formattedServingDate }) {
  const isFutureDate =
    new Date(order.servingDate).setHours(0, 0, 0, 0) >
    new Date().setHours(0, 0, 0, 0);
  const isToday =
    new Date(order.servingDate).setHours(0, 0, 0, 0) ===
    new Date().setHours(0, 0, 0, 0);

  const { t } = useTranslation();
  const { analytics, events } = useAnalytics();
  const [statusOpened, setStatusOpened] = useState(isFutureDate || isToday);
  const [orderState, setOrderState] = useState("");

  const orderStatus = useMemo(
    () => getOrderStatus(),
    [orderState, order.state, order.fulfilmentState],
  );

  const timeline = useMemo(() => getTimeline(), [order.fulfilmentType]);

  const { stopPolling, startPolling } = useQuery(GET_ORDER, {
    variables: { id: parseInt(order.id) },
    context: {
      graph: "diners",
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getOrder) {
        setOrderState(data?.getOrder?.state);
      }
    },
  });

  useEffect(() => {
    if (statusOpened) {
      analytics.track(events.order_status_viewed);
    }
  }, [statusOpened]);

  useEffect(() => {
    const isOrderFulfilled =
      orderStatus === constants.ORDER_STATUSES.READY_FOR_PICKUP ||
      orderStatus === constants.ORDER_STATUSES.COMPLETED ||
      orderStatus === constants.ORDER_STATUSES.CANCELLED;

    if (isToday && statusOpened && !isOrderFulfilled) {
      startPolling(10000);
    } else {
      stopPolling();
    }
  }, [statusOpened]);

  function getTimeline() {
    switch (order.fulfilmentType) {
      case constants.FULFILMENT_TYPES.DELIVERY:
        return constants.ORDER_STATUS_TIMELINES.DELIVERY;
      case constants.FULFILMENT_TYPES.PICKUP:
        return constants.ORDER_STATUS_TIMELINES.PICKUP;
    }
    return [];
  }

  function getOrderStatus() {
    const state = orderState || order.state;
    switch (state) {
      case constants.ORDER_STATUSES.CONFIRMED:
        switch (order.fulfilmentState) {
          case constants.ORDER_STATUSES.ASSIGNED:
          case constants.ORDER_STATUSES.DISPATCHED:
          case constants.ORDER_STATUSES.READY_FOR_PICKUP:
            return order.fulfilmentState;
        }
    }
    return order.state;
  }

  if (isFutureDate) {
    return (
      <li className="py-4">
        <div className="flex-grow mr-2 italic">
          {t("account.orders.orderStatusFutureDateHeader", {
            servingDate: formattedServingDate,
          })}
        </div>
      </li>
    );
  }

  return (
    track &&
    timeline.length > 0 &&
    ![
      constants.ORDER_STATUSES.COMPLETED,
      constants.ORDER_STATUSES.CANCELLED,
    ].includes(orderStatus) && (
      <li className="py-4">
        <div
          className="flex items-center text-sm font-bold cursor-pointer text-default "
          onClick={() => setStatusOpened((statusOpened) => !statusOpened)}
        >
          <div className="flex-grow mr-2">
            {t("account.orders.orderStatusHeader")}
          </div>
          <ChevronRightIcon
            className={classNames([
              "h-4 w-4 transform transition-transform ease-in-out",
              {
                "rotate-90": statusOpened,
              },
            ])}
          />
        </div>
        <Transition
          show={statusOpened}
          enter="transition-opacity duration-75"
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave="transition-opacity duration-150"
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}
        >
          <div className="relative flow-root">
            <div className="absolute border-l border-gray-500 border-dashed flow-line left-2 inset-y-6"></div>
            <ul>
              {timeline.map((event, index) => (
                <li key={index}>
                  <div className="relative">
                    <div className="relative flex space-x-3">
                      <div className="w-6 sm:w-12">
                        <div className="relative flex items-center w-8 h-12 justify-items-center sm:w-12">
                          <span
                            className={classNames(
                              "rounded-full justify-center",
                              index > timeline.indexOf(orderStatus)
                                ? "bg-gray-500"
                                : "bg-green-600",
                              orderStatus === event
                                ? "h-4 w-4 animate-ping"
                                : "h-2 w-2 mt-1 ml-1",
                            )}
                          ></span>
                          {orderStatus === event && (
                            <span className="h-3 w-3 bg-green-600 relative -left-3.5 rounded-full"></span>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between flex-grow space-x-4">
                        <div
                          className={classNames(
                            "m-0 text-sm text-default",
                            orderStatus === event && "font-bold",
                            index > timeline.indexOf(orderStatus)
                              ? "opacity-50"
                              : "",
                          )}
                        >
                          {t(`account.orders.track.${event}`, {
                            labelForPickup: order.outlet.labelForPickup,
                          })}
                          {(event === constants.ORDER_STATUSES.DISPATCHED ||
                            event === constants.ORDER_STATUSES.ASSIGNED) &&
                            orderStatus === event && (
                              <div className="mt-1 pl-7 w-[210px] justify-between text-xs border border-gray-400 p-4">
                                <strong>
                                  {t(`account.orders.deliveredBy`, {
                                    logisticsName: t(
                                      `account.orders.logistics.${order.logisticsType}`,
                                    ),
                                  })}
                                </strong>
                                {order.courierName && (
                                  <div className="mt-2">
                                    <br />
                                    {order.courierName}
                                    <br />
                                    {order.courierNumber && (
                                      <a
                                        className="underline"
                                        href={`tel:${order.courierNumber}`}
                                      >
                                        {order.courierNumber}
                                      </a>
                                    )}
                                  </div>
                                )}
                                {order.trackingUrl && (
                                  <Button
                                    className="mt-4"
                                    type="primary"
                                    onClick={() =>
                                      window.open(order.trackingUrl, "_blank")
                                    }
                                  >
                                    {t("account.orders.trackingLink")}
                                  </Button>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Transition>
      </li>
    )
  );
}
