import PropTypes from "prop-types";

Announcement.propTypes = {
  children: PropTypes.any,
};

export default function Announcement({ children }) {
  return (
    <div className="bg-primary text-on-primary text-center p-2">{children}</div>
  );
}
