import React from "react";

import PropTypes from "prop-types";

Image.propTypes = {
  rest: PropTypes.any,
};

export default function Image({ ...rest }) {
  let { src, ...props } = rest;

  if (
    !import.meta.env.DEV &&
    import.meta.env.VITE_OPTIMIZE_IMAGE &&
    src?.startsWith("https://storage.googleapis.com")
  ) {
    src = src.replace(
      "https://storage.googleapis.com",
      `${window.location.origin}/assets/images`,
    );
  }

  return <img src={src} {...props} />;
}
