import posthog from "posthog-js";

import enhancedEcommerceEvents from "@/lib/analytics/enhancedEcommerceEvents";
import { facebookEvents } from "@/lib/analytics/facebookEvents";
import tikTokEvents from "@/lib/analytics/tikTokEvents";

function gtag(event, action, properties) {
  if (typeof window !== "undefined") {
    if (window.gtagEnabled) {
      window.gtag(event, action, properties);
    } else {
      // GTag failed to load
    }
  }

  return false;
}

function ttq(event, properties) {
  if (typeof window !== "undefined") {
    if (window.ttq) {
      const enhancedResult = tikTokEvents(event, properties);
      window.ttq.track(enhancedResult?.event, enhancedResult?.properties);
    } else {
      // TikTok failed to load
    }
  }

  return false;
}

function fbq(event, action, properties) {
  if (typeof window !== "undefined") {
    if (window.facebookPixelEnabled) {
      window.fbq(event, action, properties);
    } else {
      // Facebook Pixel failed to load
    }
  }

  return false;
}

function dataLayer(event, properties) {
  // If GTM is not loaded, all data is just pushed to an array.
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "flush",
    eventProps: null,
    ecommerce: null,
  }); // We need to flush dataLayer eventProps and ecommerce so it does not carry any existing values over

  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#data-layer
  let dataLayerEvent;
  if (window.dinerManifest?.useEnhancedEcommerce) {
    dataLayerEvent = enhancedEcommerceEvents(event, properties);
  } else {
    dataLayerEvent = {
      event,
      eventProps: properties,
    };
  }

  window.dataLayer.push(dataLayerEvent);
}

const Analytics = {
  page(path) {
    gtag("event", "page_view", {
      page_path: path,
      page_location: window.location,
      page_title: document.title,
    });
    // Push to dataLayer for GTM usage
    dataLayer("pageview", null);
  },

  track(event, properties = {}) {
    gtag("event", event, properties);

    ttq(event, properties);

    // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events

    if (facebookEvents?.[event]) {
      fbq("track", facebookEvents?.[event], properties);
    } else {
      fbq("trackCustom", event, properties);
    }

    // Push to dataLayer for GTM usage
    dataLayer(event, properties);
  },

  identify(userId, traits = {}) {
    // PostHog
    posthog.identify(userId);
    posthog.people.set(traits);
  },
};

export default Analytics;
