import { gql } from "@apollo/client";

export const SUBMIT_CONTACT_FORM_MUTATION = gql`
  mutation SubmitContactForm(
    $formType: String
    $name: String!
    $email: String!
    $contactNumber: String
    $subject: String
    $message: String!
  ) {
    submitContactForm(
      input: {
        formType: $formType
        name: $name
        email: $email
        contactNumber: $contactNumber
        subject: $subject
        message: $message
      }
    ) {
      id
    }
  }
`;
