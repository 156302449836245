import React, { useContext } from "react";

import { useQuery } from "@apollo/client";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import { useTranslation } from "react-i18next";

import PointIcon from "@/common/Loyalty/PointIcon";
import ConfigContext from "@/components/Config/configContext";
import { POINT_BALANCE_WITH_PROFILE_QUERY } from "@/graphql/queries/getProfile";
import formatPrice from "@/utils/formatPrice";

export function PointBalanceCard() {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const pointProgram = configQuery.config?.pointProgram;

  const { data, loading } = useQuery(POINT_BALANCE_WITH_PROFILE_QUERY, {
    context: {
      graph: "diners",
      headers: { "X-Point-Program-Hash": pointProgram?.pointProgramHash },
    },
    fetchPolicy: "cache-and-network",
  });

  if (!pointProgram || loading || !(pointProgram.currencyValueInCents > 0))
    return null;
  const { pointBalance, profile } = data;

  return (
    <div className="overflow-hidden rounded-lg border border-white border-opacity-50 shadow-basic font-poppins shadow">
      <div
        className={`flex flex-col flex-grow p-5 backdrop-blur-[20px] bg-opacity-70 gap-2`}
        style={{
          color: pointProgram?.colors?.textColor,
          backgroundColor: `${pointProgram?.colors?.brandColor}b3`,
        }}
      >
        <div className="flex items-start">
          <div className="flex-grow font-semibold tracking-wider uppercase text-xs">
            {t("account.loyalty.wallet")}
          </div>
          <div
            onClick={() => {
              if (pointProgram?.userPortalUrl) {
                window.open(
                  pointProgram.userPortalUrl,
                  "_blank",
                  "noopener,noreferrer",
                );
              }
            }}
            className="flex items-center text-xs font-semibold cursor-pointer min-w-fit"
          >
            {t("account.loyalty.goToWallet")}
            <ChevronRightIcon className="w-4 h-4 stroke-[2.5px]" />
          </div>
        </div>

        <div className="flex flex-col">
          {pointProgram?.isCustomCurrency ? (
            <>
              <div className="flex items-center text-4xl font-semibold gap-1">
                <div> {pointBalance?.points}</div>
                <PointIcon size={40} />
              </div>
              <div className="text-xl">
                {pointProgram?.currencyNames?.plural}
              </div>
              <div className="text-xs font-inter">
                {t("account.loyalty.worth", {
                  pointValue: formatPrice(pointBalance?.value),
                })}
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center text-4xl font-semibold">
                {formatPrice(pointBalance?.value)}
              </div>
              <div className="text-xl">{t("account.loyalty.cashback")}</div>
            </>
          )}
        </div>
      </div>

      {!!profile && (
        <div className="flex flex-col flex-grow text-sm bg-white py-3 px-5">
          <div>{profile?.name}</div>
          <div className="font-inter text-gray-400">{profile?.email}</div>
        </div>
      )}
    </div>
  );
}

export default PointBalanceCard;
