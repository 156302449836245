import React, { useEffect, useContext, useMemo, useState } from "react";

import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Spin from "@/common/Spin";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import {
  REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_MUTATION,
  REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/removeAlcoholicBeveragesFromCart";
import {
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import { PROFILE_QUERY } from "@/graphql/queries/getProfile";
import graphqlConstants from "@/graphql/utils/constants";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import useAuth from "@/hooks/useAuth";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";
import getAgeFromDateOfBirth, {
  ALCOHOL_LEGAL_AGE,
} from "@/utils/getAgeFromDateOfBirth";

import CartFooterSummary from "./CartFooterSummary";
import CartPromotedProducts from "./CartPromotedProducts";
import VerifyAlcoholAgeModal from "./VerifyAlcoholAgeModal";

CartFooter.propTypes = {
  isCartOpen: PropTypes.bool,
  availableFulfilmentTypes: PropTypes.arrayOf(PropTypes.string),
  fulfilmentType: PropTypes.string,
  paymentBreakdown: PropTypes.object,
  outlet: PropTypes.object,
  alcoholicBeverageIds: PropTypes.arrayOf(PropTypes.number),
  topLevelItems: PropTypes.arrayOf(PropTypes.object),
  stateCheckingOut: PropTypes.array,
};

export default function CartFooter({
  isCartOpen,
  availableFulfilmentTypes,
  fulfilmentType,
  paymentBreakdown,
  outlet,
  alcoholicBeverageIds,
  topLevelItems,
  stateCheckingOut,
}) {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const { cartValidations, cartQuery: data } = useContext(CartContext);
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [verifyAlcoholAgeModalVisible, setVerifyAlcoholAgeModalVisible] =
    useState(false);
  const alcoholAgeVerified =
    localStorage.getItem(graphqlConstants.ALCOHOL_AGE_VERIFIED) === "true";
  const [isCheckingOut, setCheckingOut] = stateCheckingOut;

  const minimumOrderValueNotMet = useMemo(
    () =>
      fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
      paymentBreakdown.minimumOrderValue &&
      paymentBreakdown.subtotal < paymentBreakdown.minimumOrderValue,
    [
      fulfilmentType,
      paymentBreakdown.minimumOrderValue,
      paymentBreakdown.subtotal,
    ],
  );

  const alcoholicBeverages = useMemo(
    () =>
      topLevelItems.filter((topLevelItem) =>
        alcoholicBeverageIds.includes(topLevelItem.id),
      ),
    [alcoholicBeverageIds, topLevelItems],
  );

  const { loggedIn } = useAuth();
  const { data: profileData } = useQuery(PROFILE_QUERY, {
    // only query if logged in and isCheckingOut is false
    skip: !(loggedIn() && !isCheckingOut),
    context: { graph: "diners" },
  });
  const age = useMemo(() => {
    if (profileData?.profile?.dateOfBirth) {
      return getAgeFromDateOfBirth(profileData.profile.dateOfBirth);
    }
    return 0;
  }, [profileData?.profile?.dateOfBirth]);

  const client = useApolloClient();
  const [removeAlcoholicBeveragesFromCart, { loading: removeLoading }] =
    useMutation(
      useOptimisedDinerCartType
        ? REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_OPTIMISED_MUTATION
        : REMOVE_ALCOHOLIC_BEVERAGES_FROM_CART_MUTATION,
      {
        context: { graph: "diners" },
        errorPolicy: "all",
        update: (_, { data }) => {
          const updatedCartData = useOptimisedDinerCartType
            ? data?.removeAlcoholicBeveragesFromCartOptimised
            : data?.removeAlcoholicBeveragesFromCart;
          // Reading query before updating to find removed product
          const { cart } = client.readQuery({
            query: useOptimisedDinerCartType
              ? GET_CART_OPTIMISED_QUERY
              : GET_CART_QUERY,
          });
          if (cart?.topLevelItems && cart?.alcoholicBeverageIds) {
            cart.topLevelItems
              .filter((topLevelItem) =>
                cart?.alcoholicBeverageIds?.includes(topLevelItem?.id),
              )
              .forEach((topLevelItem) => {
                analytics.trackEventWithProperties(
                  events.product_removed,
                  topLevelItem,
                );
              });
          }

          client.writeQuery({
            query: useOptimisedDinerCartType
              ? GET_CART_OPTIMISED_QUERY
              : GET_CART_QUERY,
            data: {
              cart: updatedCartData,
              ...(useOptimisedDinerCartType
                ? { cartOptimised: updatedCartData }
                : {}),
            },
          });

          if (updatedCartData) {
            setVerifyAlcoholAgeModalVisible(false);
            proceedToCheckout();
          }
        },
      },
    );

  useEffect(() => {
    if (!isCartOpen) {
      setCheckingOut(false);
    }
  }, [isCartOpen]);

  function proceedToCheckout() {
    setCheckingOut(true);
    analytics.trackEventWithProperties(
      events.checkout_step_viewed,
      {},
      { configQuery },
    );
  }

  return (
    <div className="flex flex-col cart-footer text-default">
      {/* Cross-sell products */}
      <CartPromotedProducts isCartOpen={isCartOpen} />

      <CartFooterSummary
        isCheckingOut={isCheckingOut}
        availableFulfilmentTypes={availableFulfilmentTypes}
        fulfilmentType={fulfilmentType}
        paymentBreakdown={paymentBreakdown}
        outlet={outlet}
      />
      {!isCheckingOut && (
        <div className="flex p-4 cart-footer-action">
          <Button
            type="primary"
            className="w-full py-4"
            disabled={minimumOrderValueNotMet}
            onClick={() => {
              trackEvent(eventsV2.beginCheckout, data?.cartOptimised);
              if (cartValidations.noAddressOrOutletSelected) {
                emitEvent("selectAddress");
              } else if (cartValidations.noTimeslotSelected) {
                emitEvent("selectTimeslot");
              } else if (
                alcoholicBeverageIds?.length > 0 &&
                age < ALCOHOL_LEGAL_AGE &&
                !alcoholAgeVerified
              ) {
                setVerifyAlcoholAgeModalVisible(true);
              } else {
                proceedToCheckout();
              }
            }}
          >
            {typeof paymentBreakdown.total === "number" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("menu.cart.footer.labels.checkout", {
                    // price should not show negative
                    amount: formatPrice(Math.max(0, paymentBreakdown.total)),
                  }),
                }}
              />
            ) : (
              <Spin logoClassName="ml-2 w-5 h-5 text-on-primary" />
            )}
          </Button>
          {verifyAlcoholAgeModalVisible && (
            <VerifyAlcoholAgeModal
              loading={removeLoading}
              onYes={() => {
                // store alcohol age verification in local storage
                localStorage.setItem(
                  graphqlConstants.ALCOHOL_AGE_VERIFIED,
                  true,
                );
                setVerifyAlcoholAgeModalVisible(false);
                proceedToCheckout();
              }}
              onNo={() => {
                removeAlcoholicBeveragesFromCart();
              }}
              onCancel={() => setVerifyAlcoholAgeModalVisible(false)}
            >
              {alcoholicBeverages.length > 0 && (
                <ul className="pl-4 mt-2 space-y-1">
                  {alcoholicBeverages.map((alcoholicBeverage) => (
                    <li
                      key={`alcoholic_beverage_${alcoholicBeverage.id}`}
                      className="list-disc"
                    >
                      {alcoholicBeverage.name}
                      {alcoholicBeverage.quantity > 1 && (
                        <>
                          {" "}
                          {t("common.quantity", {
                            quantity: alcoholicBeverage.quantity,
                          })}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </VerifyAlcoholAgeModal>
          )}
        </div>
      )}
    </div>
  );
}
