import React, { useContext } from "react";

import ConfigContext from "@/components/Config/configContext";

import PointBalanceCard from "./PointBalanceCard";

export default function Loyalty() {
  const { configQuery } = useContext(ConfigContext);
  const pointProgram = configQuery.config?.pointProgram;

  return (
    <div>
      <h2 className="hidden mb-4 text-2xl font-display sm:block">
        {pointProgram?.label}
      </h2>
      <PointBalanceCard />
    </div>
  );
}
