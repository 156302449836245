import { gql } from "@apollo/client";

export const GET_ACTIVE_ANNOUNCEMENT_QUERY = gql`
  query GetActiveAnnouncement($outletId: Int) {
    activeAnnouncement(outletId: $outletId) {
      id
      title
      message
      outletIds
      storefrontIds
      backgroundColor
      textColor
      isDismissable
      linkUrl
      startDatetime
      endDatetime
      isActive
      createdAt
      updatedAt
    }
  }
`;
