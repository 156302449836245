import React, { useLayoutEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HasErrorTooltip from "@/common/HasErrorTooltip";
import Tooltip from "@/common/Tooltip";
import { LG_BREAKPOINT } from "@/utils/constants";

import SelectedAddressLabel from "./SelectedAddressLabel";
import SelectedTimeslotLabel from "./SelectedTimeslotLabel";
import TableIcon from "./TableIcon";

AddressTimeslotHeader.propTypes = {
  cartLoading: PropTypes.bool,
  currentOutletClosedError: PropTypes.string,
  allOutletsClosedError: PropTypes.string,
  selectedTimeslotError: PropTypes.string,
  selectedAddressOrPickupLabel: PropTypes.string,
  selectedTimeslotLabel: PropTypes.string,
  isAddressOrOutletSelected: PropTypes.bool,
  setCreateAddressOpen: PropTypes.func,
  setTimeslotModalVisible: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  isTransparent: PropTypes.bool,
  isAddressModalVisible: PropTypes.bool,
  isTimeslotModalVisible: PropTypes.bool,
  brandIsOnlyAvailableForPickup: PropTypes.bool,
  hasOnlyOneOutletToPickupFrom: PropTypes.bool,
  tableName: PropTypes.string,
  outletQrHash: PropTypes.string,
  tableQrHash: PropTypes.string,
  hideQrTimeslots: PropTypes.bool,
};

export default function AddressTimeslotHeader({
  cartLoading,
  currentOutletClosedError,
  allOutletsClosedError,
  selectedTimeslotError,
  selectedAddressOrPickupLabel,
  selectedTimeslotLabel,
  isAddressOrOutletSelected,
  setCreateAddressOpen,
  setTimeslotModalVisible,
  isTransparent,
  isAddressModalVisible,
  isTimeslotModalVisible,
  brandIsOnlyAvailableForPickup,
  hasOnlyOneOutletToPickupFrom,
  tableName,
  tableQrHash,
  hideQrTimeslots,
}) {
  const { t } = useTranslation();

  const [visible, setVisibility] = useState(false);

  const changeVisibilityOnClick = (visibility) => {
    setVisibility(visibility);
  };

  const [isMobileVer, setIsMobileVer] = useState(
    window.innerWidth < LG_BREAKPOINT,
  );

  const updateWindowDimensions = () => {
    setIsMobileVer(window.innerWidth < LG_BREAKPOINT);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      <div className="flex space-x-3 overflow-x-hidden">
        {/* Show the header when delivery is available or has more that one outlet to pickup from */}
        {!(brandIsOnlyAvailableForPickup && hasOnlyOneOutletToPickupFrom) ? (
          <HasErrorTooltip
            color="var(--color-danger)"
            visible={visible}
            error={currentOutletClosedError}
            isMobileVer={isMobileVer}
          >
            <SelectedAddressLabel
              className="w-48 truncate xl:w-72 xl:overflow-visible"
              onClick={() => setCreateAddressOpen(true)}
              label={selectedAddressOrPickupLabel}
              isTransparent={isTransparent}
            />
          </HasErrorTooltip>
        ) : (
          <Tooltip
            color="var(--color-primary)"
            title={selectedAddressOrPickupLabel}
            destroyTooltipOnHide
          >
            <SelectedAddressLabel
              className="w-48 truncate xl:w-72 xl:overflow-visible"
              readOnly
              label={selectedAddressOrPickupLabel}
              isTransparent={isTransparent}
            />
          </Tooltip>
        )}
        {tableQrHash && (
          <div>
            <div
              className={classNames([
                "flex items-center w-32 px-1 py-2 truncate cursor-pointer xl:overflow-visible",
                isTransparent
                  ? "text-on-transparent-header border-on-transparent-header"
                  : "border-on-header",
              ])}
            >
              <div
                className={classNames([
                  "mr-2",
                  isTransparent ? "text-on-transparent-header" : "text-primary",
                ])}
              >
                <TableIcon />
              </div>
              {t("navbar.tableName", { tableName })}
            </div>
          </div>
        )}
        {!hideQrTimeslots &&
          (isAddressOrOutletSelected ? (
            <HasErrorTooltip
              color="var(--color-danger)"
              visible={visible}
              error={selectedTimeslotError}
              isMobileVer={isMobileVer}
            >
              <HasErrorTooltip
                color="var(--color-danger)"
                visible={visible}
                error={allOutletsClosedError}
                isMobileVer={isMobileVer}
              >
                <SelectedTimeslotLabel
                  className={classNames([
                    "w-48 truncate xl:w-72 xl:overflow-visible",
                    (selectedTimeslotError || allOutletsClosedError) &&
                      "text-danger border-danger",
                  ])}
                  onClick={() => setTimeslotModalVisible(true)}
                  label={selectedTimeslotLabel}
                  isTransparent={isTransparent}
                />
              </HasErrorTooltip>
            </HasErrorTooltip>
          ) : (
            <HasErrorTooltip
              color="var(--color-danger)"
              visible={visible}
              error={t("navbar.addressToolTip")}
              isMobileVer={isMobileVer}
            >
              <SelectedTimeslotLabel
                className="w-48 truncate opacity-50 xl:w-72 xl:overflow-visible"
                label={selectedTimeslotLabel}
                isTransparent={isTransparent}
                onClick={() =>
                  changeVisibilityOnClick(
                    !isAddressModalVisible &&
                      !isTimeslotModalVisible &&
                      !cartLoading,
                  )
                }
              />
            </HasErrorTooltip>
          ))}
      </div>
    </>
  );
}
