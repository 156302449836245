import { gql } from "@apollo/client";

export const USER_SIGN_UP_WITH_OTP_OR_SSO_OPERATION_NAME =
  "UserSignupWithOtpOrSso";

export const USER_SIGNUP_WITH_OTP_OR_SSO_MUTATION = gql`
  mutation ${USER_SIGN_UP_WITH_OTP_OR_SSO_OPERATION_NAME}($input: UserSignupWithOTPOrSSOInput!) {
    userSignupWithOtpOrSso(input: $input) {
      accessToken
      refreshToken
      user {
        id
        name
      }
    }
  }
`;
