import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import cx from "classnames";
import { useLocation } from "react-router-dom";

import CartContext from "@/components/Cart/cartContext";
import { GET_ACTIVE_LANDING_PAGE_BANNER_QUERY } from "@/graphql/queries/getActiveLandingPageBanner";
import getUrlParams from "@/utils/getUrlParams";

import Button from "../Button";

const BACKGROUND_POSITION_OPTIONS = {
  TOP_LEFT: {
    label: "Top Left",
    value: "top_left",
  },
  TOP_CENTER: {
    label: "Top Center",
    value: "top_center",
  },
  TOP_RIGHT: {
    label: "Top Right",
    value: "top_right",
  },
  MIDDLE_LEFT: {
    label: "Middle Left",
    value: "middle_left",
  },
  MIDDLE_CENTER: {
    label: "Middle Center",
    value: "middle_center",
  },
  MIDDLE_RIGHT: {
    label: "Middle Right",
    value: "middle_right",
  },
  BOTTOM_LEFT: {
    label: "Bottom Left",
    value: "bottom_left",
  },
  BOTTOM_CENTER: {
    label: "Bottom Center",
    value: "bottom_center",
  },
  BOTTOM_RIGHT: {
    label: "Bottom Right",
    value: "bottom_right",
  },
};

const CONTENT_BOX_WIDTH_OPTIONS = {
  FULL: {
    label: "100%",
    value: 100,
  },
  HALF: {
    label: "50%",
    value: 50,
  },
  ONE_THIRD: {
    label: "35%",
    value: 35,
  },
};

const getCSSBackgroundPositionValue = (backgroundPositionOption) => {
  switch (backgroundPositionOption) {
    case BACKGROUND_POSITION_OPTIONS.TOP_LEFT.value:
      return "left top";
    case BACKGROUND_POSITION_OPTIONS.TOP_CENTER.value:
      return "center top";
    case BACKGROUND_POSITION_OPTIONS.TOP_RIGHT.value:
      return "right top";
    case BACKGROUND_POSITION_OPTIONS.MIDDLE_LEFT.value:
      return "left center";
    case BACKGROUND_POSITION_OPTIONS.MIDDLE_CENTER.value:
      return "center center";
    case BACKGROUND_POSITION_OPTIONS.MIDDLE_RIGHT.value:
      return "right center";
    case BACKGROUND_POSITION_OPTIONS.BOTTOM_LEFT.value:
      return "left bottom";
    case BACKGROUND_POSITION_OPTIONS.BOTTOM_CENTER.value:
      return "center bottom";
    case BACKGROUND_POSITION_OPTIONS.BOTTOM_RIGHT.value:
      return "right bottom";
    default:
      return "center";
  }
};

const getJustifyContentCssClass = (boxContentPositionOption) => {
  switch (boxContentPositionOption) {
    case CONTENT_ALIGNMENT_OPTIONS.LEFT.value:
      return "justify-start";
    case CONTENT_ALIGNMENT_OPTIONS.CENTER.value:
      return "justify-center";
    case CONTENT_ALIGNMENT_OPTIONS.RIGHT.value:
      return "justify-end";
    default:
      return "justify-start";
  }
};

const getContentBoxWidthCssClass = (contentBoxWidth) => {
  switch (contentBoxWidth) {
    case CONTENT_BOX_WIDTH_OPTIONS.FULL.value:
      return "w-full";
    case CONTENT_BOX_WIDTH_OPTIONS.HALF.value:
      return "w-2/4";
    case CONTENT_BOX_WIDTH_OPTIONS.ONE_THIRD.value:
      return "w-[35%]";
    default:
      return "w-full";
  }
};

const CONTENT_ALIGNMENT_OPTIONS = {
  LEFT: {
    label: "Left",
    value: "left",
  },
  CENTER: {
    label: "Center",
    value: "center",
  },
  RIGHT: {
    label: "Right",
    value: "right",
  },
};

const renderLandingPageBanner = ({
  imageUrl,
  backgroundImagePosition,
  contentBoxWidth,
  contentBoxPosition,
  contentAlignment,
  spaceAboveContent = 0,
  spaceAboveContentUnit = "px",
  spaceBelowContent = 0,
  spaceBelowContentUnit = "px",
  logoImageUrl,
  logoImageWidth,
  headline,
  description,
  hasButton,
  buttonUrl,
  buttonText,
  isLogoHidden,
  isPreviewMode,
}) => {
  return (
    <div
      className={cx("relative shadow-xl bg-cover")}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: getCSSBackgroundPositionValue(
          backgroundImagePosition,
        ),
      }}
    >
      {/* Content */}
      <div
        className={cx("max-w-screen-xl mx-auto p-3", {
          "flex items-start": !!contentBoxPosition,
          [getJustifyContentCssClass(contentBoxPosition)]: !!contentBoxPosition,
        })}
      >
        <div
          className={cx({
            [`text-${contentAlignment}`]: true,
            [getContentBoxWidthCssClass(contentBoxWidth)]: true,
          })}
          style={{
            marginTop: `${spaceAboveContent}${spaceAboveContentUnit}`,
            marginBottom: `${spaceBelowContent}${spaceBelowContentUnit}`,
          }}
        >
          {logoImageUrl && (
            <div
              className={cx("mb-6 inline-block", {
                hidden: isLogoHidden,
              })}
            >
              <img
                className="object-cover max-w-full"
                src={logoImageUrl}
                style={{
                  width: logoImageWidth,
                }}
              />
            </div>
          )}

          {headline && (
            <div
              className={cx("mb-3 font-display")}
              dangerouslySetInnerHTML={{ __html: headline }}
            ></div>
          )}

          {description && (
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}

          {hasButton && (!!buttonUrl || isPreviewMode) && !!buttonText && (
            <a href={buttonUrl} target="_blank" rel="noreferrer">
              <Button type="primary">{buttonText}</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const LandingPageBanner = () => {
  const location = useLocation();
  const params = getUrlParams(location);
  const isPreviewMode = params.bannerPreview === "true";
  const [activeLandingPageBanner, setActiveLandingPageBanner] = useState();
  const { cartQuery } = useContext(CartContext);

  const { data: activeLandingPageBannerData } = useQuery(
    GET_ACTIVE_LANDING_PAGE_BANNER_QUERY,
    {
      skip: isPreviewMode,
      context: { graph: "diners" },
      variables: {
        outletId: cartQuery?.cart?.outletId,
      },
    },
  );

  useEffect(() => {
    if (
      activeLandingPageBannerData?.activeLandingPageBanner?.id &&
      !isPreviewMode
    ) {
      setActiveLandingPageBanner(
        activeLandingPageBannerData.activeLandingPageBanner,
      );
    } else {
      setActiveLandingPageBanner(null);
    }
  }, [activeLandingPageBannerData?.activeLandingPageBanner, isPreviewMode]);

  useEffect(() => {
    const handler = (event) => {
      const data = JSON.parse(event.data);
      if (data.messageType === "banner") {
        setActiveLandingPageBanner(data);
      }
    };

    if (isPreviewMode) {
      window.addEventListener("message", handler);
    }

    return () => window.removeEventListener("message", handler);
  }, [isPreviewMode]);

  return (
    !!activeLandingPageBanner && (
      <>
        {/* Mobile */}
        <div className="md:hidden">
          {renderLandingPageBanner({
            imageUrl: activeLandingPageBanner?.mobileImageUrl,
            backgroundImagePosition:
              activeLandingPageBanner?.mobileBackgroundImagePosition,
            contentBoxWidth: CONTENT_BOX_WIDTH_OPTIONS.FULL.value,
            contentBoxPosition: null,
            contentAlignment: activeLandingPageBanner?.mobileContentAlignment,
            spaceAboveContent: activeLandingPageBanner?.mobileSpaceAboveContent,
            spaceAboveContentUnit:
              activeLandingPageBanner?.mobileSpaceAboveContentUnit,
            spaceBelowContent: activeLandingPageBanner?.mobileSpaceBelowContent,
            spaceBelowContentUnit:
              activeLandingPageBanner?.mobileSpaceBelowContentUnit,
            logoImageUrl: activeLandingPageBanner?.logoImageUrl,
            logoImageWidth: activeLandingPageBanner?.logoImageWidth,
            headline: activeLandingPageBanner?.headline,
            description: activeLandingPageBanner?.description,
            hasButton: activeLandingPageBanner?.hasButton,
            buttonUrl: activeLandingPageBanner?.buttonUrl,
            buttonText: activeLandingPageBanner?.buttonText,
            isLogoHidden: activeLandingPageBanner?.isMobileImageHidden,
            isPreviewMode,
          })}
        </div>

        {/* Desktop */}
        <div className="hidden md:block">
          {renderLandingPageBanner({
            imageUrl: activeLandingPageBanner?.desktopImageUrl,
            backgroundImagePosition:
              activeLandingPageBanner?.desktopBackgroundImagePosition,
            contentBoxWidth: activeLandingPageBanner?.desktopContentBoxWidth,
            contentBoxPosition:
              activeLandingPageBanner?.desktopContentBoxPosition,
            contentAlignment: activeLandingPageBanner?.desktopContentAlignment,
            spaceAboveContent:
              activeLandingPageBanner?.desktopSpaceAboveContent,
            spaceAboveContentUnit:
              activeLandingPageBanner?.desktopSpaceAboveContentUnit,
            spaceBelowContent:
              activeLandingPageBanner?.desktopSpaceBelowContent,
            spaceBelowContentUnit:
              activeLandingPageBanner?.desktopSpaceBelowContentUnit,
            logoImageUrl: activeLandingPageBanner?.logoImageUrl,
            logoImageWidth: activeLandingPageBanner?.logoImageWidth,
            headline: activeLandingPageBanner?.headline,
            description: activeLandingPageBanner?.description,
            hasButton: activeLandingPageBanner?.hasButton,
            buttonUrl: activeLandingPageBanner?.buttonUrl,
            buttonText: activeLandingPageBanner?.buttonText,
            isLogoHidden: activeLandingPageBanner?.isDesktopImageHidden,
            isPreviewMode,
          })}
        </div>
      </>
    )
  );
};

export default LandingPageBanner;
