import React from "react";

import classNames from "classnames";
import keyBy from "lodash/keyBy";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useTranslatedText from "@/hooks/useTranslationText";

import MenuItem from "./MenuItem";

MenuSectionWithItems.propTypes = {
  isSubSection: PropTypes.bool,
  section: PropTypes.shape({
    id: PropTypes.number,
    sectionId: PropTypes.number,
    isSubSection: PropTypes.bool,
    label: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    disabledReason: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        description: PropTypes.string,
        unitPriceFractional: PropTypes.number,
      }),
    ),
    subSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        sectionId: PropTypes.number,
        label: PropTypes.string,
        description: PropTypes.string,
        items: PropTypes.array,
      }),
    ),
    translation: PropTypes.shape({
      title: PropTypes.object,
      description: PropTypes.object,
    }),
  }),
  items: PropTypes.arrayOf(PropTypes.object),
  setSelectedItem: PropTypes.func,
  setSelectedItemSection: PropTypes.func,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
  cartData: PropTypes.object,
};

export default function MenuSectionWithItems({
  isSubSection,
  section,
  items,
  setSelectedItem,
  setSelectedItemSection,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
  cartData,
}) {
  const { t } = useTranslation();

  const cartQuantityTree = keyBy(cartData?.cart?.topLevelItems, "item.id");

  const sectionLabel = useTranslatedText({
    resource: section,
    fallbackValue: section?.label,
  });

  const sectionDescription = useTranslatedText({
    resource: section,
    fallbackValue: section?.description,
    key: "description",
  });

  return (
    <>
      <h1
        className={classNames(
          "mb-4.5 font-display",
          isSubSection ? "text-xl" : "text-2xl",
          `opacity-${section.disabled ? 50 : 100}`,
        )}
      >
        {sectionLabel}
      </h1>
      {section.disabledReason && (
        <div className="text-danger mb-4.5">{section.disabledReason}</div>
      )}
      {sectionDescription && (
        <div
          className={classNames(
            "mb-4.5 text-body font-body",
            `opacity-${section.disabled ? 50 : 100}`,
          )}
          dangerouslySetInnerHTML={{ __html: sectionDescription }}
        />
      )}
      <div
        className={classNames(
          "grid grid-cols-2 mt-4.5 gap-x-3 sm:gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3",
          `opacity-${section.disabled ? 50 : 100}`,
        )}
      >
        {items?.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            isHeroCard={item.isHighlight}
            id={parseInt(item?.id)}
            cartItemId={cartQuantityTree[`${item?.id}`]?.id}
            description={item?.description}
            unitPriceFractional={item?.unitPriceFractional}
            currency={item?.currency}
            isConfigurable={item?.isConfigurable}
            quantity={cartQuantityTree[`${item?.id}`]?.quantity}
            setSelectedItem={() => setSelectedItem(item)}
            horizontalImageUrl={item?.horizontalImageUrl}
            isLeadTimeBreached={item?.isLeadTimeBreached}
            menuSectionDisabled={section.disabled}
            menuSectionDisabledReason={section.disabledReason}
            setSelectedItemSection={setSelectedItemSection}
            humanizedLeadTime={item?.humanizedLeadTime}
            isAvailable={item?.isAvailable}
            humanizedAvailability={item?.humanizedAvailability}
            showPreOrderModal={showPreOrderModal}
            showAvailabilityModal={showAvailabilityModal}
            showMenuSectionDisabledModal={showMenuSectionDisabledModal}
            cart={cartData?.cart}
            itemStock={item?.itemStock}
          />
        ))}
        {(!items || items.length === 0) && (
          <h1 className="italic font-semibold lg:col-span-3 sm:col-span-2">
            {t("menu.menuSection.empty")}
          </h1>
        )}
      </div>
    </>
  );
}
