import { useContext } from "react";

import { useApolloClient } from "@apollo/client";
import { decamelizeKeys } from "humps";
import isEmpty from "lodash/isEmpty";

import ConfigContext from "@/components/Config/configContext";
import {
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import graphqlConstants from "@/graphql/utils/constants";
import analytics from "@/lib/analytics";
import { events } from "@/lib/analytics/events";
import constants from "@/utils/constants";

const eventQueryMap = (event, configQuery) => {
  switch (event) {
    case events.cart_viewed:
    case events.checkout_step_viewed:
    case events.checkout_started:
    case events.checkout_step_failed:
    case events.order_completed:
      return configQuery?.config?.features?.includes(
        constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
      )
        ? GET_CART_OPTIMISED_QUERY
        : GET_CART_QUERY;
    default:
      return null;
  }
};

const convertItemsToProducts = (items) => {
  return items?.map((item, index) => ({
    position: index + 1,
    name: item?.label,
    sku: item?.identifier,
    productId: item?.id,
    currency: item?.currency,
    price: item?.unitPriceFractional / 100,
    url: `${window.location.origin}/menu?itemId=${item?.id}`,
    imageUrl: item?.horizontalImageUrl,
  }));
};

const convertTopLevelItemsToProducts = (topLevelItems) => {
  return topLevelItems?.map((topLevelItem, index) => ({
    position: index + 1,
    category: topLevelItem?.item?.sections?.[0]?.label,
    name: topLevelItem?.name,
    sku: topLevelItem?.item?.identifier,
    productId: topLevelItem?.item?.id,
    currency: topLevelItem?.item?.currency,
    price: topLevelItem?.item?.unitPriceFractional / 100,
    url: `${window.location.origin}/menu?itemId=${topLevelItem?.item?.id}`,
    imageUrl: topLevelItem?.item?.horizontalImageUrl,
    quantity: topLevelItem?.quantity,
  }));
};

const cartProperties = (cart) => {
  return {
    orderId: cart?.id,
    affiliation: graphqlConstants.X_CLIENT_NAME,
    value: cart?.paymentBreakdown?.totalIncludingTax / 100,
    revenue: cart?.paymentBreakdown?.subtotal / 100,
    shipping: cart?.paymentBreakdown?.deliveryFee / 100,
    tax: cart?.paymentBreakdown?.tax / 100,
    surcharge: cart?.paymentBreakdown?.surcharge / 100,
    surchargeLabel: cart?.paymentBreakdown?.surchargeLabel,
    discount: cart?.paymentBreakdown?.discount / 100,
    coupon: cart?.promoCode,
    currency: cart?.topLevelItems?.[0]?.item?.currency, // Get currency from first top level item
    products: convertTopLevelItemsToProducts(cart?.topLevelItems),
  };
};

const eventPropertiesMap = (event, properties) => {
  switch (event) {
    case events.cart_viewed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#cart-viewed
      const cart = properties?.cartOptimised || properties?.cart || {};
      return {
        cartId: cart?.id,
        products: convertTopLevelItemsToProducts(cart?.topLevelItems),
      };
    }
    case events.checkout_step_viewed:
    case events.checkout_started:
    case events.order_completed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
      const cart = properties?.cartOptimised || properties?.cart || {};
      return cartProperties(cart);
    }
    case events.checkout_step_failed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
      // + error message
      const cart = properties?.cartOptimised || properties?.cart || {};
      return {
        error: properties?.error?.message,
        ...cartProperties(cart),
      };
    }
    case events.checkout_step_completed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-step-completed
      return {
        step: 2,
        checkoutId: properties?.identifier,
        paymentMethod:
          properties?.paymentDetails?.paymentLabel ?? properties?.paymentType,
        // TODO: Delivery or Pickup?
        // shippingMethod: "Fedex",
      };
    }
    case events.coupon_applied: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#coupon-applied
      return {
        orderId: properties?.id,
        cartId: properties?.id,
        couponId: properties?.id,
        couponName: properties?.promoCode,
        discount: properties?.discount,
      };
    }
    case events.coupon_removed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#coupon-removed
      return {
        orderId: properties?.id,
        cartId: properties?.id,
        couponId: properties?.id,
        couponName: properties?.promoCode,
        discount: properties?.discount,
      };
    }
    case events.coupon_denied: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#coupon-denied
      return {
        orderId: properties?.id,
        cartId: properties?.id,
        coupon: properties?.promoCode,
        reason: properties?.promoCodeError,
      };
    }
    case events.product_added: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
      return {
        ...convertTopLevelItemsToProducts([properties])[0],
      };
    }
    case events.product_removed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
      return {
        ...convertTopLevelItemsToProducts([properties])[0],
      };
    }
    case events.product_list_filtered:
    case events.product_list_viewed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-viewed
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-filtered
      return {
        listId: properties?.id,
        category: properties?.label,
        products: convertItemsToProducts(properties?.items),
      };
    }
    case events.product_viewed: {
      // https://segment.com/docs/connections/spec/ecommerce/v2/#product-viewed
      return {
        ...convertItemsToProducts([properties])[0],
      };
    }
    default:
      return null;
  }
};

export default function useAnalytics() {
  const client = useApolloClient();
  const { configQuery } = useContext(ConfigContext);
  const analyticsV2Enabled = configQuery?.config?.features?.includes(
    constants?.FEATURES.ANALYTICS_V2,
  );
  const trackEventWithProperties = (
    event,
    properties = {},
    additionalData = {},
  ) => {
    let _properties = properties;
    const query = eventQueryMap(event, additionalData?.configQuery);
    const clientQuery = query ? client.readQuery({ query }) : {};
    _properties = isEmpty(properties)
      ? clientQuery
      : {
          ...properties,
          ...clientQuery,
        };
    const parsedProperties = decamelizeKeys(
      eventPropertiesMap(event, _properties),
    );

    return analytics.track(event, parsedProperties);
  };

  let _analytics = analytics;
  _analytics.trackEventWithProperties = trackEventWithProperties;
  if (analyticsV2Enabled) {
    _analytics = {
      ..._analytics,
      trackEventWithProperties: () => {},
      track: () => {},
      page: () => {},
    };
  }

  return {
    analytics: _analytics,
    events,
  };
}
