import React from "react";

import PropTypes from "prop-types";
import "./TimeslotPickerModal.less";

import Modal from "@/common/Modal";
import Timeslots from "@/components/Menu/Timeslots";

TimeslotPickerModal.propTypes = {
  isTimeslotModalVisible: PropTypes.bool,
  closeTimeslotModal: PropTypes.func,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  cartQuery: PropTypes.object,
};

export default function TimeslotPickerModal({
  isTimeslotModalVisible,
  closeTimeslotModal,
  selectedDate,
  setSelectedDate,
  cartQuery,
}) {
  return (
    <Modal
      visible={isTimeslotModalVisible}
      onCancel={() => {
        if (
          cartQuery?.cart?.servingDate &&
          cartQuery?.cart?.servingDate !== selectedDate
        ) {
          setSelectedDate(cartQuery.cart.servingDate);
          setTimeout(() => {
            closeTimeslotModal();
          }, 0);
        } else {
          closeTimeslotModal();
        }
      }}
      footer={null}
      className="timeslot-picker-modal"
      wrapperClassName="timeslot-picker-modal-wrapper"
      width="100%"
      closable={false}
      centered
      zIndex={1010}
    >
      <Timeslots
        closeTimeslotModal={closeTimeslotModal}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </Modal>
  );
}
