import i18next from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enJs from "./en.js";
import zhJs from "./zh.js";

const resources = {
  en: {
    translation: enJs,
  },
  zh: {
    translation: zhJs,
  },
};

i18next
  .use(initReactI18next)
  // .use(LanguageDetector) // Will disable this for now as language selection is feature flipped
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
