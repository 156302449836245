import React, { useEffect, useState } from "react";

import AdyenCheckout from "@adyen/adyen-web";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import { GET_ADYEN_PAYMENT_METHODS_MUTATION } from "@/graphql/mutations/getAdyenPaymentMethods";
import { MAKE_ADYEN_PAYMENT_MUTATION } from "@/graphql/mutations/makeAdyenPayment";
import { GET_ADYEN_PAYMENT_DETAILS_QUERY } from "@/graphql/queries/getAdyenPaymentDetails";
import { GET_CART_ORDER_ID_QUERY } from "@/graphql/queries/getCartOrder";
import constants from "@/graphql/utils/constants";
import useDebounce from "@/hooks/useDebounce";
import {
  ADYEN_PAYMENT_AUTHORISED,
  ADYEN_PAYMENT_PENDING,
} from "@/utils/constants";
import { emitEvent, useListenEvent } from "@/utils/eventBus";

import PayNowInstructionModal from "./PayNowInstructionModal";

import "@adyen/adyen-web/dist/adyen.css";

const CHANNELS = {
  WEB: "Web",
};

const CART_ORDER_POLL_INTERVAL = 1000;

AdyenCheckoutModal.propTypes = {
  checkoutParams: PropTypes.object,
  totalIncludingTax: PropTypes.number,
  onOrderProcessing: PropTypes.func,
  cartId: PropTypes.number,
  paymentMethod: PropTypes.object,
};

export default function AdyenCheckoutModal({
  checkoutParams,
  totalIncludingTax,
  onOrderProcessing,
  cartId,
  paymentMethod,
}) {
  const { t, i18n } = useTranslation();

  const [isVisible, setVisible] = useState(false);

  const debouncedCheckoutParams = useDebounce(checkoutParams, 1000);
  const [adyenPaymentMethods, setAdyenPaymentMethods] = useState(null);

  const EXCLUSION_LIST = []; // TODO: Add feature flip to enable/disable payment methods from backend
  const [getAdyenPaymentMethods] = useMutation(
    GET_ADYEN_PAYMENT_METHODS_MUTATION,
    {
      context: { graph: "diners" },
      errorPolicy: "all",
      onCompleted: (data) => {
        const { getAdyenPaymentMethods } = data;
        setAdyenPaymentMethods({
          ...getAdyenPaymentMethods,
          paymentMethods: getAdyenPaymentMethods?.paymentMethods?.filter(
            (pm) => {
              // Loading Apple Pay in localhost will crash the app
              if (pm.type === "applepay" && location.protocol !== "https:") {
                return false;
              }
              return !EXCLUSION_LIST.includes(pm.type);
            },
          ),
        });
      },
    },
  );

  const { startPolling, stopPolling } = useQuery(GET_CART_ORDER_ID_QUERY, {
    context: { graph: "diners" },
    variables: {
      cartId,
    },
    onCompleted: (data) => {
      if (data?.getCartOrder?.id) {
        onOrderProcessing({
          result: {
            resultCode: ADYEN_PAYMENT_AUTHORISED,
          },
        });
        stopPolling();
        setVisible(false);
      }
    },
  });

  const [payNowModalVisible, setPayNowModalVisible] = useState(false);
  const [adyenDropIn, setAdyenDropIn] = useState(null);
  const [isMounting, setMounting] = useState(true);
  const [isMakingPayment, setMakingPayment] = useState(false);
  const [adyenError, setAdyenError] = useState(null);

  const [makeAdyenPayment, { error: makeAdyenPaymentError }] = useMutation(
    MAKE_ADYEN_PAYMENT_MUTATION,
    {
      context: { graph: "diners" },
      errorPolicy: "all",
    },
  );

  const [getAdyenPaymentDetails] = useLazyQuery(
    GET_ADYEN_PAYMENT_DETAILS_QUERY,
    {
      context: { graph: "diners" },
      errorPolicy: "all",
    },
  );

  useEffect(() => {
    getAdyenPaymentMethods({
      variables: {
        channel: CHANNELS.WEB,
      },
    });
  }, [totalIncludingTax]);

  useEffect(() => {
    if (isVisible && isMounting && adyenDropIn) {
      adyenDropIn.mount("#dropin-container");
      setMounting(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (adyenPaymentMethods?.paymentMethods) {
      mountAdyenCheckout();
    }
  }, [adyenPaymentMethods, JSON.stringify(debouncedCheckoutParams)]);

  useEffect(() => {
    if (makeAdyenPaymentError?.message) {
      setAdyenError(makeAdyenPaymentError.message);
    }
  }, [makeAdyenPaymentError?.message]);

  useListenEvent("showAdyenPayment", () => setVisible(true));

  const getClientKey = () => {
    if (paymentMethod?.clientKey) {
      return paymentMethod?.clientKey;
    } else if (paymentMethod?.accountId) {
      return import.meta.env.VITE_ADYEN_CLIENT_KEY;
    } else {
      // Do nothing
    }
  };

  async function mountAdyenCheckout() {
    const clientKey = getClientKey();

    const configuration = {
      amount: totalIncludingTax,
      showPayButton: true,
      setStatusAutomatically: false,
      // The `/paymentMethods` response from the server.
      paymentMethodsResponse: adyenPaymentMethods,
      clientKey,
      locale: i18n.resolvedLanguage === "zh" ? "zh-CN" : "en-US",
      // Change to 'live' for the live environment.
      environment: clientKey.startsWith("live_") ? "live" : "test",
      onSubmit: (state, dropin) => {
        emitEvent("startAdyenPayment", {
          makeAdyenPayment: () => {
            setMakingPayment(true);
            // clear errors
            setAdyenError(null);

            if (state.data.paymentMethod.type === "paynow") {
              setPayNowModalVisible(true);
            }
            // Your function calling your server to make the `/payments` request
            makeAdyenPayment({
              variables: {
                ...checkoutParams,
                paymentMethod: state.data.paymentMethod,
                url: `${window.location.origin}${window.location.pathname}`,
              },
            })
              .then((response) => handleResponse(dropin, response))
              .catch((error) => {
                Sentry.setTag(
                  "client",
                  localStorage.getItem(constants.X_CLIENT_UUID),
                );
                Sentry.captureException(error);
                throw Error(error);
              });
          },
          showAdyenSpinner: () => setMakingPayment(true),
        });
      },
      onAdditionalDetails: (state, dropin) => {
        // Your function calling your server to make a `/payments/details` request
        getAdyenPaymentDetails({
          variables: { redirectResult: state?.data?.details?.payload },
        })
          .then((response) => handleResponse(dropin, response))
          .catch((error) => {
            throw Error(error);
          });
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: false,
          holderNameRequired: false,
          billingAddressRequired: false,
          hideCVC: false, // Change this to true to hide the CVC field for stored cards
          name: t("adyen.labels.card"),
        },
        threeDS2: {
          // Web Components 4.0.0 and above: sample configuration for the threeDS2 action type
          // Set to any of the following:
          // '02': ['390px', '400px'] -  The default window size
          // '01': ['250px', '400px']
          // '03': ['500px', '600px']
          // '04': ['600px', '400px']
          // '05': ['100%', '100%']
          challengeWindowSize: "05",
        },
        applepay: {
          amount: {
            value: totalIncludingTax,
            currency: "SGD",
          },
          countryCode: "SG",
        },
        googlepay: {
          amount: {
            value: totalIncludingTax,
            currency: "SGD",
          },
          countryCode: "SG",
          environment:
            import.meta.env.VITE_SENTRY_ENV === "production"
              ? "PRODUCTION"
              : "TEST",
        },
      },
    };

    // unmount if mounted previously
    adyenDropIn?.unmount();
    setMounting(true);

    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await AdyenCheckout(configuration);

    // Create an instance of Drop-in and mount it to the container you created.
    const dropIn = checkout.create("dropin", {
      instantPaymentTypes: ["applepay", "googlepay"],
    });

    setAdyenDropIn(dropIn);

    if (isVisible) {
      dropIn.mount("#dropin-container");
      setMounting(false);
    }
  }

  function handleResponse(dropin, response) {
    setMakingPayment(false);
    if (response.action) {
      // Drop-in handles the action object from the /payments response
      dropin.handleAction(response.action);
    } else if (
      response?.data?.makeAdyenPayment?.resultCode === ADYEN_PAYMENT_PENDING
    ) {
      dropin.handleAction(response.data.makeAdyenPayment.action);
      if (
        response.data.makeAdyenPayment.action?.paymentMethodType === "paynow"
      ) {
        // start polling for cart order
        startPolling(CART_ORDER_POLL_INTERVAL);
      }
    } else {
      // Your function to show the final result to the shopper
      handleFinalResult(response.data);
    }
  }

  function handleFinalResult(data) {
    const { makeAdyenPayment, getAdyenPaymentDetails } = data;
    const result = makeAdyenPayment || getAdyenPaymentDetails;
    let error;
    if (result) {
      switch (result.resultCode) {
        case ADYEN_PAYMENT_AUTHORISED:
          // handle success case
          onOrderProcessing({ result });
          stopPolling();
          setVisible(false);
          break;
        default:
          // handle errors
          error = t("adyen.errors.generic");
          if (
            makeAdyenPayment.refusalReason &&
            makeAdyenPayment.refusalReason !== "Refused"
          ) {
            error = makeAdyenPayment.refusalReason;
          }
          Sentry.captureException(error);
          setAdyenError(error);
      }
    }
  }

  return (
    <Modal
      centered
      visible={isVisible}
      mobileClosable
      maskClosable={false}
      footer={null}
      onCancel={() => setVisible(false)}
      antModalClassName="adyen-checkout-modal max-w-[420px] sm:max-w-[420px]"
      wrapperClassName="adyen-checkout-modal-wrapper"
      title={t("adyen.labels.header")}
    >
      <Spin spinning={isMakingPayment}>
        <div id="dropin-container" className="mt-2 text-left">
          {isMounting && <Spin spinning={true} />}
        </div>
      </Spin>
      <PayNowInstructionModal
        visible={payNowModalVisible}
        closeModal={() => setPayNowModalVisible(false)}
      />
      {adyenError && (
        <div className="mt-4 font-bold text-danger">{adyenError}</div>
      )}
    </Modal>
  );
}
