import React, { useState, useEffect } from "react";

import CheckIcon from "@heroicons/react/solid/CheckIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import PropTypes from "prop-types";

Switch.propTypes = {
  /** State to set the switch to. */
  isEnabled: PropTypes.bool,
  /** Method to call on switch state change. Passes the new switch state as an argument. */
  onChange: PropTypes.func,
  enabledBgColor: PropTypes.string,
  disabledBgColor: PropTypes.string,
  textOnEnabledColor: PropTypes.string,
  textOnDisabledColor: PropTypes.string,
  /** Any other properties or events that will be passed on to the switch instance. */
  rest: PropTypes.any,
};

Switch.defaultProps = {
  isEnabled: false,
  onChange: () => {},
  enabledBgColor: "bg-primary",
  disabledBgColor: "bg-faded",
  textOnEnabledColor: "text-on-primary",
  textOnDisabledColor: "text-default",
};

export default function Switch({
  isEnabled,
  onChange,
  enabledBgColor,
  disabledBgColor,
  textOnEnabledColor,
  textOnDisabledColor,
  ...rest
}) {
  const [isLocalEnabled, setLocalEnabled] = useState(isEnabled);

  useEffect(() => {
    setLocalEnabled(isEnabled);
  }, [isEnabled]);

  const additionalButtonProps = {
    ...rest,
    onClick: () => {
      const newState = !isLocalEnabled;
      setLocalEnabled(newState);
      onChange(newState);

      if (rest.onClick) {
        rest.onClick();
      }
    },
  };

  return (
    <button
      type="button"
      className="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      aria-pressed="false"
      {...additionalButtonProps}
    >
      {isLocalEnabled ? (
        <CheckIcon
          className={`absolute left-[8px] z-10 w-3 h-3 ${textOnEnabledColor}`}
        />
      ) : (
        <XIcon
          className={`absolute right-[8px] z-10 w-3 h-3 ${textOnDisabledColor}`}
        />
      )}
      <span
        aria-hidden="true"
        className="absolute w-full h-full rounded-md pointer-events-none"
      />
      <span
        aria-hidden="true"
        className={`${
          isLocalEnabled ? enabledBgColor : disabledBgColor
        } pointer-events-none absolute h-4 w-9 mx-auto border border-default rounded-full transition-colors ease-in-out duration-200`}
      />
      {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
      <span
        aria-hidden="true"
        className={`${
          isLocalEnabled ? "translate-x-5" : "translate-x-0"
        } z-10 pointer-events-none absolute left-0 inline-block h-5 w-5 border border-default rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
      />
    </button>
  );
}
