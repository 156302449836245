import { ApolloClient, InMemoryCache, ApolloLink, gql } from "@apollo/client";

import httpLink from "./httpLink";
import refreshTokenAuthLink from "./refreshTokenAuthLink";

export default () => {
  const client = new ApolloClient({
    link: ApolloLink.from([refreshTokenAuthLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return client.mutate({
    mutation: gql`
      mutation UserGenerateAccessToken {
        userGenerateAccessToken(input: {}) {
          accessToken
        }
      }
    `,
    context: { graph: "diners" },
  });
};
