import { gql } from "@apollo/client";

export const SEARCH_ADDRESS_QUERY = gql`
  query SearchAddress($query: String!) {
    searchAddress(query: $query) {
      address
      postalCode
      longitude
      latitude
      nearestOutlet {
        id
        labelForPickup
        distanceFromAddress
      }
    }
  }
`;
