import React from "react";

import { Spin } from "antd";
import PropTypes from "prop-types";
import "./Spin.less";

Spinner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  logoClassName: PropTypes.string,
  rest: PropTypes.any,
};

Spinner.defaultProps = {
  logoClassName: "",
};

export default function Spinner({ className, style, logoClassName, ...rest }) {
  let localClassName = "flex items-center justify-center h-full";
  if (className) {
    localClassName += ` ${className}`;
  }
  let localStyle = { display: "flex", maxHeight: "none" };
  if (style) {
    localStyle = {
      ...localStyle,
      ...style,
    };
  }
  if (!logoClassName?.match(/\bw-\d+/)) {
    logoClassName += " w-10";
  }
  if (!logoClassName?.match(/\bh-\d+/)) {
    logoClassName += " h-10";
  }

  const loadingIcon = (
    <>
      {/* copied from https://tailwindcss.com/docs/animation */}
      <svg
        className={`animate-spin text-default ${logoClassName}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </>
  );

  return (
    <Spin
      className={localClassName}
      style={localStyle}
      indicator={loadingIcon}
      {...rest}
    />
  );
}
