import React, { useEffect, useRef, useState, useContext, useMemo } from "react";

import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { Modal } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import Button from "@/common/Button";
import Input from "@/common/Input";
import TableIcon from "@/common/Layout/Header/TableIcon";
import { useOmise } from "@/components/Cart/useOmise";
import ConfigContext from "@/components/Config/configContext";
import { CHECKOUT_CART_MUTATION } from "@/graphql/mutations/checkoutCart";
import {
  UPDATE_CART_FULFILMENT_DETAILS_MUTATION,
  UPDATE_CART_FULFILMENT_DETAILS_OPTIMISED_MUTATION,
} from "@/graphql/mutations/updateCartFulfilmentDetails";
import {
  UPDATE_CART_PROMO_MUTATION,
  UPDATE_CART_PROMO_OPTIMISED_MUTATION,
} from "@/graphql/mutations/updateCartPromo";
import {
  GET_CART_ID_QUERY,
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import { GET_CART_VALIDATIONS_QUERY } from "@/graphql/queries/getCartValidations";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import useAuth from "@/hooks/useAuth";
import constants, { PAYMENT_TYPES } from "@/utils/constants";
import { emitEvent, useListenEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";

import CartLogin from "./CartLogin";
import CartPaymentMethods from "./CartPaymentMethods";
import CartPromoCode from "./CartPromoCode";
import CartUseLoyalty from "./CartUseLoyalty";
import CheckoutSignupModal from "./CheckoutSignupModal";

const GIFT_MESSAGE_MAX_LENGTH = 300;

CartCheckout.propTypes = {
  cart: PropTypes.object,
  availableFulfilmentTypes: PropTypes.arrayOf(PropTypes.string),
  checkoutScrollableContainer: PropTypes.object,
  mobileCheckoutScrollableContainer: PropTypes.object,
  setCheckoutLoading: PropTypes.func,
  onOrderProcessing: PropTypes.func,
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  cartOutlet: PropTypes.object,
};

export default function CartCheckout({
  cart,
  availableFulfilmentTypes,
  checkoutScrollableContainer,
  mobileCheckoutScrollableContainer,
  setCheckoutLoading,
  onOrderProcessing,
  cartOutlet,
  footer,
}) {
  const { t } = useTranslation();
  const { loggedIn } = useAuth();
  const [line2, setLine2] = useState("");
  const [isCutleryRequired, setCutleryRequired] = useState(
    cart.address?.isCutleryRequired || false,
  );
  const [notes, setNotes] = useState(cart.notes || cart.address?.notes || "");
  const [contactName, setContactName] = useState(cart.contactName);
  const [contactNumber, setContactNumber] = useState(cart.contactNumber);
  const [email, setEmail] = useState(cart.contactEmail);
  const [recipientName, setRecipientName] = useState(cart.recipientName);
  const [recipientContactNumber, setRecipientContactNumber] = useState(
    cart.recipientContactNumber,
  );
  const [isGift, setGift] = useState(cart.isGift || false);
  const [giftMessage, setGiftMessage] = useState(cart.giftMessage);

  const [
    postUpdateFulfilmentDetailsCallback,
    setPostUpdateFulfilmentDetailsCallback,
  ] = useState();
  const [isValidationsVisible, setValidationsVisible] = useState(false);
  const [isCheckoutSignupVisible, setCheckoutSignupVisible] = useState(false);
  const [paymentRequestEvent, setPaymentRequestEvent] = useState(null);

  const [omiseToken, setOmiseToken] = useState();
  const { openOmiseCard } = useOmise({
    totalIncludingTax: cart.paymentBreakdown.totalIncludingTax,
    omisePaymentKey: cart?.paymentMethods?.[0]?.apiPublicKey,
    paymentType: getPaymentType(),
  });

  // to store a callback, wrap callback in an anonymous function, eg. setAdyenPaymentCallback(() => () => callback())
  const [adyenPaymentCallback, setAdyenPaymentCallback] = useState(null);
  const [showAdyenSpinnerCallback, setShowAdyenSpinnerCallback] =
    useState(null);

  const { outletQrHash, configQuery } = useContext(ConfigContext);
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );
  const hideQrTimeslots =
    configQuery?.currentStorefront?.config?.hide_qr_timeslots &&
    window.location.pathname.startsWith("/qr/");
  const disableCheckoutCartLogin =
    configQuery?.currentStorefront?.config?.disable_checkout_cart_login;
  const hideCutleryRequired =
    configQuery?.config?.features?.includes(
      constants.FEATURES.HIDE_CUTLERY_REQUIRED,
    ) || configQuery?.currentStorefront?.config?.hide_cutlery_option;
  const disablePromoCode =
    configQuery?.currentStorefront?.config?.disable_promo_code;
  const checkoutContactNameInput =
    configQuery?.currentStorefront?.config?.checkout_contact_name_input;
  const checkoutContactEmailInput =
    configQuery?.currentStorefront?.config?.checkout_contact_email_input;
  const checkoutContactNumberInput =
    configQuery?.currentStorefront?.config?.checkout_contact_number_input;

  const checkoutParams = useMemo(
    () => ({
      line2,
      isCutleryRequired,
      notes,
      contactName: contactName?.length > 0 ? contactName : null,
      contactNumber: contactNumber?.length > 0 ? contactNumber : null,
      email: email?.length > 0 ? email : null,
      paymentType: getPaymentType(),
    }),
    [
      line2,
      isCutleryRequired,
      notes,
      contactName,
      contactNumber,
      email,
      cart?.paymentMethods?.[0]?.paymentType, // this is basically getPaymentType()
    ],
  );

  const refLine2 = useRef();
  const refContactName = useRef();
  const refContactNumber = useRef();
  const refContactEmail = useRef();
  const refRecipientName = useRef();
  const refRecipientContactNumber = useRef();
  const refGiftMessage = useRef();

  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();

  const client = useApolloClient();

  const [
    getCartValidations,
    { loading: validatingCart, error: cartValidationError },
  ] = useLazyQuery(GET_CART_VALIDATIONS_QUERY, {
    context: { graph: "diners" },
    errorPolicy: "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // currently only used by Adyen
      if (data?.getCartValidations) {
        emitEvent("showAdyenPayment");
      }
    },
  });

  const [getCartId] = useLazyQuery(GET_CART_ID_QUERY, {
    context: { graph: "diners" },
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.cart?.id) {
        performCheckout({ skipLoginPrompt: true });
      }
    },
  });

  const [updateCartPromo, updateCartPromoResponse] = useMutation(
    useOptimisedDinerCartType
      ? UPDATE_CART_PROMO_OPTIMISED_MUTATION
      : UPDATE_CART_PROMO_MUTATION,
    {
      errorPolicy: "all",
      context: { graph: "diners" },
      onCompleted(data) {
        try {
          const updateCartPromo = useOptimisedDinerCartType
            ? data.updateCartPromoOptimised
            : data.updateCartPromo;
          const {
            id,
            promoCode,
            promoCodeError,
            paymentBreakdown: { discount },
          } = updateCartPromo;

          if (promoCode && !promoCodeError) {
            analytics.trackEventWithProperties(events.coupon_applied, {
              id,
              promoCode,
              discount,
            });
          } else {
            analytics.trackEventWithProperties(events.coupon_removed, {
              id,
              promoCode,
              discount,
            });
          }
          if (promoCodeError) {
            analytics.trackEventWithProperties(events.coupon_denied, {
              id,
              promoCode,
              discount,
              promoCodeError,
            });
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      },
    },
  );

  const [
    updateCartFulfilmentDetails,
    {
      loading: updatingCartFulfilmentDetails,
      data: updateCartFulfilmentDetailsData,
    },
  ] = useMutation(
    useOptimisedDinerCartType
      ? UPDATE_CART_FULFILMENT_DETAILS_OPTIMISED_MUTATION
      : UPDATE_CART_FULFILMENT_DETAILS_MUTATION,
    {
      context: { graph: "diners" },
      errorPolicy: "all",
    },
  );

  useListenEvent("makePayment", (token) => {
    setOmiseToken(token);
    performCheckout();
  });

  const [checkoutCart, checkoutCartResponse] = useMutation(
    CHECKOUT_CART_MUTATION,
    {
      errorPolicy: "all",
      context: { graph: "diners" },
      onCompleted({ checkout }) {
        if (checkout) {
          analytics.trackEventWithProperties(
            events.checkout_step_completed,
            checkout,
          );
        }

        // Stripe payment intent
        if (checkout?.clientSecret) {
          onOrderProcessing({
            paymentPending: true,
          });
          emitEvent("startPayment", {
            clientSecret: checkout.clientSecret,
            paymentRequestEvent,
          });
        }

        // PayNow
        if (checkout?.paymentDetails) {
          setCheckoutLoading(false);
          onOrderProcessing({
            paymentType: checkout.paymentType,
            paymentDetails: checkout.paymentDetails,
            identifier: checkout.identifier,
          });
        }

        // $0 orders will return paymentType as null
        if ([PAYMENT_TYPES.OMISE, null].includes(checkout?.paymentType)) {
          setCheckoutLoading(false);
          onOrderProcessing({
            paymentType: checkout.paymentType,
            identifier: checkout.identifier,
            servingDate: checkout.servingDate,
            timeslotRange: checkout.timeslotRange,
          });
        }

        // Set loading to false when checkout fails
        // Display errors using checkoutCartResponse
        if (!checkout) {
          setCheckoutLoading(false);
        }
      },
    },
  );

  useEffect(() => {
    setLine2(cart.address?.line2 || "");
  }, [cart.address?.id]);

  useEffect(() => {
    setGift(cart.isGift || false);
  }, [cart.isGift]);

  useEffect(() => {
    setCheckoutLoading(validatingCart || !!postUpdateFulfilmentDetailsCallback);
  }, [validatingCart, !!postUpdateFulfilmentDetailsCallback]);

  useEffect(() => {
    if (
      updateCartFulfilmentDetailsData &&
      postUpdateFulfilmentDetailsCallback
    ) {
      postUpdateFulfilmentDetailsCallback();
      setPostUpdateFulfilmentDetailsCallback(null);
    }
  }, [updateCartFulfilmentDetailsData]);

  useEffect(() => {
    if (checkoutCartResponse?.error?.message) {
      analytics.trackEventWithProperties(
        events.checkout_step_failed,
        { error: checkoutCartResponse?.error },
        { configQuery },
      );
    }
  }, [checkoutCartResponse?.error?.message]);

  useEffect(() => {
    if (checkoutCartResponse?.loading) {
      setCheckoutLoading(true);
    }
  }, [checkoutCartResponse?.loading]);

  const formattedDate = dayjs(cart.servingDate).format("MMM D");

  const validations = isValidationsVisible ? getValidations() : {};
  const validate = () => {
    const firstValidationKey = Object.keys(getValidations())[0];
    setValidationsVisible(!!firstValidationKey);

    let fieldRef;
    switch (firstValidationKey) {
      case "line2":
        fieldRef = refLine2;
        break;
      case "contactName":
        fieldRef = refContactName;
        break;
      case "contactNumber":
        fieldRef = refContactNumber;
        break;
      case "contactEmail":
        fieldRef = refContactEmail;
        break;
      case "recipientContactNumber":
        fieldRef = refRecipientContactNumber;
        break;
      case "recipientName":
        fieldRef = refRecipientName;
        break;
      case "giftMessage":
        fieldRef = refGiftMessage;
        break;
    }
    if (fieldRef?.current) {
      [checkoutScrollableContainer, mobileCheckoutScrollableContainer].forEach(
        (scrollableContainer) => {
          if (scrollableContainer?.current) {
            scrollableContainer.current.style.scrollBehavior = "smooth";
            scrollableContainer.current.scrollTo(
              0,
              fieldRef.current.offsetTop -
                scrollableContainer.current.offsetTop -
                8,
            );
          }
        },
      );
    }
    return !firstValidationKey;
  };

  function getValidations() {
    const validations = {};
    if (
      cart?.fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
      (!line2 || line2.trim().length <= 0)
    ) {
      validations.line2 = t("menu.cart.checkout.validations.addressLine2");
    }
    if (isGift) {
      if (!recipientName || recipientName.length <= 0)
        validations.recipientName = t(
          "menu.cart.checkout.validations.recipientName",
        );
      if (!giftMessage || giftMessage.length <= 0)
        validations.giftMessage = t(
          "menu.cart.checkout.validations.giftMessage",
        );
      if (!recipientContactNumber || recipientContactNumber.length <= 0) {
        validations.recipientContactNumber = t(
          "menu.cart.checkout.validations.contactNumber",
        );
      } else if (!isValidPhoneNumber(recipientContactNumber)) {
        validations.recipientContactNumber = t(
          "menu.cart.checkout.validations.invalidContactNumber",
        );
      }
    }
    if (!loggedIn()) {
      if (
        isFieldRequired(checkoutContactNameInput) &&
        (!contactName || contactName.length <= 0)
      )
        validations.contactName = t(
          "menu.cart.checkout.validations.contactName",
        );
      if (isFieldRequired(checkoutContactNumberInput)) {
        if (!contactNumber || contactNumber.length <= 0) {
          validations.contactNumber = t(
            "menu.cart.checkout.validations.contactNumber",
          );
        } else if (!isValidPhoneNumber(contactNumber)) {
          validations.contactNumber = t(
            "menu.cart.checkout.validations.invalidContactNumber",
          );
        }
      }
      if (
        isFieldRequired(checkoutContactEmailInput) &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(email)
      )
        validations.contactEmail = t("menu.cart.checkout.validations.email");
    }
    return validations;
  }

  function clearPromoCodeError() {
    const updatedCartData = {
      ...cart,
      promoCodeError: null,
    };
    client.writeQuery({
      query: useOptimisedDinerCartType
        ? GET_CART_OPTIMISED_QUERY
        : GET_CART_QUERY,
      data: {
        cart: updatedCartData,
        ...(useOptimisedDinerCartType
          ? { cartOptimised: updatedCartData }
          : {}),
      },
    });
  }

  function getPaymentType() {
    const paymentMethod = cart.paymentMethods[0];

    return PAYMENT_TYPES[paymentMethod?.paymentType];
  }

  function processCheckout() {
    trackEvent(eventsV2.addPaymentInfo, cart);
    if (cart.promoCode && cart.promoCodeError) return;
    if (validate()) {
      analytics.trackEventWithProperties(
        events.checkout_started,
        {},
        { configQuery },
      );
      if (cart.paymentBreakdown.totalIncludingTax === 0) {
        Modal.confirm({
          className: "generic-checkout-confirmation",
          content: t("menu.cart.checkout.genericConfirmation"),
          okText: t("common.confirm"),
          onOk() {
            performCheckout();
          },
        });
        return;
      }

      const paymentMethod = getPaymentType();
      switch (paymentMethod) {
        case "PAYNOW":
          Modal.confirm({
            content: t("menu.cart.checkout.offlinePaymentsConfirmation"),
            onOk() {
              performCheckout();
            },
          });
          break;
        case "OMISE":
          // Omise card will trigger a checkout after token is retrieved
          openOmiseCard();
          break;
        case "ADYEN":
          getCartValidations();
          break;
        default:
          performCheckout();
      }
    }
  }

  function startCheckout(e) {
    setPaymentRequestEvent(e);
    performCheckout();
  }

  function performCheckout({ skipLoginPrompt = false } = {}) {
    if (outletQrHash || skipLoginPrompt || loggedIn()) {
      if (adyenPaymentCallback) {
        adyenPaymentCallback();
        setAdyenPaymentCallback(null);
      } else {
        checkoutCart({
          variables: {
            ...checkoutParams,
            token: omiseToken,
          },
        });
        setCheckoutLoading(true);
      }
      setCheckoutSignupVisible(false);
    } else {
      setCheckoutSignupVisible(true);
    }
  }

  useListenEvent(
    "startAdyenPayment",
    ({ makeAdyenPayment, showAdyenSpinner }) => {
      if (outletQrHash || loggedIn()) {
        makeAdyenPayment();
      } else {
        // to store a callback, wrap callback in an anonymous function
        setAdyenPaymentCallback(() => () => makeAdyenPayment());
        setShowAdyenSpinnerCallback(() => () => showAdyenSpinner());
        setCheckoutSignupVisible(true);
      }
    },
  );

  const isOmiseEnabled = getPaymentType() === PAYMENT_TYPES.omise;
  const isAdyenEnabled = getPaymentType() === PAYMENT_TYPES.adyen;

  function renderCheckoutButtonContainer({ className }) {
    return (
      <div
        className={`checkout-button-container p-4 space-y-3 bg-default ${className}`}
      >
        {cart.promoCode && cart.promoCodeError && (
          <div className="font-bold text-danger">{cart.promoCodeError}</div>
        )}
        {updateCartPromoResponse?.error && (
          <div className="font-bold text-danger">
            {updateCartPromoResponse.error.message}
          </div>
        )}
        {cartValidationError && (
          <div className="font-bold text-danger">
            {cartValidationError.message}
          </div>
        )}
        {checkoutCartResponse?.error && (
          <div className="font-bold text-danger">
            {checkoutCartResponse.error.message}
          </div>
        )}
        <div>
          <Button
            type="primary"
            className="w-full py-4"
            disabled={minimumOrderValueNotMet}
            onClick={() => {
              if (updatingCartFulfilmentDetails) {
                setPostUpdateFulfilmentDetailsCallback(() => processCheckout);
              } else {
                processCheckout();
              }
            }}
          >
            {minimumOrderValueNotMet ? (
              t("menu.cart.checkout.labels.minimumOrderValue", {
                minimumOrderValue: formatPrice(
                  cart?.paymentBreakdown?.minimumOrderValue,
                ),
              })
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("menu.cart.checkout.labels.checkout", {
                    amount: cart?.paymentBreakdown
                      ? formatPrice(cart.paymentBreakdown.totalIncludingTax)
                      : "",
                  }),
                }}
              />
            )}
          </Button>
        </div>
      </div>
    );
  }

  const minimumOrderValueNotMet =
    cart?.fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
    cart?.paymentBreakdown.minimumOrderValue &&
    cart?.paymentBreakdown.subtotal < cart?.paymentBreakdown.minimumOrderValue;

  function isFieldRequired(fieldConfiguration) {
    return (
      !fieldConfiguration ||
      fieldConfiguration == constants.FIELD_CONFIGURATION.REQUIRED
    );
  }

  function isFieldHidden(fieldConfiguration) {
    return fieldConfiguration == constants.FIELD_CONFIGURATION.HIDDEN;
  }

  return (
    <div className="h-full cart-checkout">
      <div className="flex flex-col h-full space-y-4 text-default2">
        <div
          ref={checkoutScrollableContainer}
          className="flex flex-col h-full px-4 pt-4 space-y-4 overflow-y-auto"
        >
          {!disableCheckoutCartLogin && !loggedIn() && <CartLogin />}
          {!hideQrTimeslots && (
            <div>
              <div className="flex items-center justify-between">
                <div className="mb-1 font-bold text-body text-default">
                  {t(
                    `menu.cart.checkout.labels.serveOn.${cart.fulfilmentType}`,
                  )}
                </div>
                <a
                  className="inline-block text-link hover:text-link"
                  onClick={() => emitEvent("selectTimeslot")}
                >
                  {t("menu.cart.checkout.labels.change")}
                </a>
              </div>
              <div className="flex items-center justify-between">
                <div>{`${formattedDate}, ${cart.timeslot?.rangeLabel}`}</div>
              </div>
            </div>
          )}
          {[
            constants.FULFILMENT_TYPES.PICKUP,
            constants.FULFILMENT_TYPES.DINE_IN,
          ].includes(cart.fulfilmentType) && (
            <div>
              <div className="flex items-start justify-between mb-1">
                <div className="mb-1 font-bold text-body text-default">
                  {t(
                    `menu.cart.checkout.labels.${
                      outletQrHash ? "qrOutletLabel" : "pickUpFrom"
                    }`,
                  )}
                </div>
                {availableFulfilmentTypes.length > 1 && !outletQrHash && (
                  <a
                    className="inline-block text-link hover:text-link"
                    onClick={() => emitEvent("selectAddress")}
                  >
                    {t("menu.cart.checkout.labels.change")}
                  </a>
                )}
              </div>
              <div>
                {cartOutlet?.labelForPickup}
                {cart?.tableName && (
                  <div className="flex mt-2">
                    <div className="mr-2 text-primary">
                      <TableIcon />
                    </div>
                    <div className="mb-1 font-bold text-body text-default">
                      {t("menu.cart.checkout.labels.tableName", {
                        tableName: cart.tableName,
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {cart.fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY && (
            <div ref={refLine2}>
              <div className="flex items-start justify-between mb-1">
                <div className="mb-1 font-bold text-body text-default">
                  {t("menu.cart.checkout.labels.deliveryAddress")}
                </div>
                {availableFulfilmentTypes.length > 1 && (
                  <a
                    className="inline-block text-link hover:text-link"
                    onClick={() => emitEvent("selectAddress")}
                  >
                    {t("menu.cart.checkout.labels.change")}
                  </a>
                )}
              </div>
              <div className="flex items-end justify-between">
                <div>
                  <div>{cart.address?.line1}</div>
                  {!cart.address?.line1?.includes(cart.address?.postalCode) && (
                    <div>{cart.address?.postalCode}</div>
                  )}
                </div>
                <span className="ml-2 text-xs">
                  {t("common.form.required")}
                </span>
              </div>
              <Input
                className="mt-1"
                type="text"
                value={line2}
                onChange={(event) => setLine2(event.target.value)}
                onBlur={(event) => {
                  if (event.target.value !== cart.address?.line2) {
                    updateCartFulfilmentDetails({
                      variables: {
                        line2: event.target.value,
                      },
                    });
                  }
                }}
                placeholder={t("menu.cart.checkout.placeholders.addressLine2")}
                error={validations.line2}
              />
            </div>
          )}

          <div>
            <div className="flex items-center justify-between">
              <div className="mb-1 font-bold text-body text-default">
                {t("menu.cart.checkout.labels.isGift")}
              </div>
              <Input
                type="switch"
                value={isGift}
                onChange={(value) => {
                  setGift(value);
                  updateCartFulfilmentDetails({
                    variables: {
                      isGift: value,
                    },
                  });
                }}
              />
            </div>
            <div className="text-xs opacity-60">
              {t("menu.cart.checkout.labels.isGiftDescription")}
            </div>
          </div>
          {isGift && (
            <>
              <div ref={refRecipientName}>
                <div className="mb-1 font-bold text-body text-default">
                  {t("menu.cart.checkout.labels.recipientName")}
                </div>
                <Input
                  className="mt-1"
                  type="text"
                  value={recipientName || ""}
                  onChange={(event) => {
                    setRecipientName(event.target.value);
                  }}
                  onBlur={(event) => {
                    if (event.target.value !== cart.recipientName) {
                      updateCartFulfilmentDetails({
                        variables: {
                          recipientName: event.target.value,
                        },
                      });
                    }
                  }}
                  placeholder={t(
                    "menu.cart.checkout.placeholders.recipientName",
                  )}
                  error={validations.recipientName}
                />
              </div>
              <div ref={refRecipientContactNumber}>
                <div className="mb-1 font-bold text-body text-default">
                  {t("menu.cart.checkout.labels.recipientContactNumber")}
                </div>
                <PhoneInput
                  className={
                    validations.recipientContactNumber ? "error" : null
                  }
                  type="tel"
                  name="mobileNumber"
                  countrySelectProps={{ className: "bg-default" }}
                  placeholder={t("signup.mobilePlaceholder")}
                  country="SG"
                  international={true}
                  withCountryCallingCode={true}
                  defaultCountry="SG"
                  value={recipientContactNumber || ""}
                  onChange={(value) => setRecipientContactNumber(value)}
                  onBlur={(event) => {
                    const newNumber = event.target.value;
                    // don't save number if it's just the country code
                    if (
                      newNumber.split(" ").length > 1 &&
                      newNumber !== cart.recipientContactNumber
                    ) {
                      updateCartFulfilmentDetails({
                        variables: {
                          recipientContactNumber: newNumber,
                        },
                      });
                    }
                  }}
                />
                {validations.recipientContactNumber && (
                  <p className="mt-2 text-sm text-danger">
                    {validations.recipientContactNumber}
                  </p>
                )}
              </div>
              <div ref={refGiftMessage}>
                <div className="mb-1 font-bold text-body text-default">
                  {t("menu.cart.checkout.labels.giftMessage")}
                </div>
                <Input
                  className="mt-1"
                  type="textarea"
                  maxLength={GIFT_MESSAGE_MAX_LENGTH}
                  value={giftMessage || ""}
                  onChange={(event) => setGiftMessage(event.target.value)}
                  onBlur={(event) => {
                    if (event.target.value !== cart.giftMessage) {
                      updateCartFulfilmentDetails({
                        variables: {
                          giftMessage: event.target.value,
                        },
                      });
                    }
                  }}
                  placeholder={t("menu.cart.checkout.placeholders.giftMessage")}
                  error={validations.giftMessage}
                />
                <div className="mt-1 text-xs text-default opacity-60">
                  {t("menu.cart.checkout.wordcount.giftMessage", {
                    charactersLeft:
                      GIFT_MESSAGE_MAX_LENGTH - (giftMessage?.length || 0),
                  })}
                </div>
              </div>
            </>
          )}

          {!loggedIn() && (
            <>
              {!isFieldHidden(checkoutContactNameInput) && (
                <div ref={refContactName}>
                  <div className="flex items-end justify-between mb-1">
                    <div className="font-bold text-body text-default">
                      {t("menu.cart.checkout.labels.contactName")}
                    </div>
                    {isFieldRequired(checkoutContactNameInput) && (
                      <span className="text-xs">
                        {t("common.form.required")}
                      </span>
                    )}
                  </div>
                  <Input
                    type="text"
                    placeholder={t(
                      "menu.cart.checkout.placeholders.contactName",
                    )}
                    value={contactName}
                    onChange={(event) => setContactName(event.target.value)}
                    onBlur={(event) => {
                      if (event.target.value !== cart.contactName) {
                        updateCartFulfilmentDetails({
                          variables: {
                            contactName: event.target.value,
                          },
                        });
                      }
                    }}
                    error={validations.contactName}
                  />
                </div>
              )}
              {!isFieldHidden(checkoutContactNumberInput) && (
                <div ref={refContactNumber}>
                  <div className="flex items-end justify-between mb-1">
                    <div className="font-bold text-body text-default">
                      {t("menu.cart.checkout.labels.contactNumber")}
                    </div>
                    {isFieldRequired(checkoutContactNumberInput) && (
                      <span className="text-xs">
                        {t("common.form.required")}
                      </span>
                    )}
                  </div>
                  <PhoneInput
                    className={validations.contactNumber ? "error" : null}
                    type="tel"
                    name="mobileNumber"
                    countrySelectProps={{ className: "bg-default" }}
                    placeholder={t("signup.mobilePlaceholder")}
                    country="SG"
                    international={true}
                    withCountryCallingCode={true}
                    defaultCountry="SG"
                    value={contactNumber}
                    onChange={(value) => setContactNumber(value)}
                    onBlur={(event) => {
                      const newNumber = event.target.value;
                      // don't save number if it's just the country code
                      if (
                        newNumber.split(" ").length > 1 &&
                        newNumber !== cart.contactNumber
                      ) {
                        updateCartFulfilmentDetails({
                          variables: {
                            contactNumber: newNumber,
                          },
                        });
                      }
                    }}
                  />
                  {validations.contactNumber && (
                    <p className="mt-2 text-sm text-danger">
                      {validations.contactNumber}
                    </p>
                  )}
                </div>
              )}
              {!isFieldHidden(checkoutContactEmailInput) && (
                <div ref={refContactEmail}>
                  <div className="flex items-end justify-between mb-1">
                    <div className="font-bold text-body text-default">
                      {t("menu.cart.checkout.labels.email")}
                    </div>
                    {isFieldRequired(checkoutContactEmailInput) && (
                      <span className="text-xs">
                        {t("common.form.required")}
                      </span>
                    )}
                  </div>
                  <Input
                    type="email"
                    placeholder={t("menu.cart.checkout.placeholders.email")}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={(event) => {
                      if (event.target.value !== cart.contactEmail) {
                        updateCartFulfilmentDetails({
                          variables: {
                            email: event.target.value,
                          },
                        });
                      }
                    }}
                    error={validations.contactEmail}
                  />
                </div>
              )}
            </>
          )}
          {!hideCutleryRequired && (
            <div className="flex items-center justify-between">
              <div className="mb-1 font-bold text-body text-default">
                {t("menu.cart.checkout.labels.cutlery")}
              </div>
              <Input
                type="switch"
                value={isCutleryRequired}
                onChange={(value) => {
                  setCutleryRequired(value);
                  updateCartFulfilmentDetails({
                    variables: {
                      isCutleryRequired: value,
                    },
                  });
                }}
              />
            </div>
          )}
          <div>
            <div className="mb-1 font-bold text-body text-default">
              {t(`menu.cart.checkout.labels.notes.${cart?.fulfilmentType}`)}
            </div>
            <Input
              type="textarea"
              rows={2}
              placeholder={t(
                `menu.cart.checkout.placeholders.notes.${cart?.fulfilmentType}`,
              )}
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              onBlur={(event) => {
                if (event.target.value !== cart.notes) {
                  updateCartFulfilmentDetails({
                    variables: {
                      notes: event.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="flex flex-col flex-grow">
            {!disablePromoCode && (
              <div>
                <div className="mb-1 font-bold text-body text-default">
                  {t("menu.cart.checkout.labels.promoCode")}
                </div>
                <CartPromoCode
                  promoCode={cart.promoCode}
                  promoCodeError={cart.promoCodeError}
                  promoWarning={cart.promoWarning}
                  updateCartPromo={updateCartPromo}
                  updateCartPromoResponse={updateCartPromoResponse}
                  clearPromoCodeError={clearPromoCodeError}
                />
              </div>
            )}
            {!disableCheckoutCartLogin && (
              <CartUseLoyalty useLoyalty={cart.paymentBreakdown?.usePoints} />
            )}
            {cart.paymentBreakdown.totalIncludingTax > 0 && !isOmiseEnabled && (
              <div className="mt-4">
                {!isAdyenEnabled && (
                  <div className="mb-1 font-bold text-body text-default">
                    {t("menu.cart.checkout.labels.payment.label")}
                  </div>
                )}
                <CartPaymentMethods
                  cart={cart}
                  checkoutParams={checkoutParams}
                  validateCheckout={validate}
                  onPaymentRequestPaymentMethod={startCheckout}
                  onOrderProcessing={onOrderProcessing}
                />
              </div>
            )}
            {/* intentional empty space */}
            <div className="flex-grow" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          {footer}
          {renderCheckoutButtonContainer({
            className: "opacity-0 pointer-events-none",
          })}
        </div>
      </div>
      {renderCheckoutButtonContainer &&
        renderCheckoutButtonContainer({
          className: "fixed bottom-0 z-10",
        })}
      {!outletQrHash && (
        <CheckoutSignupModal
          visible={isCheckoutSignupVisible}
          defaultName={contactName}
          defaultMobileNumber={contactNumber}
          defaultEmail={email}
          performCheckout={() => {
            // cart ID will be different after login/signup; fetch latest cart ID before performing checkout
            if (loggedIn()) {
              if (showAdyenSpinnerCallback) {
                showAdyenSpinnerCallback();
              }
              getCartId();
            } else {
              performCheckout({ skipLoginPrompt: true });
            }
          }}
        />
      )}
    </div>
  );
}
