import React, { useState, useEffect, useContext, useCallback } from "react";

import { useQuery } from "@apollo/client";
import cx from "classnames";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import CartContext from "@/components/Cart/cartContext";
import { GET_ACTIVE_POPUP_QUERY } from "@/graphql/queries/getActivePopUp";
import getUrlParams from "@/utils/getUrlParams";

import styles from "./PopUpModal.module.less";
import ScrollContext from "../../scrollContext";

const BUTTON_HORIZONTAL_ALIGNMENTS = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

const TRIGGER_TYPE_OPTIONS = {
  PAGE_ARRIVAL: "page_arrival",
  DELAY: "delay",
  PAGE_EXIT: "page_exit",
  PAGE_SCROLL: "page_scroll",
};

const FREQUENCY_TYPE_OPTIONS = {
  EQUAL: "equal",
  EVERY: "every",
  GREATER_THAN: "greater_than",
};

let isPopUpScrollTriggered = false;

const POPUP_LOG_KEY = "popUpLogs";

export default function PopUpModal() {
  const params = getUrlParams(location);
  const isPreviewMode = params.popUpPreview === "true";
  const [activePopUp, setActivePopUp] = useState();
  const scroller = useContext(ScrollContext);
  const { cartQuery } = useContext(CartContext);

  const { data: activePopUpData } = useQuery(GET_ACTIVE_POPUP_QUERY, {
    skip: isPreviewMode,
    context: { graph: "diners" },
    variables: {
      outletId: cartQuery?.cart?.outletId,
    },
  });

  const showPopUpModal = useCallback(() => {
    const popUp = activePopUpData?.activePopup;
    setActivePopUp(popUp);
  }, [activePopUpData?.activePopup]);

  const checkPopUpFrequency = useCallback(() => {
    const popUp = activePopUpData?.activePopup;

    const popUpLogs = JSON.parse(localStorage.getItem(POPUP_LOG_KEY) || "{}");
    const log = popUpLogs[`popUp_${popUp.id}`] || {
      id: popUp.id,
      dismissed: false,
      pending: 0,
    };

    // Check frequency conditions
    if (
      (popUp.frequencyType === FREQUENCY_TYPE_OPTIONS.EQUAL &&
        log.pending + 1 === popUp.frequencyValue) ||
      (popUp.frequencyType === FREQUENCY_TYPE_OPTIONS.EVERY &&
        (log.pending + 1) % popUp.frequencyValue === 0) ||
      (popUp.frequencyType === FREQUENCY_TYPE_OPTIONS.GREATER_THAN &&
        log.pending + 1 > popUp.frequencyValue)
    ) {
      showPopUpModal();
    }

    popUpLogs[`popUp_${popUp.id}`] = {
      ...log,
      pending: log.pending + 1,
    };
    localStorage.setItem(POPUP_LOG_KEY, JSON.stringify(popUpLogs));
  }, [activePopUpData?.activePopup, showPopUpModal]);

  const showPopUpModalOnUnload = useCallback(
    (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
        checkPopUpFrequency();
      }
      return "";
    },
    [checkPopUpFrequency],
  );

  const showPopUpModalOnScroll = useCallback(() => {
    const popUp = activePopUpData?.activePopup;

    const maxScroll =
      scroller.current.scrollHeight - scroller.current.offsetHeight;
    const currentScroll = scroller.current.scrollTop;

    if (
      !isPopUpScrollTriggered &&
      (currentScroll * 100) / maxScroll >= (popUp.triggerValue || 0)
    ) {
      isPopUpScrollTriggered = true;
      checkPopUpFrequency();
    }
  }, [activePopUpData?.activePopup, scroller, checkPopUpFrequency]);

  useEffect(() => {
    const popUp = activePopUpData?.activePopup;
    if (popUp?.id && !isPreviewMode) {
      // Check popup trigger conditions
      if (popUp.triggerType === TRIGGER_TYPE_OPTIONS.PAGE_ARRIVAL) {
        // Page arrival
        checkPopUpFrequency();
      } else if (popUp.triggerType === TRIGGER_TYPE_OPTIONS.DELAY) {
        // Delay n seconds
        setTimeout(() => {
          checkPopUpFrequency();
        }, (popUp.triggerValue || 1) * 1000);
      } else if (popUp.triggerType === TRIGGER_TYPE_OPTIONS.PAGE_EXIT) {
        // Close the page
        window.addEventListener("beforeunload", showPopUpModalOnUnload);

        return () => {
          window.removeEventListener("beforeunload", showPopUpModalOnUnload);
        };
      } else if (
        popUp.triggerType === TRIGGER_TYPE_OPTIONS.PAGE_SCROLL &&
        scroller?.current
      ) {
        // Page scroll n percent
        const scrollerRef = scroller?.current;

        scrollerRef.addEventListener("scroll", showPopUpModalOnScroll);

        return () => {
          scrollerRef.removeEventListener("scroll", showPopUpModalOnScroll);
        };
      }
    } else {
      setActivePopUp(null);
    }
  }, [
    activePopUpData?.activePopup,
    isPreviewMode,
    scroller,
    checkPopUpFrequency,
    showPopUpModalOnScroll,
    showPopUpModalOnUnload,
  ]);

  useEffect(() => {
    const handler = (event) => {
      const data = JSON.parse(event.data);
      if (data.messageType === "popUp") {
        setActivePopUp(data);
      }
    };

    if (isPreviewMode) {
      window.addEventListener("message", handler);
    }

    return () => window.removeEventListener("message", handler);
  }, [isPreviewMode]);

  const handleDismissPopUp = (e) => {
    e.preventDefault();

    if (!isPreviewMode) {
      const popUpLogs = JSON.parse(localStorage.getItem(POPUP_LOG_KEY) || "{}");
      const log = popUpLogs[`popUp_${activePopUp.id}`] || {
        id: activePopUp.id,
        dismissed: false,
        pending: 0,
      };

      popUpLogs[`popUp_${activePopUp.id}`] = {
        ...log,
        dismissed: true,
      };
      localStorage.setItem(POPUP_LOG_KEY, JSON.stringify(popUpLogs));

      setActivePopUp(null);
    }
  };

  return (
    <Modal
      centered
      visible={!!activePopUp}
      mobileClosable
      maskClosable={false}
      footer={null}
      onCancel={handleDismissPopUp}
      padding={false}
      antModalClassName={styles.wrapper}
      width={null}
    >
      <div
        className={cx(
          "flex items-center w-full max-w-[400px] h-[500px] md:max-w-[800px] md:h-[600px] relative p-6",
        )}
      >
        {activePopUp?.desktopImageUrl && (
          <img
            className="absolute hidden w-full h-full md:block object-cover -z-10 top-0 left-0"
            src={activePopUp?.desktopImageUrl}
          />
        )}
        {activePopUp?.mobileImageUrl && (
          <img
            className="absolute w-full h-full md:hidden object-cover -z-10 top-0 left-0"
            src={activePopUp?.mobileImageUrl}
          />
        )}

        {activePopUp?.hasButton && !!activePopUp?.buttonText && (
          <div
            className={cx(
              "flex w-full absolute left-0 bottom-0 p-[20px] md:p-[30px]",
              {
                "justify-center":
                  !activePopUp?.buttonHorizontalAlignment ||
                  activePopUp?.buttonHorizontalAlignment ===
                    BUTTON_HORIZONTAL_ALIGNMENTS.CENTER,
                "justify-start":
                  activePopUp?.buttonHorizontalAlignment ===
                  BUTTON_HORIZONTAL_ALIGNMENTS.LEFT,
                "justify-end":
                  activePopUp?.buttonHorizontalAlignment ===
                  BUTTON_HORIZONTAL_ALIGNMENTS.RIGHT,
              },
            )}
          >
            <a
              href={activePopUp?.buttonUrl}
              onClick={activePopUp?.buttonUrl ? undefined : handleDismissPopUp}
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">{activePopUp?.buttonText}</Button>
            </a>
          </div>
        )}
      </div>
    </Modal>
  );
}
