import { gql } from "@apollo/client";

import { PAYMENT_BREAKDOWN_FIELDS } from "../queries/getCart";

export const UPDATE_CART_USE_POINTS_OPTIMISED_MUTATION = gql`
  mutation UpdateCartUsePointsOptimised($usePoints: Boolean!) {
    updateCartUsePointsOptimised(input: { usePoints: $usePoints }) {
      id
      paymentBreakdown {
        ...PaymentBreakdownFields
      }
    }
  }
  ${PAYMENT_BREAKDOWN_FIELDS}
`;
