import React, { useContext, useEffect, useRef, useState } from "react";

import XIcon from "@heroicons/react/outline/XIcon";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SlideOver from "@/common/SlideOver";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import { useListenEvent } from "@/utils/eventBus";

import CartCheckout from "./CartCheckout";
import CartFooter from "./CartFooter";
import CartItem from "./CartItem";
import CartContext from "./cartContext";

import "./Cart.less";

Cart.propTypes = {
  isCartOpen: PropTypes.bool,
  closeCart: PropTypes.func,
  onOrderProcessing: PropTypes.func,
};

export default function Cart({ isCartOpen, closeCart, onOrderProcessing }) {
  const { t } = useTranslation();

  const {
    cartLoading: loading,
    cartError: error,
    cartQuery: data,
    addressesAndOutlets,
  } = useContext(CartContext);
  const { configQuery } = useContext(ConfigContext);

  const fulfilmentTypes =
    configQuery?.config?.brandAvailableFulfilmentTypes || [];

  const [isCheckingOut, setCheckingOut] = useState(false);
  const [cartOutlet, setCartOutlet] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const checkoutScrollableContainer = useRef();
  const mobileCheckoutScrollableContainer = useRef();

  useListenEvent("openCartCheckout", () => setCheckingOut(true));

  useListenEvent("stripeLoading", (bool) => setCheckoutLoading(bool));

  useEffect(() => {
    if (data?.cart?.topLevelItems?.length === 0) {
      setCheckingOut(false);
    }
  }, [data?.cart?.topLevelItems?.length]);

  useEffect(() => {
    if (data?.cart?.outletId && addressesAndOutlets?.outlets) {
      setCartOutlet(
        addressesAndOutlets.outlets.find(
          (outlet) => outlet.id === data?.cart?.outletId,
        ),
      );
    }
  }, [data?.cart?.outletId, addressesAndOutlets?.outlets]);

  return (
    <div
      data-testid="cart"
      className={"cart absolute z-40" + (isCheckingOut ? " checking-out" : "")}
    >
      {data && (
        <SlideOver
          panelClassName="text-default bg-default max-w-full transition-all"
          visible={isCartOpen}
          closePanel={closeCart}
        >
          <Spin spinning={checkoutLoading} wrapperClassName="h-full">
            <div className="flex flex-col fullscreen">
              <div className="flex items-start justify-between p-4 border-b cart-header border-default">
                <h2
                  id="slide-over-heading"
                  className="flex flex-row items-center mb-0 text-lg font-bold text-default"
                >
                  {isCheckingOut && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() => {
                        setCheckingOut(false);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  )}
                  {t(
                    `menu.cart.${
                      isCheckingOut ? "checkoutHeader" : "cartHeader"
                    }`,
                  )}
                </h2>
                <div className="flex items-center ml-3 h-7">
                  <button
                    className="rounded-md bg-default text-default2 hover:text-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary md:hidden"
                    onClick={closeCart}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>

              <div
                ref={mobileCheckoutScrollableContainer}
                className="flex flex-col flex-grow h-full overflow-y-auto cart-body md:flex-row md:overflow-hidden"
              >
                <div
                  className={classNames([
                    "flex-col cart-column",
                    isCheckingOut
                      ? "h-auto hidden md:flex" // Hiding the entire cart summary for now on mobile
                      : "h-full flex",
                  ])}
                  aria-hidden="true"
                >
                  <div
                    className={`flex-grow h-full cart-items md:overflow-y-auto ${
                      isCheckingOut ? "" : "overflow-y-auto"
                    }`}
                  >
                    {isCheckingOut && (
                      <div className="px-4 pt-4 mb-1 font-semibold">
                        {t("menu.cart.summary")}
                      </div>
                    )}
                    {error?.message && (
                      <div className="px-4 pt-4 text-danger">
                        {error?.message}
                      </div>
                    )}
                    {data?.cart?.topLevelItems &&
                      data?.cart?.topLevelItems.length === 0 && (
                        <div className="p-4">{t("menu.cart.empty")}</div>
                      )}
                    <div className="cart-line-items">
                      {configQuery?.brands.length > 0 ? (
                        <MultiBrandCart
                          cartItems={data?.cart?.topLevelItems}
                          isCheckingOut={isCheckingOut}
                        />
                      ) : (
                        data?.cart?.topLevelItems?.map((cartItem) => (
                          <CartItem
                            key={cartItem.id}
                            cartItem={cartItem}
                            isCheckingOut={isCheckingOut}
                          />
                        ))
                      )}
                    </div>
                  </div>
                  {(data?.cart?.topLevelItems?.length || 0) > 0 && (
                    <div
                      className={classNames([
                        "flex flex-grow flex-col",
                        { "hidden md:block": isCheckingOut },
                      ])}
                    >
                      <Spin spinning={loading}>
                        <CartFooter
                          isCartOpen={isCartOpen}
                          availableFulfilmentTypes={fulfilmentTypes}
                          fulfilmentType={data?.cart?.fulfilmentType}
                          paymentBreakdown={data?.cart?.paymentBreakdown}
                          outlet={cartOutlet}
                          alcoholicBeverageIds={
                            data?.cart?.alcoholicBeverageIds || []
                          }
                          topLevelItems={data?.cart?.topLevelItems || []}
                          stateCheckingOut={[isCheckingOut, setCheckingOut]}
                        />
                      </Spin>
                    </div>
                  )}
                </div>
                {isCheckingOut && (
                  <div className="flex flex-col cart-column md:border-l md:border-default">
                    <div className="flex-grow h-full overflow-y-auto">
                      <CartCheckout
                        cart={data?.cart}
                        availableFulfilmentTypes={fulfilmentTypes}
                        checkoutScrollableContainer={
                          checkoutScrollableContainer
                        }
                        mobileCheckoutScrollableContainer={
                          mobileCheckoutScrollableContainer
                        }
                        cartOutlet={cartOutlet}
                        setCheckoutLoading={setCheckoutLoading}
                        onOrderProcessing={(data) => {
                          data.closeCart = closeCart;
                          data.setCheckoutLoading = setCheckoutLoading;
                          onOrderProcessing(data);
                        }}
                        footer={
                          (data?.cart?.topLevelItems?.length || 0) > 0 && (
                            <div className="mt-4 md:hidden">
                              <CartFooter
                                isCartOpen={isCartOpen}
                                availableFulfilmentTypes={fulfilmentTypes}
                                fulfilmentType={data?.cart?.fulfilmentType}
                                paymentBreakdown={data?.cart?.paymentBreakdown}
                                outlet={cartOutlet}
                                alcoholicBeverageIds={
                                  data?.cart?.alcoholicBeverageIds || []
                                }
                                topLevelItems={data?.cart?.topLevelItems || []}
                                stateCheckingOut={[
                                  isCheckingOut,
                                  setCheckingOut,
                                ]}
                              />
                            </div>
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </SlideOver>
      )}
    </div>
  );
}

MultiBrandCart.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object),
  isCheckingOut: PropTypes.bool,
};

function MultiBrandCart({ cartItems, isCheckingOut }) {
  return map(groupBy(cartItems, "brand.label"), (sortedCartItems, brand) => (
    <React.Fragment key={brand}>
      <div className="pb-1 m-4 mb-0 border-b-2 text-default font-display border-primary">
        {brand}
      </div>
      {sortedCartItems.map((cartItem) => (
        <CartItem
          key={`${brand}-${cartItem.id}`}
          cartItem={cartItem}
          isCheckingOut={isCheckingOut}
        />
      ))}
    </React.Fragment>
  ));
}
