import { setContext } from "@apollo/client/link/context";

import constants from "./utils/constants";

// eslint-disable-next-line no-unused-vars
export default setContext((_, { headers }) => {
  localStorage.removeItem(constants.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN);
  const xClientUUID = localStorage.getItem(constants.X_CLIENT_UUID);

  const outletSlug = localStorage.getItem(constants.OUTLET_SLUG);
  const outletId = outletSlug?.split("-")[0];

  const outletQrHash = localStorage.getItem(constants.OUTLET_QR_HASH);
  const tableQrHash = localStorage.getItem(constants.TABLE_QR_HASH);

  return {
    headers: {
      "X-Client-UUID": xClientUUID,
      "X-Client-Name": constants.X_CLIENT_NAME,
      "X-Platform": constants.X_PLATFORM,
      ...(outletId ? { "X-Outlet-ID": outletId } : {}),
      ...(outletQrHash ? { "X-Outlet-QR-Hash": outletQrHash } : {}),
      ...(tableQrHash ? { "X-Table-QR-Hash": tableQrHash } : {}),
      ...(import.meta.env.VITE_X_BRAND_ID
        ? { "X-Brand-ID": import.meta.env.VITE_X_BRAND_ID }
        : {}),
      Authorization: `Bearer ${refreshToken}`,
    },
  };
});
