import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import { emitEvent } from "@/utils/eventBus";

AvailabilityModal.propTypes = {
  menuSectionDisabledReason: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default function AvailabilityModal({
  menuSectionDisabledReason,
  closeModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={!!menuSectionDisabledReason}
      onCancel={closeModal}
      title={t("menu.availability.header")}
      footer={
        <div className="grid gap-3 mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            type="primary"
            onClick={() => {
              emitEvent("selectTimeslot");
              closeModal();
            }}
          >
            {t("menu.availability.selectAnotherDate")}
          </Button>
        </div>
      }
    >
      {menuSectionDisabledReason}
    </Modal>
  );
}
