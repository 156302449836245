import React from "react";

export default function PoweredByAtlasId() {
  return (
    <svg
      version="1.1"
      id="poweredByAtlasId"
      className="h-6"
      x="0px"
      y="0px"
      viewBox="0 0 172 31"
      fill="var(--color-primary)"
      style={{
        enableBackground: "new 0 0 172 31",
      }}
      xmlSpace="preserve"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167,1H4C2.3,1,1,2.3,1,4v23c0,1.7,1.3,3,3,3h163c1.7,0,3-1.3,3-3V4C170,2.3,168.7,1,167,1z M4,0
	C1.8,0,0,1.8,0,4v23c0,2.2,1.8,4,4,4h163c2.2,0,4-1.8,4-4V4c0-2.2-1.8-4-4-4H4z"
      />
      <path
        d="M17.9,13.2c0,0.5-0.1,1-0.4,1.4c-0.2,0.4-0.6,0.8-1.1,1.1c-0.5,0.3-1.2,0.4-2,0.4h-1.8V20h-1.6v-9.7h3.4
	c0.7,0,1.4,0.1,1.9,0.4c0.5,0.3,0.9,0.6,1.2,1C17.7,12.1,17.9,12.6,17.9,13.2z M14.4,14.7c0.6,0,1.1-0.1,1.4-0.4
	c0.3-0.3,0.4-0.7,0.4-1.2c0-1.1-0.6-1.6-1.8-1.6h-1.8v3.2H14.4z"
      />
      <path
        d="M22.6,20.1c-0.7,0-1.4-0.2-2-0.5c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1
	c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.3-0.5,2-0.5c0.7,0,1.4,0.2,2,0.5c0.6,0.3,1.1,0.8,1.4,1.4c0.3,0.6,0.5,1.3,0.5,2.1
	c0,0.8-0.2,1.5-0.5,2.1c-0.4,0.6-0.8,1.1-1.5,1.4C24,20,23.3,20.1,22.6,20.1z M22.6,18.7c0.4,0,0.8-0.1,1.1-0.3
	c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.9,0.3-1.4c0-0.6-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.7-0.3-1.1-0.3
	c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.8,0.2,1.5,0.6,1.9C21.4,18.5,21.9,18.7,22.6,18.7z"
      />
      <path d="M38.4,12.3L36,20h-1.7l-1.6-5.7L31.2,20h-1.7l-2.4-7.7h1.6l1.6,6.2l1.6-6.2h1.7l1.6,6.2l1.6-6.2H38.4z" />
      <path
        d="M46.5,16c0,0.3,0,0.6-0.1,0.8h-5.9c0,0.6,0.3,1.1,0.7,1.5c0.4,0.4,0.9,0.6,1.5,0.6c0.9,0,1.5-0.4,1.8-1.1h1.7
	c-0.2,0.7-0.7,1.3-1.3,1.8c-0.6,0.4-1.4,0.7-2.3,0.7c-0.7,0-1.4-0.2-2-0.5c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1
	c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.3-0.5,2-0.5c0.7,0,1.4,0.2,1.9,0.5c0.6,0.3,1,0.8,1.3,1.3
	C46.3,14.5,46.5,15.2,46.5,16z M44.8,15.4c0-0.6-0.2-1.1-0.6-1.4c-0.4-0.4-0.9-0.5-1.5-0.5c-0.6,0-1,0.2-1.4,0.5
	c-0.4,0.3-0.6,0.8-0.7,1.4H44.8z"
      />
      <path
        d="M49.5,13.4c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3v1.7h-0.4c-0.6,0-1.1,0.2-1.4,0.5c-0.3,0.3-0.5,0.9-0.5,1.7V20
	h-1.6v-7.7h1.6V13.4z"
      />
      <path
        d="M60.2,16c0,0.3,0,0.6-0.1,0.8h-5.9c0,0.6,0.3,1.1,0.7,1.5c0.4,0.4,0.9,0.6,1.5,0.6c0.9,0,1.5-0.4,1.8-1.1H60
	c-0.2,0.7-0.7,1.3-1.3,1.8c-0.6,0.4-1.4,0.7-2.3,0.7c-0.7,0-1.4-0.2-2-0.5c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2.1
	c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.3-0.5,2-0.5c0.7,0,1.4,0.2,1.9,0.5c0.6,0.3,1,0.8,1.3,1.3
	C60.1,14.5,60.2,15.2,60.2,16z M58.6,15.4c0-0.6-0.2-1.1-0.6-1.4c-0.4-0.4-0.9-0.5-1.5-0.5c-0.6,0-1,0.2-1.4,0.5
	c-0.4,0.3-0.6,0.8-0.7,1.4H58.6z"
      />
      <path
        d="M61.1,16.1c0-0.8,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5c0.5,0,1,0.1,1.5,0.3
	c0.5,0.2,0.9,0.5,1.2,0.9V9.6h1.6V20h-1.6v-1.2c-0.3,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1,0.4-1.6,0.4c-0.7,0-1.3-0.2-1.8-0.5
	c-0.6-0.3-1-0.8-1.3-1.4C61.3,17.6,61.1,16.9,61.1,16.1z M67.5,16.1c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9
	c-0.4-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.6,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.5,0.1,1,0.3,1.4
	c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.7,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3c0.4-0.2,0.6-0.5,0.9-0.9C67.3,17.1,67.5,16.7,67.5,16.1z"
      />
      <path
        d="M76.1,13.4c0.3-0.4,0.6-0.7,1.1-0.9c0.5-0.2,1-0.4,1.6-0.4c0.7,0,1.3,0.2,1.8,0.5c0.6,0.3,1,0.8,1.3,1.4
	c0.3,0.6,0.5,1.3,0.5,2.1c0,0.8-0.2,1.5-0.5,2.1c-0.3,0.6-0.8,1.1-1.3,1.4c-0.6,0.3-1.2,0.5-1.8,0.5c-0.6,0-1.1-0.1-1.6-0.4
	c-0.5-0.2-0.8-0.5-1.1-0.9V20h-1.6V9.6h1.6V13.4z M80.8,16.1c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9
	c-0.4-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.6,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.4
	c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.7,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3c0.4-0.2,0.7-0.5,0.9-0.9C80.7,17.1,80.8,16.6,80.8,16.1z"
      />
      <path d="M90.8,12.3l-4.7,11.3h-1.7l1.6-3.8l-3-7.6h1.8l2.2,5.9l2.3-5.9H90.8z" />
      <path
        d="M98.7,20c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0.1h-1.7c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.3l4.5-8.9
	c0.1-0.3,0.2-0.5,0.3-0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.3,0.4,0.6l4.5,8.9c0,0.1,0.1,0.2,0.1,0.2
	c0,0.1-0.1,0.2-0.3,0.2h-1.8c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-2.5-5.1L98.7,20z"
      />
      <path
        d="M114.7,12.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-2.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0.1,0,0.2v7
	c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-1.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-7c0-0.1,0-0.1-0.1-0.2
	c0,0-0.1-0.1-0.2-0.1H107c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V11c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h7.3
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3V12.5z"
      />
      <path
        d="M118.4,18c0,0.1,0,0.1,0,0.2c0,0,0.1,0,0.2,0h3.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3v1.5c0,0.1,0,0.2-0.1,0.3
	c-0.1,0.1-0.2,0.1-0.3,0.1h-5.9c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V11c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h1.7
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3V18z"
      />
      <path
        d="M126.2,20c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0.1h-1.7c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.3l4.5-8.9
	c0.1-0.3,0.2-0.5,0.3-0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.3,0.4,0.6l4.5,8.9c0,0.1,0.1,0.2,0.1,0.2
	c0,0.1-0.1,0.2-0.3,0.2h-1.8c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.4l-2.5-5.1L126.2,20z"
      />
      <path
        d="M138.2,10.4c1.3,0,2.5,0.4,3.5,1.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3l-0.8,1.2c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-0.7-0.5-1.1-0.6c-0.4-0.1-0.7-0.2-1.1-0.2c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.3,0.3-0.3,0.6
	c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.6,0.2,1,0.3c0.7,0.2,1.3,0.4,1.8,0.7c0.5,0.3,0.8,0.6,1.1,1
	c0.2,0.4,0.4,0.8,0.4,1.4c0,0.7-0.2,1.2-0.5,1.7c-0.3,0.5-0.8,0.8-1.4,1.1c-0.6,0.3-1.3,0.4-2.1,0.4c-0.7,0-1.4-0.1-2-0.3
	c-0.6-0.2-1.1-0.5-1.6-0.9c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.1-0.3l0.7-1.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.3,0.1
	c0.3,0.3,0.7,0.5,1.1,0.6c0.4,0.1,0.8,0.2,1.3,0.2c0.5,0,0.8-0.1,1-0.2c0.2-0.1,0.4-0.3,0.4-0.6c0-0.2-0.1-0.3-0.2-0.4
	c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.6-0.2-1.1-0.3c-0.7-0.2-1.3-0.4-1.7-0.6c-0.5-0.2-0.8-0.6-1-0.9c-0.2-0.4-0.4-0.9-0.4-1.4
	c0-0.7,0.2-1.2,0.5-1.7c0.3-0.5,0.8-0.8,1.3-1.1C136.9,10.6,137.5,10.4,138.2,10.4z"
      />
      <path
        d="M149.8,20.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-1.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V11
	c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h1.7c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3V20.1z"
      />
      <path
        d="M151.9,11c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h3.5c1.1,0,2.1,0.2,2.9,0.6c0.8,0.4,1.4,0.9,1.8,1.7
	c0.4,0.7,0.6,1.6,0.6,2.5c0,1-0.2,1.9-0.7,2.7c-0.4,0.7-1.1,1.3-1.9,1.7c-0.8,0.4-1.9,0.6-3,0.6h-3.2c-0.1,0-0.2,0-0.3-0.1
	c-0.1-0.1-0.1-0.2-0.1-0.3V11z M154.6,12.9c-0.1,0-0.1,0-0.2,0.1c0,0,0,0.1,0,0.2V18c0,0.1,0,0.1,0,0.2c0,0,0.1,0,0.2,0h0.9
	c1,0,1.8-0.2,2.3-0.7c0.5-0.5,0.8-1.1,0.8-2c0-0.5-0.1-1-0.4-1.4c-0.2-0.4-0.6-0.7-1-0.9c-0.5-0.2-1-0.3-1.7-0.3H154.6z"
      />
    </svg>
  );
}
