import { gql } from "@apollo/client";

export const PAYMENT_BREAKDOWN_FIELDS = gql`
  fragment PaymentBreakdownFields on PaymentBreakdown {
    subtotal
    surcharge
    surchargeLabel
    discount
    pointsAmount
    pointsValue
    isPostTaxDiscount
    minimumOrderValue
    deliveryFee
    amountToFreeDelivery
    amountToNextDeliveryFee {
      nextFeeTier
      nextAmount
      baseDeliveryFee
    }
    deliveryFeeWaiverCartSubtotal
    total
    serviceCharge
    tax
    totalIncludingTax
    taxInclusivePrices
    usePoints
  }
`;

const generateCartItemFieldsFragment = (typename) => gql`
  fragment CartItemFields on ${typename} {
    id
    name
    quantity
    quantityLeft
    notes
    subtotal
    itemModifierGroupId
    translation
    item {
      id
      isConfigurable
      itemStock {
        id
        quantityLeft
      }
      horizontalImageUrl
      unitPriceFractional
      currency
      identifier
      sections {
        label
      }
    }
  }
`;
const generateCartTopLevelItemsFragment = (
  typename,
  cartItemFieldsFragment,
) => gql`
  fragment CartTopLevelItems on ${typename} {
    topLevelItems {
      ...CartItemFields
      brand {
        id
        label
      }
      item {
        idBreadcrumb
      }
      sortedSubItems {
        label
        subItems {
          modifierId
          ...CartItemFields
          sortedSubItems {
            label
            subItems {
              modifierId
              ...CartItemFields
            }
          }
        }
      }
      subItems {
        modifierId
        ...CartItemFields
        subItems {
          modifierId
          ...CartItemFields
        }
      }
    }
  }
  ${cartItemFieldsFragment}
`;

const CART_ITEM_FIELDS = generateCartItemFieldsFragment("SubItem");
export const CART_TOP_LEVEL_ITEMS = generateCartTopLevelItemsFragment(
  "Cart",
  CART_ITEM_FIELDS,
);

const CART_ITEM_OPTIMISED_FIELDS = generateCartItemFieldsFragment(
  "SubItemTypeOptimised",
);

export const CART_OPTIMISED_TOP_LEVEL_ITEMS = generateCartTopLevelItemsFragment(
  "DinerCartTypeOptimised",
  CART_ITEM_OPTIMISED_FIELDS,
);

export const GET_CART_ID_QUERY = gql`
  query GetCartId {
    cart {
      id
    }
  }
`;

const generateCartFragment = (typename, topLevelItemsFragment) => gql`
  fragment CartFields on ${typename} {
    id
    servingDate
    timeslot {
      id
      rangeLabel
      startTime
      endTime
    }
    timeslotType
    fulfilmentType
    address {
      id
      line1
      line2
      latitude
      longitude
      postalCode
      isCutleryRequired
      notes
      availableOutlets {
        id
      }
      user {
        selectedAddressId
      }
    }
    tableId
    tableName
    canChangeOutlet
    paymentMethods
    notes
    contactName
    contactEmail
    contactNumber
    isGift
    recipientName
    recipientContactNumber
    giftMessage
    outletId
    outlet {
      labelForPickup
    }
    promoCode
    promoCodeError
    promoWarning
    promoItems
    paymentBreakdown {
      ...PaymentBreakdownFields
    }
    outletClosed
    additionalLeadTime
    orderValueWithLeadTime
    alcoholicBeverageIds
    ...CartTopLevelItems
    removedItems
  }
  ${PAYMENT_BREAKDOWN_FIELDS}
  ${topLevelItemsFragment}
`;

export const CART_FIELDS = generateCartFragment("Cart", CART_TOP_LEVEL_ITEMS);

export const CART_OPTIMISED_FIELDS = generateCartFragment(
  "DinerCartTypeOptimised",
  CART_OPTIMISED_TOP_LEVEL_ITEMS,
);

const generateCartQuery = (operationName, queryName, cartFragment) => gql`
  query ${operationName} {
    ${queryName} {
      ...CartFields
    }
  }
  ${cartFragment}
`;

export const GET_CART_QUERY = generateCartQuery("GetCart", "cart", CART_FIELDS);

export const GET_CART_OPTIMISED_QUERY = generateCartQuery(
  "GetCartOptimised",
  "cartOptimised",
  CART_OPTIMISED_FIELDS,
);
