import { gql } from "@apollo/client";

const ITEM_STOCKS_FRAGMENT = gql`
  fragment ItemStockableType on Item {
    itemStock {
      id
      quantityLeft
    }
  }
`;

export const ITEM_FIELDS_FRAGMENT = gql`
  fragment ItemFields on Item {
    id
    identifier
    label
    description
    displayOrder
    isHighlight
    unitPriceFractional
    currency
    isConfigurable
    hasConfigurations
    horizontalImageUrl
    descriptionImageUrl
    promotionalLabelText
    promotionalLabelFontColor
    promotionalLabelBgColor
    sugarLevel
    nutriGrade
    ...ItemStockableType
  }

  ${ITEM_STOCKS_FRAGMENT}
`;

/* Add upsell to modifiers
  upsellLabel
  upsellLabelTextColor
  upsellLabelBackgroundColor
*/

export const OPTIMISED_MENU_QUERY = gql`
  query GetOptimisedMenu(
    $brandId: Int
    $cartId: Int
    $fulfilmentType: String
    $outletId: Int
    $servingDate: ISO8601Date!
    $timeslot: [Int!]
  ) {
    getMenuOptimised(
      brandId: $brandId
      cartId: $cartId
      fulfilmentType: $fulfilmentType
      outletId: $outletId
      servingDate: $servingDate
      timeslot: $timeslot
    ) {
      id
      label
      startDate
      endDate
      isAvailable
      translation
      sections {
        id
        label
        description
        displayOrder
        isAvailable
        humanizedAvailability
        disabledReason
        disabled
        translation
        items {
          id
          label
          description
          displayOrder
          isHighlight
          unitPriceFractional
          currency
          isConfigurable
          horizontalImageUrl
          idBreadcrumb
          disabled
          disabledReason
          promotionalLabelText
          promotionalLabelFontColor
          promotionalLabelBgColor
          sugarLevel
          nutriGrade
          translation
          humanizedAvailability
          humanizedLeadTime
          isAvailable
          isLeadTimeBreached
          itemStock {
            id
            quantityLeft
          }
          modifierGroups {
            id
            label
            selectionRequiredMin
            selectionRequiredMax
            displayOrder
            isFixed
            maxQuantityPerModifier
            hideThumbnails
            idBreadcrumb
            modifiers {
              id
              label
              description
              displayOrder
              unitPriceFractional
              currency
              isConfigurable
              horizontalImageUrl
              idBreadcrumb
              defaultQuantity
              quantityLeft
              modifierGroups {
                id
                label
                selectionRequiredMin
                selectionRequiredMax
                displayOrder
                isFixed
                maxQuantityPerModifier
                hideThumbnails
                idBreadcrumb
                modifiers {
                  id
                  label
                  description
                  displayOrder
                  unitPriceFractional
                  currency
                  isConfigurable
                  horizontalImageUrl
                  idBreadcrumb
                  defaultQuantity
                  quantityLeft
                  isAvailable
                  humanizedAvailability
                  disabledReason
                  disabled
                  upsellLabel
                  upsellLabelTextColor
                  upsellLabelBackgroundColor
                  sugarLevel
                  nutriGrade
                  translation
                }
                isAvailable
                humanizedAvailability
                disabledReason
                disabled
              }
              isAvailable
              humanizedAvailability
              disabledReason
              disabled
              upsellLabel
              upsellLabelTextColor
              upsellLabelBackgroundColor
              sugarLevel
              nutriGrade
              translation
            }
            isAvailable
            humanizedAvailability
            disabledReason
            disabled
          }
        }
        subSections {
          id
          label
          description
          displayOrder
          disabled
          disabledReason
          humanizedAvailability
          isAvailable
          translation
          items {
            id
            label
            description
            displayOrder
            isHighlight
            unitPriceFractional
            currency
            isConfigurable
            horizontalImageUrl
            idBreadcrumb
            disabled
            disabledReason
            promotionalLabelText
            promotionalLabelFontColor
            promotionalLabelBgColor
            sugarLevel
            nutriGrade
            humanizedAvailability
            humanizedLeadTime
            isAvailable
            isLeadTimeBreached
            translation
            itemStock {
              id
              quantityLeft
            }
            modifierGroups {
              id
              label
              selectionRequiredMin
              selectionRequiredMax
              displayOrder
              isFixed
              maxQuantityPerModifier
              hideThumbnails
              idBreadcrumb
              modifiers {
                id
                label
                description
                displayOrder
                unitPriceFractional
                currency
                isConfigurable
                horizontalImageUrl
                idBreadcrumb
                defaultQuantity
                quantityLeft
                modifierGroups {
                  id
                  label
                  selectionRequiredMin
                  selectionRequiredMax
                  displayOrder
                  isFixed
                  maxQuantityPerModifier
                  hideThumbnails
                  idBreadcrumb
                  modifiers {
                    id
                    label
                    description
                    displayOrder
                    unitPriceFractional
                    currency
                    isConfigurable
                    horizontalImageUrl
                    idBreadcrumb
                    defaultQuantity
                    quantityLeft
                    isAvailable
                    humanizedAvailability
                    disabledReason
                    disabled
                    upsellLabel
                    upsellLabelTextColor
                    upsellLabelBackgroundColor
                    sugarLevel
                    nutriGrade
                    translation
                  }
                  isAvailable
                  humanizedAvailability
                  disabledReason
                  disabled
                }
                isAvailable
                humanizedAvailability
                disabledReason
                disabled
                upsellLabel
                upsellLabelTextColor
                upsellLabelBackgroundColor
                sugarLevel
                nutriGrade
                translation
              }
              isAvailable
              humanizedAvailability
              disabledReason
              disabled
            }
          }
        }
      }
    }
  }
`;
