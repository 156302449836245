import { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";

import ConfigContext from "@/components/Config/configContext";
import constants from "@/utils/constants";

export default function useTranslatedText({
  resource,
  fallbackValue,
  key = "title",
}) {
  const { i18n } = useTranslation();
  const { configQuery } = useContext(ConfigContext);

  const hasLanguageSelector = configQuery?.config?.features?.includes(
    constants.FEATURES.LANGUAGE_SELECTOR,
  );

  return useMemo(() => {
    if (!resource) return fallbackValue;

    function getResourceValue(resource, key) {
      if (hasLanguageSelector && resource.translation?.[key]) {
        return (
          resource.translation?.[key]?.[i18n.resolvedLanguage] ||
          resource.translation?.[key]?.["default"] ||
          (typeof fallbackValue === "function"
            ? fallbackValue(resource)
            : fallbackValue)
        );
      }

      return typeof fallbackValue === "function"
        ? fallbackValue(resource)
        : fallbackValue;
    }

    if (Array.isArray(resource)) {
      return resource.map((r) => getResourceValue(r, key));
    } else {
      return getResourceValue(resource, key);
    }
  }, [
    resource,
    key,
    i18n.resolvedLanguage,
    fallbackValue,
    hasLanguageSelector,
  ]);
}
