// https://medium.com/walmartglobaltech/lazy-loading-images-intersectionobserver-8c5bff730920
// https://usehooks-typescript.com/react-hook/use-intersection-observer
import { useEffect } from "react";

let listenerCallbacks = new WeakMap();
let observer;

function handleIntersections(entries) {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      let cb = listenerCallbacks.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver() {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: "100px",
      threshold: "0.15",
    });
  }
  return observer;
}

export function useIntersectionObserver(elementRef, callback) {
  useEffect(() => {
    const target = elementRef?.current;

    let observer = getIntersectionObserver();
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
  }, [callback, elementRef]);
}
