import { gql } from "@apollo/client";

import { PAYMENT_BREAKDOWN_FIELDS } from "@/graphql/queries/getCart";

const generateSetFulfilmentTypeMutation = (operationName, mutationName) => gql`
  mutation ${operationName}(
    $fulfilmentType: String!
    $addressId: Int
    $outletId: Int
  ) {
    ${mutationName}(
      input: {
        fulfilmentType: $fulfilmentType
        addressId: $addressId
        outletId: $outletId
      }
    ) {
      id
      fulfilmentType
      servingDate
      timeslot {
        id
        rangeLabel
        startTime
        endTime
      }
      outletId
      outlet {
        labelForPickup
      }
      address {
        id
        line1
        line2
        latitude
        longitude
        postalCode
        notes
        availableOutlets {
          id
        }
        user {
          selectedAddressId
        }
      }
      removedItems
      paymentBreakdown {
        ...PaymentBreakdownFields
      }
    }
  }
  ${PAYMENT_BREAKDOWN_FIELDS}
`;
export const SET_FULFILMENT_TYPE_MUTATION = generateSetFulfilmentTypeMutation(
  "SetFulfilmentType",
  "setFulfilmentType",
);
export const SET_FULFILMENT_TYPE_OPTIMISED_MUTATION =
  generateSetFulfilmentTypeMutation(
    "SetFulfilmentTypeOptimised",
    "setFulfilmentTypeOptimised",
  );
