import { gql } from "@apollo/client";

export const GET_PROMOTIONS_QUERY = gql`
  query GetPromotions {
    promotions {
      id
      code
      label
      description
      value
      valueType
      valueCap
      startDate
      endDate
      active
      unreleasedClaimsCount
      remainingClaimsCount
    }
  }
`;
