import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

OneColumnTemplate.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sectionRef: PropTypes.object,
  rest: PropTypes.any,
};

export default function OneColumnTemplate({
  section,
  index,
  sectionRef,
  ...rest
}) {
  return (
    <div
      ref={sectionRef}
      className={classNames([
        index % 2 === 1 ? "" : "bg-default2",
        section?.fullWidth ? "" : "py-12",
      ])}
      style={section?.style}
      {...rest}
    >
      <div
        className={classNames([
          section?.wide
            ? "max-w-screen-xl px-3 sm:px-6"
            : "max-w-5xl px-4 sm:px-8",
          "mx-auto",
          section?.fullWidth ? "py-12" : "",
        ])}
      >
        {section?.header && (
          <div
            className={classNames([
              "text-2xl sm:text-3xl font-display",
              section?.description ? "mb-6" : "",
            ])}
            dangerouslySetInnerHTML={{ __html: section.header }}
          />
        )}
        {section?.description && (
          <div dangerouslySetInnerHTML={{ __html: section.description }} />
        )}
      </div>
    </div>
  );
}
