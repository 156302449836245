import { gql } from "@apollo/client";

import { ITEM_FIELDS_FRAGMENT } from "@/graphql/queries/getMenu";

export const ITEM_SHAPE_FIELDS_FRAGMENT = gql`
  fragment ItemShapeFields on Modifier {
    id
    label
    description
    displayOrder
    unitPriceFractional
    currency
    isConfigurable
    horizontalImageUrl
    promotionalLabelText
    promotionalLabelFontColor
    promotionalLabelBgColor
    sugarLevel
    nutriGrade
    translation
  }
`;

const MODIFIER_GROUP_FRAGMENT = gql`
  fragment ModifierGroupFields on ModifierGroup {
    id
    idBreadcrumb
    label
    selectionRequiredMin
    selectionRequiredMax
    isFixed
    maxQuantityPerModifier
    hideThumbnails
    modifiers {
      ...ItemShapeFields
      quantityLeft
      defaultQuantity
      idBreadcrumb
      disabled
      disabledReason
      upsellLabel
      upsellLabelTextColor
      upsellLabelBackgroundColor
    }
  }
  ${ITEM_SHAPE_FIELDS_FRAGMENT}
`;

export const ITEM_CONFIGURATION_FRAGMENT = gql`
  fragment ItemConfiguration on Item {
    idBreadcrumb
    modifierGroups {
      ...ModifierGroupFields
      modifiers {
        modifierGroups {
          ...ModifierGroupFields
        }
      }
    }
  }
  ${MODIFIER_GROUP_FRAGMENT}
`;

export const ITEM_QUERY = gql`
  query GetItem($itemId: Int!) {
    getItem(itemId: $itemId) {
      ...ItemFields
      isLeadTimeBreached
      humanizedLeadTime
      isAvailable
      humanizedAvailability
      itemStock {
        id
        quantityLeft
      }
      ...ItemConfiguration
    }
  }

  ${ITEM_FIELDS_FRAGMENT}
  ${ITEM_CONFIGURATION_FRAGMENT}
`;

export const ITEM_STOCK_QUERY = gql`
  query GetStock($itemId: Int!) {
    getItem(itemId: $itemId) {
      id
      itemStock {
        id
        quantityLeft
      }
    }
  }
`;
