export const events = {
  signUp: "signUp",
  login: "login",
  addToCart: "addToCart",
  viewCart: "viewCart",
  removeFromCart: "removeFromCart",
  beginCheckout: "beginCheckout",
  addPaymentInfo: "addPaymentInfo",
  purchase: "purchase",
};
