import React, { useContext, useEffect, useMemo, useState } from "react";

import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import { message } from "@/common/Message";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import {
  UPDATE_CART_PROMO_MUTATION,
  UPDATE_CART_PROMO_OPTIMISED_MUTATION,
} from "@/graphql/mutations/updateCartPromo";
import useAnalytics from "@/hooks/useAnalytics";
import constants from "@/utils/constants";

PromoCodeDetailsModal.propTypes = {
  promo: PropTypes.object,
  closeModal: PropTypes.func,
  goToMenu: PropTypes.func,
  rest: PropTypes.any,
};

const DAYS_IN_YEAR = 365;

export default function PromoCodeDetailsModal({
  promo,
  closeModal,
  goToMenu,
  ...rest
}) {
  const { t } = useTranslation();
  const { analytics, events } = useAnalytics();
  const { configQuery } = useContext(ConfigContext);

  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  // used to check if promo applied after newly opened modal
  const [isUpdatedAfterNewlyOpened, setUpdatedAfterNewlyOpened] =
    useState(false);
  const [usePromoError, setUsePromoError] = useState(null);
  const [updateCartPromo, { loading, error, data }] = useMutation(
    useOptimisedDinerCartType
      ? UPDATE_CART_PROMO_OPTIMISED_MUTATION
      : UPDATE_CART_PROMO_MUTATION,
    {
      errorPolicy: "all",
      context: { graph: "diners" },
      onCompleted(data) {
        setUpdatedAfterNewlyOpened(true);
        try {
          const updateCartPromo = useOptimisedDinerCartType
            ? data.updateCartPromoOptimised
            : data.updateCartPromo;
          const {
            id,
            promoCode,
            promoCodeError,
            paymentBreakdown: { discount },
          } = updateCartPromo; // eslint-disable-next-line no-empty
          if (promoCodeError) {
            setUsePromoError(usePromoError);
          }

          if (promoCode && !promoCodeError) {
            analytics.trackEventWithProperties(events.coupon_applied, {
              id,
              promoCode,
              discount,
            });
          } else {
            analytics.trackEventWithProperties(events.coupon_removed, {
              id,
              promoCode,
              discount,
            });
          }
          if (promoCodeError) {
            analytics.trackEventWithProperties(events.coupon_denied, {
              id,
              promoCode,
              discount,
              promoCodeError,
            });
          }
        } catch (e) {
          message.error(t("common.unexpectedError"));
        }
      },
    },
  );

  const isPromoCodeApplied = useMemo(
    () =>
      isUpdatedAfterNewlyOpened &&
      (useOptimisedDinerCartType
        ? data?.updateCartPromoOptimised
        : data?.updateCartPromo
      )?.promoCode === promo?.code &&
      !usePromoError,
    [
      isUpdatedAfterNewlyOpened,
      useOptimisedDinerCartType,
      data,
      promo,
      usePromoError,
    ],
  );

  useEffect(() => {
    setUsePromoError(null);
    if (!promo) {
      setUpdatedAfterNewlyOpened(false);
    }
  }, [promo]);

  useEffect(() => {
    setUsePromoError(error?.message);
  }, [error?.message]);

  return (
    <Modal
      visible={!!promo}
      onCancel={closeModal}
      footer={
        <Spin spinning={loading}>
          <Button
            type={isPromoCodeApplied ? "success" : "primary"}
            className="w-full"
            onClick={() =>
              isPromoCodeApplied
                ? goToMenu()
                : updateCartPromo({
                    variables: {
                      promoCode: promo.code,
                    },
                  })
            }
          >
            {t(
              `account.promoCodes.details.${
                isPromoCodeApplied ? "goToMenu" : "useNow"
              }`,
            )}
          </Button>
        </Spin>
      }
      {...rest}
    >
      {promo && (
        <div className="mt-4 text-left">
          <h2 className="mb-3 text-2xl font-display">{promo.label}</h2>
          <div className="space-y-3">
            <div>{promo.code}</div>
            <div className="font-bold">
              {t("account.promoCodes.details.termsHeader")}
            </div>
            <ul className="pb-12 list-disc list-inside">
              {/* Only show validity for promo codes that expire in next 365 days */}
              {dayjs(promo.endDate).diff(dayjs(promo.startDate), "days") <
                DAYS_IN_YEAR && (
                <li>
                  {t("account.promoCodes.details.validityPeriod", {
                    formattedDate: dayjs(promo.endDate, "YYYY-MM-DD").format(
                      "MMMM DD, YYYY",
                    ),
                  })}
                </li>
              )}
              <li>
                {t("account.promoCodes.details.maxClaimsPerUser", {
                  maxClaimsPerUser:
                    promo.remainingClaimsCount + promo.unreleasedClaimsCount,
                })}
              </li>
            </ul>
          </div>
          <div
            className={`mb-3 text-center ${
              isPromoCodeApplied ? "" : "opacity-0 pointer-events-none"
            }`}
          >
            {t("account.promoCodes.details.applied")}
          </div>
        </div>
      )}
    </Modal>
  );
}
