/* src/App.jsx */
import React from "react";

import dinerManifest from "@/lib/dinerManifest.json"; /* @vite- ignore */
import "./dinerStyles.css";

function DevImports() {
  window.dinerManifest = dinerManifest;

  return <></>;
}

export default DevImports;
