import React, { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import nutriGradeA from "@/assets/nutri-grade/nutri-grade-A.png";
import nutriGradeB from "@/assets/nutri-grade/nutri-grade-B.png";
import nutriGradeC from "@/assets/nutri-grade/nutri-grade-C.png";
import nutriGradeD from "@/assets/nutri-grade/nutri-grade-D.png";
import nutriGradeTopping from "@/assets/nutri-grade/nutri-grade-topping.png";

NutriGradeBadge.propTypes = {
  className: PropTypes.string,
  sugarLevel: PropTypes.number,
  nutriGrade: PropTypes.string,
};

export default function NutriGradeBadge({ className, sugarLevel, nutriGrade }) {
  const { t } = useTranslation();

  const badgeSrc = useMemo(() => {
    switch (nutriGrade) {
      case "A":
        return nutriGradeA;
      case "B":
        return nutriGradeB;
      case "C":
        return nutriGradeC;
      case "D":
        return nutriGradeD;
      default:
        return nutriGradeTopping;
    }
  }, [nutriGrade]);

  if (!badgeSrc) return null;

  const isTopping = !nutriGrade;

  return (
    <div className={className}>
      <div className="relative">
        <img
          className={classNames({
            "h-[30px]": !isTopping,
            "h-[23px]": isTopping,
          })}
          src={badgeSrc}
          alt={t("menu.nutriGrade.alt", { nutriGrade })}
        />
        <div
          className={classNames(
            "absolute text-center text-black tracking-normal",
            {
              "top-[5px] left-[30px] w-[20px]": !isTopping,
              "top-[2px] left-0 w-[40px]": isTopping,
            },
          )}
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bolder",
            lineHeight: 1,
          }}
        >
          <div>
            {isTopping && (
              <span style={{ fontSize: "12px" }}>
                {t("menu.nutriGrade.plus")}
              </span>
            )}
            <span style={{ fontSize: "12px" }}>{Math.round(sugarLevel)}</span>
            <span style={{ fontSize: "6px" }}>
              {t("menu.nutriGrade.percent")}
            </span>
          </div>
          <span
            className={classNames("absolute top-[12px] left-0 text-center", {
              "w-[20px]": !isTopping,
              "w-[40px]": isTopping,
            })}
            style={{ fontSize: "6px" }}
          >
            {t("menu.nutriGrade.sugar")}
          </span>
        </div>
      </div>
    </div>
  );
}
