import constants, { COOKIES } from "@/utils/constants";
import { ApolloLink, HttpLink } from "@apollo/client";

const modifiedOperation = (operation) => {
  const graph = operation.getContext().graph;
  const credentials = operation.getContext().credentials;
  const csrfToken = getCookie(COOKIES.CSRF_TOKEN);

  const link = new HttpLink({
    uri: `${import.meta.env.VITE_ACCOUNT_GRAPH_URL}/v1/${graph}/graphql`,
    credentials,
    ...(credentials === constants.CORS_CREDENTIALS.INCLUDE
      ? {
          headers: {
            "x-csrf-token": csrfToken,
          },
        }
      : {}),
  });
  return link.request(operation);
};

// https://github.com/apollographql/apollo-client/blob/1dbff98573a782f8bf77ab6c21a57d81c91e02ee/src/link/core/ApolloLink.ts#L47
// https://www.apollographql.com/docs/react/api/link/introduction/#directional-composition
// ApolloLink.split chain's execution to branch, depending on the details of the operation being performed.
// This is a hack to always execute next and handle the dynamic routing based on operation.

// For this to work, the operations (eg; useQuery, useMutation) must always send graph name as the context
// useQuery(QUERY_NAME, {
//    context: { graph: "accounts" },
// }
export default ApolloLink.split(
  () => true,
  (operation) => modifiedOperation(operation),
);

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
