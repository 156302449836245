import React, { useState, useEffect, useContext, useRef } from "react";

import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import ExclamationIcon from "@heroicons/react/solid/ExclamationIcon";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Image from "@/common/Image";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin/Spin";
import OrderHistory from "@/components/Account/Orders/OrderHistory";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import { GET_CART_ID_QUERY } from "@/graphql/queries/getCart";
import { GET_CART_ORDER_QUERY } from "@/graphql/queries/getCartOrder";
import { PROFILE_QUERY } from "@/graphql/queries/getProfile";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import useAuth from "@/hooks/useAuth";
import constants, {
  STRIPE_PAYMENT_INTENT_SUCCESS,
  ADYEN_PAYMENT_AUTHORISED,
  PAYMENT_TYPES,
} from "@/utils/constants";
import formatPrice from "@/utils/formatPrice";

import AdyenHandleRedirect from "./AdyenHandleRedirect";
import LoyaltyClaimCTA from "./LoyaltyClaimCTA";

CheckoutModal.propTypes = {
  orderProcessing: PropTypes.object,
  setOrderProcessing: PropTypes.func,
};

export default function CheckoutModal({ orderProcessing, setOrderProcessing }) {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();
  const trackedPurchaseId = useRef("");

  const [copyReferralLinkTitle, setCopyReferralLinkTitle] = useState(
    t("account.referrals.copyReferralLink"),
  );
  const [originalPath, setOriginalPath] = useState(window.location.pathname);

  const { cartQuery, refetchCart } = useContext(CartContext);
  const { configQuery, pathConfig } = useContext(ConfigContext);
  const { loggedIn } = useAuth();

  const isReferralsProgramEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.ENABLE_REFERRALS,
  );

  const shouldTruncateOrderNumber = configQuery?.config?.features?.includes(
    constants.FEATURES.TRUNCATE_ORDER_CALL_NUMBER,
  );

  const cartIdQuery = client.readQuery({ query: GET_CART_ID_QUERY });
  const {
    data: orderData,
    stopPolling,
    startPolling,
  } = useQuery(GET_CART_ORDER_QUERY, {
    skip: !orderProcessing,
    variables: {
      // Setting this to 0 as this fires after resetStore and query needs a valid integer
      cartId:
        orderProcessing?.cartId ||
        cartIdQuery?.cart?.id ||
        cartQuery?.cart?.id ||
        0,
    },
    context: {
      graph: "diners",
    },
  });

  // Only fetch this if logged in to prevent an unauthorized query error
  const [getProfile, { data: profileData }] = useLazyQuery(PROFILE_QUERY, {
    skip: !loggedIn(),
    context: { graph: "diners" },
  });
  const referralCode = profileData?.profile?.referralCode;
  const referralLink = `${window.location.origin}/signup?referral=${referralCode}`;

  useEffect(() => {
    if (loggedIn()) {
      getProfile();
    }
  }, [loggedIn()]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Omise and $0 orders
    if (
      [PAYMENT_TYPES.OMISE, null].includes(orderProcessing?.paymentType) &&
      !orderData?.getCartOrder
    ) {
      orderProcessing?.setCheckoutLoading(false);
      startPolling(1000);
    }

    // Stripe payment intent
    if (
      orderProcessing?.status === STRIPE_PAYMENT_INTENT_SUCCESS &&
      !orderData?.getCartOrder
    ) {
      orderProcessing?.setCheckoutLoading(false);
      startPolling(1000);
    }

    // Adyen payment with redirect
    if (
      orderProcessing?.result?.resultCode === ADYEN_PAYMENT_AUTHORISED &&
      !orderData?.getCartOrder
    ) {
      startPolling(1000);
    }

    // PayNow
    if (orderProcessing?.paymentDetails) {
      orderProcessing?.closeCart();
    }
  }, [orderProcessing, orderData?.getCartOrder, startPolling]);

  useEffect(() => {
    if (orderData?.getCartOrder) {
      setOriginalPath(window.location.pathname);
      window.history.replaceState(
        null,
        t("menu.checkoutModal.thankYou"),
        `${pathConfig?.menuPath}/thank-you`.replace("//", "/"),
      );
      analytics.trackEventWithProperties(
        events.order_completed,
        {},
        { configQuery },
      );
      if (trackedPurchaseId.current != orderData?.getCartOrder?.identifier) {
        trackedPurchaseId.current = orderData.getCartOrder.identifier;
        trackEvent(eventsV2.purchase, orderData.getCartOrder);
      }
      stopPolling();
      if (orderProcessing?.closeCart) orderProcessing?.closeCart();
    }
  }, [
    orderData?.getCartOrder,
    analytics,
    events,
    orderProcessing,
    stopPolling,
    t,
  ]);

  function reload() {
    setOrderProcessing(null);
    client.resetStore();
    client.onResetStore(() => {
      refetchCart();
      window.history.replaceState(null, t("menu.title"), originalPath);
    });
  }

  function copyReferralLinkToClipboard() {
    const textField = document.createElement("textarea");
    textField.innerText = referralLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    analytics.trackEventWithProperties(events.shared_referral_link, {
      referralLink,
    });
  }

  const renderReferralContent = (options) => {
    const { className } = options || {};
    return (
      isReferralsProgramEnabled &&
      loggedIn() && (
        <div className={`p-6 my-6 text-center bg-default2 ${className || ""}`}>
          <h2 className="mb-4 text-lg lg:text-2xl font-display">
            {configQuery?.referral?.label}
          </h2>

          <div className="flex flex-col max-w-full space-y-3">
            <div className="w-full px-3 py-2 rounded-sm md:overflow-x-auto bg-default md:whitespace-nowrap scrollbar">
              {referralLink}
            </div>
            <Button
              type="primary"
              className="whitespace-nowrap"
              onClick={() => {
                copyReferralLinkToClipboard();
                setCopyReferralLinkTitle(t("account.referrals.copied"));
                setTimeout(
                  () =>
                    setCopyReferralLinkTitle(
                      t("account.referrals.copyReferralLink"),
                    ),
                  2000,
                );
              }}
            >
              {copyReferralLinkTitle}
            </Button>
          </div>

          <div
            className="mt-4 whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: configQuery?.referral?.shortDescription,
            }}
          />
        </div>
      )
    );
  };

  return (
    <>
      <AdyenHandleRedirect setOrderProcessing={setOrderProcessing} />
      <Modal
        className={`max-w-lg max-h-[90vh] checkout-success-modal ${
          isReferralsProgramEnabled ? "md:max-w-full" : ""
        }`}
        visible={
          orderProcessing?.paymentType === null ||
          orderProcessing?.status === STRIPE_PAYMENT_INTENT_SUCCESS ||
          orderProcessing?.result?.resultCode === ADYEN_PAYMENT_AUTHORISED ||
          orderData?.getCartOrder ||
          !!orderProcessing?.paymentDetails
        }
        centered
        title={
          orderProcessing?.paymentDetails ? null : orderData?.getCartOrder ? (
            <CheckCircleIcon className="mx-auto mb-3 h-14 w-14 text-success" />
          ) : (
            <div className="text-sm">
              <Spin spinning={!orderData?.getCartOrder} />
            </div>
          )
        }
        footer={null}
        maskClosable={false}
        noCancel={true}
        onCancel={reload}
        closable={
          !!orderProcessing?.paymentDetails || !!orderData?.getCartOrder
        }
        mobileClosable={
          !!orderProcessing?.paymentDetails || !!orderData?.getCartOrder
        }
      >
        {orderProcessing?.paymentDetails ? (
          <div className="flex">
            <div
              className={
                isReferralsProgramEnabled
                  ? "md:w-1/2 md:mr-3 md:-ml-6 md:px-6"
                  : null
              }
            >
              <div className="mb-6 text-xl text-center lg:text-2xl font-display">
                {t("menu.checkoutModal.paymentDetails.header")}
              </div>
              <div
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: t("menu.checkoutModal.paymentDetails.beforeQrCode", {
                    identifier: orderProcessing.identifier,
                    price: formatPrice(orderProcessing.paymentDetails.total),
                    brand: orderProcessing.paymentDetails.brandLabel,
                    uenOrMobile:
                      orderProcessing.paymentDetails.paymentUen ||
                      orderProcessing.paymentDetails.paymentMobileNumber,
                    paymentMobileNumber:
                      orderProcessing.paymentDetails.paymentMobileNumber,
                  }),
                }}
              />
              <div className="p-4 my-6 rounded-md bg-yellow-50 sm:hidden">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-left">
                    <h3 className="text-sm font-medium text-yellow-800">
                      {t(
                        "menu.checkoutModal.paymentDetails.mobileNotes.header",
                      )}
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        {t(
                          "menu.checkoutModal.paymentDetails.mobileNotes.body",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-6 text-center">
                <Image
                  className="mx-auto"
                  src={orderProcessing.paymentDetails.qrCode}
                />
                <span className="font-semibold">
                  {orderProcessing.paymentDetails.paymentLabel}
                </span>
              </div>
              <div
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: t("menu.checkoutModal.paymentDetails.afterQrCode", {
                    brand: orderProcessing.paymentDetails.brandLabel,
                  }),
                }}
              />
              {/* Referral notification */}
              {renderReferralContent({ className: "md:hidden" })}
              <div>
                <Button type="primary" className="w-full mt-6" onClick={reload}>
                  {t("menu.checkoutModal.paymentDetails.action")}
                </Button>
              </div>
            </div>
            {isReferralsProgramEnabled && (
              <div className="hidden -mt-5 -mb-4 -mr-4 md:ml-3 md:block md:w-1/2 md:-my-6 md:-mr-0">
                <div className="h-full md:-mr-6">
                  {renderReferralContent({
                    className:
                      "md:my-0 flex flex-col items-center justify-center h-full",
                  })}
                </div>
              </div>
            )}
          </div>
        ) : orderProcessing && orderData?.getCartOrder ? (
          <div className="space-y-4 text-left">
            <div className="space-y-2">
              {shouldTruncateOrderNumber &&
              orderData.getCartOrder.fulfilmentType ===
                constants.FULFILMENT_TYPES.PICKUP ? (
                <div className="text-center !font-bold">
                  <div className="text-2xl">
                    {t("menu.checkoutModal.success.headerTruncated")}
                  </div>
                  <div className="text-3xl">
                    {orderData?.getCartOrder.identifier
                      .match(/\d+$/)?.[0]
                      ?.slice(-4)}
                  </div>
                </div>
              ) : (
                <div className="text-xl font-semibold text-center">
                  {t(
                    "menu.checkoutModal.success.header",
                    orderData?.getCartOrder,
                  )}
                </div>
              )}
              <LoyaltyClaimCTA order={orderData.getCartOrder} />
              <div
                className="text-sm text-center"
                dangerouslySetInnerHTML={{
                  __html:
                    orderData?.getCartOrder?.isAsap &&
                    orderData?.getCartOrder?.fulfilmentType ===
                      constants.FULFILMENT_TYPES.PICKUP
                      ? t(`menu.checkoutModal.success.asap_pickup`, {
                          minutesToPickup:
                            orderData?.getCartOrder?.pickupTiming,
                        })
                      : t(
                          `menu.checkoutModal.success.${orderData?.getCartOrder?.fulfilmentType}`,
                          {
                            ...orderData?.getCartOrder,
                            servingDate: orderData?.getCartOrder?.servingDate
                              ? dayjs(
                                  orderData?.getCartOrder?.servingDate,
                                ).format("MMM D")
                              : orderData?.getCartOrder?.servingDate,
                          },
                        ),
                }}
              />
            </div>
            {orderData?.getCartOrder?.confirmationInstructions && (
              <div className="text-sm text-center">
                {orderData?.getCartOrder?.confirmationInstructions}
              </div>
            )}

            {/* Referral notification */}
            {renderReferralContent({ className: "mt-12" })}
            <OrderHistory
              order={orderData?.getCartOrder}
              track={false}
              isConfirmationScreen={true}
            />
          </div>
        ) : (
          <div className="text-center">
            <div className="text-xl font-semibold font-display">
              {t("menu.checkoutModal.processing.header")}
            </div>
            <div
              className="mt-2 text-sm"
              dangerouslySetInnerHTML={{
                __html: t("menu.checkoutModal.processing.description"),
              }}
            />
            {cartQuery?.cart && (
              <div className="mt-6 text-left">
                <OrderHistory
                  order={{
                    ...cartQuery.cart,
                    orderItems: cartQuery.cart.topLevelItems,
                    brands: configQuery?.brands || [],
                  }}
                  track={false}
                  isConfirmationScreen={true}
                  isCart
                />
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
}
