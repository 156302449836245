import React from "react";

import PropTypes from "prop-types";

import ModifierGroup from "./ModifierGroup";

ProductConfiguration.propTypes = {
  detailedItem: PropTypes.object,
  isValidationsVisible: PropTypes.bool,
  onChange: PropTypes.func,
};

export default function ProductConfiguration({
  detailedItem,
  isValidationsVisible,
  onChange,
}) {
  if (!detailedItem) return null;
  return (
    <div className="space-y-6 product-configuration">
      {detailedItem?.modifierGroups?.map((modifierGroup, index) => {
        const key = `configure-item-${detailedItem.id}-modifier-group-${index}`;
        return (
          <ModifierGroup
            key={key}
            id={key}
            modifierGroup={modifierGroup}
            isValidationsVisible={isValidationsVisible}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}
