import React, { useContext, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "@/common/Input";
import PointIcon from "@/common/Loyalty/PointIcon";
import ConfigContext from "@/components/Config/configContext";
import { GET_POINT_BALANCE_QUERY } from "@/graphql/queries/getPointBalance";
import { UPDATE_CART_USE_POINTS_OPTIMISED_MUTATION } from "@/graphql/queries/updateCartUsePointsOptimised";
import useAuth from "@/hooks/useAuth";
import formatPrice from "@/utils/formatPrice";

CartUseLoyalty.propTypes = {
  useLoyalty: PropTypes.bool,
};

export default function CartUseLoyalty({ useLoyalty }) {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const { loggedIn } = useAuth();
  const [usePoints, setUsePoints] = useState(useLoyalty ?? false);
  const pointProgram = configQuery?.config?.pointProgram || {};

  const [updateCartUsePoints] = useMutation(
    UPDATE_CART_USE_POINTS_OPTIMISED_MUTATION,
    {
      context: { graph: "diners" },
      onCompleted: (data) => {
        if (data?.updateCartUsePointsOptimised) {
          setUsePoints(!usePoints);
        }
      },
    },
  );

  const { data: pointBalanceData, loading } = useQuery(
    GET_POINT_BALANCE_QUERY,
    {
      context: {
        graph: "diners",
        headers: { "X-Point-Program-Hash": pointProgram?.pointProgramHash },
      },
      skip: !loggedIn(),
    },
  );

  const pointBalance = pointBalanceData?.pointBalance || {
    points: 0,
    value: 0,
  };

  if (
    !pointProgram ||
    !(pointProgram?.currencyValueInCents > 0) ||
    loading ||
    !loggedIn()
  )
    return null;

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between">
        <div className="mb-1  font-bold text-body text-default">
          {pointProgram.isCustomCurrency
            ? t("menu.cart.checkout.labels.redeemPoints", {
                customCurrencyPlural: pointProgram.currencyNames?.plural,
              })
            : t("menu.cart.checkout.labels.redeemCashback")}
        </div>
        <Input
          type="switch"
          value={usePoints}
          onChange={() => {
            updateCartUsePoints({ variables: { usePoints: !usePoints } });
          }}
        />
      </div>
      <div className="text-sm text-gray-600 flex items-end gap-1">
        {pointProgram.isCustomCurrency && <PointIcon />}
        {pointProgram.isCustomCurrency
          ? t("menu.cart.checkout.labels.pointBalance", {
              pointAmount: pointBalance?.points,
              customCurrencyPlural: pointProgram.currencyNames?.plural,
              pointValue: formatPrice(pointBalance?.value),
            })
          : t("menu.cart.checkout.labels.cashbackBalance", {
              pointValue: formatPrice(pointBalance?.value),
            })}
      </div>
    </div>
  );
}
