import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import ConfigContext from "@/components/Config/configContext";
import useAnalytics from "@/hooks/useAnalytics";

import NavigationItemWithSubMenu from "./NavigationItemWithSubMenu";

NavigationItem.propTypes = {
  nav: PropTypes.object,
  isMobile: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onClick: PropTypes.func,
};

export default function NavigationItem({
  nav,
  isMobile,
  isTransparent,
  onClick,
}) {
  const location = useLocation();
  const { analytics, events } = useAnalytics();
  const { pathConfig } = useContext(ConfigContext);

  function getNavClass(nav) {
    const isSelected = isSelectedNav(nav.link);
    if (isMobile) {
      return classNames([
        "border-l-2 pl-3 py-1 border-transparent text-on-header whitespace-nowrap hover:text-on-header2 hover:border-primary block text-md",
        isSelected && "font-bold",
      ]);
    }

    return classNames([
      "border-transparent hover:border-primary inline-flex items-center px-1 text-md whitespace-nowrap hover:font-bold",
      isSelected && "font-bold",
      isTransparent
        ? "text-on-transparent-header hover:text-on-transparent-header"
        : "text-on-header hover:text-on-header2",
    ]);
  }

  function isSelectedNav(link) {
    return location.pathname === link;
  }

  const { navigations, link, label, ...props } = nav;

  if (navigations) {
    return (
      <NavigationItemWithSubMenu
        nav={nav}
        isMobile={isMobile}
        isTransparent={isTransparent}
        getNavClass={getNavClass}
      />
    );
  }

  if (link) {
    return (
      <Link to={link} className={getNavClass(nav)} onClick={onClick} {...props}>
        <div
          onClick={() => {
            try {
              if (link === pathConfig?.menuPath) {
                analytics.track(events.requested_menu_from_header);
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }}
        >
          {label}
        </div>
      </Link>
    );
  }

  return (
    <a className={getNavClass(nav)} onClick={onClick} {...props}>
      {label}
    </a>
  );
}
