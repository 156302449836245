import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import XIcon from "@heroicons/react/outline/XIcon";
import classNames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Spin from "@/common/Spin";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import {
  SELECT_TIMESLOT_MUTATION,
  SELECT_TIMESLOT_OPTIMISED_MUTATION,
} from "@/graphql/mutations/selectTimeslot";
import { TIMESLOTS_QUERY } from "@/graphql/queries/getTimeslots";
import useAnalytics from "@/hooks/useAnalytics";
import constants from "@/utils/constants";
import formatPrice from "@/utils/formatPrice";
import "./Timeslots.less";

Timeslots.propTypes = {
  closeTimeslotModal: PropTypes.func,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};

export default function Timeslots({
  closeTimeslotModal,
  selectedDate,
  setSelectedDate,
}) {
  const { t } = useTranslation();
  const { analytics, events } = useAnalytics();
  const [upcomingDates, setUpcomingDates] = useState(
    [...Array(14)].map((_, index) => {
      return {
        date: new Date().getTime() + index * 86_400_000,
      };
    }),
  );

  const { configQuery } = useContext(ConfigContext);
  const isAsapTimeslotEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.ASAP_TIMESLOTS,
  );
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [getTimeslots, { loading, error, data }] = useLazyQuery(
    TIMESLOTS_QUERY,
    {
      fetchPolicy: "cache-and-network", // https://www.apollographql.com/docs/react/data/queries/#cache-and-network
      queryDeduplication: false,
      notifyOnNetworkStatusChange: true,
      context: { graph: "diners" },
      onCompleted: (data) => {
        if (data?.getTimeslots?.servingDate) {
          setSelectedDate(data.getTimeslots.servingDate);
          setUpcomingDates(data.getTimeslots.upcomingDates);
        }
      },
    },
  );
  const { cartQuery, refetchCart } = useContext(CartContext);

  const [
    selectTimeslot,
    { loading: selectTimeslotLoading, data: selectTimeslotData },
  ] = useMutation(
    useOptimisedDinerCartType
      ? SELECT_TIMESLOT_OPTIMISED_MUTATION
      : SELECT_TIMESLOT_MUTATION,
    {
      update: () => {
        analytics.track(events.timeslot_selected);
      },
      errorPolicy: "all",
      context: { graph: "diners" },
    },
  );

  useEffect(() => {
    if (selectTimeslotData) {
      refetchCart();
      closeTimeslotModal();
    }
  }, [selectTimeslotData]);

  useEffect(() => {
    if (cartQuery?.cart?.servingDate) {
      if (selectedDate !== cartQuery?.cart?.servingDate) {
        setSelectedDate(cartQuery?.cart?.servingDate);
      }
    }
  }, [cartQuery?.cart?.servingDate]);

  useEffect(() => {
    if (selectedDate && selectedDate !== data?.getTimeslots?.servingDate) {
      analytics.track(events.timeslots_filtered);
      getTimeslots({
        variables: {
          servingDate: selectedDate,
        },
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    analytics.track(events.timeslots_viewed);
  }, []);

  function getErrorMessage() {
    if (error?.message) {
      return error.message;
    }

    if (cartQuery?.cart?.servingDate !== selectedDate) {
      return t("timeslotPicker.noTimeslotSelected");
    }

    // check if timeslot is available for selected fulfilment type
    if (
      !loading && // Do not show validation is timeslots are loading
      !getTimeslotsForFulfilmentType().some(
        (timeslot) => timeslot.id === cartQuery?.cart?.timeslot?.id,
      ) &&
      cartQuery?.cart?.timeslotType !== constants.ASAP_TIMESLOT // Do not show validation if timeslot is ASAP
    ) {
      return t("timeslotPicker.fulfilmentTypeUnavailable", {
        fulfilmentType: cartQuery?.cart?.fulfilmentType,
      });
    }

    return null;
  }

  function getWarningMessage() {
    if (
      cartQuery?.cart?.orderValueWithLeadTime &&
      cartQuery?.cart.additionalLeadTime
    ) {
      return t("timeslotPicker.orderValueAdditionalLeadTimeWarning", {
        orderValue: formatPrice(cartQuery?.cart?.orderValueWithLeadTime),
        additionalLeadTime: getUnitOfTime(cartQuery?.cart?.additionalLeadTime),
      });
    }

    return null;
  }

  function getUnitOfTime(seconds) {
    let valueOfTime = seconds / 60;
    let unitOfTime = valueOfTime == 1 ? "minute" : "minutes";
    if (seconds % 86400 == 0) {
      valueOfTime = seconds / 86400;
      unitOfTime = valueOfTime == 1 ? "day" : "days";
    } else if (seconds % 3600 == 0) {
      valueOfTime = seconds / 3600;
      unitOfTime = valueOfTime == 1 ? "hour" : "hours";
    }

    return `${valueOfTime} ${unitOfTime}`;
  }
  function getTimeslotsForFulfilmentType() {
    if (data?.getTimeslots?.timeslots?.length) {
      return data.getTimeslots.timeslots.filter(
        (timeslot) =>
          !cartQuery?.cart?.fulfilmentType ||
          timeslot.fulfilmentTypes.includes(cartQuery?.cart?.fulfilmentType),
      );
    }
    return [];
  }

  // this will group timeslots by type
  function getGroupedTimeslots() {
    if (data?.getTimeslots?.timeslots?.length) {
      const timeslotGroups = [];
      let lastTimeslotGroup;
      let lastTimeslot;
      // filter out timeslots that do not support the selected fulfilment type
      getTimeslotsForFulfilmentType().forEach((timeslot) => {
        if (
          !lastTimeslot ||
          !belongsToSameTimeslotGroup(lastTimeslot, timeslot)
        ) {
          if (lastTimeslot) {
            timeslotGroups.push(lastTimeslotGroup);
          }
          lastTimeslotGroup = {
            type: timeslot.type,
            label: timeslot.label,
            timeslots: [timeslot],
          };
          lastTimeslot = timeslot;
        } else {
          lastTimeslotGroup.timeslots.push(timeslot);
          lastTimeslot = timeslot;
        }
      });
      if (lastTimeslotGroup) {
        timeslotGroups.push(lastTimeslotGroup);
      }
      return timeslotGroups;
    }
    return [];
  }

  function belongsToSameTimeslotGroup(timeslot1, timeslot2) {
    if (timeslot1.label || timeslot2.label) {
      return timeslot1.label === timeslot2.label;
    }
    return timeslot1.type === timeslot2.type;
  }

  function isTimeslotSelected(timeslot) {
    if (cartQuery?.cart?.servingDate !== selectedDate) return false;
    return cartQuery?.cart?.timeslot?.id === timeslot.id;
  }

  function getNoTimeslotsMessage() {
    const selectedDay = upcomingDates.find((day) => day.date === selectedDate);
    if (selectedDay?.message) {
      return selectedDay.message;
    }
    return t("timeslotPicker.noTimeslots");
  }

  return (
    <Spin data-testid="timeslots" spinning={loading || selectTimeslotLoading}>
      <div className="relative p-4 font-bold text-center shadow-lg text-body timeslot-picker-header bg-default3">
        {t("timeslotPicker.header")}
        {closeTimeslotModal && (
          <div
            className="absolute top-0 right-0 p-4 lg:hidden"
            onClick={() => {
              if (
                cartQuery?.cart?.servingDate &&
                cartQuery?.cart?.servingDate !== selectedDate
              ) {
                setSelectedDate(cartQuery.cart.servingDate);
                setTimeout(() => {
                  closeTimeslotModal();
                }, 0);
              } else {
                closeTimeslotModal();
              }
            }}
          >
            <XIcon className="w-6 h-6" />
          </div>
        )}
        {getErrorMessage() && (
          <div className="mt-1 text-xs font-normal text-danger">
            {getErrorMessage()}
          </div>
        )}
        {getWarningMessage() && (
          <div className="mt-1 text-xs font-normal text-yellow-600">
            {getWarningMessage()}
          </div>
        )}
      </div>

      <div
        className={`flex timeslot-picker-content ${
          getErrorMessage() ? "error" : ""
        }`}
      >
        <div className="flex flex-col w-1/4 overflow-y-auto shadow-lg date-items">
          {/* TODO: Previous/next date pagination */}
          {upcomingDates.map((day) => (
            <a
              key={day.date}
              className={
                "date-item text-center bg-default3 text-default hover:text-default py-4 pl-1 pr-2 border-l-4 border-transparent hover:border-primary" +
                (day.date === selectedDate ? " border-primary" : "")
              }
              onClick={() =>
                setSelectedDate(new Date(day.date).toISOString().split("T")[0])
              }
            >
              <div className={day.message ? "opacity-50" : null}>
                <div className="text-sm font-normal uppercase">
                  {dayjs(day.date).format("ddd")}
                </div>
                <div className="text-sm font-bold">
                  {dayjs(day.date).format("MMM D")}
                </div>
                {day.message && (
                  <div className="text-sm">{t("timeslotPicker.closed")}</div>
                )}
              </div>
            </a>
          ))}
        </div>
        <div
          className={classNames([
            "w-3/4 px-4 py-3 overflow-y-auto timeslot-items",
            isAsapTimeslotEnabled ? "space-y-2" : "space-y-6",
          ])}
        >
          {getGroupedTimeslots().map((timeslotGroup, i) => (
            <div
              key={`timeslot_group_${i}`}
              className="flex flex-col space-y-2"
            >
              {!isAsapTimeslotEnabled && (
                <div className="text-sm font-bold text-center">
                  {timeslotGroup.label ||
                    t(`timeslotPicker.typeLabel.${timeslotGroup.type}`)}
                </div>
              )}
              {timeslotGroup.timeslots.map((timeslot) => (
                <Button
                  key={timeslot.rangeLabel}
                  disabled={!timeslot.isAvailable}
                  size="xs"
                  className="timeslot-item"
                  type={isTimeslotSelected(timeslot) ? "primary" : "default"}
                  onClick={() => {
                    if (
                      selectedDate !== cartQuery?.cart?.servingDate ||
                      timeslot.id !== cartQuery?.cart?.timeslot?.id
                    ) {
                      selectTimeslot({
                        variables: {
                          servingDate: selectedDate,
                          timeslotId: timeslot.id,
                          timeslotType: timeslot.type,
                        },
                      });
                    }
                  }}
                >
                  <div className="text-sm font-normal">
                    {timeslot.rangeLabel}
                  </div>
                </Button>
              ))}
            </div>
          ))}
          {!loading && !data?.getTimeslots?.timeslots?.length && (
            <div className="mt-3 text-sm text-center text-default">
              {getNoTimeslotsMessage()}
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
}
