import React, { useContext, useEffect, useMemo, useState } from "react";

import { useMutation, useApolloClient } from "@apollo/client";
import { Transition } from "@headlessui/react";
import DeviceMobileIcon from "@heroicons/react/outline/DeviceMobileIcon";
import MailIcon from "@heroicons/react/outline/MailIcon";
import ExclamationCircleIcon from "@heroicons/react/solid/ExclamationCircleIcon";
import classNames from "classnames";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";

import Button from "@/common/Button";
import Input from "@/common/Input";
import PoweredByAtlasId from "@/common/PoweredByAtlasId";
import Select from "@/common/Select";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import OtpModal from "@/components/OtpModal";
import { REQUEST_LOGIN_OTP_MUTATION } from "@/graphql/mutations/requestLoginOtp";
import { USER_SIGNUP_WITH_OTP_OR_SSO_MUTATION } from "@/graphql/mutations/userSignupWithOtpOrSso";
import graphqlConstants from "@/graphql/utils/constants";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import useAuth from "@/hooks/useAuth";
import constants from "@/utils/constants";
import generatePrivacyAgreementHTML from "@/utils/generatePrivacyAgreementHTML";

import SocialLogin from "./SocialLogin";
import validate from "./validate";

const USER_NOT_FOUND_ERROR = "User not found";

export const LOGIN_MODES = {
  MOBILE_NUMBER: "mobileNumber",
  EMAIL: "email",
};

SessionForm.propTypes = {
  isCompact: PropTypes.bool,
  formClass: PropTypes.string,
  defaultEmail: PropTypes.string,
  defaultName: PropTypes.string,
  defaultMobileNumber: PropTypes.string,
  defaultLoginMode: PropTypes.string,
  validateOnBlur: PropTypes.bool,
  postLoginCallback: PropTypes.func,
  postSignupCallback: PropTypes.func,
  loginOnly: PropTypes.bool,
  signupOnly: PropTypes.bool,
  allowSignup: PropTypes.bool,
  partnerPrivacyPolicy: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  rest: PropTypes.any,
};

SessionForm.defaultProps = {
  isCompact: false,
  validateOnBlur: true,
  loginOnly: false,
  signupOnly: false,
  allowSignup: true,
};

export default function SessionForm({
  isCompact,
  formClass,
  defaultEmail,
  defaultName,
  defaultMobileNumber,
  defaultLoginMode,
  validateOnBlur,
  postLoginCallback,
  postSignupCallback,
  loginOnly,
  signupOnly,
  allowSignup,
  partnerPrivacyPolicy,
  footer,
  children,
  ...rest
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setTokens } = useAuth(location);
  const client = useApolloClient();
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();

  const posthog = usePostHog();
  const { configQuery } = useContext(ConfigContext);

  const isSignup = location.pathname.includes("signup");

  const allowSocialLoginForOnline = configQuery?.config?.features?.includes(
    constants.FEATURES.ALLOW_SOCIAL_LOGIN_FOR_ONLINE,
  );

  const [otpModalForm, setOtpModalForm] = useState(null);
  const [signupFormVisible, setSignupFormVisible] = useState(signupOnly);
  const [currentLoginMode, setCurrentLoginMode] = useState(
    defaultLoginMode || LOGIN_MODES.MOBILE_NUMBER,
  );
  const enableCorsCredentialsForOtpRequest =
    configQuery?.config?.features?.includes(
      constants.FEATURES.ENABLE_CORS_CREDENTIALS_FOR_OTP_REQUEST,
    );

  const TITLE_OPTIONS = useMemo(
    () => ({
      MR: {
        label: t("signup.options.title.mr"),
        value: t("signup.options.title.mr"),
      },
      MISS: {
        label: t("signup.options.title.miss"),
        value: t("signup.options.title.miss"),
      },
      MRS: {
        label: t("signup.options.title.mrs"),
        value: t("signup.options.title.mrs"),
      },
      MX: {
        label: t("signup.options.title.mx"),
        value: t("signup.options.title.mx"),
      },
    }),
    [],
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setTouched,
  } = useFormik({
    initialValues: {
      email:
        defaultEmail ||
        (localStorage.getItem(graphqlConstants.USER_EMAIL) ?? ""),
      title: undefined,
      name: defaultName || undefined,
      mobileNumber: defaultMobileNumber || undefined,
      marketingConsent: true,
      signupSource: "atlas",
      ssoToken: null,
    },
    validate: (values) =>
      validate(values, {
        emailOnly: !signupFormVisible && currentLoginMode === LOGIN_MODES.EMAIL,
        mobileNumberOnly:
          !signupFormVisible && currentLoginMode === LOGIN_MODES.MOBILE_NUMBER,
      }),
    onSubmit: async (values, { setSubmitting }) => {
      if (signupFormVisible) {
        await userSignupWithOtpOrSso({
          variables: {
            input: {
              ...values,
              dateOfBirth: values.dateOfBirth
                ? values.dateOfBirth.format("YYYY-MM-DD")
                : null,
            },
          },
        });
      } else {
        await requestLoginOtp({
          variables: {
            input: {
              ...(currentLoginMode === LOGIN_MODES.EMAIL
                ? { email: values.email }
                : { mobileNumber: values.mobileNumber }),
              deliveryMode: constants.OTP_CHANNELS.SMS,
              loginSource: values.signupSource,
            },
          },
        });
      }
      setSubmitting(false);
    },
  });

  const [
    requestLoginOtp,
    { loading: requestLoginOtpLoading, error: requestLoginOtpError },
  ] = useMutation(REQUEST_LOGIN_OTP_MUTATION, {
    context: {
      graph: "diners",
      credentials: enableCorsCredentialsForOtpRequest
        ? constants.CORS_CREDENTIALS.INCLUDE
        : constants.CORS_CREDENTIALS.OMIT,
    },
    onCompleted: (data) => {
      if (data?.requestLoginOtp) {
        if (!loginOnly) {
          // redirect to login page
          navigate("/login", { replace: true });
        }

        setOtpModalForm({
          maskedEmail: data.requestLoginOtp.maskedEmail,
          maskedMobileNumber: data.requestLoginOtp.maskedMobileNumber,
          initialOtpDeliveryMode: data.requestLoginOtp.lastOtpDeliveryMode,
          ...(currentLoginMode === LOGIN_MODES.EMAIL
            ? { email: values.email }
            : { mobileNumber: values.mobileNumber }),
          ssoToken: values.ssoToken,
          loginSource: values.signupSource,
        });
      }
    },
    onError: (error) => {
      if (error?.message === USER_NOT_FOUND_ERROR) {
        if (!loginOnly) {
          // redirect to signup page
          navigate("/signup", { replace: true });

          setSignupFormVisible(true);
          setTouched({
            title: false,
            name: false,
            mobileNumber: false,
          });
        }
      } else if (error?.message) {
        setOtpModalForm({
          initialOtpDeliveryMode: constants.OTP_CHANNELS.SMS,
          email: values.email,
          mobileNumber: values.mobileNumber,
          ssoToken: values.ssoToken,
          loginSource: values.signupSource,
          ...(otpModalForm || {}),
          errorMessage: error?.message,
        });
      }
    },
  });

  const [
    userSignupWithOtpOrSso,
    {
      loading: userSignupWithOtpOrSsoLoading,
      error: userSignupWithOtpOrSsoError,
    },
  ] = useMutation(USER_SIGNUP_WITH_OTP_OR_SSO_MUTATION, {
    context: {
      graph: "diners",
      credentials: enableCorsCredentialsForOtpRequest
        ? constants.CORS_CREDENTIALS.INCLUDE
        : constants.CORS_CREDENTIALS.OMIT,
    },
    onCompleted: (data) => {
      if (data?.userSignupWithOtpOrSso) {
        setOtpModalForm({
          maskedMobileNumber: values.mobileNumber,
          initialOtpDeliveryMode: constants.OTP_CHANNELS.SMS,
          email: values.email,
          ssoToken: values.ssoToken,
          loginSource: values.signupSource,
        });
      }
    },
  });

  useEffect(() => {
    if (!signupOnly && !isSignup) {
      setSignupFormVisible(false);
    }
  }, [isSignup]);

  useEffect(() => {
    if (
      !signupOnly &&
      currentLoginMode === LOGIN_MODES.EMAIL &&
      values?.email &&
      signupFormVisible
    ) {
      setSignupFormVisible(false);
    }
    // only listen to change in email
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.email]);

  useEffect(() => {
    if (
      !signupOnly &&
      currentLoginMode === LOGIN_MODES.MOBILE_NUMBER &&
      values?.mobileNumber &&
      signupFormVisible
    ) {
      setSignupFormVisible(false);
    }
    // only listen to change in mobileNumber
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.mobileNumber]);

  function renderFormField(field) {
    switch (field) {
      case "email":
        return (
          <div className={isCompact ? "mt-2" : "mt-4"}>
            <Input
              className="flex-grow"
              label={
                loginOnly && !postLoginCallback ? null : t("login.emailLabel")
              }
              hint={signupFormVisible ? t("common.form.required") : null}
            >
              <div
                className={classNames("w-full", {
                  "flex items-start space-x-2": loginOnly,
                })}
              >
                <Input
                  className="flex-grow"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={validateOnBlur ? handleBlur : () => {}}
                  value={values.email}
                  placeholder={t("login.emailPlaceholder")}
                  error={errors.email && touched.email && errors.email}
                />
                {loginOnly && (
                  <Button
                    className="-mr-3 whitespace-nowrap pointer-events-auto"
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    {t("login.otpButton")}
                  </Button>
                )}
              </div>
            </Input>
          </div>
        );
      case "mobileNumber":
        return (
          <div className={isCompact ? "mt-2" : "mt-4"}>
            <Input
              label={
                loginOnly && !postLoginCallback
                  ? null
                  : t("signup.mobilePlaceholder")
              }
              hint={signupFormVisible ? t("common.form.required") : null}
              error={touched.mobileNumber ? errors.mobileNumber : ""}
            >
              <div
                className={classNames("w-full", {
                  "flex space-x-2": loginOnly,
                })}
              >
                <div className="relative flex-grow">
                  <PhoneInput
                    type="tel"
                    name="mobileNumber"
                    onBlur={validateOnBlur ? handleBlur : () => {}}
                    className={
                      errors.mobileNumber && touched.mobileNumber
                        ? "error"
                        : null
                    }
                    countrySelectProps={{ className: "bg-default" }}
                    placeholder={t("signup.mobilePlaceholder")}
                    country="SG"
                    international={true}
                    withCountryCallingCode={true}
                    defaultCountry="SG"
                    value={values.mobileNumber}
                    onChange={(value) => setFieldValue("mobileNumber", value)}
                  />
                  {errors.mobileNumber && touched.mobileNumber && (
                    <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                      <ExclamationCircleIcon className="w-5 h-5 text-danger" />
                    </div>
                  )}
                </div>
                {loginOnly && (
                  <Button
                    className="-mr-3 whitespace-nowrap pointer-events-auto"
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    {t("login.otpButton")}
                  </Button>
                )}
              </div>
            </Input>
          </div>
        );
      case "name":
        return (
          <div className={isCompact ? "mt-2" : "mt-4"}>
            <Input
              type="text"
              name="name"
              label={t("signup.nameLabel")}
              hint={t("common.form.required")}
              onChange={handleChange}
              onBlur={validateOnBlur ? handleBlur : () => {}}
              value={values.name}
              placeholder={t("signup.namePlaceholder")}
              error={errors.name && touched.name && errors.name}
            />
          </div>
        );
      case "title":
        return (
          <div className={isCompact ? "mt-2" : "mt-4"}>
            <Input
              error={errors.title && touched.title && errors.title}
              label={t("signup.titleLabel")}
            >
              <Select
                name="title"
                popupClassName="title-options"
                placeholder={t("signup.titlePlaceholder")}
                onBlur={validateOnBlur ? handleBlur : () => {}}
                value={values.title}
                options={Object.values(TITLE_OPTIONS)}
                onSelect={(value) => setFieldValue("title", value)}
              />
            </Input>
          </div>
        );
      case "dateOfBirth":
        return (
          <div className={isCompact ? "mt-2" : "mt-4"}>
            <Input
              label={t("signup.dobLabel")}
              type="datePicker"
              error={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              format="D MMM YYYY"
              onChange={(value) => setFieldValue("dateOfBirth", value)}
              onBlur={handleBlur}
              value={values.dateOfBirth}
              placeholder={t("signup.dobPlaceholder")}
              maxDate={dayjs()}
            />
          </div>
        );
      case "marketingConsent":
        return (
          <div className={isCompact ? "mt-4" : "mt-6"}>
            <label
              htmlFor="marketingConsent"
              className="flex items-start space-x-2"
            >
              <Input
                inputClassName="w-5 h-5 inline-block"
                type="checkbox"
                id="marketingConsent"
                name="marketingConsent"
                onChange={(event) =>
                  setFieldValue("marketingConsent", event.target.checked)
                }
                checked={values.marketingConsent}
                error={
                  errors.marketingConsent &&
                  touched.marketingConsent &&
                  errors.marketingConsent
                }
              />
              <span>{t("signup.marketingConsentLabel")}</span>
            </label>
          </div>
        );
    }
  }

  function handleLogin(data) {
    try {
      const {
        accessToken,
        refreshToken,
        user: { id, name, email, mobileNumber },
      } = data;
      if (accessToken && refreshToken) {
        // redirect only if there is no callback
        const redirect = !postLoginCallback && !postSignupCallback;
        setTokens({
          accessToken,
          refreshToken,
          redirect,
          delay: 2000,
        });
        localStorage.removeItem(graphqlConstants.USER_EMAIL);
        analytics.identify(id, { name, email });
        posthog.identify(id, { name, email, phone: mobileNumber });
        analytics.track(signupFormVisible ? events.signup : events.login);
        trackEvent(signupFormVisible ? eventsV2.signUp : eventsV2.login);

        // reset store only if there is no callback
        if (redirect) {
          client.resetStore();
        }

        if (postLoginCallback) {
          postLoginCallback();
        } else if (postSignupCallback) {
          postSignupCallback();
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  return (
    <Spin spinning={requestLoginOtpLoading || userSignupWithOtpOrSsoLoading}>
      <form onSubmit={handleSubmit} {...rest}>
        {children}

        <div className={formClass}>
          {loginOnly ? (
            <>
              {!signupFormVisible &&
                requestLoginOtpError?.message &&
                requestLoginOtpError?.message === USER_NOT_FOUND_ERROR && (
                  <div className="mt-2 text-danger">
                    {currentLoginMode === LOGIN_MODES.EMAIL
                      ? t("login.error.emailNotFound")
                      : t("login.error.mobileNumberNotFound")}
                  </div>
                )}
            </>
          ) : (
            <>
              {/* USER_NOT_FOUND_ERROR means email is available for signup, so we can skip displaying that error */}
              {!signupFormVisible &&
                requestLoginOtpError?.message &&
                requestLoginOtpError?.message !== USER_NOT_FOUND_ERROR && (
                  <div className="mt-2 text-danger">
                    {requestLoginOtpError.message}
                  </div>
                )}
              {signupFormVisible && userSignupWithOtpOrSsoError?.message && (
                <div className="mt-2 text-danger">
                  {userSignupWithOtpOrSsoError.message}
                </div>
              )}
            </>
          )}

          {!signupOnly &&
            (currentLoginMode === LOGIN_MODES.EMAIL
              ? renderFormField("email")
              : renderFormField("mobileNumber"))}

          {loginOnly && allowSignup && (
            <>
              {isCompact && (
                <div
                  className="mt-2 text-sm font-bold cursor-pointer text-link hover:text-link"
                  onClick={() =>
                    setCurrentLoginMode(
                      currentLoginMode === LOGIN_MODES.EMAIL
                        ? LOGIN_MODES.MOBILE_NUMBER
                        : LOGIN_MODES.EMAIL,
                    )
                  }
                >
                  {currentLoginMode === LOGIN_MODES.EMAIL
                    ? t("login.useMobileNumberInput")
                    : t("login.useEmailInput")}
                </div>
              )}
              <div className="mt-2">
                {t("login.descriptionBeforeSignupLink")}
                {/* intentional whitespace */}{" "}
                <Link
                  className="text-sm font-bold text-link hover:text-link"
                  to="/signup"
                >
                  {t("login.signupAction")}
                </Link>
              </div>
            </>
          )}

          {!signupFormVisible && !isCompact && (
            <div className="mt-6">
              <Button
                className="flex items-center w-full"
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
              >
                {isSignup ? t("signup.otpButton") : t("login.otpButton")}
              </Button>
            </div>
          )}

          {!signupFormVisible && !isCompact && (
            <>
              <div className="relative my-4 w-full text-center uppercase">
                <div className="absolute top-[calc(50%-2px)] w-full border-t border-button-default" />
                <div className="inline-block relative px-4 bg-default">
                  {t("common.orBreak")}
                </div>
              </div>
              <div>
                <Button
                  className="flex gap-2 w-full"
                  onClick={() =>
                    setCurrentLoginMode(
                      currentLoginMode === LOGIN_MODES.EMAIL
                        ? LOGIN_MODES.MOBILE_NUMBER
                        : LOGIN_MODES.EMAIL,
                    )
                  }
                >
                  {currentLoginMode === LOGIN_MODES.EMAIL ? (
                    <>
                      <DeviceMobileIcon className="mb-1 w-4 h-4" />
                      <div>{t("login.useMobileNumberInput")}</div>
                    </>
                  ) : (
                    <>
                      <MailIcon className="mb-0.5 w-4 h-4" />
                      <div>{t("login.useEmailInput")}</div>
                    </>
                  )}
                </Button>
              </div>
            </>
          )}

          {allowSocialLoginForOnline && !signupFormVisible && !isCompact && (
            <>
              <div className="relative my-4 w-full text-center uppercase">
                <div className="absolute top-[calc(50%-2px)] w-full border-t border-button-default" />
                <div className="inline-block relative px-4 bg-default">
                  {t("common.orBreak")}
                </div>
              </div>
              <SocialLogin
                isSignup={isSignup}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
            </>
          )}

          <Transition
            show={signupFormVisible}
            {...(signupOnly
              ? {}
              : {
                  enter: "ease-in-out duration-500 overflow-hidden",
                  enterFrom: "max-h-0",
                  enterTo: "max-h-[100vh]",
                  leave: "ease-in-out duration-200 overflow-hidden",
                  leaveFrom: "max-h-[100vh]",
                  leaveTo: "max-h-0",
                })}
          >
            {!signupOnly && (
              <>
                {currentLoginMode === LOGIN_MODES.EMAIL
                  ? renderFormField("mobileNumber")
                  : renderFormField("email")}
                {renderFormField("name")}
              </>
            )}
            {renderFormField("title")}
            {renderFormField("dateOfBirth")}
            {renderFormField("marketingConsent")}

            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: `${t("signup.privacyAgreement", {
                  partnerPolicy: generatePrivacyAgreementHTML({
                    language: i18n.resolvedLanguage,
                    joinCopy: t("signup.privacyJoinCopy"),
                    partnerPrivacyPolicy,
                    privacyPolicyTitle: t("signup.privacyPolicyTitle"),
                    termsOfServiceTitle: t("signup.termsOfServiceTitle"),
                    privacyPolicyLineEnding: t(
                      "signup.privacyPolicyLineEnding",
                    ),
                  }),
                })}`,
              }}
            />

            {footer || (
              <div className="mt-6">
                <Button
                  type="primary"
                  htmlType={"submit"}
                  disabled={isSubmitting}
                  className="flex items-center w-full"
                >
                  {t("signup.signupButton")}
                </Button>
              </div>
            )}
          </Transition>

          {(!loginOnly || !!postLoginCallback) && (
            <div className="flex justify-center items-center mt-6 mb-2 font-bold text-primary">
              <PoweredByAtlasId />
            </div>
          )}
        </div>
      </form>

      <OtpModal
        visible={!!otpModalForm}
        closeModal={() => setOtpModalForm(null)}
        isSignUp={isSignup}
        email={otpModalForm?.email}
        maskedEmail={otpModalForm?.maskedEmail}
        phone={otpModalForm?.mobileNumber}
        maskedPhone={otpModalForm?.maskedMobileNumber}
        ssoToken={otpModalForm?.ssoToken}
        loginSource={otpModalForm?.loginSource}
        initialOtpDeliveryMode={otpModalForm?.initialOtpDeliveryMode}
        errorMessage={otpModalForm?.errorMessage}
        handleLogin={handleLogin}
      />
    </Spin>
  );
}
