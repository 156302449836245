import { gql } from "@apollo/client";

import {
  CART_OPTIMISED_TOP_LEVEL_ITEMS,
  CART_TOP_LEVEL_ITEMS,
  PAYMENT_BREAKDOWN_FIELDS,
} from "@/graphql/queries/getCart";

const generateSelectTimeslotMutation = (
  operationName,
  mutationName,
  topLevelItemsFragment,
) => gql`
  mutation ${operationName}(
    $servingDate: ISO8601Date!
    $timeslotId: Int
    $timeslotType: String!
  ) {
    ${mutationName}(
      input: {
        servingDate: $servingDate
        timeslotId: $timeslotId
        timeslotType: $timeslotType
      }
    ) {
      id
      servingDate
      outletClosed
      timeslot {
        id
        rangeLabel
      }
      timeslotType
      ...CartTopLevelItems
      paymentBreakdown {
        ...PaymentBreakdownFields
      }
    }
  }
  ${topLevelItemsFragment}
  ${PAYMENT_BREAKDOWN_FIELDS}
`;
export const SELECT_TIMESLOT_MUTATION = generateSelectTimeslotMutation(
  "SelectTimeslot",
  "selectTimeslot",
  CART_TOP_LEVEL_ITEMS,
);
export const SELECT_TIMESLOT_OPTIMISED_MUTATION =
  generateSelectTimeslotMutation(
    "SelectTimeslotOptimised",
    "selectTimeslotOptimised",
    CART_OPTIMISED_TOP_LEVEL_ITEMS,
  );
