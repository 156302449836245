import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Button from "@/common/Button";
import Image from "@/common/Image";
import Banner from "@/components/Banner";
import MenuItem from "@/components/Menu/MenuItem";

HomeLegacyContent.propTypes = {
  bestsellersRef: PropTypes.object,
  storiesRef: PropTypes.object,
  reviewsRef: PropTypes.object,
};

// TODO: deprecate on next release
export default function HomeLegacyContent({
  bestsellersRef,
  storiesRef,
  reviewsRef,
}) {
  const dinerManifest = window.dinerManifest;
  const bestsellers = dinerManifest?.bestsellers;
  const stories = dinerManifest?.stories;
  const reviews = dinerManifest?.reviews;

  return (
    <div className="text-center">
      <Banner data={dinerManifest?.banners?.["home"]} />
      <div
        ref={bestsellersRef}
        className="py-6 sm:py-12"
        style={bestsellers?.style}
      >
        <div className="max-w-screen-xl px-3 mx-auto sm:px-6">
          {bestsellers?.header && (
            <div className="mb-10 text-3xl font-display">
              {bestsellers?.header}
            </div>
          )}
          <div
            className={`grid sm:grid-cols-${
              bestsellers?.items?.length || 1
            } gap-6 text-left`}
          >
            {bestsellers?.items?.map((bestseller, i) => (
              <MenuItem key={`bestseller_${i}`} isPreview {...bestseller} />
            ))}
          </div>
          {bestsellers?.cta && (
            <div className="mt-12">
              <Link to="/menu">
                <Button type="primary">{bestsellers?.cta}</Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div
        ref={storiesRef}
        className={`bg-default2 ${stories?.fullWidth ? "" : "py-6 sm:py-12"}`}
        style={stories?.style}
      >
        <div
          className={
            stories?.fullWidth ? null : "max-w-screen-xl mx-auto px-3 sm:px-6"
          }
        >
          {stories?.header && (
            <div className="mb-10 text-3xl font-display">{stories?.header}</div>
          )}
          <div
            className={`grid sm:grid-cols-${stories?.items?.length || 1} gap-6`}
          >
            {(stories?.fullWidth ? [stories?.items?.[0]] : stories?.items)?.map(
              (story, i) => (
                <div
                  key={`story_${i}`}
                  className={`flex flex-col items-center justify-center w-full ${
                    story?.image ? "" : "h-96 p-3 sm:p-6"
                  }`}
                  style={story?.style}
                >
                  {story?.image && (
                    <Image src={story.image.url} style={story.image?.style} />
                  )}
                  <div className="max-w-xl">
                    {story?.label && (
                      <div
                        className={`font-display ${
                          stories?.fullWidth ? "text-3xl" : "text-2xl"
                        } text-default mb-3`}
                        dangerouslySetInnerHTML={{ __html: story?.label }}
                      />
                    )}
                    {story?.description && (
                      <div
                        className={`text-default ${
                          stories?.fullWidth ? "text-body" : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: story?.description }}
                      />
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>

      <div ref={reviewsRef} className="py-6 sm:py-12" style={reviews?.style}>
        <div className="max-w-screen-xl px-3 mx-auto sm:px-6">
          {reviews?.header && (
            <div className="mb-10 text-3xl font-display">{reviews?.header}</div>
          )}
          <div
            className={`grid sm:grid-cols-${reviews?.items?.length || 1} gap-6`}
          >
            {reviews?.items?.map((review, i) => (
              <div
                key={`customer_review_${i}`}
                className="flex items-center justify-center w-full"
              >
                <div>
                  {review?.label && (
                    <div
                      className="mb-3 text-lg font-bold text-default"
                      dangerouslySetInnerHTML={{ __html: review?.label }}
                    />
                  )}
                  {review?.description && (
                    <div
                      className="text-default"
                      dangerouslySetInnerHTML={{
                        __html: review?.description,
                      }}
                    />
                  )}
                  {review?.reviewer && (
                    <div
                      className="mt-3 text-default"
                      dangerouslySetInnerHTML={{ __html: review?.reviewer }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
