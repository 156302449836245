import { gql } from "@apollo/client";

import { CART_FIELDS, CART_OPTIMISED_FIELDS } from "@/graphql/queries/getCart";

const generateRemoveItemFromCartMutation = (
  operationName,
  mutationName,
  cartFragment,
) => gql`
  mutation ${operationName}($lineItemId: Int!) {
    ${mutationName}(input: { lineItemId: $lineItemId }) {
      ...CartFields
    }
  }
  ${cartFragment}
`;
export const REMOVE_ITEM_FROM_CART_MUTATION =
  generateRemoveItemFromCartMutation(
    "RemoveItemFromCart",
    "removeItemFromCart",
    CART_FIELDS,
  );
export const REMOVE_ITEM_FROM_CART_OPTIMISED_MUTATION =
  generateRemoveItemFromCartMutation(
    "RemoveItemFromCartOptimised",
    "removeItemFromCartOptimised",
    CART_OPTIMISED_FIELDS,
  );
