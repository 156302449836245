import { setContext } from "@apollo/client/link/context";
import { v4 as uuidv4 } from "uuid";

import constants from "./utils/constants";

// eslint-disable-next-line no-unused-vars
export default setContext((_, { headers }) => {
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);
  const resetToken = localStorage.getItem(constants.RESET_TOKEN); // Short-lived token used for password reset

  let xClientUUID = localStorage.getItem(constants.X_CLIENT_UUID);
  if (!xClientUUID) {
    xClientUUID = uuidv4();
    localStorage.setItem(constants.X_CLIENT_UUID, xClientUUID);
  }

  const outletSlug = localStorage.getItem(constants.OUTLET_SLUG);
  const outletId = outletSlug?.split("-")[0];

  const outletQrHash = localStorage.getItem(constants.OUTLET_QR_HASH);
  const tableQrHash = localStorage.getItem(constants.TABLE_QR_HASH);

  return {
    headers: {
      ...headers,
      "X-Client-UUID": xClientUUID,
      "X-Client-Name": constants.X_CLIENT_NAME,
      "X-Platform": constants.X_PLATFORM,
      ...(outletId ? { "X-Outlet-ID": outletId } : {}),
      ...(outletQrHash ? { "X-Outlet-QR-Hash": outletQrHash } : {}),
      ...(tableQrHash ? { "X-Table-QR-Hash": tableQrHash } : {}),
      ...(import.meta.env.VITE_X_BRAND_ID
        ? { "X-Brand-ID": import.meta.env.VITE_X_BRAND_ID }
        : {}),
      ...(resetToken ?? accessToken // If reset token exists, use that instead
        ? { Authorization: `Bearer ${resetToken ?? accessToken}` }
        : {}),
    },
  };
});
