import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "@/common/Modal";

DeselectedUnavailableModal.propTypes = {
  label: PropTypes.string,
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

export default function DeselectedUnavailableModal({
  label,
  visible,
  closeModal,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={t("menu.deselected.header")}
      footer={null}
    >
      {t("menu.deselected.body", {
        label: label,
      })}
    </Modal>
  );
}
