import { gql } from "@apollo/client";

import { CART_FIELDS, CART_OPTIMISED_FIELDS } from "@/graphql/queries/getCart";

const generateUpdateItemInCartMutation = (
  operationName,
  mutationName,
  cartFragment,
) => gql`
  mutation ${operationName}($itemConfiguration: ItemConfiguration!) {
    ${mutationName}(input: { itemConfiguration: $itemConfiguration }) {
      ...CartFields
    }
  }
  ${cartFragment}
`;
export const UPDATE_ITEM_IN_CART_MUTATION = generateUpdateItemInCartMutation(
  "UpdateItemInCart",
  "updateItemInCart",
  CART_FIELDS,
);

export const UPDATE_ITEM_IN_CART_OPTIMISED_MUTATION =
  generateUpdateItemInCartMutation(
    "UpdateItemInCartOptimised",
    "updateItemInCartOptimised",
    CART_OPTIMISED_FIELDS,
  );
