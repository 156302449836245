import React, { useContext } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button/Button";
import Modal from "@/common/Modal";
import CartContext from "@/components/Cart/cartContext";

RemovedItemsModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
};

export default function RemovedItemsModal({ items, onClose }) {
  const { t } = useTranslation();

  const { cartQuery, addressesAndOutlets } = useContext(CartContext);

  let outletName = "";

  if (cartQuery?.cart?.outletId && addressesAndOutlets?.outlets) {
    outletName = addressesAndOutlets.outlets.filter(
      (outlet) => outlet.id == cartQuery.cart.outletId,
    )[0].labelForPickup;
  }

  return (
    <>
      <Modal
        visible={true}
        noCancel={true}
        closable={false}
        maskClosable={false}
        wrapperClassName="z-30"
        footer={
          <Button onClick={onClose} type="primary" size="xl" fullWidth>
            {t("menu.itemsRemoved.proceed")}
          </Button>
        }
      >
        <p className="mb-4 text-2xl font-display">
          {t("menu.itemsRemoved.modalHeader")}
        </p>
        <p className="mb-4 text-lg">
          {t("menu.itemsRemoved.modalContent", { outletName })}
        </p>
        <ul className="pl-0 mb-5 text-lg list-disc list-inside">
          {items.map((item, i) => (
            <li key={i}>{t("menu.itemsRemoved.removedLineItem", { item })}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
}
