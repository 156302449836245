import { gql } from "@apollo/client";

export const REQUEST_LOGIN_OTP_OPERATION_NAME = "RequestLoginOtp";

export const REQUEST_LOGIN_OTP_MUTATION = gql`
  mutation ${REQUEST_LOGIN_OTP_OPERATION_NAME}($input: RequestOTPInput!) {
    requestLoginOtp(input: $input) {
      maskedEmail
      maskedMobileNumber
      lastOtpDeliveryMode
      isMobileVerified
    }
  }
`;
