import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import XCircleIcon from "@heroicons/react/solid/XCircleIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import { GET_ADYEN_PAYMENT_DETAILS_QUERY } from "@/graphql/queries/getAdyenPaymentDetails";
import { ADYEN_PAYMENT_AUTHORISED } from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import getUrlParams from "@/utils/getUrlParams";

AdyenHandleRedirect.propTypes = {
  setOrderProcessing: PropTypes.func,
};

export default function AdyenHandleRedirect({ setOrderProcessing }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cartId, redirectResult } = getUrlParams(window.location);

  const [isErrorVisible, setErrorVisible] = useState(false);
  const [getAdyenPaymentDetails, { data }] = useLazyQuery(
    GET_ADYEN_PAYMENT_DETAILS_QUERY,
    {
      context: { graph: "diners" },
      errorPolicy: "all",
      onCompleted: (data) => {
        if (data) {
          const { getAdyenPaymentDetails } = data;
          if (getAdyenPaymentDetails?.resultCode === ADYEN_PAYMENT_AUTHORISED) {
            setOrderProcessing({
              result: getAdyenPaymentDetails,
              cartId: parseInt(cartId),
            });
          } else {
            setErrorVisible(true);
          }
        }
      },
    },
  );

  useEffect(() => {
    if (redirectResult) {
      getAdyenPaymentDetails({
        variables: {
          redirectResult,
        },
      });
    }
  }, []);

  if (isErrorVisible && data?.getAdyenPaymentDetails) {
    return (
      <Modal
        centered
        visible={true}
        closable={false}
        maskClosable={false}
        footer={null}
        onCancel={() => setErrorVisible(false)}
        antModalClassName="adyen-checkout-modal min-w-[300px] max-w-[400px] sm:max-w-[400px]"
        wrapperClassName="adyen-checkout-modal-wrapper"
      >
        <XCircleIcon className="mx-auto mb-3 h-14 w-14 text-danger" />
        {data?.getAdyenPaymentDetails?.refusalReason && (
          <>
            <div className="mt-4 font-bold text-center text-danger">
              {data.getAdyenPaymentDetails.refusalReason}
            </div>
            <Button
              type="primary"
              className="w-full mt-4"
              onClick={() => {
                // remove url params
                navigate(window.location.pathname, { replace: true });

                emitEvent("openCart");
                emitEvent("openCartCheckout");
                setTimeout(() => emitEvent("showAdyenPayment"), 0);

                setErrorVisible(false);
              }}
            >
              {t("adyen.actions.tryAgain")}
            </Button>
          </>
        )}
      </Modal>
    );
  }

  return null;
}
