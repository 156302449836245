import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { importStripeScript, initializeStripe } from "@/utils/stripeUtils";

StripeNewCard.propTypes = {
  newCardLabel: PropTypes.string,
  stripeNewCardElement: PropTypes.object,
  setStripeCardElement: PropTypes.func,
  stripeAccountId: PropTypes.string,
  country: PropTypes.string,
  currency: PropTypes.string,
  label: PropTypes.string,
  amount: PropTypes.number,
  validateCheckout: PropTypes.func,
  onPaymentRequestPaymentMethod: PropTypes.func,
  rest: PropTypes.any,
};

export default function StripeNewCard({
  newCardLabel,
  stripeNewCardElement,
  setStripeCardElement,
  stripeAccountId,
  country,
  currency,
  label,
  amount,
  validateCheckout,
  onPaymentRequestPaymentMethod,
  ...rest
}) {
  useEffect(() => {
    importStripeScript({
      onComplete: () => {
        const styles = getComputedStyle(document.body);
        let cssSrc = "https://fonts.googleapis.com/css?family=Inter";
        try {
          // styles.getPropertyValue will return '' in this format: " \'\'"
          // so we will use JSON.parse to get the desired output
          cssSrc =
            JSON.parse(styles.getPropertyValue("--font-body-css-url")) ||
            cssSrc;
          // eslint-disable-next-line no-empty
        } catch {}
        setStripeCardElement(
          initializeStripe({
            stripeKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            stripeAccountId,
            stripeNewCardElement,
            stripeNewCardElementFont: {
              cssSrc,
            },
            stripeNewCardElementStyle: {
              base: {
                fontFamily:
                  styles.getPropertyValue("--font-body") ||
                  "Inter, Helvetica, Arial, sans-serif",
                fontSize: "14px",
                fontWeight: "bold",
                color: styles.getPropertyValue("--color-text") || "#333333",
                backgroundColor: "transparent",
                lineHeight: "20px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              empty: {
                fontWeight: "normal",
              },
              invalid: {
                color: styles.getPropertyValue("--color-danger") || "#ed0900",
                iconColor:
                  styles.getPropertyValue("--color-danger") || "#ed0900",
              },
            },
            country,
            currency,
            label,
            amount,
            newCardSelector: "#new-card",
            paymentRequestButtonSelector: "#payment-request-button",
            paymentRequestLabelSelector: "#payment-request-label",
            paymentRequestButtonStyle: {
              type: "default",
              theme: "dark",
              height: "44px",
            },
            onPaymentRequestButtonClick: (e) => {
              // prevent default if checkout validation fails
              if (validateCheckout && !validateCheckout()) {
                e.preventDefault();
              }
            },
            onPaymentRequestPaymentMethod,
          }),
        );
      },
    });
  }, []);

  return (
    <div {...rest}>
      <div
        id="payment-request-label"
        className="mb-1 text-sm font-normal text-default"
      />
      <div id="payment-request-button" className="mb-4" />
      {newCardLabel && (
        <div
          id="new-card-label"
          className="mb-1 text-sm font-normal text-default"
        >
          {newCardLabel}
        </div>
      )}
      <div
        id="new-card"
        className="p-2 border rounded-sm shadow-sm bg-default border-default"
      />
    </div>
  );
}
