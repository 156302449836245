import React, { useEffect, useRef, useContext, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { LayoutProvider } from "@/common/Layout/layoutContext";
import ScrollToTop from "@/common/ScrollToTop";
import ConfigContext from "@/components/Config/configContext";

import Footer from "./Footer";
import Header from "./Header";
import { ScrollProvider } from "./scrollContext";

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  isTransparentHeader: PropTypes.bool,
  headerCart: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  addressModal: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  timeslotModal: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  scrollRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  rest: PropTypes.any,
};

Layout.defaultProps = {
  fullscreen: false,
  isTransparentHeader: false,
};

export default function Layout({
  children,
  className,
  fullscreen,
  isTransparentHeader,
  headerCart,
  addressModal,
  timeslotModal,
  scrollRef,
  ...rest
}) {
  const layoutScrollRef = useRef(null);
  const { setOutletSlug, setOutletQrHash, setTableQrHash } =
    useContext(ConfigContext);
  const { outletSlug, outletQrHash, tableQrHash } = useParams();
  const [layoutState, setLayoutState] = useState({
    scrollRef: scrollRef || layoutScrollRef,
    transparent: false,
  });

  let klass =
    "fullscreen overflow-y-scroll bg-default flex flex-col cursor-default";
  if (className) {
    klass += ` ${className}`;
  }

  // This is to detect height changes for mobile web and fix layout issues
  const updateWindowDimensions = () => {
    if (window.currentHeight !== window.innerHeight) {
      if (!window.maxHeight || window.innerHeight > window.maxHeight) {
        window.maxHeight = window.innerHeight;
      }
      window.currentHeight = window.innerHeight;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setOutletSlug(outletSlug);
  }, [outletSlug]);

  useEffect(() => {
    setOutletQrHash(outletQrHash);
  }, [outletQrHash]);

  useEffect(() => {
    setTableQrHash(tableQrHash);
  }, [tableQrHash]);

  updateWindowDimensions();

  return (
    <div
      id="scroller"
      ref={scrollRef || layoutScrollRef}
      className={klass}
      {...rest}
    >
      <LayoutProvider value={{ layoutState, setLayoutState }}>
        <ScrollToTop />
        <ScrollProvider value={scrollRef || layoutScrollRef}>
          <Header
            isTransparentHeader={isTransparentHeader}
            headerCart={headerCart}
            addressModal={addressModal}
            timeslotModal={timeslotModal}
          />
          <div
            className={classNames(
              "flex-grow",
              fullscreen ? null : "pt-6 pb-12 md:pt-12",
              isTransparentHeader ? null : "lg:mt-16",
            )}
          >
            <main className="h-full">
              <div
                className={classNames(
                  "h-full",
                  fullscreen ? null : "max-w-screen-xl mx-auto px-6",
                )}
              >
                {children}
              </div>
            </main>
          </div>
          <Footer />
        </ScrollProvider>
      </LayoutProvider>
    </div>
  );
}
