import React, { useContext, useEffect, useMemo, useState } from "react";

import { Transition } from "@headlessui/react";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import { Tag } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Image from "@/common/Image";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import NutriGradeBadge from "@/components/Menu/NutriGradeBadge";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";
import {
  getQuantityWithIdBreadcrumb,
  prefillFixedModifierConfiguration,
  updateProductConfiguration,
} from "@/utils/itemConfiguration";

import ModifierGroup from "./ModifierGroup";

Modifier.propTypes = {
  modifier: PropTypes.object,
  disabled: PropTypes.bool,
  maxQuantityPerModifier: PropTypes.number,
  slotsLeft: PropTypes.number,
  selectionRequiredMax: PropTypes.number,
  removeCurrentSelection: PropTypes.func,
  isValidationsVisible: PropTypes.bool,
  onChange: PropTypes.func,
  nested: PropTypes.bool,
  hideThumbnail: PropTypes.bool,
};

Modifier.defaultProps = {
  disabled: false,
  nested: false,
};

export default function Modifier({
  modifier,
  disabled,
  maxQuantityPerModifier,
  slotsLeft,
  selectionRequiredMax,
  removeCurrentSelection,
  isValidationsVisible,
  onChange,
  nested,
  hideThumbnail,
}) {
  const { t, i18n } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const [quantity, setQuantity] = useState(
    getQuantityWithIdBreadcrumb(modifier.idBreadcrumb),
  );

  function onLocalChange(newQuantity) {
    setQuantity(newQuantity);
    updateProductConfiguration({
      idBreadcrumb: modifier.idBreadcrumb,
      quantity: newQuantity,
      increment: false,
    });
    onChange();
  }

  const modifierLabel = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      modifier
    ) {
      return (
        modifier.translation?.title?.[i18n.resolvedLanguage] ??
        modifier.translation?.title?.["default"] ??
        modifier.label
      );
    } else {
      return modifier.label;
    }
  }, [modifier, i18n.resolvedLanguage, configQuery?.config?.features]);

  const hideWhenSlotsFilled = window?.dinerManifest?.hideWhenSlotsFilled;
  const showImages = !hideThumbnail;

  useEffect(() => {
    // quantity is only prefilled/set from cart item after a tick
    setTimeout(() => {
      setQuantity(getQuantityWithIdBreadcrumb(modifier.idBreadcrumb));
      onChange();
    }, 0);
  });

  useEffect(() => {
    if (quantity > 0 && modifier.modifierGroups?.length > 0) {
      prefillFixedModifierConfiguration(modifier, false);
    }
  }, [quantity > 0]);

  const { cartValidations } = useContext(CartContext);

  const soldOut =
    typeof modifier.quantityLeft["entire"] === "number" &&
    modifier.quantityLeft["entire"] <= 0;

  function isModifierGroupsVisible(modifierGroups) {
    // don't show if only 1 modifier group and is fixed
    return !(modifierGroups.length === 1 && modifierGroups[0].isFixed);
  }

  const renderUpsellLabel = () => {
    const { upsellLabel, upsellLabelTextColor, upsellLabelBackgroundColor } =
      modifier;
    if (
      !!upsellLabel &&
      !!upsellLabelTextColor &&
      !!upsellLabelBackgroundColor
    ) {
      return (
        <Tag
          className="max-w-full px-3 py-1 mr-0 align-middle border-0 rounded-full"
          style={{
            backgroundColor: upsellLabelBackgroundColor,
            color: upsellLabelTextColor,
          }}
        >
          <div className="truncate">{upsellLabel}</div>
        </Tag>
      );
    }
  };

  return (
    <div
      className={classNames([
        "modifier relative rounded-sm",
        {
          hidden: hideWhenSlotsFilled && slotsLeft === 0 && quantity === 0,
          "bg-default": nested,
          "bg-default2": !nested,
        },
      ])}
    >
      <Transition
        className={
          "transition-width modifier-count absolute z-10 top-3 -left-3 bg-success px-1 h-5 text-on-success text-sm-body font-bold rounded-sm"
        }
        show={quantity > 0}
        enter="ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-0"
        leaveFrom="opacity-0"
        leaveTo="opacity-0"
      >
        {quantity > 0 &&
          (maxQuantityPerModifier === 1 ? (
            <CheckIcon className="w-4 h-5" />
          ) : (
            t("menu.productDetails.modifier.selectedCount", {
              count: quantity,
            })
          ))}
      </Transition>
      <div
        className={classNames([
          "flex space-x-3 transition-all ease-in-out w-full",
          {
            "opacity-40 non-interactable": slotsLeft === 0 && quantity === 0,
            "cursor-pointer": !disabled && !modifier.disabled,
          },
        ])}
        onClick={() => {
          if (disabled || modifier.disabled || soldOut) return;
          if (
            cartValidations?.notWithinServiceZone ||
            cartValidations?.addressOrTimeslotNotSelected
          ) {
            if (cartValidations?.notWithinServiceZone) return;

            cartValidations?.noAddressOrOutletSelected
              ? emitEvent("selectAddress")
              : emitEvent("selectTimeslot");
          } else if (
            slotsLeft > 0 &&
            (maxQuantityPerModifier === null ||
              quantity < maxQuantityPerModifier)
          ) {
            onLocalChange(quantity + 1);
          } else if (selectionRequiredMax === 1 && quantity === 0) {
            removeCurrentSelection();
            onLocalChange(quantity + 1);
          }
        }}
      >
        {showImages && (
          <div
            className={classNames([
              "modifier-image",
              {
                "opacity-40": soldOut || modifier.disabled,
              },
            ])}
          >
            <Image
              className="object-cover w-full h-full"
              src={
                modifier.horizontalImageUrl ??
                window.dinerManifest.defaultImageUrl
              }
            />
          </div>
        )}
        <div
          className={classNames([
            "flex flex-col text-sm-body modifier-info transition-all ease-in-out py-2",
            {
              "with-images pr-2": showImages,
              "ml-4": quantity > 0,
              "w-full px-2": !showImages,
            },
          ])}
        >
          <div>
            <span
              className={classNames([
                "inline-block align-middle mr-3 pt-1 font-semibold",
                { "opacity-40": soldOut || modifier.disabled },
              ])}
            >
              {modifierLabel}
            </span>
            {renderUpsellLabel()}
          </div>
          {modifier.description && (
            <div
              className={classNames([
                "mt-1",
                { "opacity-40": soldOut || modifier.disabled },
              ])}
              dangerouslySetInnerHTML={{ __html: modifier.description }}
            />
          )}
          {soldOut ? (
            <div className="mt-1 font-bold text-danger">
              {t("menu.menuItem.soldOut")}
            </div>
          ) : (
            modifier.disabled && (
              <div className="mt-1 font-bold text-danger">
                {modifier.disabledReason}
              </div>
            )
          )}
          {modifier?.sugarLevel !== undefined &&
            modifier?.sugarLevel !== null && (
              <NutriGradeBadge
                className={classNames("my-2", {
                  "opacity-60": soldOut || modifier.disabled,
                })}
                sugarLevel={modifier?.sugarLevel}
                nutriGrade={modifier?.nutriGrade}
              />
            )}
          <div className="flex items-end flex-grow">
            <div
              className={classNames([
                "flex-grow font-semibold",
                { "opacity-40": soldOut || modifier.disabled },
              ])}
            >
              {modifier.unitPriceFractional > 0 &&
                `+ ${formatPrice(modifier.unitPriceFractional)}`}
            </div>
            {quantity > 0 && !disabled && !modifier.disabled && (
              <a
                className="font-semibold text-link hover:text-link"
                onClick={(e) => {
                  onLocalChange(0);
                  e.stopPropagation();
                }}
              >
                {t("menu.productDetails.modifier.remove")}
              </a>
            )}
          </div>
        </div>
      </div>
      {quantity > 0 &&
        modifier.modifierGroups?.length > 0 &&
        isModifierGroupsVisible(modifier.modifierGroups) && (
          <div className="px-4 py-4 space-y-4 modifier-configuration">
            {modifier.modifierGroups?.map((modifierGroup, i) => {
              const key = `${modifier.idBreadcrumb.join("-")}-${i}`;
              return (
                <ModifierGroup
                  key={key}
                  id={key}
                  modifierGroup={modifierGroup}
                  isValidationsVisible={isValidationsVisible}
                  onChange={onChange}
                  nested
                />
              );
            })}
          </div>
        )}
    </div>
  );
}
