import { isValidPhoneNumber } from "react-phone-number-input";

import i18n from "@/lib/i18n";
import emailRegexTest from "@/utils/emailRegexTest";

export default function validate(
  values,
  { emailOnly = false, mobileNumberOnly = false } = {},
) {
  const errors = {};

  // validate if not emailOnly or mobileNumberOnly
  if (!(emailOnly || mobileNumberOnly)) {
    if (!values.name) {
      errors.name = i18n.t("signup.error.nameRequired");
    }
  }

  if (!mobileNumberOnly) {
    if (!values.email) {
      errors.email = i18n.t("signup.error.emailRequired");
    } else if (!emailRegexTest(values.email)) {
      errors.email = i18n.t("signup.error.invalidEmail");
    }
  }

  if (!emailOnly) {
    if (!values.mobileNumber) {
      errors.mobileNumber = i18n.t("signup.error.mobileNumberRequired");
    } else if (!isValidPhoneNumber(values.mobileNumber)) {
      errors.mobileNumber = i18n.t("signup.error.invalidMobileNumber");
    }
  }

  return errors;
}
