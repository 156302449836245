import React, { useState } from "react";

import XIcon from "@heroicons/react/outline/XIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "@/common/Input";
import Spin from "@/common/Spin";

import PromoCodeModal from "./PromoCodeModal";

CartPromoCode.propTypes = {
  promoCode: PropTypes.string,
  promoCodeError: PropTypes.string,
  promoWarning: PropTypes.string,
  updateCartPromo: PropTypes.func,
  updateCartPromoResponse: PropTypes.object,
  clearPromoCodeError: PropTypes.func,
};

export default function CartPromoCode({
  promoCode,
  promoCodeError,
  promoWarning,
  updateCartPromo,
  updateCartPromoResponse,
  clearPromoCodeError,
}) {
  const { t } = useTranslation();
  const [isPromoCodeModalVisible, setPromoCodeModalVisible] = useState(false);

  return (
    <div className="relative cart-promo-code">
      <Spin
        spinning={!isPromoCodeModalVisible && updateCartPromoResponse.loading}
      >
        <Input
          inputClassName="cursor-pointer"
          type="text"
          placeholder={t("menu.cart.checkout.placeholders.promoCode")}
          value={promoCode || ""}
          onChange={() => {
            /* do nothing */
          }}
          onClick={() => setPromoCodeModalVisible(true)}
          onFocus={(event) => event.target.blur()}
        />
        {promoCode && (
          <>
            <div
              className="absolute top-0 right-0 flex items-center px-2 cursor-pointer"
              style={{ height: 38 }}
              onClick={() =>
                updateCartPromo({
                  variables: { clear: true },
                })
              }
            >
              <XIcon className="w-6 h-6 text-on-button-default" />
            </div>
            {promoWarning && (
              <span className="inline-block mt-1 text-success">
                {promoWarning}
              </span>
            )}
          </>
        )}
      </Spin>
      <PromoCodeModal
        visible={isPromoCodeModalVisible}
        closeModal={() => {
          setPromoCodeModalVisible(false);
          clearPromoCodeError();
        }}
        promoCode={promoCode}
        promoCodeError={promoCodeError}
        updateCartPromo={updateCartPromo}
        updateCartPromoResponse={updateCartPromoResponse}
      />
    </div>
  );
}
