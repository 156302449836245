import React from "react";

import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

PromoCodeList.propTypes = {
  promoCodes: PropTypes.array,
  isActiveSelectedTab: PropTypes.bool,
  setSelectedPromo: PropTypes.func,
};

PromoCodeList.defaultProps = {
  isActiveSelectedTab: true,
};

const DAYS_IN_YEAR = 365;

export default function PromoCodeList({
  promoCodes,
  isActiveSelectedTab,
  setSelectedPromo,
}) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6 text-left">
      {promoCodes.map((promo) => {
        return (
          <div
            key={promo.id}
            className={`p-6 space-y-3 bg-default2 ${
              isActiveSelectedTab ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (isActiveSelectedTab) {
                setSelectedPromo(promo);
              }
            }}
          >
            <div className="font-bold">{promo.label}</div>
            <div className="flex justify-between space-x-6">
              <div>{promo.code}</div>
              <div className="font-bold text-right">
                {t("account.promoCodes.promoQuantity", {
                  quantity: isActiveSelectedTab
                    ? promo.remainingClaimsCount
                    : promo.unreleasedClaimsCount +
                      (promo.active ? 0 : promo.remainingClaimsCount),
                })}
              </div>
            </div>
            <div className="flex justify-between space-x-6">
              <div>
                {/* Only show validity for promo codes that expire in next 365 days */}
                {dayjs(promo.endDate).diff(dayjs(promo.startDate), "days") <
                  DAYS_IN_YEAR && (
                  <li>
                    {t("account.promoCodes.promoValidity", {
                      formattedDate: dayjs(promo.endDate, "YYYY-MM-DD").format(
                        "MMM DD, YYYY",
                      ),
                    })}
                  </li>
                )}
              </div>
              {isActiveSelectedTab ? (
                <div className="text-right underline text-default">
                  {t("account.promoCodes.viewPromoDetails")}
                </div>
              ) : (
                <div className="text-right">
                  {t(
                    `account.promoCodes.${
                      promo.unreleasedClaimsCount > 0 ? "used" : "expired"
                    }`,
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
