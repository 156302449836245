import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Modal from "@/common/Modal";

PayNowInstructionModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default function PayNowInstructionModal({ visible, closeModal }) {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      visible={visible}
      closable={false}
      mobileClosable={false}
      maskClosable={false}
      footer={null}
      antModalClassName="max-w-[420px] sm:max-w-[420px]"
      width={420}
    >
      <div className="flex flex-col gap-6 text-left">
        <strong className="block text-xl text-center">
          {t("adyen.payNowModal.header")}
        </strong>
        <ol className="pl-5 text-base list-decimal">
          <li>{t("adyen.payNowModal.instruction1")}</li>
          <li>{t("adyen.payNowModal.instruction2")}</li>
          <li>{t("adyen.payNowModal.instruction3")}</li>
        </ol>
        <span
          className="text-center text-danger"
          dangerouslySetInnerHTML={{
            __html: t("adyen.payNowModal.instructionWarning") || "",
          }}
        />
        <Button type="danger" onClick={closeModal}>
          {t("adyen.payNowModal.acknowledge")}
        </Button>
      </div>
    </Modal>
  );
}
