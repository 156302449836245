import React, { memo, useContext, useState } from "react";

import { useQuery } from "@apollo/client";
import ShoppingCartIcon from "@heroicons/react/outline/ShoppingCartIcon";
import { Badge } from "antd";
import { keyBy, sumBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AppContext from "@/components/App/appContext";
import CartContext from "@/components/Cart/cartContext";
import MenuItem from "@/components/Menu/MenuItem";
import MenuContext from "@/components/Menu/menuContext";
import { ITEM_PROMOTED_PRODUCTS_QUERY } from "@/graphql/queries/getItemPromotedProducts";
import { emitEvent } from "@/utils/eventBus";
import { filterPromotableProducts } from "@/utils/filterPromotableProducts";
import formatPrice from "@/utils/formatPrice";

import Modal from "../Modal";

const PromotedProducts = ({ setSelectedItem }) => {
  const { t } = useTranslation();
  const [appState, setAppState] = useContext(AppContext);
  const { cartQuery: cartData } = useContext(CartContext);
  const cartQuantityTree = keyBy(cartData?.cart?.topLevelItems, "item.id");
  const [promotedProducts, setPromotedProducts] = useState([]);
  const { menu } = useContext(MenuContext);

  useQuery(ITEM_PROMOTED_PRODUCTS_QUERY, {
    context: { graph: "diners" },
    skip: !appState.crossSellTriggerProductId,
    variables: {
      itemId: parseInt(appState.crossSellTriggerProductId),
    },
    onCompleted: ({ itemPromotedProducts = [] } = {}) => {
      if (appState.crossSellTriggerProductId) {
        setPromotedProducts(
          filterPromotableProducts(itemPromotedProducts, menu, cartData),
        );
        setAppState({ ...appState, crossSellTriggerProductId: null });
      }
    },
    onError: () => {
      setPromotedProducts([]);
      setAppState({
        ...appState,
        crossSellTriggerProductId: null,
      });
    },
  });
  const handleClose = () => {
    setPromotedProducts([]);
  };

  const handleCartOpen = () => {
    handleClose();
    emitEvent("openCart");
  };

  let bodyMaxHeight = "none";
  if (promotedProducts.length > 0) {
    const modal = document.querySelector(".promoted-products-modal");
    if (modal) {
      const modalComputedStyle = window.getComputedStyle(modal);
      const modalHeaderHeight = 48;
      const marginBetweenHeaderAndBody = 16;
      const modalFooter = modal.lastChild;
      bodyMaxHeight =
        window.innerHeight -
        parseFloat(modalComputedStyle.paddingTop) -
        parseFloat(modalComputedStyle.paddingBottom) -
        marginBetweenHeaderAndBody -
        modalHeaderHeight -
        modalFooter.offsetHeight;
    }
  }

  return (
    <>
      <Modal
        visible={promotedProducts.length > 0}
        onCancel={handleClose}
        width={1024}
        className="promoted-products-modal"
        antModalClassName="max-w-full sm:!max-w-screen-sm lg:!max-w-full"
        centered
        title={t("promotedProductsModal.title")}
        cancelText={t("promotedProductsModal.cancelButton")}
        okText={
          <Badge
            size="small"
            count={
              sumBy(cartData?.cart?.topLevelItems, (item) => item.quantity) ?? 0
            }
            style={{
              backgroundColor: "var(--color-primary)",
              color: "var(--color-text-on-primary)",
              right: "calc(100% + 15px)",
              top: -8,
              height: 20,
              minWidth: 20,
              lineHeight: "20px",
              borderRadius: 20,
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            <div
              className="flex items-center gap-2"
              style={{
                color: "var(--color-text-on-primary)",
              }}
            >
              <ShoppingCartIcon className="w-5 h-5 transform -scale-x-1" />
              <span>
                {t("promotedProductsModal.okButton", {
                  amount: formatPrice(
                    cartData?.cart?.topLevelItems?.length > 0
                      ? cartData?.cart?.paymentBreakdown?.totalIncludingTax
                      : 0,
                  ),
                })}
              </span>
            </div>
          </Badge>
        }
        onOk={handleCartOpen}
      >
        <div
          className="grid grid-cols-2 mt-2 overflow-y-auto gap-x-3 sm:gap-x-6 gap-y-8 lg:grid-cols-3"
          style={{ maxHeight: bodyMaxHeight }}
        >
          {(promotedProducts || [])?.map((item, index) => (
            <MenuItem
              key={index}
              item={item}
              id={parseInt(item?.id)}
              cartItemId={cartQuantityTree[`${item?.id}`]?.id}
              label={item?.label}
              name={item?.name}
              description={item?.description}
              unitPriceFractional={item?.unitPriceFractional}
              currency={item?.currency}
              isConfigurable={item?.isConfigurable}
              quantity={cartQuantityTree[`${item?.id}`]?.quantity}
              setSelectedItem={() =>
                setSelectedItem({
                  ...item,
                  preventPromotedProductsChecking: true,
                })
              }
              horizontalImageUrl={item?.horizontalImageUrl}
              isLeadTimeBreached={item?.isLeadTimeBreached}
              humanizedLeadTime={item?.humanizedLeadTime}
              isAvailable={item?.isAvailable}
              humanizedAvailability={item?.humanizedAvailability}
              cart={cartData?.cart}
              itemStock={item?.itemStock}
              checkPromotedProducts={false}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

PromotedProducts.propTypes = {
  setSelectedItem: PropTypes.func,
};

export default memo(PromotedProducts);
