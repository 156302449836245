import React, { useEffect, useRef, useState, useContext, useMemo } from "react";

import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import XIcon from "@heroicons/react/outline/XIcon";
import find from "lodash/find";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Button from "@/common/Button";
import Image from "@/common/Image";
import Input from "@/common/Input";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import AppContext from "@/components/App/appContext";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import NutriGradeBadge from "@/components/Menu/NutriGradeBadge";
import MenuContext from "@/components/Menu/menuContext";
import QuantityControl from "@/components/QuantityControl";
import {
  ADD_ITEM_TO_CART_MUTATION,
  ADD_ITEM_TO_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/addItemToCart";
import {
  REMOVE_ITEM_FROM_CART_MUTATION,
  REMOVE_ITEM_FROM_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/removeItemFromCart";
import {
  UPDATE_ITEM_IN_CART_MUTATION,
  UPDATE_ITEM_IN_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/updateItemInCart";
import {
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import { ITEM_QUERY } from "@/graphql/queries/getItem";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";
import {
  getInvalidNestedConfigurationIndexes,
  getItemPayloadFromWorkingTree,
  isItemConfigurationValid,
  isModifierGroupValid,
  prefillFixedModifierConfiguration,
  setWorkingTreeFromLineItem,
  getItemConfigurationTotal,
} from "@/utils/itemConfiguration";
import toKebabCase from "@/utils/kebabCase";

import ProductConfiguration from "./ProductConfiguration";

import "./ProductDetailsModal.less";

const SPECIAL_INSTRUCTIONS_MAX_LENGTH = 500;

ProductDetailsModal.propTypes = {
  selectedCartItem: PropTypes.object,
  setSelectedCartItem: PropTypes.func,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
  selectedItemSection: PropTypes.object,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
  showLoadingSpinner: PropTypes.bool,
};

export default function ProductDetailsModal({
  selectedCartItem,
  setSelectedCartItem,
  selectedItem,
  setSelectedItem,
  selectedItemSection,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
  showLoadingSpinner,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { itemSlug } = useParams();
  const [appState, setAppState] = useContext(AppContext);

  const { cartQuery, cartValidations } = useContext(CartContext);
  const { activeBrandId } = useContext(MenuContext);
  const { configQuery, pathConfig } = useContext(ConfigContext);
  const cartQuantityTree = keyBy(cartQuery?.cart?.topLevelItems, "item.id");

  const useDishNotesOnItem = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_DISH_NOTES_ON_ITEM,
  );
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const productDetailsContent = useRef();
  const productDetailsBody = useRef();
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();
  const [shouldRefetchItem, setShouldRefetchItem] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState("");
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [isValidationsVisible, setValidationsVisible] = useState(false);
  const [itemTotal, setItemTotal] = useState(
    selectedItem?.unitPriceFractional || 0,
  );

  const [detailedItem, setDetailedItem] = useState(selectedItem);

  const [getDetailedItem, { loading: detailedItemLoading }] = useLazyQuery(
    ITEM_QUERY,
    {
      context: { graph: "diners" },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setDetailedItem({
          ...selectedItem,
          ...data.getItem,
        });
      },
    },
  );

  const client = useApolloClient();
  const [addToCart] = useMutation(
    useOptimisedDinerCartType
      ? ADD_ITEM_TO_CART_OPTIMISED_MUTATION
      : ADD_ITEM_TO_CART_MUTATION,
    getMutationOptions(
      useOptimisedDinerCartType ? "addItemToCartOptimised" : "addItemToCart",
    ),
  );
  const [updateInCart] = useMutation(
    useOptimisedDinerCartType
      ? UPDATE_ITEM_IN_CART_OPTIMISED_MUTATION
      : UPDATE_ITEM_IN_CART_MUTATION,
    getMutationOptions(
      useOptimisedDinerCartType
        ? "updateItemInCartOptimised"
        : "updateItemInCart",
    ),
  );
  const [removeFromCart] = useMutation(
    useOptimisedDinerCartType
      ? REMOVE_ITEM_FROM_CART_OPTIMISED_MUTATION
      : REMOVE_ITEM_FROM_CART_MUTATION,
    getMutationOptions(
      useOptimisedDinerCartType
        ? "removeItemFromCartOptimised"
        : "removeItemFromCart",
    ),
  );

  const itemLabel = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      selectedItem
    ) {
      return (
        selectedItem.translation?.title?.[i18n.resolvedLanguage] ??
        selectedItem.translation?.title?.["default"] ??
        selectedItem?.label
      );
    } else {
      return selectedItem?.label;
    }
  }, [selectedItem, i18n.resolvedLanguage, configQuery?.config?.features]);

  const itemDescription = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      selectedItem
    ) {
      return (
        selectedItem.translation?.description?.[i18n.resolvedLanguage] ??
        selectedItem.translation?.description?.["default"] ??
        selectedItem?.description
      );
    } else {
      return selectedItem?.description;
    }
  }, [selectedItem, i18n.resolvedLanguage, configQuery?.config?.features]);

  const itemUrl = (selectedItem) =>
    selectedItem &&
    `${pathConfig?.menuPath}/items/${toKebabCase(
      [selectedItem?.id, selectedItem?.label].join(),
    )}`.replace("//", "/");

  useEffect(() => {
    if (selectedCartItem) {
      setQuantity(selectedCartItem.quantity);
      setNotes(selectedCartItem.notes);
      setWorkingTreeFromLineItem(selectedCartItem);
    }
  }, [selectedCartItem]);

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.hasConfigurations) {
        getDetailedItem({
          variables: {
            itemId: selectedItem.id,
          },
        });
      } else {
        setDetailedItem(selectedItem);
      }

      setQuantity(cartQuantityTree[`${selectedItem?.id}`]?.quantity || 1);
      if (!selectedCartItem) {
        setNotes("");
      }

      analytics.trackEventWithProperties(events.product_viewed, selectedItem);

      if (pathConfig?.menuPath) {
        navigate(itemUrl(selectedItem));
      }
    } else {
      if (itemSlug) {
        setDetailedItem(selectedItem);
        // scroll to top before closing modal
        [productDetailsContent, productDetailsBody].forEach((containerRef) => {
          if (containerRef.current) {
            containerRef.current.classList.add("immediate-scroll");
            containerRef.current.scrollTo(0, 0);
            containerRef.current.classList.remove("immediate-scroll");
          }
        });
        // remove item slug from url
        if (pathConfig?.menuPath) {
          navigate(`${pathConfig?.menuPath}`);
        }
      }
    }
  }, [selectedItem?.id, pathConfig]);

  useEffect(() => {
    if (detailedItem) {
      if (
        detailedItem.isConfigurable ||
        detailedItem.modifierGroups?.length > 0
      ) {
        if (!selectedCartItem && detailedItem.isConfigurable) {
          setQuantity(1);
          setNotes("");
        } else {
          const lineItem = cartQuery?.cart?.topLevelItems.find((cartItem) => {
            if (selectedCartItem) {
              return cartItem.id === selectedCartItem.id;
            }
            return cartItem.item.id === selectedItem?.id;
          });
          if (lineItem) {
            setQuantity(lineItem.quantity);
            setNotes(lineItem.notes);
            setSelectedCartItem(lineItem);
          }
        }
        if (!selectedCartItem) {
          prefillFixedModifierConfiguration(detailedItem);
        }
      } else {
        const lineItem = cartQuery?.cart?.topLevelItems.find((cartItem) => {
          return cartItem.item.id === selectedItem?.id;
        });
        setSelectedCartItem(lineItem);
      }
    }
  }, [JSON.stringify(detailedItem)]);

  useEffect(() => {
    if (cartValidations?.noAddressOrOutletSelected) {
      // if outlet not selected yet, set to refetch item for stocks
      setShouldRefetchItem(true);
    } else if (shouldRefetchItem && selectedItem?.id) {
      setShouldRefetchItem(false);
      // refetch item for stocks
      getDetailedItem({
        variables: {
          itemId: selectedItem.id,
        },
      });
    }
  }, [cartValidations?.noAddressOrOutletSelected]);

  function onCancel() {
    setDeleteConfirmationVisible(false);
    setValidationsVisible(false);
    setSelectedCartItem(null);
    setSelectedItem(null);
    setDetailedItem(null);
    if (pathConfig?.menuPath) {
      navigate(`${pathConfig?.menuPath}`);
    }
  }

  const LG_BREAKPOINT = 1024;

  function scrollToFirstInvalidModifierGroup() {
    let invalidNestedIndexes = [-1, -1];
    const invalidModifierGroupIndex = detailedItem.modifierGroups.findIndex(
      (modifierGroup) => {
        if (isModifierGroupValid(modifierGroup)) {
          invalidNestedIndexes =
            getInvalidNestedConfigurationIndexes(modifierGroup);
          // if either index is -1, it means the nested configurations are all valid
          return invalidNestedIndexes[0] !== -1;
        }
        return true;
      },
    );
    // TODO: use refs instead of index selector
    let querySelector = `.product-configuration > .modifier-group:nth-child(${
      invalidModifierGroupIndex + 1
    })`;
    if (invalidNestedIndexes[0] !== -1) {
      querySelector = `${querySelector} > .modifiers > .modifier:nth-child(${
        invalidNestedIndexes[0] + 1
      }) > .modifier-configuration > .modifier-group:nth-child(${
        invalidNestedIndexes[1] + 1
      })`;
    }
    const invalidModifierGroup = document.querySelector(querySelector);
    // add a 12px top spacing
    if (window.innerWidth >= LG_BREAKPOINT) {
      productDetailsBody.current.scrollTo(
        0,
        productDetailsBody.current.scrollTop -
          productDetailsBody.current.getBoundingClientRect().top +
          invalidModifierGroup.getBoundingClientRect().top -
          12,
      );
    } else {
      productDetailsContent.current.scrollTo(
        0,
        productDetailsContent.current.scrollTop -
          productDetailsContent.current.getBoundingClientRect().top +
          invalidModifierGroup.getBoundingClientRect().top -
          12,
      );
    }
  }

  function getMutationOptions(mutationName) {
    return {
      context: { graph: "diners" },
      errorPolicy: "all",
      update: (_, { data }) => {
        const cartData = get(data, mutationName);
        client.writeQuery({
          query: useOptimisedDinerCartType
            ? GET_CART_OPTIMISED_QUERY
            : GET_CART_QUERY,
          data: {
            cart: cartData,
            ...(useOptimisedDinerCartType ? { cartOptimised: cartData } : {}),
          },
        });

        // TODO: Check for quantity change and trigger the right event
        const eventName = [
          "removeItemFromCart",
          "removeItemFromCartOptimised",
        ].includes(mutationName)
          ? events.product_removed
          : events.product_added;
        analytics.trackEventWithProperties(
          eventName,
          find(
            cartData.topLevelItems,
            (topLevelItem) => topLevelItem?.item?.id === detailedItem?.id,
          ),
        );
      },
    };
  }

  function addNewItemToOptimistic() {
    // Use spread for shallow clone vs deep clone
    const itemUniqueId = uuidv4();
    const brand = {
      id: activeBrandId,
      label: configQuery?.brands?.find((brand) => brand.id === activeBrandId)
        ?.label,
    };
    const optimisticallyAddedItem = {
      id: itemUniqueId,
      name: selectedItem?.label,
      translation: {},
      quantity: quantity,
      notes: notes,
      quantityLeft: null,
      subtotal: itemTotal,
      itemModifierGroupId: null,
      item: {
        identifier: null,
        ...selectedItem,
        sections: [],
        idBreadcrumb: [`i${selectedItem.id}`],
      },
      brand: activeBrandId ? brand : null,
      sortedSubItems: [],
      subItems: [],
      __typename: useOptimisedDinerCartType
        ? "SubItemTypeOptimised"
        : "SubItem",
    };
    // add to the front to show at the top
    return [optimisticallyAddedItem, ...(cartQuery?.cart?.topLevelItems || [])];
  }

  function getUpdatedTopLevelItems(selectedCartItemId) {
    let updatedTopLevelItems =
      JSON.parse(JSON.stringify(cartQuery?.cart?.topLevelItems)) || [];
    let updatedCartItem = updatedTopLevelItems.find(
      (cartItem) => cartItem?.id === selectedCartItemId,
    );
    updatedCartItem.quantity = quantity;
    updatedCartItem.notes = notes;
    updatedCartItem.subtotal = itemTotal;
    return updatedTopLevelItems;
  }

  function removeFromTopLevelItems(selectedCartItemId) {
    // Use spread for shallow clone vs deep clone
    var updatedTopLevelItems = [...(cartQuery?.cart?.topLevelItems || [])];
    updatedTopLevelItems = updatedTopLevelItems.filter(
      (cartItem) => cartItem?.id !== selectedCartItemId,
    );
    return updatedTopLevelItems;
  }

  function getActionButtonCopy() {
    let key;
    if (detailedItem?.isLeadTimeBreached) {
      key = "preOrder";
    } else if (detailedItem?.isAvailable === false) {
      key = "notAvailable";
    } else if (selectedCartItem && !detailedItem) {
      key = "updateWithoutPrice";
    } else if (selectedCartItem) {
      key = "update";
    } else if (!detailedItem) {
      key = "addToCartWithoutPrice";
    } else {
      key = "addToCart";
    }
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t(`menu.productDetails.labels.${key}`, {
            price: formatPrice(
              detailedItem?.isConfigurable ||
                detailedItem?.modifierGroups?.length > 0
                ? itemTotal
                : detailedItem?.unitPriceFractional,
            ),
          }),
        }}
      />
    );
  }

  const soldOut =
    selectedItem?.itemStock?.quantityLeft &&
    typeof selectedItem?.itemStock?.quantityLeft["entire"] === "number" &&
    selectedItem?.itemStock?.quantityLeft["entire"] <= 0;

  const renderFooter = ({ className }) => {
    return (
      <div
        className={`p-4 space-y-4 product-details-footer bg-default2 ${className}`}
      >
        {deleteConfirmationVisible ? (
          <>
            <div className="font-bold">
              {t("menu.productDetails.removeConfirmation")}
            </div>
            <div className="flex flex-row-reverse space-x-3 space-x-reverse">
              <Button
                type="danger"
                id="remove-item-from-cart-confirmation"
                onClick={() => {
                  removeFromCart({
                    variables: {
                      lineItemId: selectedCartItem.id,
                    },
                    optimisticResponse: {
                      [useOptimisedDinerCartType
                        ? "removeItemFromCartOptimised"
                        : "removeItemFromCart"]: {
                        __typename: useOptimisedDinerCartType
                          ? "DinerCartTypeOptimised"
                          : "Cart",
                        ...cartQuery?.cart,
                        topLevelItems: removeFromTopLevelItems(
                          selectedCartItem.id,
                        ),
                        paymentBreakdown: {
                          ...cartQuery.cart.paymentBreakdown,
                          total: null,
                        },
                      },
                    },
                  });
                  setDeleteConfirmationVisible(false);
                  // close modal since there is an optimistic response
                  onCancel();
                }}
              >
                {t("menu.productDetails.remove")}
              </Button>
              <Button onClick={() => setDeleteConfirmationVisible(false)}>
                {t("menu.productDetails.keep")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex space-x-3">
              {cartValidations?.notWithinServiceZone ? (
                <Button
                  id={`add-to-cart-${selectedItem?.id}`}
                  className="w-full"
                  type="primary"
                  disabled={true}
                >
                  {t("menu.menuItem.addToCart")}
                </Button>
              ) : cartValidations?.addressOrTimeslotNotSelected ? (
                <Button
                  id={`add-to-cart-${selectedItem?.id}`}
                  type="primary"
                  className="w-full"
                  onClick={() => {
                    cartValidations?.noAddressOrOutletSelected
                      ? emitEvent("selectAddress")
                      : emitEvent("selectTimeslot");
                  }}
                >
                  {t("menu.menuItem.addToCart")}
                </Button>
              ) : selectedItemSection?.disabled ? (
                <Button
                  type="primary"
                  className="w-full opacity-50"
                  onClick={() => {
                    showMenuSectionDisabledModal(
                      selectedItemSection.disabledReason,
                    );
                  }}
                >
                  {t("menu.menuItem.notAvailable")}
                </Button>
              ) : soldOut ? (
                <Button className="w-full" type="primary" disabled>
                  {t("menu.menuItem.soldOut")}
                </Button>
              ) : (
                <>
                  <QuantityControl
                    min={selectedCartItem ? 0 : 1}
                    debounce={0}
                    quantityLeft={
                      selectedCartItem?.quantityLeft ||
                      selectedItem?.itemStock?.quantityLeft?.entire
                    }
                    quantity={quantity}
                    onChange={(newQuantity) => {
                      if (newQuantity > 0) {
                        setQuantity(newQuantity);
                      } else if (selectedCartItem) {
                        setDeleteConfirmationVisible(true);
                      }
                    }}
                  />
                  <Button
                    type="primary"
                    id={`add-to-cart-${selectedItem?.id}`}
                    className={`w-full ${
                      detailedItem?.isAvailable === false ? "opacity-50" : ""
                    }`}
                    disabled={detailedItemLoading}
                    onClick={() => {
                      if (detailedItem?.isLeadTimeBreached) {
                        showPreOrderModal(detailedItem.humanizedLeadTime);
                        onCancel();
                        return;
                      }

                      // check specifically for false, and not null or undefined
                      if (detailedItem?.isAvailable === false) {
                        showAvailabilityModal(
                          detailedItem.humanizedAvailability,
                        );
                        onCancel();
                        return;
                      }

                      if (
                        !(
                          selectedItem?.isConfigurable ||
                          selectedItem?.modifierGroups?.length > 0
                        )
                      ) {
                        const quantityInCart =
                          cartQuantityTree[`${selectedItem?.id}`]?.quantity ??
                          0;
                        const lineItemIdCart =
                          cartQuantityTree[`${selectedItem?.id}`]?.id;
                        if (quantityInCart > 0 && lineItemIdCart) {
                          // Analytics
                          const qtyDiff = quantity - quantityInCart;
                          trackEvent(
                            qtyDiff > 0
                              ? eventsV2.addToCart
                              : eventsV2.removeFromCart,
                            {
                              value:
                                getItemConfigurationTotal(detailedItem) *
                                qtyDiff,
                              item: detailedItem,
                            },
                          );

                          // Update cart
                          updateInCart({
                            variables: {
                              itemConfiguration: {
                                notes,
                                quantity,
                                lineItemId: lineItemIdCart,
                              },
                            },
                            optimisticResponse: {
                              [useOptimisedDinerCartType
                                ? "updateItemInCartOptimised"
                                : "updateItemInCart"]: {
                                __typename: useOptimisedDinerCartType
                                  ? "DinerCartTypeOptimised"
                                  : "Cart",
                                ...cartQuery?.cart,
                                topLevelItems:
                                  getUpdatedTopLevelItems(lineItemIdCart),
                                paymentBreakdown: {
                                  ...cartQuery.cart.paymentBreakdown,
                                  total: null,
                                },
                              },
                            },
                          });
                          // close modal since there is an optimistic response
                          onCancel();
                        } else {
                          if (
                            !selectedItem?.preventPromotedProductsChecking &&
                            !appState.crossSellTriggerProductId
                          ) {
                            setAppState({
                              ...appState,
                              crossSellTriggerProductId: selectedItem?.id,
                            });
                          }
                          // Analytics -->
                          trackEvent(eventsV2.addToCart, {
                            value:
                              getItemConfigurationTotal(detailedItem) *
                              quantity,
                            item: detailedItem,
                          });
                          // Analytics -->

                          addToCart({
                            variables: {
                              itemConfiguration: {
                                notes,
                                quantity,
                                itemId: selectedItem?.id,
                              },
                            },
                            optimisticResponse: {
                              [useOptimisedDinerCartType
                                ? "addItemToCartOptimised"
                                : "addItemToCart"]: {
                                __typename: useOptimisedDinerCartType
                                  ? "DinerCartTypeOptimised"
                                  : "Cart",
                                ...cartQuery?.cart,
                                topLevelItems: addNewItemToOptimistic(),
                                paymentBreakdown: {
                                  ...cartQuery.cart.paymentBreakdown,
                                  total: null,
                                },
                              },
                            },
                          });
                          // close modal since there is an optimistic response
                          onCancel();
                        }

                        return;
                      }

                      if (
                        detailedItem?.isConfigurable ||
                        detailedItem?.modifierGroups?.length > 0
                      ) {
                        if (!isItemConfigurationValid(detailedItem)) {
                          setValidationsVisible(true);
                          scrollToFirstInvalidModifierGroup();
                        } else {
                          const mutation = selectedCartItem
                            ? updateInCart
                            : addToCart;

                          // Analytics -->
                          const quantityInCart =
                            cartQuantityTree[`${selectedItem?.id}`]?.quantity ??
                            0;
                          const qtyDiff = quantity - quantityInCart;
                          trackEvent(
                            qtyDiff > 0
                              ? eventsV2.addToCart
                              : eventsV2.removeFromCart,
                            {
                              value:
                                getItemConfigurationTotal(detailedItem) *
                                qtyDiff,
                              item: detailedItem,
                            },
                          );
                          // Analytics -->

                          const optimisticResponseName = selectedCartItem
                            ? useOptimisedDinerCartType
                              ? "updateItemInCartOptimised"
                              : "updateItemInCart"
                            : useOptimisedDinerCartType
                            ? "addItemToCartOptimised"
                            : "addItemToCart";

                          if (
                            !selectedItem?.preventPromotedProductsChecking &&
                            !selectedCartItem &&
                            !appState.crossSellTriggerProductId
                          ) {
                            setAppState({
                              ...appState,
                              crossSellTriggerProductId: detailedItem.id,
                            });
                          }

                          mutation({
                            variables: {
                              itemConfiguration: {
                                ...getItemPayloadFromWorkingTree({
                                  quantity,
                                  notes,
                                  lineItemId: selectedCartItem?.id,
                                }),
                                ...(selectedCartItem
                                  ? {}
                                  : { itemId: detailedItem.id }),
                              },
                            },
                            optimisticResponse: {
                              [optimisticResponseName]: {
                                __typename: useOptimisedDinerCartType
                                  ? "DinerCartTypeOptimised"
                                  : "Cart",
                                ...cartQuery?.cart,
                                topLevelItems: selectedCartItem
                                  ? getUpdatedTopLevelItems(selectedCartItem.id)
                                  : addNewItemToOptimistic(),
                                paymentBreakdown: {
                                  ...cartQuery.cart.paymentBreakdown,
                                  total: null,
                                },
                              },
                            },
                          });
                          // close modal since there is an optimistic response
                          onCancel();
                        }
                      } else {
                        const isAddToCart =
                          get(
                            cartQuantityTree,
                            `${detailedItem?.id}.quantity`,
                            0,
                          ) === 0;
                        const mutation = isAddToCart ? addToCart : updateInCart;
                        const optimisticResponseName = isAddToCart
                          ? useOptimisedDinerCartType
                            ? "updateItemInCartOptimised"
                            : "updateItemInCart"
                          : useOptimisedDinerCartType
                          ? "addItemToCartOptimised"
                          : "addItemToCart";

                        // Analytics -->
                        const quantityInCart =
                          cartQuantityTree[`${selectedItem?.id}`]?.quantity ??
                          0;
                        const qtyDiff = quantity - quantityInCart;
                        trackEvent(
                          qtyDiff > 0
                            ? eventsV2.addToCart
                            : eventsV2.removeFromCart,
                          {
                            value:
                              getItemConfigurationTotal(detailedItem) * qtyDiff,
                            item: detailedItem,
                          },
                        );
                        // Analytics -->

                        if (
                          !selectedItem?.preventPromotedProductsChecking &&
                          isAddToCart &&
                          !appState.crossSellTriggerProductId
                        ) {
                          setAppState({
                            ...appState,
                            crossSellTriggerProductId: detailedItem?.id,
                          });
                        }

                        mutation({
                          variables: {
                            itemConfiguration: {
                              quantity,
                              notes,
                              lineItemId:
                                selectedCartItem && selectedCartItem?.id,
                              ...(selectedCartItem
                                ? {}
                                : { itemId: detailedItem?.id }),
                            },
                          },
                          optimisticResponse: {
                            [optimisticResponseName]: {
                              __typename: useOptimisedDinerCartType
                                ? "DinerCartTypeOptimised"
                                : "Cart",
                              ...cartQuery?.cart,
                              topLevelItems: isAddToCart
                                ? addNewItemToOptimistic()
                                : getUpdatedTopLevelItems(selectedCartItem.id),
                              paymentBreakdown: {
                                ...cartQuery.cart.paymentBreakdown,
                                total: null,
                              },
                            },
                          },
                        });
                        // close modal since there is an optimistic response
                        onCancel();
                      }
                    }}
                  >
                    {getActionButtonCopy()}
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  if (!selectedItem && !selectedCartItem) return null;

  return (
    <Modal
      wrapperClassName="product-details-modal-wrapper"
      className="flex-grow product-details-modal"
      visible={!!selectedItem}
      centered
      closable={false}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
    >
      <div
        data-testid="product-details-modal"
        className="flex flex-col flex-grow text-sm text-left product-details-wrapper font-body lg:h-auto"
      >
        <div className="absolute top-0 right-0 z-10 pt-4 pr-4">
          <button
            type="button"
            className="p-1 rounded-full bg-default text-default2 hover:text-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={onCancel}
          >
            <span className="sr-only">Close</span>
            <XIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="hidden" id="selected-product-sku">
          {selectedItem?.id}
        </div>
        <div
          ref={productDetailsContent}
          className="flex flex-col flex-grow overflow-y-auto product-details-modal-content lg:flex-row"
        >
          {selectedItem && (
            <Helmet>
              <meta
                property="og:title"
                content={`${window.dinerManifest.title}${` | ${itemLabel}`}`}
              />
              <meta property="og:description" content={itemDescription} />
              <meta property="og:url" content={window.location.href} />
              <meta
                property="og:image"
                content={
                  selectedItem.descriptionImageUrl ??
                  selectedItem.horizontalImageUrl ??
                  window.dinerManifest?.defaultImageUrl
                }
              />
              <meta
                property="product:availability"
                content={
                  selectedItem.itemStock?.quantityLeft > 0
                    ? "in stock"
                    : "out of stock"
                }
              />
              <meta property="product:condition" content="new" />
              <meta
                property="product:brand"
                content={window.dinerManifest.brand ?? window.location.host}
              />
              <meta
                property="product:retailer_item_id"
                content={selectedItem.id}
              />
              <meta
                property="product:price:amount"
                content={selectedItem.unitPriceFractional / 100}
              />
              <meta
                property="product:price:currency"
                content={selectedItem.currency}
              />
              <title>{`${
                window.dinerManifest.title
              }${` | ${itemLabel}`}`}</title>
              <meta name="description" content={itemDescription} />
              <link rel="canonical" href={`${window.location.href}`} />
            </Helmet>
          )}
          <div className="relative lg:overflow-hidden product-details-image">
            <Image
              className="object-cover h-64 lg:h-full"
              src={
                selectedItem?.descriptionImageUrl ??
                selectedItem?.horizontalImageUrl ??
                window.dinerManifest?.defaultImageUrl
              }
            />
            {selectedItem?.promotionalLabelText && (
              <div
                className="absolute z-10 px-4 py-2 rounded-sm bottom-4 left-4"
                style={{
                  backgroundColor: selectedItem?.promotionalLabelBgColor,
                  color: selectedItem?.promotionalLabelFontColor,
                }}
              >
                {selectedItem?.promotionalLabelText}
              </div>
            )}
          </div>
          <Spin spinning={detailedItemLoading || showLoadingSpinner}>
            <div className="flex flex-col product-details-panel">
              <div
                ref={productDetailsBody}
                className="relative flex flex-col flex-grow p-6 pt-12 space-y-3 overflow-y-auto product-details-body"
              >
                {selectedItem?.sugarLevel !== undefined &&
                  selectedItem?.sugarLevel !== null && (
                    <NutriGradeBadge
                      className="absolute top-6 left-6"
                      sugarLevel={selectedItem?.sugarLevel}
                      nutriGrade={selectedItem?.nutriGrade}
                    />
                  )}
                <div className="space-y-3 product-details-info">
                  <div className="text-2xl font-display">{itemLabel}</div>

                  <div
                    className="text-sm-body"
                    dangerouslySetInnerHTML={{
                      __html: itemDescription,
                    }}
                  />

                  {/* TODO: Use ingredients and allergens API when it's ready */}
                  {/* <div className="space-y-2">
                  <div className="font-bold">
                    {t("menu.productDetails.labels.ingredients")}
                  </div>
                  <div>placeholder, ingredients</div>
                </div>

                <div className="space-y-2">
                  <div className="font-bold">
                    {t("menu.productDetails.labels.allergens")}
                  </div>
                  <div>placeholder, allergens</div>
                </div> */}
                </div>

                <ProductConfiguration
                  detailedItem={detailedItem}
                  isValidationsVisible={isValidationsVisible}
                  onChange={() =>
                    setItemTotal(
                      getItemConfigurationTotal(detailedItem) * quantity,
                    )
                  }
                />
                {useDishNotesOnItem && (
                  <div>
                    <div className="mt-2 mb-1 font-bold text-body text-default">
                      {t("menu.productDetails.labels.notes")}
                    </div>
                    <Input
                      type="textarea"
                      maxLength={SPECIAL_INSTRUCTIONS_MAX_LENGTH}
                      rows={2}
                      placeholder={t("menu.productDetails.placeholders.notes")}
                      value={notes || ""}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </div>
                )}
              </div>
              {renderFooter({
                className: "hidden lg:block",
              })}
            </div>
          </Spin>
        </div>
        {renderFooter({
          className: "lg:hidden",
        })}
      </div>
    </Modal>
  );
}
