import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { CartProvider } from "@/components/Cart/cartContext";
import { ConfigProvider } from "@/components/Config/configContext";
import {
  SET_FULFILMENT_TYPE_MUTATION,
  SET_FULFILMENT_TYPE_OPTIMISED_MUTATION,
} from "@/graphql/mutations/setFulfilmentType";
import {
  GET_ADDRESSES_AND_OUTLETS_QUERY,
  GET_OUTLETS_FULFILMENT_TYPE_STATES_QUERY,
} from "@/graphql/queries/getAddresses";
import {
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import graphQlConstants from "@/graphql/utils/constants";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";

import { AppProvider } from "./components/App/appContext";

CustomProviders.propTypes = {
  configQuery: PropTypes.object,
  configLoading: PropTypes.bool,
  pathConfig: PropTypes.object,
  outletSlug: PropTypes.string,
  setOutletSlug: PropTypes.func,
  outletQrHash: PropTypes.string,
  setOutletQrHash: PropTypes.func,
  tableQrHash: PropTypes.string,
  setTableQrHash: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default function CustomProviders({
  configQuery,
  configLoading,
  pathConfig,
  outletSlug,
  setOutletSlug,
  outletQrHash,
  setOutletQrHash,
  tableQrHash,
  setTableQrHash,
  children,
}) {
  const loadWithOutletSlugRef = useRef(false);

  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [
    getCart,
    {
      data: cartData,
      loading: cartLoading,
      error: cartError,
      refetch: refetchCart,
    },
  ] = useLazyQuery(
    useOptimisedDinerCartType ? GET_CART_OPTIMISED_QUERY : GET_CART_QUERY,
    {
      context: { graph: "diners" },
      fetchPolicy: "cache-first", // https://www.apollographql.com/docs/react/data/queries/#cache-first
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const cartData = useOptimisedDinerCartType
          ? data?.cartOptimised
          : data?.cart;
        if (cartData) {
          if (outletSlug) {
            loadWithOutletSlugRef.current = true;
            // remove outletSlug from storage, since we only need X-Outlet-ID on the first getCart call
            localStorage.removeItem(graphQlConstants.OUTLET_SLUG);
          }
          // if outlet slug is provided, and cart has items, cart will be migrated
          // cart migration may remove items, so check for removedItems here
          if (cartData?.removedItems) {
            emitEvent("showRemovedItemsModal", cartData?.removedItems);
          }
        }
      },
      onError: (error) => {
        if (error.message.includes("QR hash is invalid")) {
          window.location.href = "/"; // Redirect to home page if QR hash is invalid
        }
      },
    },
  );
  const cartQuery = useMemo(() => {
    if (useOptimisedDinerCartType) {
      if (cartData?.cartOptimised) {
        return {
          ...cartData,
          cart: cartData.cartOptimised,
        };
      }
    }
    return cartData;
  }, [cartData, useOptimisedDinerCartType]);

  // Note: Fetching user addresses and outlets before hand
  // to populate selected address/outlet field in menu
  // eslint-disable-next-line no-unused-vars
  const [
    getAddressesAndOutlets,
    {
      data: addressesAndOutlets,
      loading: addressAndOutletsLoading,
      refetch: refetchAddressAndOutlets,
    },
  ] = useLazyQuery(GET_ADDRESSES_AND_OUTLETS_QUERY, {
    context: { graph: "diners" },
    notifyOnNetworkStatusChange: true,
  });

  const [
    deferGetOutletFulfilmentTypeStates,
    setDeferGetOutletFulfilmentTypeStates,
  ] = useState(false);

  const [
    getSelectedOutletFulfilmentTypeStates,
    {
      data: selectedOutletFulfilmentTypeStates,
      previousData: previousSelectedOutletFulfilmentTypeStates,
      loading: selectedOutletFulfilmentTypeStatesLoading,
      refetch: refetchSelectedOutletFulfilmentTypeStates,
    },
  ] = useLazyQuery(GET_OUTLETS_FULFILMENT_TYPE_STATES_QUERY, {
    context: { graph: "diners" },
  });

  const [
    getOtherOutletsFulfilmentTypeStates,
    {
      data: otherOutletsFulfilmentTypeStates,
      previousData: previousOtherOutletsFulfilmentTypeStates,
      loading: otherOutletsFulfilmentTypeStatesLoading,
      refetch: refetchOtherOutletsFulfilmentTypeStates,
    },
  ] = useLazyQuery(GET_OUTLETS_FULFILMENT_TYPE_STATES_QUERY, {
    context: { graph: "diners" },
  });

  const selectedOutletId = cartQuery?.cart?.outletId;
  const isAddressSelected = cartQuery?.cart && !!cartQuery?.cart?.address?.id;
  const isOutletSelected = cartQuery?.cart && !!selectedOutletId;
  const noAddressOrOutletSelected = !isAddressSelected && !isOutletSelected;
  const noTimeslotSelected =
    cartQuery?.cart &&
    !cartQuery.cart.timeslot?.id &&
    cartQuery.cart.timeslotType !== constants.ASAP_TIMESLOT;
  const addressOrTimeslotNotSelected =
    noAddressOrOutletSelected || noTimeslotSelected;
  const notWithinServiceZone =
    cartQuery?.cart &&
    cartQuery.cart?.fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY &&
    cartQuery.cart?.address &&
    cartQuery.cart?.address?.availableOutlets.length === 0;

  const cartValidations = {
    noAddressOrOutletSelected,
    noTimeslotSelected,
    addressOrTimeslotNotSelected,
    notWithinServiceZone,
  };

  useEffect(() => {
    if (outletSlug && !loadWithOutletSlugRef.current) {
      localStorage.setItem(graphQlConstants.OUTLET_SLUG, outletSlug);
    } else {
      localStorage.removeItem(graphQlConstants.OUTLET_SLUG);
    }
  }, [outletSlug]);

  useEffect(() => {
    if (configQuery?.config?.country) {
      localStorage.setItem(
        constants.CONFIG.COUNTRY,
        configQuery.config.country,
      );
    }

    if (configQuery?.config?.currency) {
      localStorage.setItem(
        constants.CONFIG.CURRENCY,
        configQuery.config.currency,
      );
    }

    if (outletQrHash) {
      localStorage.setItem(graphQlConstants.OUTLET_QR_HASH, outletQrHash);
      if (tableQrHash) {
        localStorage.setItem(graphQlConstants.TABLE_QR_HASH, tableQrHash);
      } else {
        localStorage.removeItem(graphQlConstants.TABLE_QR_HASH);
      }

      getCart();
      getAddressesAndOutlets();
      getOutletsFulfilmentTypeStates();
    } else {
      if (
        configQuery?.config &&
        pathConfig?.menuPath &&
        !pathConfig?.menuPath.includes("/qr") &&
        !window.location.pathname.includes("/qr")
      ) {
        localStorage.removeItem(graphQlConstants.OUTLET_QR_HASH);
        localStorage.removeItem(graphQlConstants.TABLE_QR_HASH);

        getCart();
        getAddressesAndOutlets();
        getOutletsFulfilmentTypeStates();
      }
    }
  }, [configQuery?.config, pathConfig?.menuPath, outletQrHash, tableQrHash]);

  useEffect(() => {
    // undefined means cart query is not returned yet; if cart outlet is not assigned, it will be null
    if (selectedOutletId !== undefined && deferGetOutletFulfilmentTypeStates) {
      setDeferGetOutletFulfilmentTypeStates(false);
      getOutletsFulfilmentTypeStates();
    }
  }, [selectedOutletId]);

  // Note: Auto assigning outlet when brand is only
  // available to pickup from one outlet
  const brandAvailableFulfilmentTypes =
    configQuery?.config?.brandAvailableFulfilmentTypes;
  const outlets = addressesAndOutlets?.outlets;
  const brandIsOnlyAvailableForPickup =
    brandAvailableFulfilmentTypes?.length === 1 &&
    brandAvailableFulfilmentTypes?.includes(constants.FULFILMENT_TYPES.PICKUP);
  const hasOnlyOneOutletToPickupFrom = outlets?.length === 1;

  useEffect(() => {
    if (brandIsOnlyAvailableForPickup && hasOnlyOneOutletToPickupFrom) {
      setFulfilmentType({
        variables: {
          fulfilmentType: constants.FULFILMENT_TYPES.PICKUP,
          outletId: outlets[0].id,
        },
      });
    }
  }, [addressesAndOutlets]);

  const [setFulfilmentType, { loading: setFulfilmentTypeLoading }] =
    useMutation(
      useOptimisedDinerCartType
        ? SET_FULFILMENT_TYPE_OPTIMISED_MUTATION
        : SET_FULFILMENT_TYPE_MUTATION,
      {
        context: { graph: "diners" },
        errorPolicy: "all",
        cachePolicy: "no-cache",
        update: (cache, { data }) => {
          const updatedCartData = useOptimisedDinerCartType
            ? data?.setFulfilmentTypeOptimised
            : data?.setFulfilmentType;

          cache.modify({
            id: cache.identify(cartQuery?.cart),
            fields: {
              outlet() {
                return updatedCartData?.outlet;
              },
              outletId() {
                return updatedCartData?.outletId;
              },
              address() {
                return updatedCartData?.address;
              },
              timeslot() {
                return updatedCartData?.timeslot;
              },
              fulfilmentType() {
                return updatedCartData?.fulfilmentType;
              },
              // update payment breakdown to reflect change in delivery fee
              paymentBreakdown() {
                return updatedCartData?.paymentBreakdown;
              },
            },
          });

          getCart();

          if (updatedCartData?.removedItems) {
            emitEvent("showRemovedItemsModal", updatedCartData?.removedItems);
          }
        },
      },
    );

  function getOutletsFulfilmentTypeStates() {
    if (selectedOutletId === undefined) {
      setDeferGetOutletFulfilmentTypeStates(true);
      return;
    }

    if (selectedOutletId) {
      getSelectedOutletFulfilmentTypeStates({
        variables: {
          outletId: selectedOutletId,
          includeSelectedOutlet: true,
        },
      });
      getOtherOutletsFulfilmentTypeStates({
        variables: {
          outletId: selectedOutletId,
          includeSelectedOutlet: false,
        },
      });
    } else {
      // get fulfilment states of all outlets if no outlet selected yet
      getSelectedOutletFulfilmentTypeStates();
    }
  }

  function refetchOutletsFulfilmentTypeStates(variables) {
    if (selectedOutletId) {
      refetchSelectedOutletFulfilmentTypeStates({
        ...variables,
        outletId: selectedOutletId,
        includeSelectedOutlet: true,
      });
      refetchOtherOutletsFulfilmentTypeStates({
        ...variables,
        outletId: selectedOutletId,
        includeSelectedOutlet: false,
      });
    } else {
      // get fulfilment states of all outlets if no outlet selected yet
      refetchSelectedOutletFulfilmentTypeStates(variables);
    }
  }

  function findOutletFulfilmentTypeStates(outlet) {
    let matchingOutlet;
    [
      selectedOutletFulfilmentTypeStates,
      previousSelectedOutletFulfilmentTypeStates,
      otherOutletsFulfilmentTypeStates,
      previousOtherOutletsFulfilmentTypeStates,
    ].some((outletsFulfilmentTypeStates) => {
      matchingOutlet = outletsFulfilmentTypeStates?.outlets?.find(
        (o) => o.id === outlet.id,
      );
      return matchingOutlet;
    });
    return matchingOutlet;
  }

  return (
    <AppProvider>
      <ConfigProvider
        value={{
          configQuery,
          configLoading,
          pathConfig,
          outletSlug,
          setOutletSlug,
          outletQrHash,
          setOutletQrHash,
          tableQrHash,
          setTableQrHash,
        }}
      >
        <CartProvider
          value={{
            addressesAndOutlets: {
              ...addressesAndOutlets,
              outlets: addressesAndOutlets?.outlets?.map((outlet) => {
                const outletWithFulfilmentTypeStates =
                  findOutletFulfilmentTypeStates(outlet);
                if (outletWithFulfilmentTypeStates) {
                  return {
                    ...outlet,
                    ...outletWithFulfilmentTypeStates,
                  };
                }
                return outlet;
              }),
            },
            refetchAddressAndOutlets: (variables) => {
              refetchAddressAndOutlets(variables);
              refetchOutletsFulfilmentTypeStates(variables);
            },
            getAddressesAndOutlets: () => {
              getAddressesAndOutlets();
              getOtherOutletsFulfilmentTypeStates();
            },
            addressAndOutletsLoading:
              addressAndOutletsLoading || setFulfilmentTypeLoading,
            selectedOutletFulfilmentTypeStatesLoading,
            otherOutletsFulfilmentTypeStatesLoading,
            cartQuery,
            cartLoading,
            cartError,
            refetchCart,
            cartValidations,
            brandIsOnlyAvailableForPickup,
            hasOnlyOneOutletToPickupFrom,
          }}
        >
          {children}
        </CartProvider>
      </ConfigProvider>
    </AppProvider>
  );
}
