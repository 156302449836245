// Utils
import { formatValueToStandardUnit } from "@/utils/formatPrice";

const itemToFbqItem = (item) => {
  return {
    id: item?.item?.id || item?.itemId,
    name: item?.name,
    price: formatValueToStandardUnit(
      (item?.subtotal || item?.price) / item?.quantity,
    ),
    quantity: item?.quantity,
  };
};

const itemsToFbqArray = (items) => {
  return items?.map(itemToFbqItem);
};

const ItemTypeOptimisedToFbqItem = (item) => {
  return {
    id: item?.id,
    name: item?.label,
    price: formatValueToStandardUnit(item?.unitPriceFractional),
  };
};

// Events
const signUp = () => {
  const fbqPayload = { content_name: "Atlas Online" };
  window.fbq("track", "CompleteRegistration", fbqPayload);
};

const login = () => {
  const fbqPayload = { content_name: "Atlas Online" };
  window.fbq("track", "Login", fbqPayload);
};

// Takes in ItemTypeOptimised
const addToCart = ({ value, item }) => {
  const fbqPayload = {
    value: formatValueToStandardUnit(value),
    currency: item?.currency || "SGD",
    contents: [ItemTypeOptimisedToFbqItem(item)],
    product_catalog_id: item?.id,
  };
  window.fbq("track", "AddToCart", fbqPayload);
};

// Takes in cartOptimised.topLevelItems as payload
const viewCart = (payload) => {
  const fbqPayload = {
    value: formatValueToStandardUnit(
      payload.reduce((total, item) => total + item.subtotal, 0),
    ),
    currency: "SGD",
    contents: itemsToFbqArray(payload),
  };
  window.fbq("track", "ViewContent", fbqPayload);
};

const removeFromCart = ({ value, item }) => {
  const fbqPayload = {
    value: formatValueToStandardUnit(Math.abs(value)),
    currency: item?.currency || "SGD",
    contents: [ItemTypeOptimisedToFbqItem(item)],
    product_catalog_id: item?.id,
  };
  window.fbq("track", "RemoveFromCart", fbqPayload);
};

const beginCheckout = (payload) => {
  const fbqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    num_items: payload.topLevelItems.length,
    contents: itemsToFbqArray(payload.topLevelItems),
  };
  window.fbq("track", "InitiateCheckout", fbqPayload);
};

const addPaymentInfo = (payload) => {
  const fbqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    contents: itemsToFbqArray(payload.topLevelItems),
  };
  window.fbq("track", "AddPaymentInfo", fbqPayload);
};

const purchase = (payload) => {
  const fbqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload?.paymentBreakdown?.subtotal),
    transaction_id: payload?.identifier,
    contents: itemsToFbqArray(payload?.orderItems),
    email: payload?.contactEmail,
    quantity: (payload.orderItems || []).reduce(
      (sum, item) => sum + (item?.quantity || 1),
      0,
    ),
  };
  window.fbq("track", "Purchase", fbqPayload);
};

// router to events
const track = (eventName, data) => {
  if (typeof fbq[eventName] === "function") {
    fbq[eventName](data);
  }
};

const fbq = {
  signUp,
  login,
  addToCart,
  viewCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  purchase,
  track,
};

export default fbq;
