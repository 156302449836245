import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RadioGroup from "@/common/RadioGroup";
import Spin from "@/common/Spin";
import constants from "@/utils/constants";

OutletSelector.propTypes = {
  visible: PropTypes.bool,
  fulfilmentType: PropTypes.string,
  loading: PropTypes.bool,
  fulfilmentTypeStatesLoading: PropTypes.bool,
  availableOutlets: PropTypes.arrayOf(PropTypes.object),
  selectedOutlet: PropTypes.object,
  setSelectedOutlet: PropTypes.func,
  isDelivery: PropTypes.bool,
};

export default function OutletSelector({
  visible,
  fulfilmentType,
  loading,
  fulfilmentTypeStatesLoading,
  availableOutlets,
  selectedOutlet,
  setSelectedOutlet,
  isDelivery,
}) {
  // Setup
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <>
      {fulfilmentType === constants.FULFILMENT_TYPES.DELIVERY && (
        <div className="flex justify-between pt-4 mb-4 font-bold text-body">
          {t("account.addresses.selectOutletHeader")}
          <Spin
            spinning={loading || fulfilmentTypeStatesLoading}
            logoClassName="w-5 h-5"
          />
        </div>
      )}
      <Spin
        spinning={
          fulfilmentType !== constants.FULFILMENT_TYPES.DELIVERY &&
          (loading || fulfilmentTypeStatesLoading)
        }
        logoClassName="w-6 h-6"
      >
        {availableOutlets.length > 0 && (
          <RadioGroup
            options={availableOutlets}
            selected={selectedOutlet}
            setSelected={setSelectedOutlet}
            matchById={true}
            renderLabel={(outlet) => {
              const fulfilmentTypeState =
                outlet?.fulfilmentTypeStates?.[
                  isDelivery
                    ? constants.FULFILMENT_TYPES.DELIVERY
                    : constants.FULFILMENT_TYPES.PICKUP
                ];
              return (
                <div className="flex justify-between">
                  <div className="flex flex-col flex-grow">
                    <span>{outlet.labelForPickup}</span>
                    {(fulfilmentTypeState?.closed ||
                      (isDelivery && fulfilmentTypeState?.notAvailable)) && (
                      <span className="text-xs">
                        {fulfilmentTypeState?.warning}
                      </span>
                    )}
                  </div>
                  {/* Don't need to wait for fulfilmentTypeStatesLoading to render distance */}
                  {isDelivery &&
                    !loading &&
                    !isNaN(outlet.distanceFromAddress) && (
                      <span className="opacity-50 text-default">
                        {t("account.addresses.distanceFromAddress", {
                          distance: (outlet.distanceFromAddress / 1000).toFixed(
                            1,
                          ),
                        })}
                      </span>
                    )}
                </div>
              );
            }}
          />
        )}
      </Spin>
    </>
  );
}
