import { formatValueToStandardUnit } from "@/utils/formatPrice";

// Events
const signUp = () => {
  const ttqPayload = { content_name: "Atlas Online" };
  window.ttq.track("CompleteRegistration", ttqPayload);
};

const login = () => {
  const ttqPayload = { content_name: "Atlas Online" };
  window.ttq.track("Login", ttqPayload);
};

// Takes in ItemTypeOptimised
const addToCart = ({ value, item }) => {
  const ttqPayload = {
    currency: item?.currency || "SGD",
    value: formatValueToStandardUnit(value),
    content_type: "product",
    content_ids: [item?.id],
  };
  window.ttq.track("AddToCart", ttqPayload);
};

// Takes in cartOptimised.topLevelItems as payload
const viewCart = (payload) => {
  const ttqPayload = {
    currency: "SGD",
    value: formatValueToStandardUnit(
      payload.reduce((total, item) => total + item.subtotal, 0),
    ),
    content_ids: payload.map((item) => item?.item?.id),
  };
  window.ttq.track("ViewCart", ttqPayload);
};

const removeFromCart = ({ value, item }) => {
  const ttqPayload = {
    currency: item?.currency || "SGD",
    value: formatValueToStandardUnit(Math.abs(value)),
    content_ids: [item?.id],
  };
  window.ttq.track("RemoveFromCart", ttqPayload);
};

const beginCheckout = (payload) => {
  const ttqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    num_items: payload.topLevelItems.length,
    content_ids: payload.topLevelItems.map((item) => item?.item?.id),
  };
  window.ttq.track("InitiateCheckout", ttqPayload);
};

const addPaymentInfo = (payload) => {
  const ttqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    content_ids: payload.topLevelItems.map((item) => item?.item?.id),
  };
  window.ttq.track("AddPaymentInfo", ttqPayload);
};

const purchase = (payload) => {
  const ttqPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload?.paymentBreakdown?.subtotal),
    content_type: "product",
    transaction_id: payload?.identifier,
    content_ids: payload?.orderItems.map((item) => item?.itemId),
    email: payload?.contactEmail,
    quantity: (payload.orderItems || []).map((item) => item?.quantity),
  };
  window.ttq.track("CompletePayment", ttqPayload);
};
// router to events
const track = (eventName, data) => {
  if (typeof ttq[eventName] === "function") {
    ttq[eventName](data);
  }
};

const ttq = {
  signUp,
  login,
  addToCart,
  viewCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  purchase,
  track,
};

export default ttq;
