import { gql } from "@apollo/client";

export const GET_ORDER = gql`
  query GetOrder($id: Int!) {
    getOrder(id: $id) {
      id
      state
      courierName
      courierNumber
      trackingUrl
      logisticsType
      fulfilmentState
    }
  }
`;
