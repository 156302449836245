import React, { useEffect, useState, useContext, useRef } from "react";

import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/solid";

import CartContext from "@/components/Cart/cartContext";
import MenuContext from "@/components/Menu/menuContext";
import { GET_ACTIVE_ANNOUNCEMENT_QUERY } from "@/graphql/queries/getActiveAnnouncement";
import getUrlParams from "@/utils/getUrlParams";

const AnnouncementBanner = () => {
  const bannerRef = useRef();
  const params = getUrlParams(location);
  const isPreviewMode = params.announcementPreview === "true";
  const [activeAnnouncement, setActiveAnnouncement] = useState();
  const { cartQuery } = useContext(CartContext);
  const { setBannerHeight } = useContext(MenuContext);

  const { data: activeAnnouncementData } = useQuery(
    GET_ACTIVE_ANNOUNCEMENT_QUERY,
    {
      skip: isPreviewMode,
      context: { graph: "diners" },
      variables: {
        outletId: cartQuery?.cart?.outletId,
      },
    },
  );

  useEffect(() => {
    const updateBannerHeight = () => {
      const height = bannerRef?.current?.offsetHeight;
      if (height) {
        setBannerHeight?.(height);
      }
    };

    updateBannerHeight();

    let timeoutId = null;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(updateBannerHeight, 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeAnnouncement, setBannerHeight]);

  useEffect(() => {
    if (activeAnnouncementData?.activeAnnouncement?.id && !isPreviewMode) {
      setActiveAnnouncement(activeAnnouncementData.activeAnnouncement);
    } else {
      setActiveAnnouncement(null);
    }
  }, [activeAnnouncementData?.activeAnnouncement, isPreviewMode]);

  useEffect(() => {
    const handler = (event) => {
      const data = JSON.parse(event.data);
      if (data.messageType === "announcement") {
        setActiveAnnouncement(data);
      }
    };

    if (isPreviewMode) {
      window.addEventListener("message", handler);
    }

    return () => window.removeEventListener("message", handler);
  }, [isPreviewMode]);

  const handleDismissAnnouncement = (e) => {
    e.preventDefault();
    if (!isPreviewMode) {
      setActiveAnnouncement(null);
    }
  };

  return (
    !!activeAnnouncement && (
      <a href={activeAnnouncement?.linkUrl} ref={bannerRef}>
        <div
          className="text-center py-2 relative"
          style={{
            backgroundColor: activeAnnouncement?.backgroundColor || "black",
            color: activeAnnouncement?.textColor || "white",
          }}
        >
          <span className="font-semibold">
            {activeAnnouncement?.title || "Enter title here."}
          </span>{" "}
          {activeAnnouncement?.message || "Enter message here."}
          {activeAnnouncement?.isDismissable && (
            <span
              className="absolute right-1 cursor-pointer"
              onClick={handleDismissAnnouncement}
            >
              <XIcon className="w-6 h-6" />
            </span>
          )}
        </div>
      </a>
    )
  );
};

export default AnnouncementBanner;
