import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

Tabs.propTypes = {
  /** Each tab option should have `id`, `label`, and `rest`.
   * <br/>
   * <br/>
   * `id`: used to compare against `selectedTab`. It will also be the `id` property of the tab element.
   * <br/>
   * `label`: what the tab will display.
   * <br/>
   * `rest`: any other properties or events that will be passed on to the tab instance. */
  options: PropTypes.arrayOf(PropTypes.object),
  /** Used to set the selected tab (compares `id`) */
  selectedTab: PropTypes.string,
  className: PropTypes.string,
  navClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  selectedTabClassName: PropTypes.string,
};

export default function Tabs({
  options,
  selectedTab,
  className,
  navClassName,
  tabClassName,
  selectedTabClassName,
}) {
  return (
    <div className={className}>
      <div className="border-b border-default">
        <nav
          className={navClassName ?? "flex -mb-px space-x-8"}
          aria-label="Tabs"
        >
          {options.map((option) => {
            const { label, className, ...props } = option;
            return (
              <a
                key={`tab-${label}`}
                className={classNames(
                  "cursor-pointer whitespace-nowrap font-medium text-sm text-default hover:text-default",
                  option.id === selectedTab
                    ? selectedTabClassName ??
                        "px-1 py-2 border-b-2 border-primary"
                    : tabClassName ??
                        "px-1 py-2 border-b-2 border-transparent opacity-40 hover:border-default",
                  className,
                )}
                {...props}
              >
                {label}
              </a>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
