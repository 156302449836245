import React from "react";

import PropTypes from "prop-types";

import Banner from "@/components/Banner";

import DynamicColumnsTemplate from "./DynamicColumnsTemplate";
import NutritionCalculator from "./NutritionCalculator";
import OneColumnTemplate from "./OneColumnTemplate";
import TwoColumnsTemplate from "./TwoColumnsTemplate";

export const TEMPLATE_TYPES = {
  BANNER: "banner",
  ONE_COLUMN: "one-column",
  TWO_COLUMNS: "two-columns",
  DYNAMIC_COLUMNS: "dynamic-columns",
  BESTSELLERS: "bestsellers",
  NUTRITION_CALCULATOR: "nutrition-calculator",
};

PageTemplateSections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  getSectionRef: PropTypes.func,
};

export default function PageTemplateSections({ sections, getSectionRef }) {
  return sections.map((section, index) => {
    switch (section.type) {
      case TEMPLATE_TYPES.BANNER:
        return (
          <>
            <Banner key={`section_${index}`} data={section.banner} />
          </>
        );
      case TEMPLATE_TYPES.ONE_COLUMN:
        return (
          <OneColumnTemplate
            key={`section_${index}`}
            section={section}
            index={index}
          />
        );
      case TEMPLATE_TYPES.TWO_COLUMNS:
        return (
          <TwoColumnsTemplate
            key={`section_${index}`}
            section={section}
            index={index}
          />
        );
      case TEMPLATE_TYPES.DYNAMIC_COLUMNS:
      case TEMPLATE_TYPES.BESTSELLERS:
        return (
          <DynamicColumnsTemplate
            key={`section_${index}`}
            sectionRef={getSectionRef ? getSectionRef(section) : null}
            section={section}
            index={index}
          />
        );
      case TEMPLATE_TYPES.NUTRITION_CALCULATOR:
        return (
          <NutritionCalculator key={`section_${index}`} section={section} />
        );
    }
  });
}
