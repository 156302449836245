import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "@/common/Modal";

BlockModal.propTypes = {
  isBeforeOpeningHours: PropTypes.bool,
  nextOpeningTime: PropTypes.string,
};

export default function BlockModal({ isBeforeOpeningHours, nextOpeningTime }) {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      closable={false}
      keyboard={false}
      visible={true}
      backdrop="static"
      footer=""
    >
      <div className="p-4 text-center">
        <div className="mb-4">
          <strong>
            {isBeforeOpeningHours
              ? t("block.willOpenAtNextOpeningHours.header")
              : t("block.outletCurrentlyClosed.header")}
          </strong>
        </div>
        <div>
          {isBeforeOpeningHours
            ? t("block.willOpenAtNextOpeningHours.body", {
                openingTime: nextOpeningTime,
              })
            : t("block.outletCurrentlyClosed.body")}
        </div>
      </div>
    </Modal>
  );
}
