import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Image from "@/common/Image";
import RadioGroup from "@/common/RadioGroup";
import { PAYMENT_TYPES } from "@/utils/constants";

import AdyenCheckoutModal from "./AdyenCheckoutModal";
import StripeCheckout from "./StripeCheckout";
import payNowLogo from "./assets/paynow-logo.png";

CartPaymentMethods.propTypes = {
  cart: PropTypes.object,
  checkoutParams: PropTypes.object,
  validateCheckout: PropTypes.func,
  onPaymentRequestPaymentMethod: PropTypes.func,
  onOrderProcessing: PropTypes.func,
};

export default function CartPaymentMethods({
  cart,
  checkoutParams,
  validateCheckout,
  onPaymentRequestPaymentMethod,
  onOrderProcessing,
}) {
  const { t } = useTranslation();

  // TODO: handle multiple payment methods
  const paymentMethod = cart.paymentMethods[0];

  const [payNowOption] = useState({
    label: t(`menu.cart.checkout.labels.payment.${PAYMENT_TYPES.PAYNOW}.label`),
    body: t(`menu.cart.checkout.labels.payment.${PAYMENT_TYPES.PAYNOW}.body`, {
      uenOrMobile:
        paymentMethod.paymentUen || paymentMethod.paymentMobileNumber,
      paymentContact: paymentMethod.paymentMobileNumber,
    }),
  });

  switch (paymentMethod?.paymentType) {
    case PAYMENT_TYPES.ADYEN:
      return (
        <div className="payment-method">
          <AdyenCheckoutModal
            checkoutParams={checkoutParams}
            totalIncludingTax={cart.paymentBreakdown.totalIncludingTax}
            onOrderProcessing={onOrderProcessing}
            cartId={cart.id}
            paymentMethod={paymentMethod}
          />
        </div>
      );
    case PAYMENT_TYPES.PAYMENT_INTENT:
      return (
        <div className="payment-method">
          <StripeCheckout
            newCardLabel={t(
              `menu.cart.checkout.labels.payment.${paymentMethod.paymentType}.label`,
            )}
            billingDetails={{
              address: {
                line1: cart.address?.line1,
              },
              name: cart.contactName,
              email: cart.contactEmail,
              phone: cart.contactNumber,
            }}
            totalIncludingTax={cart.paymentBreakdown.totalIncludingTax}
            country="SG"
            currency="sgd"
            totalLabel={t("menu.cart.checkout.stripeTotal")}
            onOrderProcessing={onOrderProcessing}
            stripeAccountId={paymentMethod?.accountId}
            cartId={cart.id}
            validateCheckout={validateCheckout}
            onPaymentRequestPaymentMethod={onPaymentRequestPaymentMethod}
          />
        </div>
      );
    case PAYMENT_TYPES.PAYNOW:
      return (
        <div className="payment-method">
          <RadioGroup
            options={[payNowOption]}
            selected={payNowOption}
            setSelected={() => {}}
            renderLabel={(option) => (
              <div>
                <div className="flex">
                  {option.label}
                  <Image
                    style={{ width: 75, height: 20 }}
                    className="p-1"
                    src={payNowLogo}
                  />
                </div>
                <div
                  className="mt-1 text-xs text-default"
                  dangerouslySetInnerHTML={{ __html: option.body }}
                />
              </div>
            )}
          />
        </div>
      );
  }
}
