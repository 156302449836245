import React, { useContext, useEffect, useRef } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import LayoutContext from "@/common/Layout/layoutContext";
import constants, { LANGUAGES } from "@/utils/constants";
import useOutsideClick from "@/utils/useOutsideClick";

import EnBadge from "./badges/EnBadge";
import ZhBadge from "./badges/ZhBadge";

const LANGUAGE_BADGES = {
  en: EnBadge,
  zh: ZhBadge,
};

LanguageSelector.propTypes = {
  mobile: PropTypes.bool,
  headerRef: PropTypes.object,
};

export default function LanguageSelector({ mobile, headerRef }) {
  const { i18n } = useTranslation();
  const { layoutState, setLayoutState } = useContext(LayoutContext);
  const container = useRef(null);

  useOutsideClick(container, () => {
    if (!layoutState.languageSelectorOpened) return;
    setLayoutState((layoutState) => ({
      ...layoutState,
      languageSelectorOpened: false,
      isMobileMenuOpen: false,
    }));
  });

  useEffect(() => {
    const currentLanguage = localStorage.getItem(constants.CURRENT_LANGUAGE);

    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
    }
  }, []);

  const headerHeight = headerRef?.current?.offsetHeight || undefined;

  return (
    <>
      <div
        ref={container}
        id="languageSelector"
        className={classNames({
          "text-on-transparent-header": layoutState.transparent,
          "text-on-header": !layoutState.transparent,
          "lg:hidden": mobile,
          "hidden lg:block": !mobile,
        })}
        onClick={() =>
          setLayoutState((layoutState) => ({
            ...layoutState,
            languageSelectorOpened: !layoutState.languageSelectorOpened,
            accountDropdownVisible: false,
            isMobileMenuOpen: false,
          }))
        }
      >
        {typeof LANGUAGE_BADGES[i18n.resolvedLanguage] !== "undefined"
          ? React.createElement(LANGUAGE_BADGES[i18n.resolvedLanguage], {
              fill: "var(--color-primary)",
              containerStyle: {
                backgroundColor: "var(--color-text-on-primary)",
              },
            })
          : null}
        {layoutState.languageSelectorOpened && (
          <div
            className={classNames("absolute", {
              "right-0 left-0 z-20 w-full": mobile,
              "mt-[20px] ml-[-168px]": !mobile,
            })}
            style={{
              top: headerHeight,
            }}
          >
            <div
              className={classNames("bg-header", {
                "w-48": !mobile,
                "px-6 py-3 space-y-3": mobile,
              })}
            >
              {Object.keys(LANGUAGES).map((language) => {
                // if (i18n.resolvedLanguage === language) return null;

                return (
                  <div
                    key={language}
                    className={classNames(
                      "px-4 py-2 cursor-pointer text-on-header hover:text-on-header2 hover:font-bold",
                      {
                        "py-1 pl-3 border-l-2 border-transparent hover:border-primary":
                          mobile,
                      },
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      i18n.changeLanguage(language);
                      setLayoutState((layoutState) => ({
                        ...layoutState,
                        language,
                        languageSelectorOpened:
                          !layoutState.languageSelectorOpened,
                      }));
                      localStorage.setItem(
                        constants.CURRENT_LANGUAGE,
                        language,
                      );
                    }}
                  >
                    {LANGUAGES[language].nativeName}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
