import { useEffect } from "react";

import hmacsha256 from "crypto-js/hmac-sha256";

import { importScript } from "@/utils/importScript";
import { importStyle } from "@/utils/importStyle";

const useEberWidget = ({ configQuery, profileData }) => {
  // Load Eber Widget
  useEffect(() => {
    if (configQuery) {
      const app = configQuery?.config?.scripts[0];
      if (app) {
        try {
          // check if Eber already loaded
          // 'eber' is declared when importing script
          // copied from imported script
          const eberWidgetButton = document.getElementById(
            "eber-btn-open-widget",
          );
          // prevent redrawing if eber widget already exists
          if (!eberWidgetButton) {
            eber.drawWidgetContainer(); // eslint-disable-line no-undef

            setTimeout(() => {
              moveEberElements();
            }, 100);
          }
        } catch (e) {
          // Eber not loaded yet
          const url = app?.config?.url;
          const hmac = app?.config?.hmac;
          const businessId = app?.config?.business_id;
          const email = profileData?.profile?.email;
          const enchodedEmail = encodeURIComponent(profileData?.profile?.email);
          const hmacHash = hmacsha256(email, hmac).toString();
          const loginURL = encodeURIComponent(
            `${window.location.origin}/login`,
          );
          const signupURL = encodeURIComponent(
            `${window.location.origin}/signup`,
          );

          importScript(
            `${url}/${businessId}?hmac_email=${enchodedEmail}&hmac_hash=${hmacHash}&login_url=${loginURL}&signup=${signupURL}`,
            {
              onComplete: () => {
                // check for eber widget script
                if (url.match(/\beber.co\b/)) {
                  setTimeout(() => {
                    moveEberElements();
                  }, 1000);
                }
              },
            },
          );
        }
      }
    }
  }, [configQuery]);

  // Re-initialise Eber Widget
  useEffect(() => {
    const app = configQuery?.config?.scripts[0];
    if (app && window.EberVars) {
      const email = profileData?.profile?.email;
      const enchodedEmail = encodeURIComponent(profileData?.profile?.email);
      if (email) {
        const hmac = app?.config?.hmac;
        window.EberVars.hmacEmail = enchodedEmail;
        window.EberVars.hmacHash = hmacsha256(email, hmac).toString();
        if (typeof window.eberReInit === "function") {
          window.eberReInit();
        }
      }
    }
  }, [profileData?.profile?.email]);
};

function moveEberElements() {
  // manipulate z-index of eber widget to not block ordering interaction
  const mainContainer = document.querySelector("main");
  const firstChild = mainContainer?.firstElementChild;
  const eberContainer = document.querySelector(".eber-container");
  const eberWidgetButton = document.getElementById("eber-btn-open-widget");

  if (mainContainer && firstChild && eberContainer && eberWidgetButton) {
    // move eber elements inside main container
    [eberContainer, eberWidgetButton].forEach((eberElement) => {
      mainContainer.insertBefore(eberElement, firstChild);
    });
    // z-index higher than scroller but lower than cart
    // change height of eber main frame to take header height into account
    importStyle(
      `.eber-container {
        z-index: 42 !important;
      }
      #eber-btn-open-widget {
        z-index: 41 !important;
      }
      .eber-main-frame {
        @media (min-width: 420.1px) {
          height: calc(100% - ${225 + 64 + 8}px) !important;
          top: ${64 + 8}px !important;
        }
      }`,
      document.body,
    );
  }
}

export default useEberWidget;
