export function importScript(src, options = {}) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = src;

  if (options.onComplete) {
    script.onload = options.onComplete;
  }
  document.head.appendChild(script);
}
