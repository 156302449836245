import React from "react";

import PropTypes from "prop-types";

Button.propTypes = {
  /** `primary`, `danger`, or `default`. */
  type: PropTypes.string,
  /** `xs`, `sm`, `md`, `lg`, or `xl`. */
  size: PropTypes.string,
  /** The HTML type property for a button, usually either `button` or `submit`. */
  htmlType: PropTypes.string,
  /** The HTML disabled property for a button */
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Leading icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  leftIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  /** Trailing icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  rightIcon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  /** Any other properties or events that will be passed on to the button instance. */
  rest: PropTypes.any,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  type: "default",
  size: "md",
  htmlType: "button",
  disabled: false,
  fullWidth: false,
};

export default function Button({
  type,
  size,
  htmlType,
  disabled,
  leftIcon,
  rightIcon,
  children,
  className,
  fullWidth,
  ...rest
}) {
  let buttonKlass =
    "inline-flex items-center justify-center font-semibold break-keep shadow-sm border focus:outline-none rounded-sm";
  switch (type) {
    case "primary":
      buttonKlass +=
        " border-button-primary text-on-primary bg-primary hover:bg-primary-dark";
      break;
    case "primary-outline":
      buttonKlass +=
        " border-primary text-primary bg-transparent hover:bg-primary-dark hover:text-on-primary hover:border-button-primary";
      break;
    case "danger":
      buttonKlass +=
        " border-button-danger text-on-danger bg-danger hover:bg-danger-dark";
      break;
    case "success":
      buttonKlass +=
        " border-button-success text-on-success bg-success hover:bg-success-dark";
      break;
    case "default":
      buttonKlass +=
        " border-button-default text-on-button-default bg-button-default hover:bg-button-default-dark";
      break;
    case "quantityControl":
      buttonKlass += " border-default text-default bg-default";
      break;
    case "dark":
      buttonKlass += " bg-neutral-900 text-white text-xl hover:bg-neutral-800";
  }

  if (fullWidth) {
    buttonKlass += " block w-full";
  }

  switch (size) {
    case "xs":
      buttonKlass += " px-2.5 py-1.5 text-xs";
      break;
    case "sm":
      buttonKlass += " px-3 py-2 text-sm leading-4";
      break;
    case "md":
      buttonKlass += " px-4 py-2 text-sm";
      break;
    case "lg":
      buttonKlass += " px-4 py-2 text-body";
      break;
    case "xl":
      buttonKlass += " px-6 py-3 text-body";
      break;
  }

  if (disabled) {
    buttonKlass += " opacity-50";
  }

  if (className) {
    buttonKlass += ` ${className}`;
  }

  let iconKlass = "";
  if (leftIcon || rightIcon) {
    switch (size) {
      case "xs":
      case "sm":
        if (leftIcon) {
          iconKlass += "-ml-0.5 mr-2 h-4 w-4";
        } else if (rightIcon) {
          iconKlass += "ml-2 -mr-0.5 h-4 w-4";
        }
        break;
      case "md":
        if (leftIcon) {
          iconKlass += "-ml-1 mr-2 h-5 w-5";
        } else if (rightIcon) {
          iconKlass += "ml-2 -mr-1 h-5 w-5";
        }
        break;
      case "lg":
      case "xl":
        if (leftIcon) {
          iconKlass += "-ml-1 mr-3 h-5 w-5";
        } else if (rightIcon) {
          iconKlass += "ml-3 -mr-1 h-5 w-5";
        }
        break;
    }
  }

  return (
    <button
      type={htmlType}
      className={buttonKlass}
      disabled={disabled}
      data-testid="button"
      {...rest}
    >
      {leftIcon && <div className={iconKlass}>{leftIcon}</div>}
      {children}
      {rightIcon && <div className={iconKlass}>{rightIcon}</div>}
    </button>
  );
}
