import { gql } from "@apollo/client";

export const PROFILE_QUERY = gql`
  query GetProfile {
    profile {
      name
      email
      mobileNumber
      isMobileVerified
      referralCode
      title
      dateOfBirth
      marketingConsent
      metadata
    }
  }
`;

export const POINT_BALANCE_WITH_PROFILE_QUERY = gql`
  query GetPointBalanceWithProfile {
    profile {
      name
      email
    }
    pointBalance {
      points
      value
    }
  }
`;
