import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  STRIPE_PAYMENT_INTENT_SUCCESS,
  STRIPE_PAYMENT_INTENT_REQUIRES_ACTION,
} from "@/utils/constants";
import { emitEvent, useListenEvent } from "@/utils/eventBus";

import StripeNewCard from "./StripeNewCard";

StripeCheckout.propTypes = {
  newCardLabel: PropTypes.string,
  billingDetails: PropTypes.shape({
    address: PropTypes.shape({
      line1: PropTypes.string,
    }),
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  totalIncludingTax: PropTypes.number,
  country: PropTypes.string,
  currency: PropTypes.string,
  totalLabel: PropTypes.string,
  onOrderProcessing: PropTypes.func,
  stripeAccountId: PropTypes.string,
  validateCheckout: PropTypes.func,
  onPaymentRequestPaymentMethod: PropTypes.func,
};

export default function StripeCheckout({
  newCardLabel,
  billingDetails,
  totalIncludingTax,
  country,
  currency,
  totalLabel,
  onOrderProcessing,
  stripeAccountId,
  validateCheckout,
  onPaymentRequestPaymentMethod,
}) {
  const [stripeNewCardElement, setStripeCardElement] = useState(null);
  const [stripeError, setStripeError] = useState(null);
  const { t } = useTranslation();

  useListenEvent(
    "startPayment",
    (options) => {
      setStripeError(null);
      emitEvent("stripeLoading", true);
      if (options?.paymentRequest) return;
      if (options?.clientSecret) {
        confirmCardPayment(options?.clientSecret, options?.paymentRequestEvent);
        return;
      }
    },
    [stripeNewCardElement],
  );

  async function confirmCardPayment(clientSecret, paymentRequestEvent) {
    if (clientSecret) {
      let paymentMethod;
      let handleActions = true;
      if (paymentRequestEvent?.paymentMethod?.id) {
        paymentMethod = paymentRequestEvent.paymentMethod.id;
        handleActions = false;
      } else {
        paymentMethod = {
          card: stripeNewCardElement,
          billing_details: {
            ...billingDetails,
          },
        };
      }

      const result = await window.stripe.confirmCardPayment(
        clientSecret,
        { payment_method: paymentMethod },
        { handleActions },
      );
      // https://stripe.com/docs/api/payment_intents/object
      if (result?.error) {
        paymentRequestEvent?.complete("fail");

        const error = result?.error?.message || t("stripe.error");
        setStripeError(error);
        emitEvent("stripeLoading", false);
      } else {
        paymentRequestEvent?.complete("success");

        // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
        // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow
        if (
          result?.paymentIntent?.status ===
          STRIPE_PAYMENT_INTENT_REQUIRES_ACTION
        ) {
          // Let Stripe.js handle the rest of the payment flow.
          const paymentResult = await window.stripe.confirmCardPayment(
            clientSecret,
          );
          if (paymentResult?.error) {
            // payment failed -- ask your customer for a new payment method
            setStripeError(paymentResult.error.message);
          } else {
            onPaymentSuccess(paymentResult);
          }
        } else if (
          result?.paymentIntent?.status === STRIPE_PAYMENT_INTENT_SUCCESS
        ) {
          onPaymentSuccess(result);
        } else if (result?.paymentIntent) {
          setStripeError(t("stripe.error"));
        }
      }
    }
  }

  function onPaymentSuccess(result) {
    onOrderProcessing({
      status: result.paymentIntent.status,
      paymentIntentId: result.paymentIntent.id,
    });
  }

  return (
    <div className="stripe-checkout">
      <StripeNewCard
        newCardLabel={newCardLabel}
        stripeNewCardElement={stripeNewCardElement}
        setStripeCardElement={setStripeCardElement}
        stripeAccountId={stripeAccountId}
        country={country}
        currency={currency}
        label={totalLabel}
        amount={totalIncludingTax}
        validateCheckout={validateCheckout}
        onPaymentRequestPaymentMethod={onPaymentRequestPaymentMethod}
      />
      {stripeError && (
        <div className="font-bold text-danger">{stripeError}</div>
      )}
    </div>
  );
}
