import { events } from "@/lib/analytics/events";

const formatProduct = (properties) => ({
  description: properties?.name,
  content_id: properties?.sku,
  value: properties?.price,
  quantity: properties?.quantity,
  currency: properties?.currency,
});

export default function tikTokEvents(event, properties) {
  switch (event) {
    case events.product_added:
      return {
        event: "AddToCart",
        properties: formatProduct(properties),
      };
    case events.checkout_started:
      return {
        event: "InitiateCheckout",
      };
    case events.product_viewed:
      return {
        event: "ViewContent",
        properties: formatProduct(properties),
      };
    case events.order_completed:
      return {
        event: "PlaceAnOrder",
        properties: {
          value: properties?.value,
          currency: properties?.currency,
        },
      };
    default:
      return {
        event,
        properties,
      };
  }
}
