import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import { emitEvent } from "@/utils/eventBus";

PreOrderModal.propTypes = {
  humanizedLeadTime: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default function PreOrderModal({ humanizedLeadTime, closeModal }) {
  const { t } = useTranslation();

  return (
    <Modal
      visible={!!humanizedLeadTime}
      onCancel={closeModal}
      title={t("menu.preOrder.header")}
      footer={
        <div className="grid gap-3 mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            type="primary"
            onClick={() => {
              emitEvent("selectTimeslot");
              closeModal();
            }}
          >
            {t("menu.preOrder.selectAnotherDate")}
          </Button>
        </div>
      }
    >
      {t("menu.preOrder.body", {
        time: humanizedLeadTime,
      })}
    </Modal>
  );
}
