import { gql } from "@apollo/client";

import { PAYMENT_BREAKDOWN_FIELDS } from "./getCart";
import { ORDER_FIELDS } from "./getOrderHistory";

export const GET_CART_ORDER_QUERY = gql`
  query GetCartOrder($cartId: Int!) {
    getCartOrder(cartId: $cartId) {
      ...OrderFields
      pickupTiming
      isAsap
      contactEmail
      promoCode
      confirmationInstructions
      claimTokenUrl
      eligiblePoints
      eligiblePointsValue
      paymentBreakdown {
        ...PaymentBreakdownFields
      }
    }
  }
  ${ORDER_FIELDS}
  ${PAYMENT_BREAKDOWN_FIELDS}
`;

export const GET_CART_ORDER_ID_QUERY = gql`
  query GetCartOrderId($cartId: Int!) {
    getCartOrder(cartId: $cartId) {
      id
    }
  }
`;
