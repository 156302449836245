import React, { useEffect, useContext, useMemo } from "react";

import { useLazyQuery } from "@apollo/client";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import { LOGIN_MODES } from "@/components/Session/SessionForm";
import { CHECK_ACCOUNT_EXISTS_QUERY } from "@/graphql/queries/checkAccountExists";
import constants from "@/utils/constants";

const LoginForm = loadable(() => import("@/components/Login/LoginForm"));
const SignupForm = loadable(() => import("@/components/Signup/SignupForm"));
const SessionForm = loadable(() => import("@/components/Session/SessionForm"));

const ERROR_CODES = {
  EMAIL_TAKEN: "EMAIL_TAKEN",
  MOBILE_NUMBER_TAKEN: "MOBILE_NUMBER_TAKEN",
};

CheckoutSignupModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  defaultName: PropTypes.string,
  defaultMobileNumber: PropTypes.string,
  defaultEmail: PropTypes.string,
  performCheckout: PropTypes.func,
};

export default function CheckoutSignupModal({
  visible,
  defaultName,
  defaultMobileNumber,
  defaultEmail,
  performCheckout,
}) {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);

  const useOtpLoginForOnline = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OTP_LOGIN_FOR_ONLINE,
  );

  const storefrontLabel = useMemo(
    () => configQuery?.currentStorefront?.label ?? "",
    [configQuery?.currentStorefront?.label],
  );

  const uiDefinitions = useMemo(
    () => configQuery?.config?.uiDefinitions || [],
    [configQuery?.config?.uiDefinitions],
  );
  const loginPromptDefinitions = useMemo(
    () =>
      uiDefinitions.filter((definition) => definition.type == "login_prompt"),
    [uiDefinitions],
  );
  const signupPromptDefinitions = useMemo(
    () =>
      uiDefinitions.filter((definition) => definition.type == "signup_prompt"),
    [uiDefinitions],
  );
  const privacyPolicyPrompt = useMemo(
    () =>
      uiDefinitions.filter(
        (definition) => definition.type == "privacy_policy_prompt",
      ),
    [uiDefinitions],
  );

  const pointProgram = configQuery.config?.pointProgram;

  const signupHeader = useMemo(() => {
    if (pointProgram?.signupHeader) {
      return pointProgram?.signupHeader;
    }
    if (signupPromptDefinitions.length > 0) {
      return signupPromptDefinitions
        .map((def) => def.definition.title)
        .join(", ");
    }
    return t("signup.header", {
      context: "aeiou".includes(storefrontLabel[0]?.toLowerCase()) && "vowel",
      storefrontLabel,
    });
  }, [pointProgram?.signupHeader, signupPromptDefinitions, t, storefrontLabel]);
  const signupDescription = useMemo(() => {
    if (pointProgram?.signupDescription) {
      return pointProgram?.signupDescription;
    }
    if (signupPromptDefinitions.length > 0) {
      return signupPromptDefinitions
        .map((def) => def.definition.body)
        .join(", ");
    }
    return t("signup.description");
  }, [pointProgram?.signupDescription, signupPromptDefinitions, t]);

  const loginHeader = useMemo(() => {
    if (pointProgram?.loginHeader) {
      return pointProgram?.loginHeader;
    }
    if (loginPromptDefinitions.length > 0) {
      return loginPromptDefinitions
        .map((def) => def.definition.title)
        .join(", ");
    }
    return t("login.subheader");
  }, [pointProgram?.loginHeader, loginPromptDefinitions, t]);
  const loginDescription = useMemo(() => {
    if (pointProgram?.loginDescription) {
      return pointProgram?.loginDescription;
    }
    if (loginPromptDefinitions.length > 0) {
      return loginPromptDefinitions
        .map((def) => def.definition.body)
        .join(", ");
    }
    return t("login.description");
  }, [pointProgram?.loginDescription, loginPromptDefinitions, t]);

  const partnerPrivacyPolicy = useMemo(() => {
    if (
      pointProgram?.privacyPolicyPdfUrl ||
      pointProgram?.termsOfServicePdfUrl
    ) {
      return {
        partnerName: pointProgram.label,
        partnerLink: pointProgram.privacyPolicyPdfUrl,
        partnerTerms: pointProgram.termsOfServicePdfUrl,
      };
    }
    return {
      partnerName: privacyPolicyPrompt
        .map((def) => def.definition.partner_name)
        .join(", "),
      partnerLink: privacyPolicyPrompt
        .map((def) => def.definition.partner_link)
        .join(", "),
      partnerTerms: privacyPolicyPrompt
        .map((def) => def.definition.partner_terms)
        .join(", "),
    };
  }, [
    pointProgram?.label,
    pointProgram?.privacyPolicyPdfUrl,
    pointProgram?.termsOfServicePdfUrl,
    privacyPolicyPrompt,
  ]);

  const [checkAccountExists, { data, loading }] = useLazyQuery(
    CHECK_ACCOUNT_EXISTS_QUERY,
    {
      context: { graph: "diners" },
      notifyOnNetworkStatusChange: true,
    },
  );

  const errorCode = getErrorCode();
  function getErrorCode() {
    if (data?.checkAccountExists?.email) {
      return ERROR_CODES.EMAIL_TAKEN;
    } else if (data?.checkAccountExists?.obfuscatedEmail) {
      return ERROR_CODES.MOBILE_NUMBER_TAKEN;
    }
    return null;
  }

  useEffect(() => {
    if (visible) {
      checkAccountExists({
        variables: {
          email: defaultEmail,
          mobileNumber: defaultMobileNumber,
        },
      });
    }
  }, [visible]);

  const LoginFormComponent = useOtpLoginForOnline ? SessionForm : LoginForm;
  const SignupFormComponent = useOtpLoginForOnline ? SessionForm : SignupForm;

  return (
    <Modal
      className="mx-auto max-w-sm checkout-login-modal"
      visible={visible}
      centered
      footer={null}
      closable={false}
      maskClosable={false}
      noCancel={true}
    >
      {loading ? (
        <Spin spinning={loading} />
      ) : errorCode ? (
        <>
          <LoginFormComponent
            isCompact
            defaultEmail={
              errorCode === ERROR_CODES.EMAIL_TAKEN ? defaultEmail : ""
            }
            defaultMobileNumber={
              errorCode === ERROR_CODES.MOBILE_NUMBER_TAKEN
                ? defaultMobileNumber
                : ""
            }
            defaultLoginMode={
              errorCode === ERROR_CODES.EMAIL_TAKEN
                ? LOGIN_MODES.EMAIL
                : LOGIN_MODES.MOBILE_NUMBER
            }
            validateOnBlur={false}
            postLoginCallback={performCheckout}
            loginOnly
            allowSignup={false}
          >
            <div className="text-2xl font-bold text-center">{loginHeader}</div>
            <div
              className="text-center text-body font-body"
              dangerouslySetInnerHTML={{
                __html: t(`login.error.${errorCode}`, {
                  email: data?.checkAccountExists?.email,
                  mobileNumber: defaultMobileNumber,
                  obfuscatedEmail: data?.checkAccountExists?.obfuscatedEmail,
                }),
              }}
            />
            <div className="mb-6 text-center">{loginDescription}</div>
          </LoginFormComponent>
          <div className="mt-4 mb-2 text-xs text-center">
            <a
              className="cursor-pointer text-link hover:text-link"
              onClick={() => performCheckout()}
            >
              {t("signup.skipSignup")}
            </a>
          </div>
        </>
      ) : (
        <SignupFormComponent
          isCompact
          passwordOnly
          defaultName={defaultName}
          defaultMobileNumber={defaultMobileNumber}
          defaultEmail={defaultEmail}
          validateOnBlur={false}
          useErrorCode
          postSignupCallback={performCheckout}
          partnerPrivacyPolicy={partnerPrivacyPolicy}
          footer={
            <div className="mt-12">
              <Button
                type="primary"
                htmlType="submit"
                className="flex items-center w-full"
              >
                {t("signup.signupAndCheckout")}
              </Button>
              <div className="mt-4 mb-2 text-xs text-center">
                <a
                  className="cursor-pointer text-link hover:text-link"
                  onClick={() => performCheckout()}
                >
                  {t("signup.skipSignup")}
                </a>
              </div>
            </div>
          }
          signupOnly
        >
          <div
            className="mb-2 text-2xl font-bold text-center"
            dangerouslySetInnerHTML={{
              __html: signupHeader,
            }}
          />
          <div
            className="mb-6 text-center"
            dangerouslySetInnerHTML={{
              __html: signupDescription,
            }}
          />
        </SignupFormComponent>
      )}
    </Modal>
  );
}
