import React, { useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Input from "@/common/Input";
import Select from "@/common/Select";
import { SUBMIT_CONTACT_FORM_MUTATION } from "@/graphql/mutations/submitContactForm";

ContactForm.propTypes = {
  formType: PropTypes.string,
  subjects: PropTypes.arrayOf(PropTypes.string),
  successMessage: PropTypes.string,
};

export default function ContactForm({ formType, subjects, successMessage }) {
  const { t } = useTranslation();

  const formRef = useRef();
  const [form, setForm] = useState({});
  const [areErrorsVisible, setErrorsVisible] = useState(false);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(null);

  const [submitContactForm, { loading: submitting, error: submitError }] =
    useMutation(SUBMIT_CONTACT_FORM_MUTATION, {
      context: { graph: "diners" },
      onCompleted: () => {
        setLocalSuccessMessage(
          successMessage || t("pages.contactForm.submitSuccessMessage"),
        );
        setForm({
          ...form,
          subject: null,
          message: null,
        });
      },
    });

  function onChange(prop, event) {
    switch (prop) {
      case "subject":
        setForm({
          ...form,
          [prop]: event,
        });
        break;
      default:
        setForm({
          ...form,
          [prop]: event.target.value,
        });
    }
  }

  function isEmailFormatValid() {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(form.email);
  }

  function submitForm(e) {
    e.preventDefault();
    setLocalSuccessMessage(null);
    if (!formRef.current.checkValidity() || !isEmailFormatValid()) {
      setErrorsVisible(true);
      formRef.current.requestSubmit();
    } else {
      setErrorsVisible(false);
      submitContactForm({
        variables: {
          formType,
          ...form,
        },
      });
    }
  }

  return (
    <form
      ref={formRef}
      id={`form-${formType ?? "contact"}`}
      className="space-y-6 sm:max-w-lg contact-form"
    >
      <div className="flex flex-col md:flex-row md:space-x-4">
        <Input
          className="flex-grow"
          type="text"
          label={t("pages.contactForm.labels.name")}
          value={form.name ?? ""}
          onChange={(event) => onChange("name", event)}
          required
        />
      </div>
      <div className="flex flex-col space-y-6 md:flex-row md:space-x-4 md:space-y-0">
        <Input
          className="flex-grow md:w-1/2"
          inputClassName={
            areErrorsVisible && !isEmailFormatValid()
              ? "bg-danger border-danger"
              : null
          }
          type="email"
          label={t("pages.contactForm.labels.email")}
          value={form.email ?? ""}
          onChange={(event) => onChange("email", event)}
          required
        />
        <Input
          className="flex-grow md:w-1/2"
          type="text"
          label={t("pages.contactForm.labels.contactNumber")}
          value={form.contactNumber ?? ""}
          onChange={(event) => onChange("contactNumber", event)}
        />
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <Input
          className="flex-grow"
          label={t("pages.contactForm.labels.subject")}
        >
          <Select
            className={
              areErrorsVisible && !form.subject
                ? "bg-danger border-danger"
                : null
            }
            value={form.subject}
            options={subjects || []}
            onSelect={(value) => onChange("subject", value)}
          />
        </Input>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <Input
          className="flex-grow"
          type="textarea"
          rows="4"
          label={t("pages.contactForm.labels.message")}
          value={form.message ?? ""}
          onChange={(event) => onChange("message", event)}
          required
        />
      </div>
      {submitError?.message && (
        <div className="font-semibold text-right text-danger">
          {submitError.message}
        </div>
      )}
      {localSuccessMessage && (
        <div className="font-semibold text-right text-success">
          {localSuccessMessage}
        </div>
      )}
      <div className="flex justify-end">
        <Button
          className="w-full sm:w-auto"
          disabled={submitting}
          type="primary"
          htmlType="button"
          onClick={submitForm}
        >
          {t("pages.contactForm.submitButtonTitle")}
        </Button>
      </div>
    </form>
  );
}
