import React, { useContext, useEffect, useState } from "react";

import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import Input from "@/common/Input";
import ConfigContext from "@/components/Config/configContext";
import { CREATE_ADDRESS_MUTATION } from "@/graphql/mutations/createAddress";
import { GET_ADDRESSES_AND_OUTLETS_QUERY } from "@/graphql/queries/getAddresses";
import { SEARCH_ADDRESS_QUERY } from "@/graphql/queries/searchAddress";
import useAnalytics from "@/hooks/useAnalytics";
import useDebounce from "@/hooks/useDebounce";
import { getPayloadFromAddress } from "@/utils/getPayloadFromAddress";

import AddressSearchResult from "./AddressSearchResult";

AddressSearch.propTypes = {
  visible: PropTypes.bool,
  setSearching: PropTypes.func,
  setCreatingAddress: PropTypes.func,
  setSelectedAddress: PropTypes.func,
  availableOutlets: PropTypes.arrayOf(PropTypes.object),
  setSelectedOutlet: PropTypes.func,
  setNoAvailableOutletsAlert: PropTypes.func,
};

const DEBOUNCE_DELAY = 800;
const ZERO_RESULTS = "ZERO_RESULTS";

export default function AddressSearch({
  visible,
  setSearching,
  setCreatingAddress,
  setSelectedAddress,
  availableOutlets,
  setSelectedOutlet,
  setNoAvailableOutletsAlert,
}) {
  // Setup
  const { t } = useTranslation();
  const client = useApolloClient();

  const { configQuery } = useContext(ConfigContext);

  const [address, setAddress] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [gqlSearchResults, setGqlSearchResults] = useState([]);
  const [searchedAddress, setSearchedAddress] = useState(null);

  const debouncedSearch = useDebounce(address, DEBOUNCE_DELAY);
  const { analytics, events } = useAnalytics();

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
    options: {
      componentRestrictions: {
        country: [configQuery?.config?.country?.toLowerCase() || "sg"],
      },
    },
  });

  // Methods
  const handleSelect = (address) => {
    if (address) {
      placesService?.getDetails?.(
        {
          placeId: address.place_id,
        },
        (placeDetails, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            try {
              const addressPayload = getPayloadFromAddress(placeDetails);
              if (!addressPayload.postalCode) {
                setSearchError(t("addressPicker.error.noPostalCode"));
              } else {
                setSearchError(null);
                setSearchedAddress(addressPayload);
              }
            } catch (e) {
              return;
            }
          }
        },
      );
    }
  };

  function onError(error) {
    if (error === ZERO_RESULTS) {
      setSearchError(error);
    }
  }

  const locationToAddress = (location) => {
    return {
      id: uuidv4(), // Add a temporary non-clashing ID for usage on cache
      newRecord: true,
      line1: location.address,
      line2: "",
      longitude: location.longitude,
      latitude: location.latitude,
      postalCode: location.postalCode,
      nearestOutlet: location.nearestOutlet,
      notes: "",
      isContactless: false,
      isCutleryRequired: false,
    };
  };

  const renderSearchResults = () => {
    const optionKlass =
      "p-2 font-normal hover:bg-primary hover:text-on-primary cursor-pointer";
    // render wrapper only if there is content to render
    const wrap = (children) => {
      return (
        <div className="overflow-hidden text-sm border border-t-0 rounded-bl-sm rounded-br-sm search-results border-default">
          {children}
        </div>
      );
    };

    if (isPlacePredictionsLoading || searchAddressResponse.loading) {
      return wrap(<div className="p-2">{t("common.loading")}</div>);
    }
    // prioritise searchResults over google places suggestions
    if (gqlSearchResults.length > 0) {
      return wrap(
        gqlSearchResults.map((location, index) => {
          return (
            <AddressSearchResult
              key={index}
              location={location}
              optionKlass={optionKlass}
              onClick={() => {
                setSearchedAddress(locationToAddress(location));
                setGqlSearchResults([]);
              }}
            />
          );
        }),
      );
    }

    if (searchError === ZERO_RESULTS) {
      return wrap(
        <div className={optionKlass}>{t("addressPicker.noResults")}</div>,
      );
    }

    if (placePredictions.length > 0) {
      return wrap(
        placePredictions.map((place, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleSelect(place);
              }}
            >
              <div className={optionKlass}>{place.description}</div>
            </div>
          );
        }),
      );
    }

    return null;
  };

  // GraphQL Actions
  const [createAddress] = useMutation(CREATE_ADDRESS_MUTATION, {
    onCompleted: () => setCreatingAddress(false),
    update: async (_, { data }) => {
      client.writeQuery({
        query: GET_ADDRESSES_AND_OUTLETS_QUERY,
        data: {
          addresses: data?.createAddress,
        },
      });

      // Created is same as selected
      analytics.track(events.address_selected);

      // Show this modal after closing the address Modal
      const createdAddress = data?.createAddress?.find(
        (address) => address.id === address.user.selectedAddressId,
      );

      setSearchedAddress(null);

      if (createdAddress?.availableOutlets.length > 0) {
        setSelectedOutlet(
          availableOutlets.find(
            (outlet) => outlet.id === createdAddress.nearestOutlet.id,
          ),
        );
        setSelectedAddress(createdAddress);
      } else {
        analytics.track(events.addresses_not_in_service_zone);
        // Show an Modal if selected address has no available outlets
        setSelectedOutlet(null);
        setNoAvailableOutletsAlert(true);
      }
    },
    errorPolicy: "all",
    context: { graph: "diners" },
  });

  // Effects
  useEffect(() => {
    if (searchedAddress) {
      const { id, ...addressParams } = searchedAddress; // eslint-disable-line
      setCreatingAddress(true);
      createAddress({
        variables: {
          ...addressParams,
        },
      });
      setSearchedAddress(null);
      setAddress("");
      setGqlSearchResults([]);
      getPlacePredictions({ input: "" });
    }
  }, [searchedAddress]);

  useEffect(() => {
    // prevent debounced search on set address on select from results list
    if (debouncedSearch) {
      searchAddress({
        variables: {
          query: debouncedSearch,
        },
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setSearching(address.length > 0);
  }, [address]);

  // GraphQL actions
  const [searchAddress, searchAddressResponse] = useLazyQuery(
    SEARCH_ADDRESS_QUERY,
    {
      context: { graph: "diners" },
      onCompleted: (data) => {
        // don't load search result if search is empty
        if (address) {
          const { searchAddress } = data;
          // if no search results, fallback to google places
          if (searchAddress?.length) {
            setGqlSearchResults(searchAddress);
            analytics.track(events.address_searched);
          } else {
            setGqlSearchResults([]);
            getPlacePredictions({ input: address.toString() });
            // Not found in database
            analytics.track(events.addresses_not_found);
          }
        }
      },
    },
  );

  if (!visible) return null;

  return (
    <div>
      <Input
        id="google-places-search"
        placeholder={t("addressPicker.placeholder")}
        searchBar={gqlSearchResults.length > 0}
        onError={onError}
        onChange={(e) => {
          setAddress(e.target.value);
          if (!e.target.value) {
            setGqlSearchResults([]);
            getPlacePredictions({ input: "" });
          }
          if (searchError) {
            setSearchError(null);
          }
        }}
        value={address}
      />
      {renderSearchResults()}
      <div className="mt-2 text-sm text-danger">
        {searchError !== ZERO_RESULTS && searchError}
      </div>
    </div>
  );
}
