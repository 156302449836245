import React from "react";

import PropTypes from "prop-types";

import Tooltip from "@/common/Tooltip";

HasErrorTooltip.propTypes = {
  error: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  zIndex: PropTypes.number,
  getPopupContainer: PropTypes.func,
  visible: PropTypes.bool,
  isMobileVer: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

HasErrorTooltip.defaultProps = {
  className: "",
  zIndex: 100,
  getPopupContainer: () => document.body,
};

export default function HasErrorTooltip({
  error,
  color,
  className,
  zIndex,
  getPopupContainer,
  visible,
  children,
  isMobileVer,
}) {
  return error ? (
    <>
      {isMobileVer ? (
        <div>
          {children}
          {visible && (
            <div className={"mt-1 text-sm text-red-500"}>{error}</div>
          )}
        </div>
      ) : (
        <Tooltip
          overlayClassName={className}
          color={color}
          title={error}
          zIndex={zIndex}
          destroyTooltipOnHide
          getPopupContainer={getPopupContainer}
          visible={visible}
        >
          {children}
        </Tooltip>
      )}
    </>
  ) : (
    <>{children}</>
  );
}
