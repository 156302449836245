import { gql } from "@apollo/client";

import { PAYMENT_BREAKDOWN_FIELDS } from "@/graphql/queries/getCart";

const generateUpdateCartPromoMutation = (operationName, mutationName) => gql`
  mutation ${operationName}($clear: Boolean, $promoCode: String) {
    ${mutationName}(input: { clear: $clear, promoCode: $promoCode }) {
      id
      promoCode
      promoCodeError
      promoItems
      paymentBreakdown {
        ...PaymentBreakdownFields
      }
    }
  }
  ${PAYMENT_BREAKDOWN_FIELDS}
`;
export const UPDATE_CART_PROMO_MUTATION = generateUpdateCartPromoMutation(
  "UpdateCartPromo",
  "updateCartPromo",
);
export const UPDATE_CART_PROMO_OPTIMISED_MUTATION =
  generateUpdateCartPromoMutation(
    "UpdateCartPromoOptimised",
    "updateCartPromoOptimised",
  );
