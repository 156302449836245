import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin";

VerifyAlcoholAgeModal.propTypes = {
  loading: PropTypes.bool,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default function VerifyAlcoholAgeModal({
  loading,
  onYes,
  onNo,
  onCancel,
  children,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      className="verify-alcohol-age-modal"
      visible={true}
      onCancel={onCancel}
      footer={
        <Spin spinning={loading}>
          <div className="flex flex-col gap-4 mt-4 text-left">
            <Button onClick={onYes}>
              {t("menu.cart.verifyAlcoholAgeModal.yesLabel")}
            </Button>
            <Button onClick={onNo}>
              {t("menu.cart.verifyAlcoholAgeModal.noLabel")}
            </Button>
          </div>
        </Spin>
      }
    >
      <div className="mb-4 text-2xl font-display">
        {t("menu.cart.verifyAlcoholAgeModal.header")}
      </div>
      <div>{t("menu.cart.verifyAlcoholAgeModal.body")}</div>

      {children}
    </Modal>
  );
}
