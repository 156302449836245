import find from "lodash/find";
import some from "lodash/some";

export const getLongNameOfAddressComponentType = (
  addressComponentsList,
  type,
) => {
  const addressComponent = find(
    addressComponentsList,
    (addressComponentItem) => {
      return some(addressComponentItem.types, (placeType) => {
        return placeType === type;
      });
    },
  );

  return addressComponent?.long_name;
};

export const getPayloadFromAddress = (address) => {
  const POSTAL_CODE_TYPE = "postal_code";
  const location = {};

  // Defaults
  location.line2 = "";
  location.notes = "";
  location.isContactless = false;
  location.isCutleryRequired = false;

  // Extract from address
  location.line1 = address?.formatted_address;
  location.latitude = address?.geometry?.location?.lat();
  location.longitude = address?.geometry?.location?.lng();

  const addressComponentsList = address?.address_components;
  location.postalCode = getLongNameOfAddressComponentType(
    addressComponentsList,
    POSTAL_CODE_TYPE,
  );

  return location;
};
