import React, { useEffect } from "react";

import { gapi } from "gapi-script";
import PropTypes from "prop-types";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"; // this is for custom styles
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import FacebookIcon from "@/common/FacebookIcon";
import GoogleIcon from "@/common/GoogleIcon";

SocialLogin.propTypes = {
  isSignup: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default function SocialLogin({
  isSignup = false,
  handleSubmit,
  setFieldValue,
}) {
  const { t } = useTranslation();

  const startGoogleApi = () => {
    gapi.client.init({
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      scope: "email",
    });
  };

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_CLIENT_ID) {
      gapi.load("client:auth2", startGoogleApi);
    }
  }, []);

  const responseFacebook = (response) => {
    const { name, email, accessToken } = response;
    setFieldValue("name", name);
    setFieldValue("email", email);
    setFieldValue("ssoToken", accessToken);
    setFieldValue("signupSource", "facebook");
    handleSubmit();
  };

  const responseGoogle = (response) => {
    const profileObj = response ? response.profileObj : {};
    const { tokenId } = response;
    const { email, name } = profileObj;
    setFieldValue("name", name);
    setFieldValue("email", email);
    setFieldValue("ssoToken", tokenId);
    setFieldValue("signupSource", "gmail");
    handleSubmit();
  };

  return (
    <div className="flex flex-col gap-4">
      {import.meta.env.VITE_FACEBOOK_APP_ID && (
        <FacebookLogin
          appId={import.meta.env.VITE_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,birthday"
          callback={responseFacebook}
          render={({ onClick, isDisabled }) => (
            <Button
              type="custom"
              className="text-center border-transparent !shadow-md bg-facebook"
              fullWidth
              onClick={onClick}
              disabled={isDisabled}
            >
              <div className="flex gap-2">
                <FacebookIcon />
                <span className="text-white">
                  {isSignup
                    ? t("signup.facebookSignupButton")
                    : t("login.facebookLoginButton")}
                </span>
              </div>
            </Button>
          )}
        />
      )}

      {import.meta.env.VITE_GOOGLE_CLIENT_ID && (
        <GoogleLogin
          clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          render={({ onClick, disabled }) => (
            <Button
              type="custom"
              className="text-center bg-white border-transparent !shadow-md"
              fullWidth
              onClick={onClick}
              disabled={disabled}
            >
              <div className="flex gap-2">
                <GoogleIcon />
                <span className="text-[#333333]">
                  {isSignup
                    ? t("signup.googleSignupButton")
                    : t("login.googleLoginButton")}
                </span>
              </div>
            </Button>
          )}
        />
      )}
    </div>
  );
}
