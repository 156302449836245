import { useContext, useState } from "react";

import { CurrencyDollarIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

import ConfigContext from "@/components/Config/configContext";

PointIcon.propTypes = {
  size: PropTypes.number,
};

export default function PointIcon({ size = 24, ...props }) {
  const { configQuery } = useContext(ConfigContext);
  const pointProgram = configQuery.config?.pointProgram;

  const [useDefaultIcon, setUseDefaultIcon] = useState(false);

  if (!pointProgram?.isCustomCurrency) {
    return (
      <CurrencyDollarIcon
        width={size}
        height={size}
        color={pointProgram?.colors?.brandColor}
        {...props}
      />
    );
  }

  if (!useDefaultIcon && !!pointProgram?.currencyIconUrl)
    return (
      <img
        src={pointProgram?.currencyIconUrl}
        width={size}
        height={size}
        onError={() => setUseDefaultIcon(true)}
        {...props}
      />
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 94 94"
      fill="none"
      id="default-point-icon"
      {...props}
    >
      <circle cx="47" cy="47" r="47" fill={pointProgram?.colors?.brandColor} />
      <path
        d="M86.0281 42.1712L69.5047 56.4291L74.5388 77.7517C74.8166 78.9092 74.7451 80.123 74.3333 81.2399C73.9215 82.3568 73.188 83.3266 72.2253 84.0267C71.2627 84.7269 70.1141 85.1261 68.9247 85.1738C67.7353 85.2216 66.5584 84.9157 65.5427 84.295L46.9998 72.8828L28.4458 84.295C27.4302 84.9122 26.2548 85.2151 25.0674 85.1656C23.8801 85.1161 22.7339 84.7164 21.7733 84.0168C20.8126 83.3172 20.0804 82.3491 19.6689 81.2342C19.2574 80.1193 19.185 78.9076 19.4607 77.7517L24.5132 56.4291L7.98977 42.1712C7.09126 41.3946 6.44143 40.3706 6.12146 39.2269C5.80148 38.0832 5.82553 36.8706 6.19061 35.7405C6.55568 34.6105 7.2456 33.613 8.17421 32.8726C9.10281 32.1323 10.2289 31.682 11.412 31.5779L33.076 29.83L41.4332 9.60535C41.8856 8.50312 42.6555 7.56029 43.645 6.89675C44.6346 6.2332 45.7991 5.87891 46.9906 5.87891C48.182 5.87891 49.3466 6.2332 50.3361 6.89675C51.3257 7.56029 52.0956 8.50312 52.548 9.60535L60.9015 29.83L82.5655 31.5779C83.7509 31.6781 84.8803 32.1259 85.8123 32.8652C86.7443 33.6045 87.4374 34.6024 87.8047 35.7339C88.1721 36.8653 88.1974 38.08 87.8774 39.2258C87.5574 40.3716 86.9065 41.3974 86.0061 42.1749L86.0281 42.1712Z"
        fill="white"
      />
    </svg>
  );
}
