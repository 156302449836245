export const events = {
  cart_viewed: "Cart Viewed",
  checkout_step_viewed: "Checkout Step Viewed",
  checkout_started: "Checkout Started",
  checkout_step_failed: "Checkout Step Failed",
  checkout_step_completed: "Checkout Step Completed",
  order_completed: "Order Completed",
  coupon_entered: "Coupon Entered",
  coupon_applied: "Coupon Applied",
  coupon_denied: "Coupon Denied",
  coupon_removed: "Coupon Removed",
  product_added: "Product Added",
  product_removed: "Product Removed",
  product_list_viewed: "Product List Viewed",
  product_list_filtered: "Product List Filtered",
  product_viewed: "Product Viewed",
  bestseller_product_viewed: "Bestseller Product Viewed",
  requested_menu_from_banner: "Menu Viewed From Banner",
  requested_menu_from_best_sellers: "Menu Viewed From Best Sellers",
  requested_menu_from_header: "Menu Viewed From Header",
  account_viewed: "Account Viewed",
  login: "Login",
  signup: "Sign Up",
  logout: "Logout",
  timeslots_viewed: "Timeslots Viewed",
  timeslots_filtered: "Timeslots Filtered",
  timeslot_selected: "Timeslot Selected",
  address_searched: "Address Searched",
  addresses_not_found: "Addresses Not Found",
  addresses_not_in_service_zone: "Addresses Not In Service Zone",
  address_list_viewed: "Address List Viewed",
  address_selected: "Address Selected",
  address_added: "Address Selected",
  address_removed: "Address Removed",
  pickup_selected: "Pickup Selected",
  orders_list_viewed: "Orders List Viewed",
  order_status_viewed: "Orders Status Viewed",
  order_receipt_viewed: "Orders Receipt Viewed",
  shared_referral_link: "Shared Referral Link",
};
