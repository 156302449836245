import { events } from "@/lib/analytics/events";

const formatProduct = (properties) => ({
  name: properties.name,
  id: properties.sku,
  price: properties.price,
  brand: "Atlas",
  category: properties.category,
  quantity: properties.quantity,
  position: properties.position,
});

export default function enhancedEcommerceEvents(event, properties) {
  switch (event) {
    case events.product_added:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#add
      return {
        event: "addToCart",
        ecommerce: {
          currencyCode: properties.currency,
          add: {
            products: [formatProduct(properties)],
          },
        },
      };
    case events.product_removed:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#remove
      return {
        event: "removeFromCart",
        ecommerce: {
          remove: {
            products: [formatProduct(properties)],
          },
        },
      };
    case events.checkout_started:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#checkout
      return {
        event: "checkout",
        ecommerce: {
          checkout: {
            products: properties.products.map((product) =>
              formatProduct(product),
            ),
          },
        },
      };
    case events.product_viewed:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#details
      return {
        event: "productViewed",
        ecommerce: {
          detail: {
            products: [formatProduct(properties)],
          },
        },
      };
    case events.product_list_viewed:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-impressions
      return {
        event: "productListViewed",
        ecommerce: {
          impressions: properties.products.map((product) => ({
            list: properties.category,
            ...formatProduct(product),
          })),
        },
      };
    case events.order_completed:
      // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases
      return {
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: properties.order_id,
              affiliation: properties.affiliation,
              revenue: properties.value,
              shipping: properties.shipping,
              tax: properties.tax,
              surcharge: properties.surcharge,
              surchargeLabel: properties.surchargeLabel,
              discount: properties.discount,
              coupon: properties.coupon,
            },
            products: properties.products.map((product) =>
              formatProduct(product),
            ),
          },
        },
      };
    default:
      return {};
  }
}
