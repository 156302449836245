import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@/common/Button";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import MenuItemImage from "@/components/Menu/MenuItemImage";
import useAnalytics from "@/hooks/useAnalytics";
import useTranslatedText from "@/hooks/useTranslationText";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";
import toKebabCase from "@/utils/kebabCase";

import MenuItemControls from "./MenuItemControls";
import NutriGradeBadge from "./NutriGradeBadge";

import "./MenuItem.less";

MenuItem.propTypes = {
  item: PropTypes.object,
  isHeroCard: PropTypes.bool,
  id: PropTypes.number,
  cartItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitPriceFractional: PropTypes.number,
  currency: PropTypes.string,
  isConfigurable: PropTypes.bool,
  quantity: PropTypes.number,
  setSelectedItem: PropTypes.func,
  horizontalImageUrl: PropTypes.string,
  menuSectionDisabled: PropTypes.bool,
  menuSectionDisabledReason: PropTypes.string,
  setSelectedItemSection: PropTypes.func,
  isLeadTimeBreached: PropTypes.bool,
  humanizedLeadTime: PropTypes.string,
  isAvailable: PropTypes.bool,
  humanizedAvailability: PropTypes.string,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
  isPreview: PropTypes.bool,
  cart: PropTypes.object,
  itemStock: PropTypes.shape({
    id: PropTypes.number,
    quantityLeft: PropTypes.object,
  }),
  checkPromotedProducts: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md"]),
};

MenuItem.defaultProps = {
  isHeroCard: false,
  quantity: 0,
  isLeadTimeBreached: false,
  isAvailable: true,
  isPreview: false,
  itemStock: {
    quantityLeft: {},
  },
  checkPromotedProducts: true,
  size: "md",
};

export default function MenuItem({
  item,
  isHeroCard,
  id,
  cartItemId,
  unitPriceFractional,
  currency,
  isConfigurable,
  quantity,
  setSelectedItem,
  horizontalImageUrl,
  isLeadTimeBreached,
  menuSectionDisabled,
  menuSectionDisabledReason,
  setSelectedItemSection,
  humanizedLeadTime,
  isAvailable,
  humanizedAvailability,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
  isPreview,
  itemStock,
  checkPromotedProducts,
  size,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { analytics, events } = useAnalytics();
  const { pathConfig } = useContext(ConfigContext);

  const itemLabel = useTranslatedText({
    resource: item,
    fallbackValue: item?.label,
  });

  const itemDescription = useTranslatedText({
    resource: item,
    fallbackValue: item?.description,
    key: "description",
  });

  const itemUrl = `${pathConfig?.menuPath}/items/${toKebabCase(
    [id, item?.label].join(),
  )}`.replace("//", "/");

  const itemPath = `${pathConfig?.menuPath}/items/${toKebabCase(
    [id, item?.label].join(),
  )}`.replace("//", "/");

  const { cartValidations } = useContext(CartContext);

  function renderActionButton() {
    if (cartValidations?.notWithinServiceZone) {
      return (
        <Button
          disabled={true}
          type="primary"
          className={classNames("sm:min-w-auto sm:w-auto", {
            "w-full": size != "sm",
          })}
        >
          {t("menu.menuItem.addToCart")}
        </Button>
      );
    }
    if (cartValidations?.addressOrTimeslotNotSelected) {
      return (
        <Button
          type="primary"
          className={classNames("sm:min-w-auto sm:w-auto", {
            "w-full": size != "sm",
          })}
          onClick={() => {
            cartValidations?.noAddressOrOutletSelected
              ? emitEvent("selectAddress")
              : emitEvent("selectTimeslot");
          }}
        >
          {t("menu.menuItem.addToCart")}
        </Button>
      );
    }
    if (menuSectionDisabled) {
      return (
        <Button
          type="primary"
          className={classNames(
            "float-right font-bold sm:min-w-auto sm:w-auto sm:ml-2",
            { "w-full": size != "sm" },
          )}
          onClick={() => {
            showMenuSectionDisabledModal(menuSectionDisabledReason);
          }}
        >
          {t("menu.menuItem.notAvailable")}
        </Button>
      );
    }
    if (isLeadTimeBreached) {
      return (
        <Button
          type="primary"
          className={classNames(
            "float-right font-bold sm:min-w-auto sm:w-auto sm:ml-2",
            { "w-full": size != "sm" },
          )}
          onClick={() => showPreOrderModal(humanizedLeadTime)}
        >
          {t("menu.menuItem.preOrder")}
        </Button>
      );
    }
    // check specifically for false, and not null or undefined
    if (isAvailable === false) {
      return (
        <Button
          type="primary"
          className={classNames(
            "float-right font-bold opacity-50 sm:min-w-auto sm:w-auto sm:ml-2",
            { "w-full": size != "sm" },
          )}
          onClick={() => showAvailabilityModal(humanizedAvailability)}
        >
          {t("menu.menuItem.notAvailable")}
        </Button>
      );
    }

    if (
      itemStock.quantityLeft &&
      typeof itemStock.quantityLeft["entire"] === "number" &&
      itemStock.quantityLeft["entire"] <= 0
    ) {
      return (
        <Button
          data-testid={`item-sold-out`}
          type="primary"
          className={classNames("sm:min-w-auto sm:w-auto", {
            "w-full": size != "sm",
          })}
          disabled
        >
          {t("menu.menuItem.soldOut")}
        </Button>
      );
    }
    if (isConfigurable) {
      return (
        <Button
          type="primary"
          className={classNames(
            "float-right font-bold sm:min-w-[96px] sm:w-auto sm:ml-2",
            { "w-full": size != "sm" },
          )}
          onClick={() => {
            setSelectedItem?.();
            setSelectedItemSection?.();
          }}
        >
          {t("menu.menuItem.addToCart")}
        </Button>
      );
    }
    return (
      <MenuItemControls
        itemId={id}
        item={item}
        quantity={quantity}
        cartItemId={cartItemId}
        showAddToCartButtonOnZero={true}
        unitPriceFractional={unitPriceFractional}
        checkPromotedProducts={checkPromotedProducts}
      />
    );
  }

  function showItemInMenu() {
    navigate(itemPath);

    analytics.track(events.bestseller_product_viewed, {
      label: item?.label,
      id,
      currency,
      unitPriceFractional,
      horizontalImageUrl,
    });
  }

  if (size === "sm") {
    return (
      <a
        data-testid={`${item?.label}-menu-item-sm`}
        href={itemUrl}
        className={
          "flex overflow-hidden gap-3 p-4 rounded-lg cursor-pointer bg-card"
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showItemInMenu(isPreview);
          if (!isPreview) {
            setSelectedItem?.();
            setSelectedItemSection?.();
          }
        }}
      >
        <div className="w-[100px] h-[100px]">
          <MenuItemImage
            source={horizontalImageUrl}
            isHeroCard={isHeroCard}
            promotionalLabelText={item?.promotionalLabelText}
            promotionalLabelFontColor={item?.promotionalLabelFontColor}
            promotionalLabelBgColor={item?.promotionalLabelBgColor}
            className="rounded-lg"
            inCart={false}
          />
        </div>
        <div className="flex flex-col flex-1 justify-between items-start">
          <div className="mb-3 space-y-2">
            <p className="text-base font-display text-default line-clamp-2">
              {itemLabel}
            </p>
            {item?.sugarLevel !== undefined && item?.sugarLevel !== null && (
              <NutriGradeBadge
                sugarLevel={item?.sugarLevel}
                nutriGrade={item?.nutriGrade}
              />
            )}
          </div>

          <div className="flex justify-between items-center w-full">
            <div
              className={`space-x-1 font-bold sm:w-auto text-md text-default`}
            >
              {unitPriceFractional !== 0 &&
                formatPrice(unitPriceFractional, currency)}
            </div>
            {!isPreview && (
              <div
                onClick={(e) => {
                  // prevent triggering opening of product details when click on action button
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {renderActionButton()}
              </div>
            )}
          </div>
        </div>
      </a>
    );
  }

  return (
    <a
      data-testid={`${item?.label}-menu-item`}
      href={itemUrl}
      className={
        "flex flex-col shadow-lg overflow-hidden menu-item cursor-pointer" +
        (isHeroCard ? " sm:flex-row col-span-2 lg:col-span-3" : "")
      }
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        showItemInMenu(isPreview);
        if (!isPreview) {
          setSelectedItem?.();
          setSelectedItemSection?.();
        }
      }}
    >
      <div className={"flex-shrink-0" + (isHeroCard ? " sm:w-1/2" : "")}>
        <MenuItemImage
          source={horizontalImageUrl}
          isHeroCard={isHeroCard}
          promotionalLabelText={item?.promotionalLabelText}
          promotionalLabelFontColor={item?.promotionalLabelFontColor}
          promotionalLabelBgColor={item?.promotionalLabelBgColor}
          sugarLevel={item?.sugarLevel}
          nutriGrade={item?.nutriGrade}
          inCart={true}
        />
      </div>
      <div className="flex flex-col justify-between flex-1 p-3 sm:p-4.5 bg-card">
        <div>
          <p className="mb-3 text-base font-display text-default line-clamp-2">
            {itemLabel}
          </p>
          <p
            className={classNames(
              "mt-1 text-sm-body text-default",
              isHeroCard ? "line-clamp-4 lg:line-clamp-10" : "line-clamp-3",
            )}
            dangerouslySetInnerHTML={{ __html: itemDescription }}
          />
        </div>
        <div className="mt-3 sm:mt-6">
          <div
            className={`flex ${
              isHeroCard
                ? "flex-row items-center"
                : "flex-col items-stretch sm:items-center"
            } justify-between justify-items-stretch sm:flex-row`}
          >
            <div
              className={classNames(
                "space-x-1 font-bold sm:w-auto text-md text-default",
                isHeroCard ? "" : "w-full mb-3 sm:mb-0",
              )}
            >
              {unitPriceFractional !== 0 &&
                formatPrice(unitPriceFractional, currency)}
            </div>
            {!isPreview && (
              <div
                className={`w-full sm:w-auto ${
                  isHeroCard ? "whitespace-nowrap max-w-1/3 sm:max-w-auto" : ""
                }`}
                onClick={(e) => {
                  // prevent triggering opening of product details when click on action button
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {renderActionButton()}
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}
