export default {
  ACCESS_TOKEN: "accessToken",
  RESET_TOKEN: "resetToken",
  REFRESH_TOKEN: "refreshToken",
  X_CLIENT_UUID: "X-Client-UUID",
  X_CLIENT_NAME: "diner-web",
  X_PLATFORM: "web",
  USER_EMAIL: "userEmail",
  OUTLET_SLUG: "outletSlug",
  OUTLET_QR_HASH: "outletQrHash",
  TABLE_QR_HASH: "tableQrHash",
  ALCOHOL_AGE_VERIFIED: "alcoholAgeVerified",
};
