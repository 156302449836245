import React, { useContext, useEffect, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import XIcon from "@heroicons/react/outline/XIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Input from "@/common/Input";
import Modal from "@/common/Modal";
import Spin from "@/common/Spin";
import PromoCodeDetailsModal from "@/components/Account/PromoCodes/PromoCodeDetailsModal";
import PromoCodeList from "@/components/Account/PromoCodes/PromoCodeList";
import ConfigContext from "@/components/Config/configContext";
import { GET_PROMOTIONS_QUERY } from "@/graphql/queries/getPromotions";
import constants from "@/utils/constants";

PromoCodeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  promoCode: PropTypes.string,
  promoCodeError: PropTypes.string,
  updateCartPromo: PropTypes.func,
  updateCartPromoResponse: PropTypes.object,
  setSelectedPromo: PropTypes.func,
};

export default function PromoCodeModal({
  visible,
  closeModal,
  promoCode,
  promoCodeError,
  updateCartPromo,
  updateCartPromoResponse,
}) {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);
  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [localPromoCode, setLocalPromoCode] = useState("");

  const [selectedPromo, setSelectedPromo] = useState(null);
  const [activePromoCodes, setActivePromoCodes] = useState([]);
  const {
    loading: promosLoading,
    error: promosError,
    data: promosData,
  } = useQuery(GET_PROMOTIONS_QUERY, {
    context: { graph: "diners" },
  });
  const { loading: updateCartPromoLoading, data: updateCartPromoData } =
    updateCartPromoResponse;
  const cartPromoData = useMemo(
    () =>
      useOptimisedDinerCartType
        ? updateCartPromoData?.updateCartPromoOptimised
        : updateCartPromoData?.updateCartPromo,
    [useOptimisedDinerCartType, updateCartPromoData],
  );

  useEffect(() => {
    if (visible) {
      setLocalPromoCode(promoCode || "");
      setTimeout(() => {
        document.querySelector("#promo-code-input").focus();
      }, 0);
    }
  }, [visible]);

  useEffect(() => {
    setLocalPromoCode(promoCode || localPromoCode || "");
  }, [promoCode]);

  useEffect(() => {
    if (cartPromoData && promoCode && !promoCodeError) {
      closeModal();
    }
  }, [cartPromoData]);

  useEffect(() => {
    if (promosData?.promotions) {
      setActivePromoCodes(
        promosData?.promotions.filter((promo) => promo.active),
      );
    }
  }, [promosData?.promotions]);

  function applyPromo() {
    if (!localPromoCode) return;

    updateCartPromo({
      variables: {
        promoCode: localPromoCode.toUpperCase(),
      },
    });
  }

  return (
    <Modal
      className="promo-code-modal"
      antModalClassName="sm:max-w-lg"
      visible={visible}
      onCancel={closeModal}
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={promosLoading || updateCartPromoLoading}>
        <div className="mb-4 text-2xl font-display">
          {t("menu.cart.checkout.promoCodeModal.header")}
          <div
            className="absolute top-0 right-0 cursor-pointer sm:hidden"
            onClick={closeModal}
          >
            <XIcon className="w-6 h-6" />
          </div>
        </div>
        <div className="text-left sm:flex sm:items-start sm:space-x-2">
          <div className="relative sm:flex-grow">
            <Input
              id="promo-code-input"
              type="text"
              placeholder={t("menu.cart.checkout.promoCodeModal.placeholder")}
              value={localPromoCode}
              onChange={(event) =>
                setLocalPromoCode(event.target.value.toUpperCase())
              }
              onKeyPress={(event) => {
                // enter key
                if (event.which === 13) {
                  applyPromo();
                }
              }}
            />
            {localPromoCode && (
              <div
                className="absolute top-0 right-0 flex items-center px-2 cursor-pointer"
                style={{ height: 38 }}
                onClick={() => {
                  if (promoCode) {
                    updateCartPromo({
                      variables: { clear: true },
                    });
                  } else {
                    setLocalPromoCode("");
                  }
                }}
              >
                <XIcon className="w-6 h-6 text-on-button-default" />
              </div>
            )}
            {promoCodeError && (
              <div className="mt-2 text-sm font-bold text-danger">
                {promoCodeError}
              </div>
            )}
          </div>
          <Button
            className="w-full mt-4 sm:w-auto sm:mt-0"
            type="primary"
            disabled={!localPromoCode}
            onClick={() => applyPromo()}
          >
            {t("menu.cart.checkout.promoCodeModal.apply")}
          </Button>
        </div>
        {promosError?.message && (
          <div className="mt-6 text-danger">{promosError?.message}</div>
        )}
        {activePromoCodes.length > 0 && (
          <div className="mt-6">
            <PromoCodeDetailsModal
              promo={selectedPromo}
              closeModal={() => setSelectedPromo(null)}
              goToMenu={() => {
                setSelectedPromo(null);
                setTimeout(() => closeModal(), 0);
              }}
              className="max-w-lg"
              antModalClassName="sm:max-w-lg"
            />
            <PromoCodeList
              promoCodes={activePromoCodes}
              setSelectedPromo={setSelectedPromo}
            />
          </div>
        )}
      </Spin>
    </Modal>
  );
}
