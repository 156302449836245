import React from "react";

export default function TableIcon() {
  return (
    <>
      <svg
        width="20"
        height="20"
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 520 520"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        fill="currentColor"
      >
        <g transform="translate(-59.999985-27.972854)">
          <ellipse
            rx="238.520523"
            ry="77.749784"
            transform="matrix(.539243 0 0 0.539243 320 458.376677)"
            strokeWidth="0"
          />
          <rect
            width="54.617735"
            height="31.479675"
            rx="0"
            ry="0"
            transform="matrix(4.709845 0 0 0.623561 191.379454 438.74719)"
            strokeWidth="0"
          />
          <ellipse
            rx="238.520523"
            ry="77.749784"
            transform="matrix(.539243 0 0 0.539243 320 438.747188)"
            strokeWidth="0"
          />
          <rect
            width="54.617735"
            height="31.479675"
            rx="0"
            ry="0"
            transform="matrix(.647725 0 0 5.571123 302.31135 255.815134)"
            strokeWidth="0"
          />
          <ellipse
            rx="238.520523"
            ry="77.749784"
            transform="translate(320 184.872465)"
            strokeWidth="0"
          />
          <rect
            width="54.617735"
            height="31.479675"
            rx="0"
            ry="0"
            transform="matrix(8.734178 0 0 0.999997 81.47949 153.392791)"
            strokeWidth="0"
          />
          <path
            d="M81.47948,187.41723c0-42.94002,106.78928-77.74978,238.52052-77.74978s238.52052,34.80976,238.52052,77.74978-106.78928,77.74978-238.52052,77.74978-238.52052-34.80976-238.52052-77.74978Z"
            transform="translate(-.000013-34.024446)"
            strokeWidth="0"
          />
        </g>
      </svg>
    </>
  );
}
