import React from "react";

import CheckCircleIcon from "@heroicons/react/outline/CheckCircleIcon";
import ExclamationCircleIcon from "@heroicons/react/outline/ExclamationCircleIcon";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import PropTypes from "prop-types";

import { message } from "./messageUtils";
export { message };

import "./Message.less";

Message.propTypes = {
  messageKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

Message.defaultProps = {
  closable: true,
  icon: "info",
};

export default function Message({ messageKey, closable, icon, children }) {
  function getIconDisplay() {
    switch (icon) {
      case "success":
        return <CheckCircleIcon className="w-6 h-6 text-success" />;
      case "error":
        return <ExclamationIcon className="w-6 h-6 text-danger" />;
      case "info":
        return <InformationCircleIcon className="w-6 h-6 text-blue-600" />;
      case "warning":
      case "warn":
        return <ExclamationCircleIcon className="w-6 h-6 text-yellow-600" />;
      default:
        return icon;
    }
  }

  return (
    <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">{getIconDisplay()}</div>
          <div className="ml-3 flex-1 pt-0.5">
            <div className="text-sm font-medium text-left text-gray-900">
              {children}
            </div>
          </div>
          {closable && (
            <div className="flex flex-shrink-0 ml-4">
              <button
                className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                onClick={() => message.destroy(messageKey)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
