import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

MultiBrandNavigation.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.object),
  activeBrandId: PropTypes.number,
  setActiveBrandId: PropTypes.func,
};

MultiBrandNavigation.defaultProps = {
  brands: [],
};

export default function MultiBrandNavigation({
  brands,
  activeBrandId,
  setActiveBrandId,
}) {
  const { t } = useTranslation();

  return (
    <div
      className="relative bg-header text-on-header"
      data-testid="multi-brand-navigation"
    >
      <div className="w-full pt-4 pb-2 text-center font-display">
        {t("multiBrand.ourConcepts")}
      </div>
      <div className="flex w-full pb-4 overflow-x-scroll overflow-y-hidden multi-brand sm:justify-center">
        {brands.map((brand, index) => (
          <div
            key={index}
            className={classNames([
              "relative border-4 cursor-pointer mx-4 transition rounded-full",
              activeBrandId === brand.id
                ? "border-primary"
                : "border-transparent opacity-60 hover:opacity-80 filter saturate-50 transform scale-90 hover:scale-100",
            ])}
            onClick={() => setActiveBrandId(brand.id)}
          >
            {brand.logoUrl ? (
              <img
                className="w-[100px] sm:w-[120px] max-w-[120px]"
                src={brand.logoUrl}
              />
            ) : (
              <div className="w-[100px] sm:w-[120px] max-w-[120px] h-[100px] sm:h-[120px]">
                <div className="flex items-center justify-center h-full px-4 py-2 text-center rounded-full bg-default">
                  {brand.label}
                </div>
              </div>
            )}
            <div
              className={classNames([
                "absolute w-2 h-2 -bottom-[4px] left-1/2 -ml-1",
                activeBrandId !== brand.id && "hidden",
              ])}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
