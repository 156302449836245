import { importScript } from "./importScript";

const PAYMENT_REQUEST_ENABLED = false;

export function importStripeScript({ onComplete }) {
  if (!window.stripeScriptImported) {
    window.stripeScriptImported = true;
    importScript("https://js.stripe.com/v3/", { onComplete });
  } else if (onComplete) {
    onComplete();
  }
}

export function initializeStripe({
  stripeKey,
  stripeAccountId,
  stripeNewCardElement,
  stripeNewCardElementFont,
  stripeNewCardElementStyle,
  country,
  currency,
  label,
  amount,
  shippingOptions = [],
  newCardSelector,
  paymentRequestButtonSelector,
  paymentRequestLabelSelector,
  paymentRequestButtonStyle,
  onPaymentRequestButtonClick,
  onPaymentRequestPaymentMethod,
}) {
  // Try to initialize Stripe. If it hasn't been defined, wait for script to load and retry
  window.stripeTries = window.stripeTries || 0;
  try {
    if (!window.stripe) {
      // eslint-disable-next-line no-undef
      window.stripe = Stripe(
        stripeKey,
        stripeAccountId && { stripeAccount: stripeAccountId },
      );
    } else {
      if (window.stripe._stripeAccount !== stripeAccountId) {
        window.stripe = null;
        // eslint-disable-next-line no-undef
        window.stripe = Stripe(
          stripeKey,
          stripeAccountId && { stripeAccount: stripeAccountId },
        );
      }
    }
  } catch (e) {} // eslint-disable-line no-empty
  if (!window.stripe) {
    window.stripeTries++;
    if (window.stripeTries > 10) return; // fail after an arbitrary number of tries
    window.setTimeout(() => {
      initializeStripe();
    }, 200);
    return;
  }

  const elements = window.stripe.elements({
    fonts: [stripeNewCardElementFont],
  });

  let newCardElement = stripeNewCardElement;
  if (!newCardElement) {
    newCardElement = elements.create("card", {
      style: stripeNewCardElementStyle,
      hidePostalCode: true,
    });
    newCardElement.mount(newCardSelector);
  }

  initializeStripePaymentRequest({
    elements,
    country,
    currency,
    label,
    amount,
    shippingOptions,
    paymentRequestButtonSelector,
    paymentRequestLabelSelector,
    paymentRequestButtonStyle,
    onPaymentRequestButtonClick,
    onPaymentRequestPaymentMethod,
  });

  return newCardElement;
}

function initializeStripePaymentRequest({
  elements,
  country,
  currency,
  label,
  amount,
  shippingOptions = [],
  paymentRequestButtonSelector,
  paymentRequestLabelSelector,
  paymentRequestButtonStyle,
  onPaymentRequestButtonClick,
  onPaymentRequestPaymentMethod,
}) {
  if (!PAYMENT_REQUEST_ENABLED) return;

  const paymentRequest = window.stripe.paymentRequest({
    country,
    currency,
    total: {
      label,
      amount,
    },
    shippingOptions,
  });

  const paymentRequestButton = elements.create("paymentRequestButton", {
    paymentRequest,
    style: {
      paymentRequestButton: paymentRequestButtonStyle,
    },
  });

  paymentRequest.canMakePayment().then((result) => {
    if (result) {
      paymentRequestButton.mount(paymentRequestButtonSelector);
      paymentRequestButton.addEventListener(
        "click",
        onPaymentRequestButtonClick,
      );
      if (result.applePay) {
        document.querySelector(paymentRequestLabelSelector).innerHTML =
          "Apple Pay";
      } else {
        // source: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
        const isIE = /* @cc_on!@ */ false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;
        if (isEdge) {
          document.querySelector(paymentRequestLabelSelector).innerHTML =
            "Microsoft Pay";
        } else {
          document.querySelector(paymentRequestLabelSelector).innerHTML =
            "Google Pay";
        }
      }
    } else {
      document.querySelector(paymentRequestButtonSelector).style.display =
        "none";
      document.querySelector(paymentRequestLabelSelector).style.display =
        "none";
    }
  });

  paymentRequest.on("paymentmethod", onPaymentRequestPaymentMethod);
}
