import React from "react";

import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";

SlideOver.propTypes = {
  visible: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  overlay: PropTypes.bool,
  enterFrom: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  rest: PropTypes.any,
};

SlideOver.defaultProps = {
  overlay: true,
  enterFrom: "right",
};

export default function SlideOver({
  visible,
  closePanel,
  children,
  overlay,
  enterFrom,
  className,
  panelClassName,
  ...rest
}) {
  let klass = "slide-over";
  if (className) {
    klass += ` ${className}`;
  }
  let panelKlass = "slide-over-panel w-screen w-full";
  if (panelClassName) {
    panelKlass += ` ${panelClassName}`;
  }

  const translateAxis = ["left", "right"].includes(enterFrom) ? "x" : "y";

  return (
    <Transition
      className={klass}
      show={visible}
      enter="duration-200"
      leave="duration-200"
      unmount={false}
      {...rest}
    >
      <section
        className="fixed inset-0 z-50 overflow-hidden"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="absolute inset-0 overflow-hidden">
          {/* <!--
            Background overlay, show/hide based on slide-over state.

            Entering: "ease-in-out duration-200"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in-out duration-200"
              From: "opacity-100"
              To: "opacity-0"
          --> */}
          {overlay && (
            <Transition
              className="absolute inset-0 transition-opacity bg-black bg-opacity-50"
              aria-hidden="true"
              onClick={closePanel}
              show={visible}
              enter="ease-in-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            />
          )}

          <div className={`absolute inset-y-0 ${enterFrom}-0 max-w-full flex`}>
            {/* <!--
              Slide-over panel, show/hide based on slide-over state.

              Entering: "transform transition ease-in-out duration-200"
                From: "translate-x-full"
                To: "translate-x-0"
              Leaving: "transform transition ease-in-out duration-200"
                From: "translate-x-0"
                To: "translate-x-full"
            --> */}
            <Transition
              className={panelKlass}
              show={visible}
              enter="transform transition ease-in-out duration-200"
              enterFrom={`translate-${translateAxis}-full`}
              enterTo={`translate-${translateAxis}-0`}
              leave="transform transition ease-in-out duration-200"
              leaveFrom={`translate-${translateAxis}-0`}
              leaveTo={`translate-${translateAxis}-full`}
            >
              <div className="flex flex-col h-full overflow-y-auto shadow-xl">
                <div className="relative flex-1">{children}</div>
              </div>
            </Transition>
          </div>
        </div>
      </section>
    </Transition>
  );
}
