import React from "react";

import ReactDOM from "react-dom";

import "./index.less";
import "./phone-input-styles.less";
import App from "./App";
import DevImports from "./DevImports";

ReactDOM.render(
  <React.StrictMode>
    <>
      {(import.meta.env.DEV || import.meta.env.VITE_UAT_BUILD) && (
        <DevImports />
      )}
      <App />
    </>
  </React.StrictMode>,
  document.getElementById("root"),
);
