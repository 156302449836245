import { gql } from "@apollo/client";

export const TIMESLOTS_QUERY = gql`
  query GetTimeslots($servingDate: ISO8601Date) {
    getTimeslots(servingDate: $servingDate) {
      closingTimeForLunch
      closingTimeForDay
      outletOperatingHours {
        start
        end
      }
      servingDate
      upcomingDates {
        date
        name
        messageCode
        message
      }
      timeslots {
        id
        startTime
        endTime
        rangeLabel
        type
        label
        leadTime
        isAvailable
        fulfilmentTypes
      }
    }
  }
`;
