import React, { useContext } from "react";

import { useMutation, useApolloClient } from "@apollo/client";
import find from "lodash/find";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import MenuContext from "@/components/Menu/menuContext";
import QuantityControl from "@/components/QuantityControl";
import {
  ADD_ITEM_TO_CART_MUTATION,
  ADD_ITEM_TO_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/addItemToCart";
import {
  REMOVE_ITEM_FROM_CART_MUTATION,
  REMOVE_ITEM_FROM_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/removeItemFromCart";
import {
  UPDATE_ITEM_IN_CART_MUTATION,
  UPDATE_ITEM_IN_CART_OPTIMISED_MUTATION,
} from "@/graphql/mutations/updateItemInCart";
import {
  GET_CART_OPTIMISED_QUERY,
  GET_CART_QUERY,
} from "@/graphql/queries/getCart";
import { ITEM_STOCK_QUERY } from "@/graphql/queries/getItem";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import constants from "@/utils/constants";
import { getItemConfigurationTotal } from "@/utils/itemConfiguration";

import AppContext from "../App/appContext";

MenuItemControls.propTypes = {
  itemId: PropTypes.number,
  item: PropTypes.object,
  cartItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cartItem: PropTypes.object,
  quantity: PropTypes.number,
  size: PropTypes.oneOf(["regular", "small"]),
  showConfirmation: PropTypes.func,
  showAddToCartButtonOnZero: PropTypes.bool,
  quantityLeft: PropTypes.number,
  unitPriceFractional: PropTypes.number,
  checkPromotedProducts: PropTypes.bool,
};

MenuItemControls.defaultProps = {
  size: "regular",
  showAddToCartButtonOnZero: false,
  checkPromotedProducts: true,
};

export default function MenuItemControls({
  itemId,
  item,
  cartItemId,
  cartItem,
  quantity,
  size,
  quantityLeft,
  showConfirmation,
  showAddToCartButtonOnZero,
  unitPriceFractional,
  checkPromotedProducts,
}) {
  const client = useApolloClient();
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();
  const { cartQuery } = useContext(CartContext);
  const { activeBrandId } = useContext(MenuContext);
  const { configQuery } = useContext(ConfigContext);
  const [appState, setAppState] = useContext(AppContext);

  const useOptimisedDinerCartType = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OPTIMISED_DINER_CART_TYPE,
  );

  const [addToCart, { loading: addLoading }] = useMutation(
    useOptimisedDinerCartType
      ? ADD_ITEM_TO_CART_OPTIMISED_MUTATION
      : ADD_ITEM_TO_CART_MUTATION,
    {
      update: (_, { data }) => {
        const updatedCartData = useOptimisedDinerCartType
          ? data?.addItemToCartOptimised
          : data?.addItemToCart;
        client.writeQuery({
          query: useOptimisedDinerCartType
            ? GET_CART_OPTIMISED_QUERY
            : GET_CART_QUERY,
          data: {
            cart: updatedCartData,
            ...(useOptimisedDinerCartType
              ? { cartOptimised: updatedCartData }
              : {}),
          },
        });

        analytics.trackEventWithProperties(
          events.product_added,
          find(
            (useOptimisedDinerCartType
              ? data?.addItemToCartOptimised
              : data?.addItemToCart
            )?.topLevelItems,
            (topLevelItem) => topLevelItem?.item?.id === itemId,
          ),
        );
      },
      errorPolicy: "all",
      context: { graph: "diners" },
    },
  );

  const [updateInCart, { loading: updateLoading }] = useMutation(
    useOptimisedDinerCartType
      ? UPDATE_ITEM_IN_CART_OPTIMISED_MUTATION
      : UPDATE_ITEM_IN_CART_MUTATION,
    {
      update: (_, { data }) => {
        const updatedCartData = useOptimisedDinerCartType
          ? data?.updateItemInCartOptimised
          : data?.updateItemInCart;
        client.writeQuery({
          query: useOptimisedDinerCartType
            ? GET_CART_OPTIMISED_QUERY
            : GET_CART_QUERY,
          data: {
            cart: updatedCartData,
            ...(useOptimisedDinerCartType
              ? { cartOptimised: updatedCartData }
              : {}),
          },
        });

        // TODO: Check for quantity change and trigger the right event
        analytics.trackEventWithProperties(
          events.product_added,
          find(
            (useOptimisedDinerCartType
              ? data?.updateItemInCartOptimised
              : data?.updateItemInCart
            )?.topLevelItems,
            (topLevelItem) => topLevelItem?.id === cartItemId,
          ),
        );
      },
      errorPolicy: "all",
      context: { graph: "diners" },
    },
  );
  const [removeItemFromCart, { loading: removeLoading }] = useMutation(
    useOptimisedDinerCartType
      ? REMOVE_ITEM_FROM_CART_OPTIMISED_MUTATION
      : REMOVE_ITEM_FROM_CART_MUTATION,
    {
      update: (_, { data }) => {
        const updatedCartData = useOptimisedDinerCartType
          ? data?.removeItemFromCartOptimised
          : data?.removeItemFromCart;
        // Reading query before updating to find removed product
        const { cart } = client.readQuery({
          query: useOptimisedDinerCartType
            ? GET_CART_OPTIMISED_QUERY
            : GET_CART_QUERY,
        });
        analytics.trackEventWithProperties(
          events.product_removed,
          find(
            cart?.topLevelItems,
            (topLevelItem) => topLevelItem?.id === cartItemId,
          ),
        );

        client.writeQuery({
          query: useOptimisedDinerCartType
            ? GET_CART_OPTIMISED_QUERY
            : GET_CART_QUERY,
          data: {
            cart: updatedCartData,
            ...(useOptimisedDinerCartType
              ? { cartOptimised: updatedCartData }
              : {}),
          },
        });
      },
      refetchQueries: [
        {
          query: ITEM_STOCK_QUERY,
          context: { graph: "diners" },
          variables: { itemId: parseInt(itemId) },
          fetchPolicy: "cache-and-network",
        },
      ],
      errorPolicy: "all",
      context: { graph: "diners" },
    },
  );

  function addNewItemToOptimistic(newQuantity) {
    // Use spread for shallow clone vs deep clone
    const itemUniqueId = uuidv4();
    const brand = {
      id: activeBrandId,
      label: configQuery?.brands?.find((brand) => brand.id === activeBrandId)
        ?.label,
    };
    const optimisticallyAddedItem = {
      id: itemUniqueId,
      name: item.label,
      translation: {},
      quantity: newQuantity,
      notes: null,
      quantityLeft: null,
      subtotal: newQuantity * unitPriceFractional,
      itemModifierGroupId: null,
      item: {
        identifier: null,
        ...item,
        sections: [],
        idBreadcrumb: [`i${itemId}`],
      },
      brand: activeBrandId ? brand : null,
      sortedSubItems: [],
      subItems: [],
      __typename: useOptimisedDinerCartType
        ? "SubItemTypeOptimised"
        : "SubItem",
    };
    // add to the front to show at the top
    return [optimisticallyAddedItem, ...(cartQuery?.cart?.topLevelItems || [])];
  }

  function getUpdatedTopLevelItems(selectedCartItemId, newQuantity) {
    // Use spread for shallow clone vs deep clone
    let updatedTopLevelItems =
      JSON.parse(JSON.stringify(cartQuery?.cart?.topLevelItems)) || [];
    let updatedCartItem = updatedTopLevelItems.find(
      (cartItem) => cartItem?.id === selectedCartItemId,
    );
    const unitPrice = Math.round(
      updatedCartItem.subtotal / updatedCartItem.quantity,
    );
    updatedCartItem.quantity = newQuantity;
    updatedCartItem.subtotal = newQuantity * unitPrice;
    return updatedTopLevelItems;
  }

  function removeFromTopLevelItems(selectedCartItemId) {
    // Use spread for shallow clone vs deep clone
    var updatedTopLevelItems = [...(cartQuery?.cart?.topLevelItems || [])];
    updatedTopLevelItems = updatedTopLevelItems.filter(
      (cartItem) => cartItem?.id !== selectedCartItemId,
    );
    return updatedTopLevelItems;
  }

  return (
    <QuantityControl
      id="menu-item"
      quantity={quantity}
      size={size}
      disabled={addLoading || updateLoading || removeLoading}
      quantityLeft={quantityLeft}
      showConfirmation={showConfirmation}
      showAddToCartButtonOnZero={showAddToCartButtonOnZero}
      onChange={(newQuantity) => {
        if (quantity === newQuantity) {
          return;
        }
        if (quantity === 0 && newQuantity !== 0) {
          if (!appState.crossSellingProductId && checkPromotedProducts) {
            setAppState({
              ...appState,
              crossSellTriggerProductId: itemId,
            });
          }
          trackEvent(eventsV2.addToCart, {
            value: getItemConfigurationTotal(item) * (newQuantity - quantity),
            item: { ...item, label: item.label || cartItem.name },
          });
          addToCart({
            variables: {
              itemConfiguration: {
                itemId,
                quantity: newQuantity,
              },
            },
            optimisticResponse: {
              [useOptimisedDinerCartType
                ? "addItemToCartOptimised"
                : "addItemToCart"]: {
                ...cartQuery?.cart,
                topLevelItems: addNewItemToOptimistic(newQuantity),
                paymentBreakdown: {
                  ...cartQuery.cart.paymentBreakdown,
                  total: null,
                },
                __typename: useOptimisedDinerCartType
                  ? "DinerCartTypeOptimised"
                  : "Cart",
              },
            },
          });
        } else if (newQuantity === 0) {
          trackEvent(eventsV2.removeFromCart, {
            value:
              ((cartItem?.subtotal && cartItem.subtotal / quantity) ||
                item.unitPriceFractional) *
              (newQuantity - quantity),
            item: { ...item, label: item.label || cartItem.name },
          });
          removeItemFromCart({
            variables: {
              lineItemId: cartItemId,
            },
            optimisticResponse: {
              [useOptimisedDinerCartType
                ? "removeItemFromCartOptimised"
                : "removeItemFromCart"]: {
                ...cartQuery?.cart,
                topLevelItems: removeFromTopLevelItems(cartItemId),
                paymentBreakdown: {
                  ...cartQuery.cart.paymentBreakdown,
                  total: null,
                },
                __typename: useOptimisedDinerCartType
                  ? "DinerCartTypeOptimised"
                  : "Cart",
              },
            },
          });
        } else if (newQuantity !== quantity) {
          const qtyDiff = newQuantity - quantity;
          if (qtyDiff > 0) {
            trackEvent(eventsV2.addToCart, {
              value:
                ((cartItem?.subtotal && cartItem.subtotal / quantity) ||
                  item.unitPriceFractional) * qtyDiff,
              item: { ...item, label: item.label || cartItem.name },
            });
          } else {
            trackEvent(eventsV2.removeFromCart, {
              value:
                ((cartItem?.subtotal && cartItem.subtotal / quantity) ||
                  item.unitPriceFractional) * qtyDiff,
              item: { ...item, label: item.label || cartItem.name },
            });
          }
          updateInCart({
            variables: {
              itemConfiguration: {
                lineItemId: cartItemId,
                quantity: newQuantity,
              },
            },
            optimisticResponse: {
              [useOptimisedDinerCartType
                ? "updateItemInCartOptimised"
                : "updateItemInCart"]: {
                ...cartQuery?.cart,
                topLevelItems: getUpdatedTopLevelItems(cartItemId, newQuantity),
                paymentBreakdown: {
                  ...cartQuery.cart.paymentBreakdown,
                  total: null,
                },
                __typename: useOptimisedDinerCartType
                  ? "DinerCartTypeOptimised"
                  : "Cart",
              },
            },
          });
        }
      }}
    />
  );
}
