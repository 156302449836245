import React, { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Image from "@/common/Image";
import PoweredByAtlas from "@/common/PoweredByAtlas";
import ConfigContext from "@/components/Config/configContext";

export default function Footer() {
  const { t } = useTranslation();
  const { pathConfig, outletQrHash, configQuery } = useContext(ConfigContext);

  const dinerManifest = window.dinerManifest;
  const hasMobileLogo = !!dinerManifest?.logo?.footer?.mobile?.src;

  const hasFacebookLink = !!dinerManifest?.footer?.socialLinks?.facebook;
  const hasTwitterLink = !!dinerManifest?.footer?.socialLinks?.twitter;
  const hasInstagramLink = !!dinerManifest?.footer?.socialLinks?.instagram;

  const hasCookiesPolicy = !!dinerManifest?.footer?.legal?.cookiesPolicy;
  const hasDocumentation = !!dinerManifest?.footer?.legal?.documentation;
  const hasPrivacyPolicy = !!dinerManifest?.footer?.legal?.privacyPolicy;

  const currentUsername = useMemo(() => {
    return configQuery?.config?.accountId ?? "guest";
  }, [configQuery?.config?.accountId]);

  return (
    <footer
      className="z-30 bg-footer text-on-footer"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-screen-xl px-4 pt-12 pb-16 mx-auto sm:px-6 lg:pt-16 lg:px-8">
        {outletQrHash ? (
          <></>
        ) : dinerManifest?.footer?.html ? (
          <div
            dangerouslySetInnerHTML={{ __html: dinerManifest?.footer?.html }}
          />
        ) : (
          <>
            <div className="xl:grid xl:grid-cols-4 xl:gap-8">
              <div className="space-y-8 xl:col-span-1">
                <Image
                  className={
                    "w-auto h-12" + (hasMobileLogo ? " hidden sm:block" : "")
                  }
                  src={dinerManifest?.footer?.logo?.src}
                  alt={dinerManifest?.footer?.logo?.alt}
                  style={dinerManifest?.footer?.logo?.style}
                />
                {hasMobileLogo && (
                  <Image
                    className="w-auto h-12 sm:hidden"
                    src={dinerManifest?.footer?.logo?.mobile?.src}
                    alt={dinerManifest?.footer?.logo?.mobile?.alt}
                    style={dinerManifest?.footer?.logo?.mobile?.style}
                  />
                )}
                <p
                  className="text-sm text-on-footer2"
                  dangerouslySetInnerHTML={{
                    __html: dinerManifest?.footer?.shortBrandCopy,
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-8 mt-12 xl:mt-0 xl:col-span-3 md:grid-cols-4">
                <div>
                  <Link to="/">
                    <h3 className="text-xs font-bold tracking-wider text-on-footer hover:text-on-footer hover:underline">
                      {t("footer.sitemap.home")}
                    </h3>
                  </Link>

                  <ul className="mt-3 space-y-1">
                    {!window.dinerManifest?.menuOnly && (
                      <li>
                        <Link
                          to={pathConfig?.menuPath}
                          className="text-xs text-on-footer hover:text-on-footer hover:underline"
                        >
                          {t("footer.sitemap.menu")}
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link
                        to="/#reviews"
                        className="text-xs text-on-footer hover:text-on-footer hover:underline"
                      >
                        {t("footer.sitemap.customerReviews")}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/faq"
                        className="text-xs text-on-footer hover:text-on-footer hover:underline"
                      >
                        {t("footer.sitemap.faqs")}
                      </Link>
                    </li>
                  </ul>
                </div>

                {(hasCookiesPolicy || hasDocumentation || hasPrivacyPolicy) && (
                  <div>
                    <h3 className="text-xs font-bold tracking-wider text-on-footer">
                      {t("footer.legal.label")}
                    </h3>

                    <ul className="mt-3 space-y-1">
                      {hasCookiesPolicy && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.legal?.cookiesPolicy}
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.legal.cookiesPolicy")}
                          </a>
                        </li>
                      )}

                      {hasDocumentation && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.legal?.documentation}
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.legal.documentation")}
                          </a>
                        </li>
                      )}

                      {hasPrivacyPolicy && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.legal?.privacyPolicy}
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.legal.privacyPolicy")}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                {(hasFacebookLink || hasInstagramLink || hasTwitterLink) && (
                  <div className="mt-0">
                    <h3 className="text-xs font-bold tracking-wider text-on-footer">
                      {t("footer.socialLinks.label")}
                    </h3>
                    <ul className="mt-3 space-y-1">
                      {hasInstagramLink && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.socialLinks?.instagram}
                            target="_blank"
                            rel="noreferrer"
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.socialLinks.instagram")}
                          </a>
                        </li>
                      )}

                      {hasFacebookLink && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.socialLinks?.facebook}
                            target="_blank"
                            rel="noreferrer"
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.socialLinks.facebook")}
                          </a>
                        </li>
                      )}

                      {hasTwitterLink && (
                        <li>
                          <a
                            href={dinerManifest?.footer?.socialLinks?.twitter}
                            target="_blank"
                            rel="noreferrer"
                            className="text-xs text-on-footer hover:text-on-footer hover:underline"
                          >
                            {t("footer.socialLinks.twitter")}
                          </a>
                        </li>
                      )}
                      {dinerManifest?.footer?.socialLinks?.others?.map(
                        (link, index) => (
                          <li key={index}>
                            <a
                              href={link.href}
                              target="_blank"
                              rel="noreferrer"
                              className="text-xs text-on-footer hover:text-on-footer hover:underline"
                            >
                              {link.label}
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
                {dinerManifest?.footer?.contactInfo && (
                  <div className="mt-0">
                    <h3 className="text-xs font-bold tracking-wider text-on-footer">
                      {t("footer.contactInfo.label")}
                    </h3>
                    <ul className="mt-3 space-y-1">
                      {dinerManifest?.footer?.contactInfo?.map(
                        (content, index) => (
                          <div
                            className="text-xs text-on-footer2"
                            key={index}
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col items-center justify-between pt-6 mt-12 space-y-6 border-t border-on-footer md:space-y-0 md:flex-row">
          <div
            className="text-sm text-center text-on-footer md:text-left"
            dangerouslySetInnerHTML={{
              __html: dinerManifest?.footer?.copyright.replace(
                "{{year}}",
                new Date().getFullYear(),
              ),
            }}
          />
          <a
            href={`https://atlas.kitchen?utm_source=${location?.hostname}&utm_medium=referral&utm_campaign=atlas_online&utm_content=${currentUsername}`}
            aria-label="atlas website"
            target="_blank"
            rel="noreferrer"
          >
            <PoweredByAtlas />
          </a>
        </div>
      </div>
    </footer>
  );
}
