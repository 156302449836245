export const ALCOHOL_LEGAL_AGE = 18;

export default function getAgeFromDateOfBirth(dateOfBirth) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  // if current month is before birth month OR if current month is birth month but current day is before birth day
  // consider as not yet having reached the birthday/age
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
