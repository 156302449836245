import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

AddressSearchResult.propTypes = {
  location: PropTypes.object,
  onClick: PropTypes.func,
  optionKlass: PropTypes.string,
};

export default function AddressSearchResult({
  location,
  onClick,
  optionKlass,
}) {
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={`flex justify-between ${optionKlass}`}>
      <div className="flex flex-col flex-grow text-left">
        <span>{location.address}</span>
        {location.nearestOutlet && (
          <span className="text-xs opacity-60">
            {t("account.addresses.nearestOutletServedBy", {
              outletLabel: location.nearestOutlet.labelForPickup,
            })}
          </span>
        )}
      </div>
      <span className="text-right opacity-50 text-default">
        {location.nearestOutlet &&
          location.nearestOutlet.distanceFromAddress &&
          t("account.addresses.distanceFromAddress", {
            distance: (
              location.nearestOutlet.distanceFromAddress / 1000
            ).toFixed(1),
          })}
      </span>
    </div>
  );
}
