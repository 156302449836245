import React, { useContext } from "react";

import { ArrowRightIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PointIcon from "@/common/Loyalty/PointIcon";
import useAuth from "@/hooks/useAuth";
import formatPrice from "@/utils/formatPrice";

import ConfigContext from "../Config/configContext";

LoyaltyClaimCTA.propTypes = {
  order: PropTypes.object,
};

export default function LoyaltyClaimCTA({ order }) {
  const { t } = useTranslation();
  const { loggedIn } = useAuth();
  const isLoggedIn = loggedIn();

  const { configQuery } = useContext(ConfigContext);
  const pointProgram = configQuery?.config.pointProgram;

  if (
    (!isLoggedIn && !order?.claimTokenUrl) ||
    (isLoggedIn && !pointProgram?.userPortalUrl) ||
    !(pointProgram?.currencyValueInCents > 0)
  ) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <div
        className="inline w-full max-w-xs text-base font-semibold m-2 mx-auto flex-col items-center font-poppins shadow"
        style={{
          color: pointProgram?.colors?.textColor || "inherit",
          borderRadius: "var(--border-radius)",
        }}
      >
        <div
          className="grow pt-[16px] px-[24px]"
          style={{
            backgroundColor: `${
              pointProgram?.colors?.brandColor || "inherit"
            }b3`,
            borderTopLeftRadius: "var(--border-radius)",
            borderTopRightRadius: "var(--border-radius)",
          }}
        >
          <div className="flex flex-col items-center pb-[12px] gap-1 text-base text-center">
            {pointProgram.isCustomCurrency ? (
              <>
                <div className="flex gap-1">
                  <div className="text-lg flex items-end translate-y-0.5">
                    {order.eligiblePoints}
                  </div>
                  <PointIcon />
                  <div className="text-lg flex items-end translate-y-0.5">
                    {t("menu.checkoutModal.success.loyalty.currencyEarned")}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center">
                <div className="flex justify-center gap-1 text-lg">
                  {formatPrice(order.eligiblePointsValue)}
                </div>
                {t("menu.checkoutModal.success.loyalty.cashbackEarned")}
              </div>
            )}
          </div>
        </div>

        <div
          className="flex flex-col grow p-[16px] gap-[24px] text-black"
          style={{
            backgroundColor: "white",
            borderBottomLeftRadius: "var(--border-radius)",
            borderBottomRightRadius: "var(--border-radius)",
          }}
        >
          <button
            className="font-semibold gap-2 flex items-center justify-center py-2 px-4"
            style={{
              backgroundColor: `${
                pointProgram?.colors?.brandColor || "inherit"
              }`,
              color: pointProgram?.colors?.textColor || "inherit",
              borderRadius: "var(--border-radius)",
            }}
            onClick={() =>
              window.open(
                isLoggedIn ? pointProgram.userPortalUrl : order.claimTokenUrl,
                "_blank",
              )
            }
          >
            {isLoggedIn
              ? t("menu.checkoutModal.success.loyalty.viewWallet")
              : t("menu.checkoutModal.success.loyalty.claimNow")}
            <ArrowRightIcon width={24} height={24} />
          </button>
        </div>
      </div>
    </div>
  );
}
