import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import useTranslatedText from "@/hooks/useTranslationText";
import formatPrice from "@/utils/formatPrice";

OrderHistoryItem.propTypes = {
  orderItem: PropTypes.object,
  nestedLevel: PropTypes.number,
};

OrderHistoryItem.defaultProps = {
  nestedLevel: 0,
};

export default function OrderHistoryItem({ orderItem, nestedLevel }) {
  const price = orderItem.price || orderItem.subtotal;

  const itemLabel = useTranslatedText({
    resource: orderItem,
    fallbackValue: orderItem?.name,
  });

  return (
    <>
      <div
        className={classNames([
          "font-medium grid gap-6 grid-flow-col-dense grid-cols-6",
          nestedLevel > 0 && "text-default2",
          nestedLevel > 0 ? "text-xs mt-1" : "text-sm mt-2",
        ])}
      >
        <div className="col-span-4">
          {nestedLevel > 0 ? (
            <div className="flex pr-2 text-xs font-medium">
              {[...Array(nestedLevel)].map((_, i) => {
                return <div key={`space-${i}`} className="w-2" />;
              })}
              – {itemLabel}
              {price > 0 && <span className="ml-2">+{formatPrice(price)}</span>}
            </div>
          ) : (
            itemLabel
          )}
        </div>
        <div className="flex flex-col">
          {orderItem?.subItems?.length > 0 || orderItem.quantity > 1 // use presence of sub items to detect nested configurations
            ? `x${orderItem.quantity}`
            : ""}
        </div>
        <div className="flex flex-row-reverse text-right break-normal">
          {nestedLevel === 0 && formatPrice(price)}
        </div>
      </div>
      {orderItem.subItems &&
        orderItem?.subItems.length > 0 &&
        orderItem.subItems.map((subItem, index) => (
          <OrderHistoryItem
            key={index}
            orderItem={subItem}
            nestedLevel={nestedLevel + 1}
          />
        ))}
      {orderItem?.notes && (
        <OrderHistoryItem
          orderItem={{
            name: (
              <span className="italic">&quot;{orderItem?.notes}&quot;</span>
            ),
          }}
          nestedLevel={nestedLevel + 1}
        />
      )}
    </>
  );
}
