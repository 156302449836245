import React, { useContext } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Button from "@/common/Button";
import Image from "@/common/Image";
import ConfigContext from "@/components/Config/configContext";
import useAnalytics from "@/hooks/useAnalytics";

Banner.propTypes = {
  data: PropTypes.object.isRequired,
};

Banner.defaultProps = {
  data: {},
};

export default function Banner({ data }) {
  const { analytics, events } = useAnalytics();
  const { pathConfig } = useContext(ConfigContext);

  function renderActionButton() {
    if (data.actionButtonTitle || data.menuActionButton) {
      const Component = data.actionButtonLink ? "a" : Link;
      const componentProps = data.actionButtonLink
        ? { href: data.actionButtonLink, target: data.actionButtonTarget }
        : { to: data.actionButtonRoute || pathConfig?.menuPath };
      return (
        <div className="mt-4">
          <Component {...componentProps}>
            <Button
              type="primary"
              onClick={() => {
                if (!data.actionButtonLink && !data.actionButtonRoute) {
                  analytics.track(events.requested_menu_from_banner);
                }
              }}
              style={data.actionButtonStyle}
            >
              {data.actionButtonTitle || data.menuActionButton}
            </Button>
          </Component>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="relative banner">
      <div className="relative shadow-xl sm:overflow-hidden">
        <div className="absolute inset-0">
          <Image
            className="object-cover w-full h-full"
            alt="banner-image"
            src={data.background}
          />
        </div>
        {data.html ? (
          <div
            className="relative"
            dangerouslySetInnerHTML={{ __html: data.html }}
          />
        ) : (
          <div
            className="relative max-w-2xl px-3 py-16 mx-auto text-center sm:px-6 sm:py-24 lg:py-32"
            style={data.style}
          >
            {data.logo && (
              <div className="mb-6">
                <Image
                  className="mx-auto"
                  src={data.logo.src}
                  alt={data.logo.alt}
                  style={data.logo.style}
                />
              </div>
            )}
            <h1
              className="mb-3 text-2xl tracking-tight text-center sm:text-3xl font-display text-default"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <div
              className="max-w-xl mx-auto text-center text-body text-default"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            {renderActionButton()}
            {data?.afterButton && (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.afterButton,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
