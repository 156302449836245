import each from "lodash/each";
import first from "lodash/first";
import forEach from "lodash/forEach";
import get from "lodash/get";
import omit from "lodash/omit";
import setWith from "lodash/setWith";

import { PRODUCT_CONFIGURATION } from "@/utils/constants";

let workingTree = {};

export const getProductConfiguration = () => {
  localStorage.getItem(PRODUCT_CONFIGURATION);
};

export const setProductConfiguration = (value) => {
  localStorage.setItem(PRODUCT_CONFIGURATION, value);
};

const getStoreKey = (idBreadcrumb) => idBreadcrumb.join(".");

export const prefillFixedModifierConfiguration = (item, reset = true) => {
  if (reset) workingTree = {};
  if (item?.modifierGroups) {
    each(item?.modifierGroups, (modifierGroup) => {
      each(modifierGroup?.modifiers, (modifier) => {
        // ignore default quantity for modifiers that are configurable
        if (modifier?.defaultQuantity > 0 && !modifier?.isConfigurable) {
          updateProductConfiguration({
            idBreadcrumb: modifier?.idBreadcrumb,
            quantity: modifier?.defaultQuantity,
            increment: false,
          });
        }
      });
    });
  }
};

export const getStoreKeyWithQuantity = (storeKey) => `${storeKey}.quantity`;

// validations

export const isItemConfigurationValid = (item) => {
  return item.modifierGroups.every((modifierGroup) => {
    if (modifierGroup.isFixed) return true;

    return (
      isModifierGroupValid(modifierGroup) &&
      isNestedConfigurationValid(modifierGroup)
    );
  });
};

export const isModifierGroupValid = (modifierGroup, numFilled = null) => {
  if (modifierGroup.isFixed) return true;
  if (numFilled === null)
    numFilled = getModifierGroupSlotsFilled(modifierGroup);
  return (
    numFilled >= modifierGroup.selectionRequiredMin &&
    numFilled <= modifierGroup.selectionRequiredMax
  );
};

export const getModifierGroupSlotsFilled = (modifierGroup) => {
  if (modifierGroup.isFixed) return 0;
  return modifierGroup.modifiers.reduce(
    (total, modifier) =>
      total + getQuantityWithIdBreadcrumb(modifier.idBreadcrumb),
    0,
  );
};

export const getModifierGroupSlotsLeft = (modifierGroup, numFilled) => {
  if (modifierGroup.isFixed) return 0;
  return modifierGroup.selectionRequiredMax - numFilled;
};

export const getInvalidNestedConfigurationIndexes = (modifierGroup) => {
  let modifierIndex = -1;
  let nestedModifierGroupIndex = -1;
  modifierGroup.modifiers.some((modifier, index) => {
    if (modifier.modifierGroups.length > 0) {
      const quantity = getQuantityWithIdBreadcrumb(modifier.idBreadcrumb);
      // only check modifier if selected, ie. quantity > 0
      if (quantity > 0) {
        nestedModifierGroupIndex = modifier.modifierGroups.findIndex(
          (modifierGroup) => !isModifierGroupValid(modifierGroup),
        );
      }
      if (nestedModifierGroupIndex >= 0) {
        modifierIndex = index;
      }
    }
    return modifierIndex >= 0;
  });
  return [modifierIndex, nestedModifierGroupIndex];
};

export const isNestedConfigurationValid = (modifierGroup) => {
  // if either index is -1, it means the nested configurations are all valid
  return getInvalidNestedConfigurationIndexes(modifierGroup)[0] === -1;
};

export const getQuantityWithIdBreadcrumb = (idBreadcrumb) => {
  const storeKeyWithQuantity = getStoreKeyWithQuantity(
    getStoreKey(idBreadcrumb),
  );

  return get(workingTree, storeKeyWithQuantity, 0);
};

// for updating the configuration

export const updateProductConfiguration = ({
  idBreadcrumb = null,
  quantity = null,
  increment = true,
}) => {
  try {
    if (!idBreadcrumb || (quantity !== 0 && !quantity)) {
      throw new Error("ID breadcrumb or quantity is missing");
    }

    const storeKey = getStoreKey(idBreadcrumb);

    if (quantity === 0) {
      // Remove if quantity is 0
      workingTree = omit(workingTree, storeKey);
    } else {
      // TODO: Add null and undefined quantity
      // Set quantity
      const storeKeyWithQuantity = getStoreKeyWithQuantity(storeKey);
      setWith(
        workingTree,
        storeKeyWithQuantity,
        increment
          ? get(workingTree, storeKeyWithQuantity, 0) + quantity
          : quantity,
        Object,
      );
    }
  } catch (e) {
    alert(e);
  }
};

export const getItemConfigurationTotal = (item) => {
  if (!item) return 0;

  // base price + additional price
  return (
    item.unitPriceFractional +
    (item?.modifierGroups || []).reduce((additionalTotal, modifierGroup) => {
      return (
        additionalTotal +
        modifierGroup.modifiers.reduce((groupTotal, modifier) => {
          return (
            groupTotal +
            getQuantityWithIdBreadcrumb(modifier.idBreadcrumb) *
              getItemConfigurationTotal(modifier)
          );
        }, 0)
      );
    }, 0)
  );
};

// for generating payload

export const getItemId = (prefixedId = "") =>
  parseInt(prefixedId.replace("img", "").replace("i", ""));
const generatePayloadFromWorkingTree = ({
  workingTreeNode,
  isParent,
  quantity,
  notes,
  lineItemId,
  itemModifierGroupKey,
}) => {
  const payload = [];

  forEach(workingTreeNode, (value, key) => {
    if (key !== "quantity" && key !== "notes") {
      if (key.startsWith("img")) {
        payload.push(
          generatePayloadFromWorkingTree({
            workingTreeNode: value,
            isParent: false,
            itemModifierGroupKey: key,
          }),
        );
      } else {
        const nodePayload = {
          quantity: quantity || value.quantity || 1,
          notes: notes || value.notes,
          modifiers: generatePayloadFromWorkingTree({
            workingTreeNode: value,
            isParent: false,
          }).flat(),
        };
        if (lineItemId) {
          nodePayload["lineItemId"] = lineItemId;
        } else {
          nodePayload[isParent ? "itemId" : "modifierId"] = getItemId(key);
        }
        if (itemModifierGroupKey && !isParent) {
          nodePayload["itemModifierGroupId"] = Number(
            itemModifierGroupKey.replace("img", ""),
          );
        }
        payload.push(nodePayload);
      }
    }
  });

  return payload;
};

export const getItemPayloadFromWorkingTree = ({
  quantity,
  notes,
  lineItemId,
}) => {
  return (
    first(
      generatePayloadFromWorkingTree({
        workingTreeNode: workingTree,
        isParent: true,
        quantity,
        notes,
        lineItemId,
      }),
    ) ?? {
      itemId: lineItemId,
      quantity,
      notes,
      modifiers: [],
    }
  );
};

// for editing configurable line item

const getWorkingTreeFromLineItem = ({ lineItem, isParent = true }) => {
  const localWorkingTree = {};
  const treeNodeId = isParent
    ? getStoreKey(lineItem.item.idBreadcrumb)
    : lineItem.modifierId;

  localWorkingTree[treeNodeId] = {
    quantity: lineItem.quantity,
  };

  forEach(lineItem.subItems || [], (subItem) => {
    const itemModifierGroupKey = `img${subItem.itemModifierGroupId}`;
    localWorkingTree[treeNodeId][itemModifierGroupKey] =
      localWorkingTree[treeNodeId][itemModifierGroupKey] || {};
    Object.assign(
      localWorkingTree[treeNodeId][itemModifierGroupKey],
      getWorkingTreeFromLineItem({
        lineItem: subItem,
        isParent: false,
      }),
    );
  });

  return localWorkingTree;
};

export const setWorkingTreeFromLineItem = (lineItem) => {
  workingTree = getWorkingTreeFromLineItem({ lineItem });
  return workingTree;
};
