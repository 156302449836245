import React, { useContext, useMemo, useState } from "react";

import { Transition } from "@headlessui/react";
import PlusIcon from "@heroicons/react/outline/PlusIcon";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Spin from "@/common/Spin";
import MenuItemControls from "@/components/Menu/MenuItemControls";
import constants from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";

import ConfigContext from "../Config/configContext";

CartItem.propTypes = {
  cartItem: PropTypes.object,
  isCheckingOut: PropTypes.bool,
};

export default function CartItem({ cartItem, isCheckingOut }) {
  const { t, i18n } = useTranslation();
  const [onConfirmRemove, setOnConfirmRemove] = useState(null);
  const [cartItemDetails, setCartItemDetails] = useState({});

  const { configQuery } = useContext(ConfigContext);

  function toggleCartItemVisibility(cartItemId) {
    setCartItemDetails({
      ...cartItemDetails,
      ...{
        [cartItemId]: !cartItemDetails[cartItemId],
      },
    });
  }

  const cartItemLabel = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      cartItem
    ) {
      return (
        cartItem.translation?.title?.[i18n.resolvedLanguage] ??
        cartItem.translation?.title?.["default"] ??
        cartItem.name
      );
    } else {
      return cartItem.name;
    }
  }, [cartItem, i18n.resolvedLanguage, configQuery?.config?.features]);

  /*
    This factors in what's in your cart, so it currently shows sold out if it breaches quantityLeft of 0.
    Stocks remaining = 2 and you have 2 in your cart shouldn't trigger a sold out.
    However, if you breach it we should show an error message.
  */
  const soldOut =
    cartItem?.quantityLeft &&
    cartItem.quantityLeft < 0 &&
    Math.abs(cartItem?.quantityLeft) >= cartItem.quantity;
  const someItemsAreUnavailable =
    cartItem?.quantityLeft &&
    cartItem.quantityLeft < 0 &&
    Math.abs(cartItem?.quantityLeft) < cartItem.quantity;

  return (
    <div
      className={classNames([
        "relative cart-item group ",
        !onConfirmRemove && "p-4 hover:bg-default2",
      ])}
      data-testid={`${cartItem.name}-cart-item`}
    >
      {onConfirmRemove ? (
        <div className="w-full font-bold">
          <div className="flex flex-col min-h-full p-4 bg-default2">
            <div className="flex-grow">
              {t("menu.cart.removeConfirmation.copy", {
                itemName: cartItemLabel,
              })}
            </div>
            <div className="flex flex-row-reverse mt-4 space-x-3 space-x-reverse">
              <Button
                type="danger"
                onClick={() => {
                  onConfirmRemove();
                  setOnConfirmRemove(null);
                }}
              >
                {t("menu.cart.removeConfirmation.remove")}
              </Button>
              <Button onClick={() => setOnConfirmRemove(null)}>
                {t("menu.cart.removeConfirmation.cancel")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex space-x-4">
          <div className="flex-grow space-y-2">
            <div
              data-testid="cart-item-show-details"
              onClick={() => {
                if (
                  (cartItem?.subItems && cartItem?.subItems.length > 0) ||
                  cartItem?.notes?.length > 0
                )
                  toggleCartItemVisibility(cartItem?.id);
              }}
              className="flex flex-row items-start mt-1 font-bold"
            >
              <div className="flex-grow">{cartItemLabel}</div>
              {(cartItem?.subItems?.length > 0 ||
                cartItem?.notes?.length > 0) &&
                !isCheckingOut && (
                  <div className="ml-1 border rounded-full opacity-50 group-hover:opacity-100">
                    <PlusIcon
                      className={classNames([
                        "w-4 h-4 text-default transition transform",
                        {
                          "rotate-135": cartItemDetails[cartItem?.id],
                        },
                      ])}
                    />
                  </div>
                )}
            </div>
            <Transition
              show={
                (cartItem?.subItems?.length > 0 ||
                  cartItem?.notes?.length > 0) &&
                (isCheckingOut || !!cartItemDetails[cartItem?.id])
              }
              enter="transition ease-in-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={`text-default2 opacity-${
                  soldOut || someItemsAreUnavailable ? 50 : 80
                }`}
              >
                <div className="sub-items">
                  {cartItem?.sortedSubItems?.map((modifierGroup) =>
                    modifierGroup?.subItems?.map((subItem) => {
                      return (
                        <Transition.Child
                          key={`cart_item_${cartItem.id}_sub_item_${subItem.id}`}
                          enter="transition transform duration-200 ease-out"
                          enterFrom="opacity-0 translate-x-4"
                          enterTo="opacity-100 translate-x-0"
                          leave="transition transform ease-in"
                          leaveFrom="opacity-100 translate-x-0"
                          leaveTo="opacity-0 translate-x-4"
                        >
                          <SubItem
                            subItem={subItem}
                            isCheckingOut={isCheckingOut}
                          />
                        </Transition.Child>
                      );
                    }),
                  )}
                  {cartItem?.notes && (
                    <Transition.Child
                      key={`cart_item_${cartItem.id}_notes`}
                      enter="transition transform duration-200 ease-out"
                      enterFrom="opacity-0 translate-x-4"
                      enterTo="opacity-100 translate-x-0"
                      leave="transition transform ease-in"
                      leaveFrom="opacity-100 translate-x-0"
                      leaveTo="opacity-0 translate-x-4"
                    >
                      <div className="flex">
                        <div className="flex-initial mt-0.5 mr-1.5 opacity-30">
                          └
                        </div>
                        <div className="flex-initial italic">
                          &quot;{cartItem?.notes}&quot;
                        </div>
                      </div>
                    </Transition.Child>
                  )}
                </div>
              </div>
            </Transition>
            {!soldOut && !someItemsAreUnavailable ? (
              (cartItem?.notes || cartItem?.item?.isConfigurable) && (
                <a
                  data-testid={`edit-cart-item`}
                  className="block mt-3 underline text-default hover:text-primary hover:underline"
                  onClick={() => emitEvent("editCartItem", cartItem)}
                >
                  {t(
                    `menu.cart.${
                      cartItem?.item?.isConfigurable ? "edit" : "editNotes"
                    }`,
                  )}
                </a>
              )
            ) : (
              <div
                data-testid={`cart-item-${
                  soldOut ? "sold-out" : "some-items-unavailable"
                }`}
                className="mt-1 font-bold text-danger"
              >
                {soldOut && t("menu.cart.cartItem.soldOut")}
                {someItemsAreUnavailable &&
                  t("menu.cart.cartItem.someItemsAreUnavailable", {
                    count: cartItem?.quantityLeft + cartItem?.quantity,
                  })}
              </div>
            )}
          </div>

          <div className="flex flex-col items-end space-y-2">
            <MenuItemControls
              item={cartItem?.item}
              cartItem={cartItem}
              itemId={cartItem?.item?.id}
              quantity={cartItem?.quantity}
              quantityLeft={
                cartItem.quantityLeft
                  ? cartItem.quantityLeft + cartItem?.quantity
                  : null
              }
              cartItemId={cartItem?.id}
              size="small"
              showConfirmation={(onConfirm) =>
                setOnConfirmRemove(() => (quantity) => onConfirm(quantity))
              }
            />
            <div className="font-normal text-right">
              {typeof cartItem.id === "string" ? (
                <Spin logoClassName="ml-2 w-5 h-5 text-default" />
              ) : (
                formatPrice(cartItem?.subtotal)
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

SubItem.propTypes = {
  subItem: PropTypes.object.isRequired,
  isCheckingOut: PropTypes.bool,
  nestedLevel: PropTypes.number,
};

SubItem.defaultProps = {
  nestedLevel: 1,
};

function SubItem({ subItem, isCheckingOut, nestedLevel }) {
  const { i18n } = useTranslation();
  const { configQuery } = useContext(ConfigContext);

  const subItemLabel = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      subItem
    ) {
      return (
        subItem.translation?.title?.[i18n.resolvedLanguage] ??
        subItem.translation?.title?.["default"] ??
        subItem.name
      );
    } else {
      return subItem.name;
    }
  }, [subItem, i18n.resolvedLanguage, configQuery?.config?.features]);

  return (
    <div data-testid={`sub-cart-item-${subItem.id}`}>
      <div
        className={classNames([
          "flex sub-item items-start flex-grow",
          !isCheckingOut && "font-normal",
        ])}
      >
        {[...Array(nestedLevel - 1)].map((_, i) => (
          <div key={`space-${i}`} className="w-4" />
        ))}
        <span className="mt-0.5 mr-1.5 opacity-30">└</span>
        {subItem.quantity && subItem.quantity > 1 && (
          <div className="h-5 px-1 mt-0.5 font-normal rounded-sm border border-primary text-xs flex items-center mr-1.5">
            {subItem.quantity}&nbsp;×
          </div>
        )}
        <span>{subItemLabel}</span>
      </div>
      {subItem?.sortedSubItems?.map((modifierGroup) =>
        modifierGroup?.subItems?.map((subSubItem) => (
          <SubItem
            key={`sub-cart-item-${subSubItem.id}`}
            subItem={subSubItem}
            isCheckingOut={isCheckingOut}
            nestedLevel={nestedLevel + 1}
          />
        )),
      )}
    </div>
  );
}
