import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import useAnalytics from "@/hooks/useAnalytics";
import constants from "@/utils/constants";

FulfilmentSelectorActions.propTypes = {
  searching: PropTypes.bool,
  selectedTab: PropTypes.string,
  selectedOutlet: PropTypes.object,
  selectedAddress: PropTypes.object,
  setFulfilmentTypeLoading: PropTypes.bool,
  setFulfilmentType: PropTypes.func,
  onClose: PropTypes.func,
  closeAddressModal: PropTypes.func,
};

export default function FulfilmentSelectorActions({
  searching,
  selectedTab,
  selectedOutlet,
  selectedAddress,
  setFulfilmentTypeLoading,
  setFulfilmentType,
  onClose,
  closeAddressModal,
}) {
  const { t } = useTranslation();

  const { analytics, events } = useAnalytics();

  if (searching) return null;

  return (
    <div className="grid gap-3 mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
      <Button
        className="w-full sm:w-auto"
        type="primary"
        disabled={
          (selectedTab === constants.FULFILMENT_TYPES.PICKUP &&
            !selectedOutlet) ||
          (selectedTab === constants.FULFILMENT_TYPES.DELIVERY &&
            !selectedAddress) ||
          setFulfilmentTypeLoading
        }
        onClick={() => {
          if (
            selectedTab === constants.FULFILMENT_TYPES.PICKUP &&
            selectedOutlet
          ) {
            analytics.track(events.pickup_selected);
            setFulfilmentType({
              variables: {
                fulfilmentType: constants.FULFILMENT_TYPES.PICKUP,
                outletId: selectedOutlet.id,
              },
            });
          } else if (
            selectedTab === constants.FULFILMENT_TYPES.DELIVERY &&
            selectedAddress
          ) {
            setFulfilmentType({
              variables: {
                fulfilmentType: constants.FULFILMENT_TYPES.DELIVERY,
                addressId: selectedAddress.id,
                outletId: selectedOutlet.id,
              },
            });
          }
        }}
      >
        {t("addressPicker.confirm")}
      </Button>
      <Button
        className="w-full sm:w-auto"
        onClick={() => {
          onClose();
          closeAddressModal();
        }}
      >
        {t("addressPicker.cancel")}
      </Button>
    </div>
  );
}
