import React from "react";

import PropTypes from "prop-types";

GoogleIcon.propTypes = {
  rest: PropTypes.any,
};

export default function GoogleIcon({ ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.68 8.18177C15.68 7.6145 15.6291 7.06905 15.5345 6.54541H8V9.64359H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0654H13.2945C14.8073 12.669 15.68 10.6181 15.68 8.18177Z"
        fill="#4285F4"
      />
      <path
        d="M8.00022 16.0001C10.1602 16.0001 11.9711 15.2873 13.2948 14.0655L10.6984 12.051C9.98567 12.531 9.07658 12.8219 8.00022 12.8219C5.92022 12.8219 4.15294 11.4183 3.52022 9.52734H0.858398V11.5928C2.17476 14.2037 4.87294 16.0001 8.00022 16.0001Z"
        fill="#34A853"
      />
      <path
        d="M3.52 9.52001C3.36 9.04001 3.26545 8.53091 3.26545 8.00001C3.26545 7.4691 3.36 6.96001 3.52 6.48001V4.41455H0.858182C0.312727 5.49091 0 6.70546 0 8.00001C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52001Z"
        fill="#FBBC05"
      />
      <path
        d="M8.00022 3.18545C9.1784 3.18545 10.2257 3.59273 11.062 4.37818L13.3529 2.08727C11.9639 0.792727 10.1602 0 8.00022 0C4.87294 0 2.17476 1.79636 0.858398 4.41455L3.52022 6.48C4.15294 4.58909 5.92022 3.18545 8.00022 3.18545Z"
        fill="#EA4335"
      />
    </svg>
  );
}
