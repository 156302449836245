import React, { useContext, useMemo, useRef } from "react";

import { useQuery } from "@apollo/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Carousel } from "antd";
import { keyBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import { CART_PROMOTED_PRODUCTS_QUERY } from "@/graphql/queries/getCartPromotedProducts";
import { emitEvent } from "@/utils/eventBus";
import { filterPromotableProducts } from "@/utils/filterPromotableProducts";

import CartContext from "./cartContext";
import MenuItem from "../Menu/MenuItem";
import MenuContext from "../Menu/menuContext";

const CartPromotedProducts = ({ isCartOpen = false }) => {
  const { t } = useTranslation();
  const { cartQuery: cartData } = useContext(CartContext);
  const { menu } = useContext(MenuContext);
  const cartQuantityTree = keyBy(cartData?.cart?.topLevelItems, "item.id");
  const carouselRef = useRef();

  const {
    data: { cartPromotedProducts = [] } = {},
    loading: cartPromotedProductsLoading,
  } = useQuery(CART_PROMOTED_PRODUCTS_QUERY, {
    context: { graph: "diners" },
    skip: !isCartOpen,
    fetchPolicy: "network-only",
  });

  const filteredCartPromotedProducts = useMemo(() => {
    if (cartPromotedProductsLoading) {
      return [];
    }
    return filterPromotableProducts(cartPromotedProducts, menu, cartData);
  }, [cartPromotedProductsLoading, cartPromotedProducts, menu, cartData]);

  if (cartPromotedProductsLoading || filteredCartPromotedProducts.length === 0)
    return null;

  return (
    <div className="p-4 bg-default">
      <p className="mb-4 font-bold">{t("menu.cart.promotedProducts.title")}</p>
      <div className="relative">
        <Carousel dots={false} ref={carouselRef} autoplay autoplaySpeed={8000}>
          {filteredCartPromotedProducts.map((item) => (
            <div className="px-2" key={item.id}>
              <MenuItem
                item={item}
                id={parseInt(item?.id)}
                cartItemId={cartQuantityTree[`${item?.id}`]?.id}
                label={item?.label}
                name={item?.name}
                description={item?.description}
                unitPriceFractional={item?.unitPriceFractional}
                currency={item?.currency}
                isConfigurable={item?.isConfigurable}
                quantity={cartQuantityTree[`${item?.id}`]?.quantity}
                horizontalImageUrl={item?.horizontalImageUrl}
                isLeadTimeBreached={item?.isLeadTimeBreached}
                humanizedLeadTime={item?.humanizedLeadTime}
                isAvailable={item?.isAvailable}
                humanizedAvailability={item?.humanizedAvailability}
                cart={cartData?.cart}
                itemStock={item?.itemStock}
                checkPromotedProducts={false}
                size="sm"
                setSelectedItem={() => {
                  emitEvent("showItemDetails", item);
                }}
              />
            </div>
          ))}
        </Carousel>

        {filteredCartPromotedProducts.length > 1 && (
          <>
            <Button
              className="w-[32px] h-[32px] flex items-center justify-center rounded-full absolute -left-[10px] top-1/2 -translate-y-1/2"
              onClick={() => {
                if (carouselRef.current) {
                  carouselRef.current.prev();
                }
              }}
              leftIcon={<ChevronLeftIcon className="w-5 h-5 -mt-0.5 -mr-2" />}
              size="sm"
            ></Button>
            <Button
              className="w-[32px] h-[32px] flex items-center justify-center rounded-full absolute -right-[10px] top-1/2 -translate-y-1/2"
              onClick={() => {
                if (carouselRef.current) {
                  carouselRef.current.next();
                }
              }}
              rightIcon={<ChevronRightIcon className="w-5 h-5 -mt-0.5 -ml-2" />}
              size="sm"
            ></Button>
          </>
        )}
      </div>
    </div>
  );
};

CartPromotedProducts.propTypes = {
  isCartOpen: PropTypes.bool,
};

export default CartPromotedProducts;
