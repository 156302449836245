import React, { useEffect, useState } from "react";

import MinusIcon from "@heroicons/react/outline/MinusIcon";
import PlusIcon from "@heroicons/react/outline/PlusIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import Input from "@/common/Input";
import Tooltip from "@/common/Tooltip";
import useDebounce from "@/hooks/useDebounce";

import "./QuantityControl.less";

QuantityControl.propTypes = {
  quantityLeft: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  maxIncrement: PropTypes.number,
  size: PropTypes.oneOf(["regular", "small"]),
  id: PropTypes.string,
  showConfirmation: PropTypes.func,
  showAddToCartButtonOnZero: PropTypes.bool,
  debounce: PropTypes.number,
  className: PropTypes.string,
};

const DEFAULT_DEBOUNCE_TIMING = 750;

QuantityControl.defaultProps = {
  disabled: false,
  min: 0,
  debounce: DEFAULT_DEBOUNCE_TIMING,
  showAddToCartButtonOnZero: false,
};

export default function QuantityControl({
  quantityLeft,
  quantity,
  onChange,
  disabled,
  min,
  maxIncrement,
  size,
  id,
  showConfirmation,
  showAddToCartButtonOnZero,
  debounce,
  className,
}) {
  const dataTestId = "quantity-control" + (id ? `-${id}` : "");
  const [localQuantity, setLocalQuantity] = useState(quantity || 0);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const debouncedQuantity = useDebounce(localQuantity, debounce);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    if (showConfirmation && quantity > 1 && localQuantity === 0) {
      showConfirmation(() => onChange(0));
    }
  }, [localQuantity]);

  useEffect(() => {
    if (quantityLeft && localQuantity >= quantityLeft) {
      // hit the limit
      if (quantityLeft < 0) {
        setError(t("menu.menuItem.soldOut"));
      } else {
        setError(t("menu.menuItem.limitedStock", { quantityLeft }));
      }
    } else {
      setError(null);
    }
  }, [t, localQuantity, quantityLeft]);

  useEffect(() => {
    // prevent unnecessary API call if the final debounced quantity is the same
    if (debouncedQuantity !== quantity) onChange(debouncedQuantity);
  }, [debouncedQuantity]);

  function onLocalChange(newQuantity, oldQuantity) {
    if (newQuantity !== oldQuantity) {
      if (
        typeof maxIncrement === "number" &&
        newQuantity > oldQuantity + maxIncrement
      ) {
        setLocalQuantity(oldQuantity + maxIncrement);
        return;
      }
      setLocalQuantity(newQuantity);
    }
  }

  return !showAddToCartButtonOnZero || localQuantity > 0 ? (
    <div
      data-testid={dataTestId}
      className={`quantity-control flex w-full sm:w-auto ${size ?? ""} ${
        className ?? ""
      }`}
    >
      {size === "small" && localQuantity === 1 && showConfirmation ? (
        <Button
          type="quantityControl"
          id={`remove-item-from-cart-${id ?? ""}`}
          disabled={disabled}
          onClick={() => {
            showConfirmation(() => onChange(0));
          }}
        >
          <TrashIcon className="w-4 h-4 text-default" />
        </Button>
      ) : (
        <Button
          type="quantityControl"
          id={`decrement-quantity-${id ?? ""}`}
          disabled={disabled}
          onClick={() => {
            if (localQuantity > min) {
              setLocalQuantity(localQuantity - 1);
            }
          }}
        >
          <MinusIcon className="w-4 h-4 text-default" />
        </Button>
      )}
      <Input
        isQuantityControl
        id={`quantity-${id ?? ""}`}
        type="number"
        min={min}
        focus={true}
        value={localQuantity}
        disabled={disabled}
        onChange={(event) => {
          if (!error) {
            if (event.target.value >= 0 && showConfirmation)
              // don't allow qty to drop to 0 (in productDetailModal)
              return setLocalQuantity(parseInt(event.target.value));
            if (event.target.value > 0 && !showConfirmation)
              // allow qty to drop to 0 (in cart)
              return setLocalQuantity(parseInt(event.target.value));
          }
        }}
        onBlur={(event) => {
          const value = parseInt(event.target.value);
          if (value > 0) {
            return onLocalChange(value, quantity);
          } else {
            if (showConfirmation) return showConfirmation(() => onChange(0));
          }
        }}
        inputStyle={window.dinerManifest?.quantityControlStyle}
      />
      <Tooltip color="var(--color-danger)" visible={!!error} title={error}>
        <Button
          type="quantityControl"
          id={`increment-quantity-${id ?? ""}`}
          disabled={disabled || parseInt(maxIncrement) <= 0}
          onClick={() => {
            if (!error) {
              setLocalQuantity(localQuantity + 1);
            }
          }}
        >
          <PlusIcon
            className={`w-4 h-4 text-default ${error && "opacity-50"}`}
          />
        </Button>
      </Tooltip>
    </div>
  ) : (
    <div data-testid={`add-item-to-cart`}>
      <Button
        type="primary"
        id={`add-item-to-cart-${id ?? ""}`}
        className="w-full font-bold sm:min-w-[96px]"
        onClick={() => setLocalQuantity(localQuantity + 1)}
      >
        {t("menu.menuItem.addToCart")}
      </Button>
    </div>
  );
}
