import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@/common/Button";
import SlideOver from "@/common/SlideOver";

MobileFulfilmentPanel.propTypes = {
  visible: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  addressLabel: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default function MobileFulfilmentPanel({
  visible,
  closePanel,
  children,
}) {
  const { t } = useTranslation();
  return (
    <SlideOver
      visible={visible}
      closePanel={closePanel}
      enterFrom="bottom"
      panelClassName="max-w-full"
    >
      <div className="flex flex-col h-full">
        {/* Overlay space to close panel on click */}
        <div className="flex-grow" onClick={closePanel} />
        <div className="px-3 py-6 bg-header sm:px-6">
          <div className="w-full mx-auto">
            <div className="mb-5 space-y-5">{children}</div>
          </div>
          <Button type="primary" className="w-full" onClick={closePanel}>
            {t("navbar.browseMenu")}
          </Button>
        </div>
      </div>
    </SlideOver>
  );
}
