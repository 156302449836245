import { useEffect } from "react";

export const useListenEvent = (eventName, callback, deps = null) => {
  useEffect(() => {
    const handleCallback = (event) => callback(event.detail);

    document.addEventListener(eventName, handleCallback);
    return () => document.removeEventListener(eventName, handleCallback);
  }, [deps, eventName, callback]);
};

export const emitEvent = (eventName, detail) => {
  document.dispatchEvent(new CustomEvent(eventName, { detail }));
};
