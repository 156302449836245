import { gql } from "@apollo/client";

const generateUpdateFulfilmentDetailsMutation = (
  operationName,
  mutationName,
) => gql`
  mutation ${operationName}(
    $line2: String
    $isCutleryRequired: Boolean
    $notes: String
    $contactName: String
    $contactNumber: String
    $email: String
    $isGift: Boolean
    $recipientName: String
    $recipientContactNumber: String
    $giftMessage: String
  ) {
    ${mutationName}(
      input: {
        line2: $line2
        isCutleryRequired: $isCutleryRequired
        notes: $notes
        contactName: $contactName
        contactNumber: $contactNumber
        email: $email
        isGift: $isGift
        recipientName: $recipientName
        recipientContactNumber: $recipientContactNumber
        giftMessage: $giftMessage
      }
    ) {
      id
      address {
        id
        line2
        isCutleryRequired
        notes
      }
      notes
      contactName
      contactNumber
      contactEmail
      isGift
      recipientName
      recipientContactNumber
      giftMessage
    }
  }
`;
export const UPDATE_CART_FULFILMENT_DETAILS_MUTATION =
  generateUpdateFulfilmentDetailsMutation(
    "UpdateFulfilmentDetails",
    "updateFulfilmentDetails",
  );
export const UPDATE_CART_FULFILMENT_DETAILS_OPTIMISED_MUTATION =
  generateUpdateFulfilmentDetailsMutation(
    "UpdateFulfilmentDetailsOptimised",
    "updateFulfilmentDetailsOptimised",
  );
