import { useState } from "react";

import { PAYMENT_TYPES } from "@/utils/constants";
import { emitEvent } from "@/utils/eventBus";
import { importScript } from "@/utils/importScript";

function importOmiseAndCheckout({ onComplete }) {
  if (!window.omiseScriptImported) {
    window.omiseScriptImported = true;
    importScript("https://cdn.omise.co/omise.js", { onComplete });
  } else if (onComplete) {
    onComplete();
  }
}

export function useOmise({ totalIncludingTax, omisePaymentKey, paymentType }) {
  const [omiseLoading, setOmiseLoading] = useState(true);

  if (paymentType !== PAYMENT_TYPES.omise) {
    return {
      omiseLoading: false,
      openOmiseCard: null,
    };
  }

  const configureOmiseAndCheckout = async () => {
    const configureOmise = async () => {
      await window.OmiseCard.configure({
        publicKey: omisePaymentKey,
      });
      await window.OmiseCard.open({
        // TODO: Add frame label and description
        // frameLabel: "Brand",
        // frameDescription: "Brand #3847 in THB",
        currency: "THB",
        amount: totalIncludingTax,
        defaultPaymentMethod: "credit_card",
        onCreateTokenSuccess: (nonce) => {
          emitEvent("makePayment", nonce);
        },
      }),
        setOmiseLoading(false);
    };

    await importOmiseAndCheckout({ onComplete: configureOmise });
  };

  return {
    omiseLoading,
    openOmiseCard: async () => await configureOmiseAndCheckout(),
  };
}
