import { gql } from "@apollo/client";

import { ADDRESS_FIELDS } from "@/graphql/mutations/createAddress";

export const GET_ADDRESSES_AND_OUTLETS_QUERY = gql`
  query GetAddressesAndOutlets($point: Point, $fulfilmentType: String) {
    addresses {
      ...AddressFields
      nearestOutlet {
        id
        labelForPickup
        distanceFromAddress
      }
    }
    outlets(point: $point, fulfilmentType: $fulfilmentType) {
      id
      labelForPickup
      distanceFromAddress
      pickupOnly
    }
  }
  ${ADDRESS_FIELDS}
`;

export const GET_OUTLETS_FULFILMENT_TYPE_STATES_QUERY = gql`
  query GetOutletsFulfilmentTypeStatesQuery(
    $point: Point
    $fulfilmentType: String
    $outletId: Int
    $includeSelectedOutlet: Boolean
  ) {
    outlets(
      point: $point
      fulfilmentType: $fulfilmentType
      outletId: $outletId
      includeSelectedOutlet: $includeSelectedOutlet
    ) {
      id
      fulfilmentTypeStates
    }
  }
`;
