import React from "react";

import PropTypes from "prop-types";

FacebookIcon.propTypes = {
  rest: PropTypes.any,
};

export default function FacebookIcon({ ...rest }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.08889 0C3.62176 0 0 3.60358 0 8.0483C0 12.0648 2.95759 15.3939 6.82528 16V10.3753H4.7705V8.0483H6.82528V6.27508C6.82528 4.25636 8.03307 3.14317 9.87995 3.14317C10.765 3.14317 11.692 3.30013 11.692 3.30013V5.2794H10.6695C9.66638 5.2794 9.3525 5.90079 9.3525 6.53748V8.04669H11.5942L11.2358 10.3737H9.3525V15.9984C13.2202 15.3955 16.1778 12.0656 16.1778 8.0483C16.1778 3.60358 12.556 0 8.08889 0Z"
        fill="white"
      />
    </svg>
  );
}
