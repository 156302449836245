import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";

import Layout from "@/common/Layout";
import PageTemplateSections from "@/components/PageTemplate/PageTemplateSections";

import HomeLegacyContent from "./HomeLegacyContent";

export default function Home() {
  const HEADER_HEIGHT = 64;

  const location = useLocation();
  const scroller = useRef(null);
  const bestsellersRef = useRef(null);
  const storiesRef = useRef(null);
  const reviewsRef = useRef(null);

  const dinerManifest = window.dinerManifest;
  const homeSections = dinerManifest?.home?.sections;

  useEffect(() => {
    scroller.current.style.scrollBehavior = "smooth";
    switch (location.hash) {
      case "#bestsellers":
        scroller.current?.scrollTo(
          0,
          bestsellersRef.current.offsetTop - HEADER_HEIGHT,
        );
        break;
      case "#stories":
        scroller.current?.scrollTo(
          0,
          storiesRef.current.offsetTop - HEADER_HEIGHT,
        );
        break;
      case "#reviews":
        scroller.current?.scrollTo(
          0,
          reviewsRef.current.offsetTop - HEADER_HEIGHT,
        );
        break;
    }
  }, [location.key]);

  function getSectionRef(section) {
    if (section.type === "bestsellers") {
      return bestsellersRef;
    }
    switch (section.id) {
      case "stories":
        return storiesRef;
      case "reviews":
        return reviewsRef;
    }
    return null;
  }

  return (
    <Layout
      className="home"
      fullscreen
      scrollRef={scroller}
      isTransparentHeader={window.dinerManifest?.transparentHeaderAtTopOfHome}
      data-testid="home-page"
    >
      {homeSections ? (
        <div className="h-full text-center">
          <PageTemplateSections
            sections={homeSections}
            getSectionRef={getSectionRef}
          />
        </div>
      ) : (
        <HomeLegacyContent
          bestsellersRef={bestsellersRef}
          storiesRef={storiesRef}
          reviewsRef={reviewsRef}
        />
      )}
    </Layout>
  );
}
