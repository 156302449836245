import { gql } from "@apollo/client";

export const USER_LOGIN_WITH_OTP_OR_SSO_MUTATION = gql`
  mutation UserLoginWithOtpOrSso($input: UserLoginWithOTPOrSSOInput!) {
    userLoginWithOtpOrSso(input: $input) {
      accessToken
      refreshToken
      user {
        id
        name
      }
    }
  }
`;
