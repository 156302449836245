import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";

import accessTokenAuthLink from "./accessTokenAuthLink";
import errorLink from "./errorLink";
import httpLink from "./httpLink";

export default () => {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, accessTokenAuthLink, httpLink]),
    // items can have the same modifier groups and modifiers, but their modifiers will have a unique idBreadcrumb
    // change the keyFields here so that the modifiers doesn't automatically merge in the cache,
    // which removes the unique idBreadcrumb
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-by-type
    cache: new InMemoryCache({
      typePolicies: {
        ModifierGroup: {
          keyFields: ["id", "idBreadcrumb"],
        },
        ModifierGroupTypeOptimised: {
          keyFields: ["id", "idBreadcrumb"],
        },
        Modifier: {
          keyFields: ["id", "idBreadcrumb"],
        },
        ModifierTypeOptimised: {
          keyFields: ["id", "idBreadcrumb"],
        },
      },
    }),
  });
};
