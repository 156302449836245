import { gql } from "@apollo/client";

import { CART_FIELDS, CART_OPTIMISED_FIELDS } from "@/graphql/queries/getCart";

const generateAddItemToCartMutation = (
  operationName,
  mutationName,
  cartFragment,
) => gql`
  mutation ${operationName}($itemConfiguration: ItemConfiguration!) {
    ${mutationName}(input: { itemConfiguration: $itemConfiguration }) {
      ...CartFields
    }
  }
  ${cartFragment}
`;
export const ADD_ITEM_TO_CART_MUTATION = generateAddItemToCartMutation(
  "AddItemToCart",
  "addItemToCart",
  CART_FIELDS,
);
export const ADD_ITEM_TO_CART_OPTIMISED_MUTATION =
  generateAddItemToCartMutation(
    "AddItemToCartOptimised",
    "addItemToCartOptimised",
    CART_OPTIMISED_FIELDS,
  );
