import React, { useContext } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CartContext from "@/components/Cart/cartContext";
import useTranslatedText from "@/hooks/useTranslationText";

import { withItemAvailability } from "./MenuArea";
import MenuSectionWithItems from "./MenuSectionWithItems";
import MenuSubSection from "./MenuSubSection";
import MenuContext from "./menuContext";

MenuSection.propTypes = {
  sectionRef: PropTypes.func,
  menuSectionRefs: PropTypes.object,
  sidebarRefIndex: PropTypes.number,
  section: PropTypes.shape({
    id: PropTypes.number,
    sectionId: PropTypes.number,
    isSubSection: PropTypes.bool,
    label: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    disabledReason: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        description: PropTypes.string,
        unitPriceFractional: PropTypes.number,
      }),
    ),
    subSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        sectionId: PropTypes.number,
        label: PropTypes.string,
        description: PropTypes.string,
        items: PropTypes.array,
      }),
    ),
    translation: PropTypes.shape({
      title: PropTypes.object,
      description: PropTypes.object,
    }),
  }),
  setSelectedItem: PropTypes.func,
  setSelectedItemSection: PropTypes.func,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
};

MenuSection.defaultProps = {
  isSubSection: false,
};

export default function MenuSection({
  section,
  sectionRef,
  menuSectionRefs,
  sidebarRefIndex,
  setSelectedItem,
  setSelectedItemSection,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
}) {
  const { cartQuery: cartData } = useContext(CartContext);
  const { t } = useTranslation();
  const { menu } = useContext(MenuContext);

  const sectionLabel = useTranslatedText({
    resource: section,
    fallbackValue: section?.label,
  });

  const sectionDescription = useTranslatedText({
    resource: section,
    fallbackValue: section?.description,
    key: "description",
  });

  if (section?.subSections?.length) {
    return (
      <div className="relative space-y-8">
        <a
          id={`menu_section_${section.id}`}
          ref={sectionRef}
          data-sidebar-ref-index={sidebarRefIndex}
          className="absolute -top-18"
        />
        <div data-testid={`${section.label}-menu-section`}>
          <h1
            className={classNames(
              "mb-4.5 text-2xl font-display",
              `opacity-${section.disabled ? 70 : 100}`,
            )}
          >
            {sectionLabel}
          </h1>
          {section.disabledReason && (
            <div className="text-danger mb-4.5">{section.disabledReason}</div>
          )}
          {sectionDescription && (
            <div
              className={classNames(
                "mb-4.5 text-body font-body",
                `opacity-${section.disabled ? 50 : 100}`,
              )}
              dangerouslySetInnerHTML={{ __html: sectionDescription }}
            />
          )}
        </div>
        <div className="space-y-12">
          {section.subSections.map((section, index) => (
            <MenuSubSection
              key={`${section?.id}-${index}`}
              section={section}
              menuSectionRefs={menuSectionRefs}
              sidebarRefIndex={sidebarRefIndex}
              setSelectedItem={setSelectedItem}
              setSelectedItemSection={setSelectedItemSection}
              showPreOrderModal={showPreOrderModal}
              showAvailabilityModal={showAvailabilityModal}
              showMenuSectionDisabledModal={showMenuSectionDisabledModal}
              cartData={cartData}
            />
          ))}
        </div>
      </div>
    );
  }
  const dataTestId = `${section.label}-menu-section`;

  if (sectionRef?.current && menuSectionRefs?.current) {
    menuSectionRefs.current[section.id] = sectionRef.current;
  }

  return (
    <div className="relative">
      <a
        id={`menu_section_${section.id}`}
        key={`menu-anchor-${section.id}`}
        ref={sectionRef}
        className="absolute -top-18"
        data-sidebar-ref-index={sidebarRefIndex}
      />
      <div data-testid={dataTestId} key={`menu-section-${section.id}`}>
        <MenuSectionWithItems
          section={section}
          items={withItemAvailability(menu.isAvailable, section?.items, t)}
          setSelectedItem={setSelectedItem}
          setSelectedItemSection={setSelectedItemSection}
          showPreOrderModal={showPreOrderModal}
          showAvailabilityModal={showAvailabilityModal}
          showMenuSectionDisabledModal={showMenuSectionDisabledModal}
          cartData={cartData}
        />
      </div>
    </div>
  );
}
