import React, { useState, useRef } from "react";

import PropTypes from "prop-types";

import Image from "@/common/Image";
import NutriGradeBadge from "@/components/Menu/NutriGradeBadge";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";

MenuItemImage.propTypes = {
  source: PropTypes.string,
  isHeroCard: PropTypes.bool,
  promotionalLabelText: PropTypes.string,
  promotionalLabelFontColor: PropTypes.string,
  promotionalLabelBgColor: PropTypes.string,
  sugarLevel: PropTypes.number,
  nutriGrade: PropTypes.string,
  className: PropTypes.string,
  inCart: PropTypes.bool,
};

export default function MenuItemImage({
  source,
  isHeroCard,
  promotionalLabelText,
  promotionalLabelFontColor,
  promotionalLabelBgColor,
  sugarLevel,
  nutriGrade,
  className,
  inCart,
}) {
  const [imageIsInView, setImageIsInView] = useState(false);
  const imgRef = useRef();

  useIntersectionObserver(imgRef, () => {
    setImageIsInView(true);
  });

  return (
    <div
      ref={imgRef}
      className={
        "relative w-full overflow-hidden" +
        (isHeroCard ? " sm:h-full" : "") +
        " " +
        className
      }
      style={{ paddingTop: "100%" }} // used to maintain 1:1 aspect ratio
    >
      {imageIsInView && (
        <Image
          className="absolute top-0 object-cover w-full h-full transition-transform transform hover:scale-110"
          src={source || window.dinerManifest?.defaultImageUrl}
        />
      )}
      {promotionalLabelText && inCart && (
        <div
          className="absolute px-2.5 py-1.5 rounded-sm text-xs sm:text-sm bottom-4 left-4"
          style={{
            backgroundColor: promotionalLabelBgColor,
            color: promotionalLabelFontColor,
          }}
        >
          {promotionalLabelText}
        </div>
      )}
      {sugarLevel !== undefined && sugarLevel !== null && (
        <NutriGradeBadge
          className="absolute top-3 left-3 sm:top-4.5 sm:left-4.5"
          sugarLevel={sugarLevel}
          nutriGrade={nutriGrade}
        />
      )}
    </div>
  );
}
