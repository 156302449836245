export const filterPromotableProducts = (
  itemPromotedProducts,
  menu,
  cartData,
) => {
  if (!menu) {
    return [];
  }
  // Extract all available items from the menu
  const allItems = menu.sections
    .filter((section) => section?.isAvailable)
    .flatMap((section) => section?.items);

  // Filter promotable products based on availability and stock, and if they are not in the cart
  const availablePromotedProducts = itemPromotedProducts
    .filter((product) => {
      const menuItem = allItems.find((item) => item?.id === product?.id);
      return (
        menuItem && // Product exists in allItems
        !cartData?.cartOptimised?.topLevelItems?.some(
          (cartItem) => cartItem.item.id === product.id,
        ) && // Not in the cart
        product?.isAvailable && // Product is available
        (menuItem?.itemStock?.quantityLeft?.entire > 0 ||
          menuItem?.itemStock?.quantityLeft?.entire === null) // Product has stock left
      );
    })
    .map((product) => ({
      ...product,
      ...allItems?.find((item) => item?.id === product?.id), // Merge product details with corresponding item from allItems
    }));

  return availablePromotedProducts;
};
