import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import NavigationItem from "./NavigationItem";

Navigation.propTypes = {
  isMobile: PropTypes.bool,
  isTransparent: PropTypes.bool,
  additionalLinks: PropTypes.array,
};

Navigation.defaultProps = {
  isMobile: false,
  isTransparent: false,
};

export default function Navigation({
  isMobile,
  isTransparent,
  additionalLinks,
}) {
  const { pages = [], ...dinerManifest } = window.dinerManifest || {};
  const { t } = useTranslation();
  const location = useLocation();

  const navigations = [];
  if (!(isTransparent && dinerManifest?.hideNavOnTransparentHeader)) {
    if (dinerManifest?.headerNavigations?.length > 0) {
      // don't show custom navigations in menu page
      if (
        dinerManifest?.showNavigationOnMenu || dinerManifest?.menuOnly
          ? !(
              location.pathname === "/" ||
              location.pathname.includes("/section") ||
              location.pathname.includes("/items")
            )
          : !location.pathname.includes("/menu")
      ) {
        dinerManifest.headerNavigations.forEach((navigation) => {
          navigations.push(navigation);
        });
      }
      if (isMobile && additionalLinks) {
        navigations.push(...additionalLinks);
      }
    } else {
      if (!dinerManifest?.menuOnly) {
        navigations.push({
          label: t("navbar.faq"),
          link: "/faq",
        });
      }
      pages.forEach((page) => {
        if (page.showOnNavbar) {
          navigations.push({
            label: page.label,
            link: page.path,
          });
        }
      });
      if (additionalLinks) {
        navigations.push(...additionalLinks);
      }
    }
  }

  return (
    <div
      className={classNames(
        "navigation",
        isMobile
          ? "px-6 py-3 space-y-3 shadow-md"
          : "h-full -my-px ml-6 flex space-x-6",
      )}
    >
      {navigations.map((nav) => {
        return (
          <NavigationItem
            key={nav?.link || nav?.label}
            nav={nav}
            isMobile={isMobile}
            isTransparent={isTransparent}
          />
        );
      })}
    </div>
  );
}
