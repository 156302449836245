import React, { useState } from "react";

import { Menu } from "@headlessui/react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import NavigationItem from "./NavigationItem";

NavigationItemWithSubMenu.propTypes = {
  nav: PropTypes.object,
  isMobile: PropTypes.bool,
  isTransparent: PropTypes.bool,
  getNavClass: PropTypes.func,
};

export default function NavigationItemWithSubMenu({
  nav,
  isMobile,
  isTransparent,
  getNavClass,
}) {
  const { navigations } = nav;
  const navWithoutNavigations = {
    ...nav,
    navigations: null,
  };

  const [isMobileSubMenuVisible, setMobileSubMenuVisible] = useState(false);

  function getSubMenuClass() {
    return `nav-sub-menu ${
      isMobile
        ? "mt-3 ml-3 space-y-3"
        : "absolute p-1 rounded bg-primary text-on-primary"
    }`;
  }

  function getSubMenuNavClass(nav, active) {
    if (isMobile) {
      return getNavClass(nav);
    }

    return `inline-block w-full px-4 py-1 whitespace-nowrap rounded text-on-primary hover:text-on-primary hover:bg-primary-dark ${
      active ? "bg-primary-dark" : ""
    }`;
  }

  return (
    <Menu
      as="div"
      className="relative flex flex-col items-start lg:items-center lg:flex-row"
    >
      {({ open }) => (
        <>
          <Menu.Button
            style={{ outline: "none" }}
            className="w-full text-left lg:text-center"
          >
            <NavigationItem
              nav={navWithoutNavigations}
              isMobile={isMobile}
              isTransparent={isTransparent}
              onClick={() => {
                if (isMobile) {
                  setMobileSubMenuVisible(!isMobileSubMenuVisible);
                }
              }}
            />
            {((!isMobile && open) || (isMobile && isMobileSubMenuVisible)) && (
              <Menu.Items
                static
                className={getSubMenuClass()}
                style={
                  isMobile
                    ? {}
                    : {
                        top: "72px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }
                }
              >
                {navigations.map((navigation) => {
                  const { link, label, ...props } = navigation;
                  const Component = link ? Link : "a";
                  const componentProps = { ...props };
                  if (link) {
                    componentProps.to = link;
                  }
                  return (
                    <Menu.Item key={link || label}>
                      {({ active }) => (
                        <Component
                          className={getSubMenuNavClass(navigation, active)}
                          {...componentProps}
                        >
                          {label}
                        </Component>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            )}
          </Menu.Button>
        </>
      )}
    </Menu>
  );
}
