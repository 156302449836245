import React, { useEffect, useLayoutEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import loadable from "@loadable/component";
import { usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import qs from "qs";
import { Helmet } from "react-helmet";
import {
  Routes as Router,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

import { GET_CONFIG_QUERY } from "@/graphql/queries/getConfig";
import useAnalytics from "@/hooks/useAnalytics";
import useAuth from "@/hooks/useAuth";
import constants, { REFERRAL_CODE } from "@/utils/constants";
import getUrlParams from "@/utils/getUrlParams";

import CustomProviders from "./CustomProviders";
import Loyalty from "./components/Account/Loyalty/Loyalty";
import Home from "./components/Home";
import Menu from "./components/Menu";

const Login = loadable(() => import("./components/Login"));
const Signup = loadable(() => import("./components/Signup"));
const Session = loadable(() => import("./components/Session"));
const Account = loadable(() => import("./components/Account"));
const Orders = loadable(() => import("./components/Account/Orders"));
const Profile = loadable(() => import("./components/Account/Profile"));
const PaymentMethods = loadable(() =>
  import("./components/Account/PaymentMethods"),
);
const Referrals = loadable(() => import("./components/Account/Referrals"));
const PromoCodes = loadable(() => import("./components/Account/PromoCodes"));

const Addresses = loadable(() => import("./components/Addresses"));
const Faq = loadable(() => import("./components/Faq"));
const PageTemplate = loadable(() => import("./components/PageTemplate"));
const ForgotPassword = loadable(() =>
  import("./components/ForgotPassword/ForgotPassword"),
);
const ResetPassword = loadable(() =>
  import("./components/ResetPassword/ResetPassword"),
);

smoothscroll.polyfill();

export default function Routes() {
  const location = useLocation();
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const [outletSlug, setOutletSlug] = useState();
  const [outletQrHash, setOutletQrHash] = useState();
  const [tableQrHash, setTableQrHash] = useState();
  const posthog = usePostHog();

  function getMenuPath() {
    if (outletQrHash) {
      if (tableQrHash) {
        return `/qr/${outletQrHash}/${tableQrHash}`;
      } else {
        return `/qr/${outletQrHash}`;
      }
    } else {
      return window.dinerManifest?.menuOnly ? "/" : "/menu";
    }
  }

  const [pathConfig, setPathConfig] = useState({
    menuPath: getMenuPath(),
  });

  // Scroll to top when pathname is changed
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Track on every page change
  useEffect(() => {
    analytics.page(location.pathname);
    posthog.capture("$pageview");
  }, [location.pathname]);

  const { data: configQuery, loading: configLoading } = useQuery(
    GET_CONFIG_QUERY,
    {
      context: { graph: "diners" },
    },
  );

  const pages = window.dinerManifest?.pages || [];

  const isReferralsProgramEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.ENABLE_REFERRALS,
  );
  const useOtpLoginForOnline = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OTP_LOGIN_FOR_ONLINE,
  );
  const loyaltyEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.LOYALTY,
  );

  useEffect(() => {
    const setReferralCode = async () => {
      if (isReferralsProgramEnabled) {
        const params = getUrlParams(location);
        if (params?.referral) {
          await localStorage.setItem(REFERRAL_CODE, params?.referral);
          navigate(window.location.pathname, { replace: true });
        }
      }
    };

    setReferralCode();
  }, [isReferralsProgramEnabled]);

  useEffect(() => {
    setPathConfig({
      ...pathConfig,
      menuPath: getMenuPath(),
    });
  }, [outletQrHash, tableQrHash]);

  return (
    <CustomProviders
      configQuery={configQuery}
      configLoading={configLoading}
      pathConfig={pathConfig}
      outletSlug={outletSlug}
      setOutletSlug={setOutletSlug}
      outletQrHash={outletQrHash}
      setOutletQrHash={setOutletQrHash}
      tableQrHash={tableQrHash}
      setTableQrHash={setTableQrHash}
    >
      <Router>
        <Route path="*" element={<Navigate to="/" />} />
        <Route
          path="/"
          title={window.dinerManifest?.menuOnly ? "Menu" : "Home"}
          element={
            <ReactRoute
              element={window.dinerManifest?.menuOnly ? <Menu /> : <Home />}
            />
          }
        />
        <Route
          exact
          path="/thank-you"
          element={
            <Navigate to={window.dinerManifest?.menuOnly ? "/" : "/menu"} />
          }
        />
        <Route
          title="Account"
          path="/account"
          element={<ReactRoute protect={true} element={<Account />} />}
        >
          <Route
            path="/account"
            element={
              <ReactRoute
                protect={true}
                element={<Navigate to="/account/orders" />}
              />
            }
          />
          <Route
            title="Account - Orders"
            path="orders"
            element={<ReactRoute protect={true} element={<Orders />} />}
          />
          <Route
            title="Account - Profile"
            path="profile"
            element={<ReactRoute protect={true} element={<Profile />} />}
          />
          <Route
            title="Account - Payment Methods"
            path="payment-methods"
            element={<ReactRoute protect={true} element={<PaymentMethods />} />}
          />
          <Route
            title="Account - Addresses"
            path="addresses"
            element={<ReactRoute protect={true} element={<Addresses />} />}
          />
          {isReferralsProgramEnabled && (
            <>
              <Route
                protect={true}
                title="Account - Referrals"
                path="referrals"
                element={<ReactRoute protect={true} element={<Referrals />} />}
              />
              <Route
                protect={true}
                title="Account - Promo codes"
                path="promo-codes"
                element={<ReactRoute protect={true} element={<PromoCodes />} />}
              />
            </>
          )}
          {loyaltyEnabled && (
            <Route
              protect={true}
              title="Account - Loyalty"
              path="loyalty"
              element={<ReactRoute protect={true} element={<Loyalty />} />}
            />
          )}
        </Route>

        {[
          "/",
          "/menu",
          "/qr/:outletQrHash",
          "/qr/:outletQrHash/:tableQrHash",
        ].map((basePath) => (
          <Route
            key={basePath}
            path={basePath}
            title="Menu"
            element={<ReactRoute element={<Menu />} />}
          >
            <Route
              title="Menu"
              path="items/:itemSlug"
              element={<ReactRoute element={<Menu />} />}
            />
            <Route
              title="Menu"
              path="section/:sectionSlug"
              element={<ReactRoute element={<Menu />} />}
            />
            <Route
              title="Menu"
              path="tab/:tabSlug"
              element={<ReactRoute element={<Menu />} />}
            />
            <Route
              title="Menu"
              path="outlet/:outletSlug"
              element={<ReactRoute element={<Menu />} />}
            />
            <Route
              path="thank-you"
              element={
                <Navigate to={window.dinerManifest?.menuOnly ? "/" : "/menu"} />
              }
            />
          </Route>
        ))}
        <Route
          title="Menu"
          path="/menu/section/:sectionSlug"
          element={<ReactRoute element={<Menu />} />}
        />

        <Route
          title="Log in"
          path="/login"
          element={
            <ReactRoute
              guest={true}
              // load Session by default, when config is not loaded yet
              element={useOtpLoginForOnline === false ? <Login /> : <Session />}
            />
          }
        />
        <Route
          title="Sign up"
          path="/signup"
          element={
            <ReactRoute
              guest={true}
              element={
                // load Session by default, when config is not loaded yet
                useOtpLoginForOnline === false ? <Signup /> : <Session />
              }
            />
          }
        />
        <Route
          title="Forgot Password"
          path="/forgot-password"
          element={<ReactRoute guest={true} element={<ForgotPassword />} />}
        />
        <Route
          title="Reset password"
          path="/reset-password"
          element={<ReactRoute guest={true} element={<ResetPassword />} />}
        />
        <Route title="FAQ" path="/faq" element={<Faq />} />
        {pages.map((page) => (
          <Route
            title={page.title}
            key={`custom_page_${page.path}`}
            path={page.path}
            element={<PageTemplate page={page} />}
          />
        ))}
      </Router>
    </CustomProviders>
  );
}

ReactRoute.propTypes = {
  path: PropTypes.string,
  element: PropTypes.element,
  title: PropTypes.string,
  guest: PropTypes.bool,
  protect: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

function ReactRoute({ element, title, guest, protect, children }) {
  const location = useLocation();
  const { loggedIn } = useAuth();

  if (guest && loggedIn()) {
    return <Navigate to={"/"} />;
  }

  if (protect && !loggedIn()) {
    return (
      <Navigate
        to={{
          pathname: "/login",
          search: qs.stringify({
            ...getUrlParams(location),
            redirect: location.pathname + location.search,
          }),
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          property="og:title"
          content={`${window.dinerManifest.title}${title ? ` | ${title}` : ""}`}
        />
        <meta
          property="og:description"
          content={window.dinerManifest.meta.description}
        />
        <meta property="og:url" content={window.location.href} />
        <title>{`${window.dinerManifest.title}${
          title ? ` | ${title}` : ""
        }`}</title>
        <meta
          property="og:image"
          content={window.dinerManifest.header.logo.src}
        />
        <meta
          name="description"
          content={window.dinerManifest.meta.description}
        />
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      {element}
      {children}
    </>
  );
}
