const getVersionInfoFromTag = (tag = "") => {
  let [version, versionEnvironment] = tag.split("-");
  version = version.replaceAll("v", "");
  return {
    version,
    versionEnvironment,
  };
};

export default getVersionInfoFromTag;
