import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  getModifierGroupSlotsFilled,
  getModifierGroupSlotsLeft,
  isModifierGroupValid,
  updateProductConfiguration,
} from "@/utils/itemConfiguration";

import Modifier from "./Modifier";

ModifierGroup.propTypes = {
  id: PropTypes.string,
  modifierGroup: PropTypes.object,
  isValidationsVisible: PropTypes.bool,
  onChange: PropTypes.func,
  nested: PropTypes.bool,
};

ModifierGroup.defaultProps = {
  nested: false,
};

export default function ModifierGroup({
  id,
  modifierGroup,
  isValidationsVisible,
  onChange,
  nested,
}) {
  const { t } = useTranslation();
  const [slotsFilled, setSlotsFilled] = useState(getSlotsFilled());
  const [slotsLeft, setSlotsLeft] = useState(getSlotsLeft(slotsFilled));
  // this is used to force re-rendering on removeCurrentSelection
  const [uniqueKey, setUniqueKey] = useState(0);

  const isValid = isModifierGroupValid(modifierGroup, slotsFilled);
  function getSlotsFilled() {
    return getModifierGroupSlotsFilled(modifierGroup);
  }

  function getSlotsLeft(numFilled) {
    return getModifierGroupSlotsLeft(modifierGroup, numFilled);
  }

  function getSelectionCopy() {
    if (modifierGroup.isFixed)
      return t("menu.productDetails.modifierGroup.selection.included");

    if (
      modifierGroup.selectionRequiredMin !== modifierGroup.selectionRequiredMax
    ) {
      return t("menu.productDetails.modifierGroup.selection.optional", {
        filled: slotsFilled,
        selectionRequiredMin: modifierGroup.selectionRequiredMin,
        selectionRequiredMax: modifierGroup.selectionRequiredMax,
      });
    }
    return t("menu.productDetails.modifierGroup.selection.required", {
      filled: slotsFilled,
      selectionRequiredMax: modifierGroup.selectionRequiredMax,
    });
  }

  function removeCurrentSelection() {
    modifierGroup.modifiers.forEach((modifier) => {
      updateProductConfiguration({
        idBreadcrumb: modifier.idBreadcrumb,
        quantity: 0,
        increment: false,
      });
    });
    // force re-render by changing uniqueKey (on data change for this component)
    setUniqueKey(uniqueKey + 1);
  }

  function onModifierChange() {
    const updatedSlotsFilled = getSlotsFilled();
    setSlotsFilled(updatedSlotsFilled);
    setSlotsLeft(getSlotsLeft(updatedSlotsFilled));
    onChange();
  }

  return (
    <div className="space-y-4 modifier-group">
      <div className="flex flex-col items-start sm:flex-row sm:items-center">
        <div className="flex-grow">
          <div className="text-base font-bold font-display">
            {modifierGroup.label}
          </div>
          {modifierGroup.description && (
            <div className="text-sm">{modifierGroup.description}</div>
          )}
        </div>
        <span
          className={`text-sm sm:text-right ${
            isValidationsVisible && !isValid ? "text-danger font-bold" : ""
          }`}
        >
          {getSelectionCopy()}
        </span>
      </div>
      <div className="space-y-4 modifiers">
        {modifierGroup.modifiers.map((modifier, index) => {
          const key = `${id}-modifier-${index}`;
          return (
            <Modifier
              key={key}
              modifier={modifier}
              disabled={modifierGroup.isFixed}
              maxQuantityPerModifier={modifierGroup.maxQuantityPerModifier}
              slotsLeft={slotsLeft}
              selectionRequiredMax={modifierGroup.selectionRequiredMax}
              removeCurrentSelection={removeCurrentSelection}
              isValidationsVisible={isValidationsVisible}
              onChange={onModifierChange}
              nested={nested}
              hideThumbnail={modifierGroup.hideThumbnails}
            />
          );
        })}
      </div>
    </div>
  );
}
