import { useContext } from "react";

import ConfigContext from "@/components/Config/configContext";
import { events } from "@/lib/analyticsV2/events";
import fbq from "@/lib/analyticsV2/fbq";
import gTag from "@/lib/analyticsV2/gtag";
import gtm from "@/lib/analyticsV2/gtm";
import ttq from "@/lib/analyticsV2/ttq";
import constants from "@/utils/constants";

const useAnalyticsV2 = () => {
  const { configQuery } = useContext(ConfigContext);
  const analyticsV2Enabled = configQuery?.config?.features?.includes(
    constants?.FEATURES.ANALYTICS_V2,
  );
  // Function to handle analytics tracking
  const trackEvent = (eventName, payload) => {
    // Check if the global gtag or pixel object is available
    if (!analyticsV2Enabled) {
      return;
    }

    if (window.dataLayer && !!window.dataLayer.push) {
      gtm.track(eventName, payload);
    } else if (window.gtag) {
      gTag.track(eventName, payload);
    }

    if (window.ttq) {
      ttq.track(eventName, payload);
    }
    if (window.fbq) {
      fbq.track(eventName, payload);
    }
  };

  return { trackEvent, eventsV2: events };
};

export default useAnalyticsV2;
