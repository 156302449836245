import constants from "./constants";

export default function formatPrice(priceFractional, currency) {
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: "currency",
    currency: currency || getCurrency(),
  });
  return formatter.format(formatValueToStandardUnit(priceFractional));
}

function getLanguage() {
  const country = localStorage.getItem(constants.CONFIG.COUNTRY);
  switch (country?.toLowerCase()) {
    case "th":
      return "th-TH";
    default:
      return "en-SG";
  }
}

function getCurrency() {
  const currency = localStorage.getItem(constants.CONFIG.CURRENCY);
  return currency || "SGD";
}

export function formatValueToStandardUnit(priceFractional) {
  return priceFractional / 100;
}
