import { gql } from "@apollo/client";

export const CART_PROMOTED_PRODUCTS_QUERY = gql`
  query GetCartPromotedProducts {
    cartPromotedProducts {
      id
      identifier
      label
      description
      displayOrder
      unitPriceFractional
      currency
      isConfigurable
      hasConfigurations
      horizontalImageUrl
      descriptionImageUrl
      promotionalLabelText
      promotionalLabelFontColor
      promotionalLabelBgColor
      sugarLevel
      nutriGrade
      isLeadTimeBreached
      humanizedLeadTime
      isAvailable
      humanizedAvailability
    }
  }
`;
