import { gql } from "@apollo/client";

export const GET_POINT_BALANCE_QUERY = gql`
  query GetPointBalance {
    pointBalance {
      points
      value
    }
  }
`;
