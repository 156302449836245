/* src/App.jsx */
import React from "react";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfigProvider as AntConfigProvider } from "antd";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { BrowserRouter } from "react-router-dom";

import client from "@/graphql";
import "@/lib/i18n";
import constants from "@/graphql/utils/constants";
import { updateManifest } from "@/lib/updateManifest";

import Routes from "./Routes";
import PopUpModal from "./common/Layout/Header/PopUpModal";
import ReloadPrompt from "./common/ReloadPrompt";

updateManifest();

// Initialize Sentry
Sentry.init({
  debug: import.meta.env.DEV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_APP_VERSION,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tags: {
    client: localStorage.getItem(constants.X_CLIENT_UUID),
  },
});

// Initialize PostHog
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: "https://ph.atlas.kitchen",
  autocapture: false,
  capture_pageview: false,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <PostHogProvider client={posthog}>
        <AntConfigProvider
          theme={{
            token: {
              fontFamily: "var(--font-body) !important",
              fontSize: 14,
            },
          }}
        >
          <BrowserRouter>
            <Routes />
          </BrowserRouter>

          {/* Reload Prompt */}
          <ReloadPrompt />
          <PopUpModal />
        </AntConfigProvider>
      </PostHogProvider>
    </ApolloProvider>
  );
}

export default App;
