import React from "react";

import { RadioGroup as TwRadioGroup } from "@headlessui/react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

RadioGroup.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  renderLabel: PropTypes.func,
  matchById: PropTypes.bool,
  disabledBy: PropTypes.func,
};

RadioGroup.defaultProps = {
  matchById: false,
  disabledBy: () => {},
};

export default function RadioGroup({
  options,
  selected,
  setSelected,
  renderLabel,
  matchById,
  disabledBy,
}) {
  // Allows matching based on ID, useful when the object is injected/modified or different from source options
  const selectedValue = matchById
    ? options.find((option) => selected?.id === option.id)
    : selected;

  return (
    <TwRadioGroup
      value={selectedValue}
      onChange={(newOption) => !newOption.disabled && setSelected(newOption)}
    >
      <div className="-space-y-px rounded-sm bg-default">
        {options.map((option, optionIdx) => (
          <TwRadioGroup.Option
            key={option.id || optionIdx}
            value={option}
            disabled={(disabledBy && disabledBy(option)) || option.disabled}
            className={({ checked, disabled }) =>
              classNames(
                optionIdx === 0 ? "rounded-tl-sm rounded-tr-sm" : "",
                optionIdx === options.length - 1
                  ? "rounded-bl-sm rounded-br-sm"
                  : "border-b-0",
                checked && !disabled ? "bg-faded z-10" : "",
                "flex items-center relative border border-default p-2.5 cursor-pointer focus:outline-none",
                option.disabled || disabled ? "opacity-50" : "",
              )
            }
          >
            {({ active, checked, disabled }) => (
              <>
                <div>
                  <span
                    className={classNames(
                      checked && !disabled
                        ? "bg-primary border-transparent"
                        : "bg-default border-default",
                      active ? "ring-2 ring-offset-2 ring-primary" : "",
                      "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center",
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-default w-1.5 h-1.5" />
                  </span>
                </div>
                <div className="flex flex-col w-full ml-3 text-left">
                  <TwRadioGroup.Label
                    as="div"
                    className="block text-sm font-medium text-default"
                  >
                    {renderLabel ? renderLabel(option) : option.label}
                  </TwRadioGroup.Label>
                  {option.description && (
                    <TwRadioGroup.Description
                      as="div"
                      className={classNames(
                        checked ? "text-primary" : "text-default2",
                        "block text-sm",
                      )}
                    >
                      {option.description}
                    </TwRadioGroup.Description>
                  )}
                </div>
              </>
            )}
          </TwRadioGroup.Option>
        ))}
      </div>
    </TwRadioGroup>
  );
}
